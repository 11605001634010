import React from "react";
import PropTypes from "prop-types";
import "./index.css"; // Assuming you move the styles to a CSS file

const ProgressBar = ({ height = "h-1", position = "absolute" }) => {
  return (
    <div className={`progress ${height} ${position}`}>
      <span className="progress-bar" />
    </div>
  );
};

ProgressBar.propTypes = {
  height: PropTypes.string,
  position: PropTypes.string,
};

export default ProgressBar;

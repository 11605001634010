import { useState, useCallback, useEffect } from "react";
import { BigNumber } from "bignumber.js";
import { useEthereum } from "../contexts/etherruemContext";
import config from "../config";
import { useWallet } from "../contexts/walletContext";
import useLiquidity from "./useLiquidityHelpers";
import useFarmCommon from "./useFarmCommon";
import { useTokenHelpers } from "./useTokenHelpers";
import { EarnOtherFixedAPR } from "../interfaces/config";
import { FixAprLockWthChangeableRatio } from "../interfaces/config";
import { useUnifiedWallet } from "../providers/UnifiedWalletProvider";

type Events = {
  onTransactionHash: (txHash: string) => void;
  onReceipt: (receipt: any) => void;
  onError: (error: any, receipt: any) => void;
};

const useAprFixLockWithChangeableRatio = () => {
  const ethereum = useEthereum();
  const wallet = useWallet();
  const { getBalanceOfLP } = useLiquidity();
  const { getPriceTokenExternalApi } = useFarmCommon();
  const { getAllowance, approveTokenToSpender } = useTokenHelpers();
  const [isReady, setIsReady] = useState(false);

  const { walletApi } = useUnifiedWallet();

  useEffect(() => {
    if (ethereum) {
      setIsReady(true);
    }
  }, [ethereum]);

  const claimLpToken = useCallback(
    async (
      contractAddress: string,
      account: string,
      events: Events
    ): Promise<any> => {
      try {
        const receipt =
          await ethereum.contracts.earnOtherFixedAPRLockRewardWithChangeableRatio[
            contractAddress
          ].methods
            .harvest()
            .send({ from: account })
            .on("transactionHash", (txHash: string) =>
              events.onTransactionHash(txHash)
            )
            .on("receipt", (receipt: any) => events.onReceipt(receipt))
            .on("error", (err: any, receipt: any) => {
              events.onError(err, receipt);
              throw err;
            });

        return receipt;
      } catch (e) {
        console.error("Error: claimLpToken", e);
        return null;
      }
    },
    [ethereum.contracts]
  );

  const depositLpToken = async (
    contractAddress: string,
    account: string,
    inputAmount: BigNumber,
    events: Events
  ): Promise<any> => {
    try {
      const receipt =
        await ethereum.contracts.earnOtherFixedAPRLockRewardWithChangeableRatio[
          contractAddress
        ].methods
          .deposit(inputAmount.toString())
          .send({ from: account })
          .on("transactionHash", (txHash: string) =>
            events.onTransactionHash(txHash)
          )
          .on("receipt", (receipt: any) => events.onReceipt(receipt))
          .on("error", (err: any, receipt: any) => {
            events.onError(err, receipt);
            throw err;
          });

      return receipt;
    } catch (e) {
      console.error("Error: depositLpToken", e);
      return null;
    }
  };

  const withdrawLpToken = async (
    contractAddress: string,
    account: string,
    inputAmount: BigNumber,
    events: Events
  ): Promise<any> => {
    try {
      const receipt =
        await ethereum.contracts.earnOtherFixedAPRLockRewardWithChangeableRatio[
          contractAddress
        ].methods
          .withdraw(inputAmount.toString())
          .send({ from: account })
          .on("transactionHash", (txHash: string) =>
            events.onTransactionHash(txHash)
          )
          .on("receipt", (receipt: any) => events.onReceipt(receipt))
          .on("error", (err: any, receipt: any) => {
            events.onError(err, receipt);
            throw err;
          });

      return receipt;
    } catch (e) {
      console.error("Error: withdrawLpToken", e);
      return null;
    }
  };

  const getAPR = async (contractAddress: string): Promise<BigNumber | null> => {
    try {
      const apr =
        await ethereum.contracts.earnOtherFixedAPRLockRewardWithChangeableRatio[
          contractAddress
        ].methods
          .apr()
          .call();
      return new BigNumber(apr);
    } catch (e) {
      console.error("Error: getAPR", e);
      return null;
    }
  };

  const getTotalStaked = async (
    contractAddress: string
  ): Promise<BigNumber | null> => {
    try {
      const totalStaked =
        await ethereum.contracts.earnOtherFixedAPRLockRewardWithChangeableRatio[
          contractAddress
        ].methods
          .totalStaked()
          .call();
      return new BigNumber(totalStaked);
    } catch (e) {
      console.error("Error: getTotalStaked", e);
      return null;
    }
  };

  const getTokenRatio = async (
    contractAddress: string
  ): Promise<BigNumber | null> => {
    try {
      const tokenRatio =
        await ethereum.contracts.earnOtherFixedAPRLockRewardWithChangeableRatio[
          contractAddress
        ].methods
          .tokenRatio()
          .call();
      return new BigNumber(tokenRatio);
    } catch (e) {
      console.error("Error: getTokenRatio", e);
      return null;
    }
  };

  const getPendingReward = async (
    contractAddress: string,
    account: string
  ): Promise<BigNumber | null> => {
    try {
      const pendingReward =
        await ethereum.contracts.earnOtherFixedAPRLockRewardWithChangeableRatio[
          contractAddress
        ].methods
          .pendingReward(account)
          .call();
      return new BigNumber(pendingReward);
    } catch (e) {
      console.error("Error: getPendingReward", e);
      return null;
    }
  };

  const getCap = async (contractAddress: string): Promise<BigNumber | null> => {
    try {
      const cap =
        await ethereum.contracts.earnOtherFixedAPRLockRewardWithChangeableRatio[
          contractAddress
        ].methods
          .cap()
          .call();
      return new BigNumber(cap);
    } catch (e) {
      console.error("Error: getCap", e);
      return null;
    }
  };

  const getRewardDebt = async (
    contractAddress: string
  ): Promise<boolean | null> => {
    try {
      return await ethereum.contracts.earnOtherFixedAPRLockRewardWithChangeableRatio[
        contractAddress
      ].methods
        .rewardDebt()
        .call();
    } catch (e) {
      console.error("Error: getIsLockWithdraw", e);
      return null;
    }
  };

  const getUserInfo = async (
    contractAddress: string,
    account: string
  ): Promise<any> => {
    try {
      return await ethereum.contracts.earnOtherFixedAPRLockRewardWithChangeableRatio[
        contractAddress
      ].methods
        .userInfo(account)
        .call();
    } catch (e) {
      console.error("Error: getUserInfo", e);
      return null;
    }
  };

  const getStartEndBlock = async (
    contractAddress: string
  ): Promise<{ startBlock: string | null; endBlock: string | null }> => {
    try {
      const [startBlock, endBlock] = await Promise.all([
        ethereum.contracts.earnOtherFixedAPRLockRewardWithChangeableRatio[
          contractAddress
        ].methods
          .startBlock()
          .call(),
        ethereum.contracts.earnOtherFixedAPRLockRewardWithChangeableRatio[
          contractAddress
        ].methods
          .endBlock()
          .call(),
      ]);
      return { startBlock, endBlock };
    } catch (e) {
      console.error("Error: getStartEndBlock", e);
      return { startBlock: null, endBlock: null };
    }
  };

  const getIsUpdateTokenRatio = async (
    contractAddress: string
  ): Promise<BigNumber | null> => {
    try {
      const isUpdateTokenRatio =
        await ethereum.contracts.earnOtherFixedAPRLockRewardWithChangeableRatio[
          contractAddress
        ].methods
          .isUpdateTokenRatio()
          .call();
      return new BigNumber(isUpdateTokenRatio);
    } catch (e) {
      console.error("Error: getCap", e);
      return null;
    }
  };

  const getFarmFixedAPRLockWithChangeableRatioInfo = async (
    index: number,
    poolData?: EarnOtherFixedAPR
  ): Promise<FixAprLockWthChangeableRatio | null> => {
    try {
      poolData = poolData || config.earnOtherFixedAPRLockChangeRatio[index];
      if (!poolData) return null;

      const account = walletApi.account.current;
      if (account === undefined) return null;

      const promises = [
        getTotalStaked(poolData.contractAddress ?? ""),
        getTokenRatio(poolData.contractAddress ?? ""),
        getAPR(poolData.contractAddress ?? ""),
        getCap(poolData.contractAddress ?? ""),
        getRewardDebt(poolData.contractAddress ?? ""),
        getBalanceOfLP(
          poolData.rewardToken ?? "",
          poolData.contractAddress ?? ""
        ),
        getStartEndBlock(poolData.contractAddress ?? ""),
        getIsUpdateTokenRatio(poolData.contractAddress ?? ""),
      ];

      const rewardExternalPricePromise = poolData.rewardExternalPrice
        ? [getPriceTokenExternalApi(poolData.rewardSlug ?? "")]
        : [Promise.resolve(null)];

      const stakingExternalPricePromise = poolData.stakingExternalPrice
        ? [getPriceTokenExternalApi(poolData.stakingSlug ?? "")]
        : [Promise.resolve(null)];

      const extendPromises = account
        ? [
            getBalanceOfLP(poolData.id ?? "", account),
            getUserInfo(poolData.contractAddress ?? "", account),
            getPendingReward(poolData.contractAddress ?? "", account),
          ]
        : [];

      const [
        totalStaked,
        tokenRatio,
        apr,
        cap,
        rewardDebt,
        rewardBalance,
        blockData,
        isUpdateTokenRatio,
        rewardExternalPrice,
        stakingPrice,
        lpBalance,
        userInfo,
        pendingReward,
      ] = await Promise.all([
        ...promises,
        ...rewardExternalPricePromise,
        ...stakingExternalPricePromise,
        ...extendPromises,
      ]);

      const { startBlock, endBlock } = blockData;
      const lpPerUsd = stakingPrice || poolData.stakingPrice;
      let rewardPrice = 0;
      if (isUpdateTokenRatio) {
        rewardPrice = rewardExternalPrice || poolData.rewardPrice;
      } else {
        rewardPrice = tokenRatio
          ? BigNumber(tokenRatio).div(1e18).multipliedBy(lpPerUsd).toNumber()
          : 0;
      }
      // let aprCalbyRewardPrice = new BigNumber(apr).div(1e18).div(100);

      // if (poolData.rewardExternalPrice) {
      //   aprCalbyRewardPrice = aprCalbyRewardPrice
      //     .multipliedBy(rewardPrice || 0)
      //     .div(poolData.rewardPrice || 0);
      // }

      const result: FixAprLockWthChangeableRatio = {
        ...poolData,
        reward: config.token[poolData.rewardToken ?? ""].name, // Adjust according to your tokenInfo structure
        totalStaked: new BigNumber(totalStaked),
        apr: new BigNumber(apr).div(1e18).div(100),
        // rewardExternalPrice: poolData.rewardExternalPrice,
        rewardDebt: new BigNumber(rewardDebt),
        lpPerUsd: new BigNumber(poolData.stakingPrice ?? ""),
        rewardPrice,
        // rewardPrice: rewardPrice || poolData.rewardPrice,
        startBlock,
        endBlock,
        cap: new BigNumber(cap),
        rewardBalance: new BigNumber(rewardBalance),
        earnedToken: pendingReward
          ? new BigNumber(pendingReward)
          : new BigNumber("0"),
        lpBalance: lpBalance ? new BigNumber(lpBalance) : new BigNumber("0"),
        balanceInPool: userInfo?.amount
          ? new BigNumber(userInfo.amount)
          : new BigNumber("0"),
      };

      return result;
    } catch (e) {
      console.log(
        `Err: getFarmFixedAPRLockRewardWithChangeableRatioInfo ${index}`,
        e
      );
      return null;
    }
  };

  const getAllFarmFixedAPRLockWithChangeableRatioInfo = async (): Promise<
    FixAprLockWthChangeableRatio[]
  > => {
    const { earnOtherFixedAPRLockChangeRatio } = config;

    const result = await earnOtherFixedAPRLockChangeRatio.reduce(
      async (
        prev: Promise<FixAprLockWthChangeableRatio[]>,
        cur: EarnOtherFixedAPR,
        index: number
      ) => {
        let acc = await prev;
        const response = await getFarmFixedAPRLockWithChangeableRatioInfo(
          index,
          cur
        );

        if (response) acc = [...acc, response];

        return acc;
      },
      Promise.resolve([])
    );

    return result;
  };

  const getTotalValueLock = async (): Promise<BigNumber> => {
    const { earnOtherFixedAPRLockChangeRatio } = config;

    const tvl = await earnOtherFixedAPRLockChangeRatio.reduce(
      async (prev: Promise<BigNumber>, cur: any) => {
        let acc = await prev;
        const response = await getTotalStaked(cur.contractAddress);

        if (response) {
          let stakingPrice = cur.stakingPrice;
          if (!stakingPrice && cur.stakingExternalPrice) {
            stakingPrice = await getPriceTokenExternalApi(cur.stakingSlug);
          }
          acc = acc.plus(new BigNumber(response).multipliedBy(stakingPrice));
        }

        return acc;
      },
      Promise.resolve(new BigNumber("0"))
    );

    return tvl;
  };

  const depositLpTokenWithCheckAllowance = async (
    code: string,
    lpAddress: string,
    contractAddress: string,
    account: string,
    inputAmount: BigNumber,
    events: Events
  ): Promise<void> => {
    const spender = contractAddress;
    const amount = new BigNumber(inputAmount);

    if (ethereum.contracts) {
      const allowance = await getAllowance(code, account, spender, lpAddress);
      if (allowance && new BigNumber(allowance).isLessThan(amount)) {
        await approveTokenToSpender(lpAddress, account, spender, {
          onTransactionHash: (txHash) => {},
          onReceipt: ({ transactionHash, status }) => {},
          onError: (error: any) => {},
        });
      }

      await depositLpToken(contractAddress, account, inputAmount, events);
    }
  };

  return {
    isReady,
    claimLpToken,
    depositLpToken,
    withdrawLpToken,
    getAPR,
    getTotalStaked,
    getPendingReward,
    getCap,
    getRewardDebt,
    getUserInfo,
    getStartEndBlock,
    getFarmFixedAPRLockWithChangeableRatioInfo,
    getAllFarmFixedAPRLockWithChangeableRatioInfo,
    getTotalValueLock,
    depositLpTokenWithCheckAllowance,
  };
};

export default useAprFixLockWithChangeableRatio;

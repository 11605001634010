import React, { forwardRef, useImperativeHandle, useState } from "react";
import Modal from "../modal";
import {
  formatBigNumber,
  formatBigNumberPercentAbbr,
} from "../../utils/filter";
import { PairEarned } from "../../interfaces/token";
import BigNumber from "bignumber.js";
import Button from "../button";

interface Props {
  onRedirect: (pair: any) => void;
}
const ROIModal = forwardRef((props: Props, ref) => {
  const { onRedirect } = props;
  const [name, setName] = useState<string>("");
  const [resolve, setResolve] = useState<any>(null);
  const [reject, setReject] = useState<any>(null);
  const [models, setModels] = useState<Array<any>>([]);
  const [amount, setAmount] = useState<number>(1000);
  const [lpLink, setLpLink] = useState<string>("");
  const [reward, setReward] = useState<string>("");
  const [pair, setPair] = useState<any>(null);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  useImperativeHandle(ref, () => ({
    open,
  }));

  const confirm = () => {
    resolve({});
    // $refs.modal.close()
    setIsModalOpen(false);
  };
  const close = () => {
    resolve({
      isClose: true,
    });
    setIsModalOpen(false);
  };
  const open = (
    pair: any,
    apr: BigNumber,
    rewardPrice: BigNumber,
    lpLink: any
  ) => {
    setName(pair.staking.name);
    setPair(pair);
    setLpLink(lpLink);
    setReward(pair.reward || "EVRY");

    const periods = [1, 7, 30, 365];
    const tempModels = [];
    const compound = (time: any) => apr.div(365).plus(1).pow(time).minus(1);
    for (let i = 0; i < periods.length; i++) {
      const suffix = i === periods.length - 1 ? "(APY)" : "";
      const percent = compound(periods[i]);
      tempModels.push({
        period: `${periods[i]} Day${i !== 0 ? "s" : ""}${suffix}`,
        roi: percent,
        evryPerPeriod: BigNumber(amount).multipliedBy(percent).div(rewardPrice),
      });
    }
    setModels(tempModels);

    setIsModalOpen(true);
    // $refs.modal.open()

    return new Promise((resolve, reject) => {
      setResolve(() => resolve);
      setReject(() => reject);
    });
  };

  return (
    <Modal
      isShowDialog={isModalOpen}
      widthClass="w-[360px]"
      title="Return on investment"
      onClose={close}
      footer={
        lpLink && (
          <Button className="w-full uppercase" onClick={() => onRedirect(pair)}>
            Get Liquidity
          </Button>
        )
      }
    >
      <div className="w-full flex flex-col gap-6 md:gap-4">
        <table className="w-full text-left">
          <thead>
            <tr className="text-xs md:text-sm">
              <th className="w-2/5 font-medium">Timeframe</th>
              <th className="w-1/5 font-medium">ROI</th>
              <th className="w-2/5 font-medium text-right">
                {reward} per ${amount}
              </th>
            </tr>
          </thead>
          <tbody>
            {models.map((model, id) => (
              <tr key={id} className="text-sm">
                <td className="py-1 pt-4 font-light">{model.period}</td>
                <td className="pt-4 font-light">
                  {formatBigNumberPercentAbbr(model.roi)}%
                </td>
                <td className="text-right pt-4 font-light">
                  {formatBigNumber(model.evryPerPeriod)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Modal>
  );
});

export default ROIModal;

import { ReactNode, useMemo } from "react";
import { WagmiProvider } from "wagmi";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { config } from "../../wagmi.config";

interface EthWalletProviderProps {
  children: ReactNode;
}
const EthWalletProvider = ({ children }: EthWalletProviderProps) => {
  // Ethereum wallet
  const queryClient = useMemo(() => new QueryClient(), []);

  return (
    <WagmiProvider config={config} reconnectOnMount={true}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </WagmiProvider>
  );
};

export default EthWalletProvider;

import React from "react";
import "./text.css"; // Import the CSS

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  type?: string;
  value?: string | number;
  placeholder?: string;
  disabled?: boolean;
  externalClass?: string;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

const InputText: React.FC<InputProps> = ({
  type = "text",
  value = "",
  placeholder = "",
  disabled = false,
  externalClass = "",
  onKeyPress,
  ...rest
}) => {
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // Prevent entering characters for number inputs (e.g., e, +, -)
    if (type === "number" && ["-", "+", "e"].includes(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <input
      className={`bg-transparent rounded focus:outline-none text-white focus:border-velo-pink ${
        !disabled ? "cursor-text" : ""
      } ${externalClass} ${
        !value ? "border border-[#8A8A8A]" : "border border-velo-pink"
      }`}
      type={type}
      value={value}
      placeholder={placeholder}
      disabled={disabled}
      onKeyDown={handleKeyDown}
      onKeyPress={onKeyPress}
      {...rest}
    />
  );
};

export default InputText;

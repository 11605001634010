// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress {
  overflow: hidden;
  left: 10px;
  right: 10px;
}

@media screen and (max-width: 768px) {
  .progress {
    left: 0;
    top: -8px;
    right: 0;
  }
}

.progress .progress-bar {
  display: block;
  height: 100%;
  background: linear-gradient(
    90.03deg,
    rgba(226, 41, 112, 0) 3.01%,
    #e22970 55.13%,
    rgba(227, 41, 112, 0) 97.65%
  );
  background-size: 300% 100%;
  animation: progress-animation 1.75s linear infinite;
}

@keyframes progress-animation {
  0% {
    background-position: 300%;
  }
  100% {
    background-position: 0;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/progressBar/index.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,UAAU;EACV,WAAW;AACb;;AAEA;EACE;IACE,OAAO;IACP,SAAS;IACT,QAAQ;EACV;AACF;;AAEA;EACE,cAAc;EACd,YAAY;EACZ;;;;;GAKC;EACD,0BAA0B;EAC1B,mDAAmD;AACrD;;AAEA;EACE;IACE,yBAAyB;EAC3B;EACA;IACE,sBAAsB;EACxB;AACF","sourcesContent":[".progress {\n  overflow: hidden;\n  left: 10px;\n  right: 10px;\n}\n\n@media screen and (max-width: 768px) {\n  .progress {\n    left: 0;\n    top: -8px;\n    right: 0;\n  }\n}\n\n.progress .progress-bar {\n  display: block;\n  height: 100%;\n  background: linear-gradient(\n    90.03deg,\n    rgba(226, 41, 112, 0) 3.01%,\n    #e22970 55.13%,\n    rgba(227, 41, 112, 0) 97.65%\n  );\n  background-size: 300% 100%;\n  animation: progress-animation 1.75s linear infinite;\n}\n\n@keyframes progress-animation {\n  0% {\n    background-position: 300%;\n  }\n  100% {\n    background-position: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

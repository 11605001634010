import { Config } from "./interfaces/config";
import {
  lp,
  dmmLP,
  farmToken,
  newFarmToken,
  pairs,
  newPairs,
  fixAPRLockTokenChangeRatio,
  fixAPRLockToken,
  earnOtherToken,
  fixAPRToken,
  stakeToken,
  bondToken,
  swapTokens,
  loansDepositTokens,
  loansRepayTokens,
  bridgeTokens,
  solTokens,
  oracleTokens,
  eTokenList,
  eTokenBorrowList,
  token,
} from "./token-config";
import { networks, networkSol } from "./network-config";

const config: Config = {
  chainId: parseInt(process.env.REACT_APP_CHAIN_ID ?? "56"),
  loans: {
    depositTokens: loansDepositTokens,
    repayTokens: loansRepayTokens,
  },
  bridgeTokens,
  swapTokens,
  eTokenList,
  eTokenBorrowList,
  loansCurrency: process.env.REACT_APP_LOANS_CURRENCY,
  solTokens,
  slippagePercentage: process.env.REACT_APP_SLIPPAGE_PERCENTAGE,
  chainNetworkName: process.env.REACT_APP_CHAIN_NETWORK_NAME,
  chainRPC: process.env.REACT_APP_CHAIN_RPC,
  solAppCluster: process.env.REACT_APP_SOL_PP_CLUSTER,
  wormholeSOLChainId: process.env.REACT_APP_WORMHOLE_SOL_CHAIN_ID,
  wormholeBNBChainId: process.env.REACT_APP_WORMHOLE_BNB_CHAIN_ID,
  ethChainRPC: process.env.REACT_APP_ETH_CHAIN_RPC,
  evrynetTokenETH: process.env.REACT_APP_ETH_EVRY_TOKEN,
  solProgramId: process.env.REACT_APP_SOL_PROGRAM_ID,
  releaseController: {
    founderAndTeamAddress: process.env.REACT_APP_ETH_FOUNDER_AND_TEAM_ADDRESS,
    ecosystemAddress: process.env.REACT_APP_ETH_ECOSYSTEM_ADDRESS,
    partnerAddress: process.env.REACT_APP_ETH_PARTNER_ADDRESS,
    advisorAddress: process.env.REACT_APP_ETH_ADVISOR_ADDRESS,
    earlyBackerAddress: process.env.REACT_APP_ETH_EARLY_BACKER_ADDRESS,
    reserveFundAddress: process.env.REACT_APP_ETH_RESERVE_FUND_ADDRESS,
    privateSalesAddress: process.env.REACT_APP_ETH_PRIVATE_SALES_ADDRESS,
  },
  timebaseAddress: process.env.REACT_APP_TIME_BASE_ADDRESS,
  performanceAddress: process.env.REACT_APP_PERFORMANCE_ADDRESS,
  chainCurrencySymbol: process.env.REACT_APP_CHAIN_CURRENCY_SYMBOL,
  chainExplorerURL: process.env.REACT_APP_CHAIN_EXPLORER_URL,
  solChainExplorerURL: process.env.REACT_APP_SOL_CHAIN_EXPLORER_URL,
  ammRouterContractAddress: process.env.REACT_APP_AMM_ROUTER_CONTRACT_ADDRESS,
  dmmRouterContractAddress: process.env.REACT_APP_DMM_ROUTER_CONTRACT_ADDRESS,
  wormholeBSCTokenBridgeAddress:
    process.env.REACT_APP_WORMHOLE_BSC_TOKEN_BRIDGE_ADDRESS,
  wormholeSOLTokenBridgeAddress:
    process.env.REACT_APP_WORMHOLE_SOL_TOKEN_BRIDGE_ADDRESS,
  wormholeBSCBridgeAddress: process.env.REACT_APP_WORMHOLE_BSC_BRIDGE_ADDRESS,
  wormholeSOLBridgeAddress: process.env.REACT_APP_WORMHOLE_SOL_BRIDGE_ADDRESS,
  wormholeSOLMaxVAAUploadRetries:
    process.env.REACT_APP_WORMHOLE_SOL_MAX_VAA_UPLOAD_RETRIES,
  autoRouterContractAddress: process.env.REACT_APP_AUTO_ROUTER_CONTRACT_ADDRESS,
  evryLoansContractAddress: process.env.REACT_APP_EVRYLOANS_CONTRACT_ADDRESS,
  evryLensContractAddress: process.env.REACT_APP_EVRY_LENS_CONTRACT_ADDRESS,
  comptrollerContractAddress:
    process.env.REACT_APP_COMPTROLLER_CONTRACT_ADDRESS,
  treasuryEVHYAddress: process.env.REACT_APP_TREASURY_EVHY_ADDRESS,
  countdownGoal: process.env.REACT_APP_COUNTDOWN_GOAL,
  gas: {
    limitBufferPercentage: process.env.REACT_APP_GAS_LIMIT_BUFFER_PERCENTAGE,
    priceBufferPercentage: process.env.REACT_APP_GAS_PRICE_BUFFER_PERCENTAGE,
    pl: process.env.REACT_APP_GAS_PL_ESTIMATED,
  },
  feeTransfer: process.env.REACT_APP_FEE_TRANSFER,
  txDeadlineDefault: parseInt(process.env.REACT_APP_TX_DEADLINE_DEFAULT ?? "0"),
  blockPerDay: parseInt(process.env.REACT_APP_BLOCK_PER_DAY_ESTIMATED ?? "0"),
  token,
  farmToken,
  newFarmToken,
  earnOtherToken,
  lp,
  dmmLP,
  farm: [
    {
      contractAddress: process.env.REACT_APP_FARM_CONTRACT_ADDRESS,
      rewardToken: farmToken.EVRY_TOKEN,
      pairs: pairs.filter((pair) =>
        process.env.REACT_APP_ENABLE_FEATURE_VELO === "false"
          ? pair.id !== "VELO_TOKEN"
          : pair.id
      ),
      tokens: farmToken,
      ABIName: "Farms",
    },
    {
      contractAddress: process.env.REACT_APP_COMMON_FARM_CONTRACT_ADDRESS,
      rewardToken: newFarmToken.VELO_TOKEN,
      pairs: newPairs,
      tokens: newFarmToken,
    },
  ],
  earnOtherNoFixedAPR: [
    // {
    //   contractAddress: process.env.REACT_APP_EARN_OTHER_VELO_VELO,
    //   rewardToken: earnOtherToken.VELO_TOKEN.code,
    //   id: earnOtherToken.VELO_TOKEN.code,
    //   staking: earnOtherToken.VELO_TOKEN,
    //   lpType: null,
    // },
    // {
    //   contractAddress: process.env.REACT_APP_EARN_OTHER_GGC1_GGC1,
    //   rewardToken: earnOtherToken.GGC1_TOKEN.code,
    //   id: earnOtherToken.GGC1_TOKEN.code,
    //   staking: earnOtherToken.GGC1_TOKEN,
    //   lpType: null,
    // },
  ],
  earnOtherFixedAPR: [
    {
      contractAddress: process.env.REACT_APP_FIX_APR_USDV_VELO,
      rewardToken: fixAPRToken.VELO_TOKEN.code,
      id: fixAPRToken.USDV_TOKEN.code,
      staking: fixAPRToken.USDV_TOKEN,
      rewardPrice: fixAPRToken.VELO_TOKEN.fixedPrice,
      // rewardExternalPrice: fixAPRToken.VELO_TOKEN.externalPrice,
      rewardExternalPrice: true,
      rewardSlug: fixAPRToken.VELO_TOKEN.slug,
      stakingPrice: fixAPRToken.USDV_TOKEN.fixedPrice,
      lpType: null,
    },
  ],
  earnOtherFixedAPRLock: [
    {
      contractAddress: process.env.REACT_APP_FIX_APR_LOCK_VELO_VELO_30D_V4,
      rewardToken: fixAPRLockToken.VELO_TOKEN.code,
      id: fixAPRLockToken.VELO_TOKEN.code,
      staking: fixAPRLockToken.VELO_TOKEN,
      rewardExternalPrice: fixAPRToken.VELO_TOKEN.externalPrice,
      stakingExternalPrice: fixAPRToken.VELO_TOKEN.externalPrice,
      rewardSlug: fixAPRToken.VELO_TOKEN.slug,
      stakingSlug: fixAPRToken.VELO_TOKEN.slug,
      lpType: null,
      isHideTooltip: true,
      isDisabledDeposit: false,
    },
    {
      contractAddress: process.env.REACT_APP_FIX_APR_LOCK_VELO_VELO_30D_V3,
      rewardToken: fixAPRLockToken.VELO_TOKEN.code,
      id: fixAPRLockToken.VELO_TOKEN.code,
      staking: fixAPRLockToken.VELO_TOKEN,
      rewardExternalPrice: fixAPRToken.VELO_TOKEN.externalPrice,
      stakingExternalPrice: fixAPRToken.VELO_TOKEN.externalPrice,
      rewardSlug: fixAPRToken.VELO_TOKEN.slug,
      stakingSlug: fixAPRToken.VELO_TOKEN.slug,
      lpType: null,
      isHideTooltip: true,
      isDisabledDeposit: false,
    },
    {
      contractAddress: process.env.REACT_APP_FIX_APR_LOCK_VELO_VELO_30D_V2,
      rewardToken: fixAPRLockToken.VELO_TOKEN.code,
      id: fixAPRLockToken.VELO_TOKEN.code,
      staking: fixAPRLockToken.VELO_TOKEN,
      rewardExternalPrice: fixAPRToken.VELO_TOKEN.externalPrice,
      stakingExternalPrice: fixAPRToken.VELO_TOKEN.externalPrice,
      rewardSlug: fixAPRToken.VELO_TOKEN.slug,
      stakingSlug: fixAPRToken.VELO_TOKEN.slug,
      lpType: null,
      isHideTooltip: true,
      isDisabledDeposit: false,
    },
    {
      contractAddress: process.env.REACT_APP_FIX_APR_LOCK_VELO_VELO_90D_V1,
      rewardToken: fixAPRLockToken.VELO_TOKEN.code,
      id: fixAPRLockToken.VELO_TOKEN.code,
      staking: fixAPRLockToken.VELO_TOKEN,
      rewardExternalPrice: fixAPRToken.VELO_TOKEN.externalPrice,
      stakingExternalPrice: fixAPRToken.VELO_TOKEN.externalPrice,
      rewardSlug: fixAPRToken.VELO_TOKEN.slug,
      stakingSlug: fixAPRToken.VELO_TOKEN.slug,
      lpType: null,
      isHideTooltip: true,
      isDisabledDeposit: false,
    },
    {
      contractAddress: process.env.REACT_APP_FIX_APR_LOCK_VELO_VELO_90D_V2,
      rewardToken: fixAPRLockToken.VELO_TOKEN.code,
      id: fixAPRLockToken.VELO_TOKEN.code,
      staking: fixAPRLockToken.VELO_TOKEN,
      rewardExternalPrice: fixAPRToken.VELO_TOKEN.externalPrice,
      stakingExternalPrice: fixAPRToken.VELO_TOKEN.externalPrice,
      rewardSlug: fixAPRToken.VELO_TOKEN.slug,
      stakingSlug: fixAPRToken.VELO_TOKEN.slug,
      lpType: null,
      isHideTooltip: true,
      isDisabledDeposit: false,
    },
    {
      contractAddress: process.env.REACT_APP_FIX_APR_LOCK_VELO_VELO_90D_V3,
      rewardToken: fixAPRLockToken.VELO_TOKEN.code,
      id: fixAPRLockToken.VELO_TOKEN.code,
      staking: fixAPRLockToken.VELO_TOKEN,
      rewardExternalPrice: fixAPRToken.VELO_TOKEN.externalPrice,
      stakingExternalPrice: fixAPRToken.VELO_TOKEN.externalPrice,
      rewardSlug: fixAPRToken.VELO_TOKEN.slug,
      stakingSlug: fixAPRToken.VELO_TOKEN.slug,
      lpType: null,
      isHideTooltip: true,
      isDisabledDeposit: false,
    },
    {
      contractAddress: process.env.REACT_APP_FIX_APR_LOCK_VELO_VELO_90D_V4,
      rewardToken: fixAPRLockToken.VELO_TOKEN.code,
      id: fixAPRLockToken.VELO_TOKEN.code,
      staking: fixAPRLockToken.VELO_TOKEN,
      rewardExternalPrice: fixAPRToken.VELO_TOKEN.externalPrice,
      stakingExternalPrice: fixAPRToken.VELO_TOKEN.externalPrice,
      rewardSlug: fixAPRToken.VELO_TOKEN.slug,
      stakingSlug: fixAPRToken.VELO_TOKEN.slug,
      lpType: null,
      isHideTooltip: true,
      isDisabledDeposit: false,
    },
    {
      contractAddress: process.env.REACT_APP_FIX_APR_LOCK_VELO_VELO_90D_V5,
      rewardToken: fixAPRLockToken.VELO_TOKEN.code,
      id: fixAPRLockToken.VELO_TOKEN.code,
      staking: fixAPRLockToken.VELO_TOKEN,
      rewardExternalPrice: fixAPRToken.VELO_TOKEN.externalPrice,
      stakingExternalPrice: fixAPRToken.VELO_TOKEN.externalPrice,
      rewardSlug: fixAPRToken.VELO_TOKEN.slug,
      stakingSlug: fixAPRToken.VELO_TOKEN.slug,
      lpType: null,
      isHideTooltip: true,
      isDisabledDeposit: false,
    },
    {
      contractAddress: process.env.REACT_APP_FIX_APR_LOCK_VELO_VELO_90D_V6,
      rewardToken: fixAPRLockToken.VELO_TOKEN.code,
      id: fixAPRLockToken.VELO_TOKEN.code,
      staking: fixAPRLockToken.VELO_TOKEN,
      rewardExternalPrice: fixAPRToken.VELO_TOKEN.externalPrice,
      stakingExternalPrice: fixAPRToken.VELO_TOKEN.externalPrice,
      rewardSlug: fixAPRToken.VELO_TOKEN.slug,
      stakingSlug: fixAPRToken.VELO_TOKEN.slug,
      lpType: null,
      isHideTooltip: true,
      isDisabledDeposit: false,
    },{
      contractAddress: process.env.REACT_APP_FIX_APR_LOCK_VELO_VELO_180D_V1,
      rewardToken: fixAPRLockToken.VELO_TOKEN.code,
      id: fixAPRLockToken.VELO_TOKEN.code,
      staking: fixAPRLockToken.VELO_TOKEN,
      rewardExternalPrice: fixAPRToken.VELO_TOKEN.externalPrice,
      stakingExternalPrice: fixAPRToken.VELO_TOKEN.externalPrice,
      rewardSlug: fixAPRToken.VELO_TOKEN.slug,
      stakingSlug: fixAPRToken.VELO_TOKEN.slug,
      lpType: null,
      isHideTooltip: true,
      isDisabledDeposit: false,
    },
    {
      contractAddress: process.env.REACT_APP_FIX_APR_LOCK_VELO_VELO_180D_V2,
      rewardToken: fixAPRLockToken.VELO_TOKEN.code,
      id: fixAPRLockToken.VELO_TOKEN.code,
      staking: fixAPRLockToken.VELO_TOKEN,
      rewardExternalPrice: fixAPRToken.VELO_TOKEN.externalPrice,
      stakingExternalPrice: fixAPRToken.VELO_TOKEN.externalPrice,
      rewardSlug: fixAPRToken.VELO_TOKEN.slug,
      stakingSlug: fixAPRToken.VELO_TOKEN.slug,
      lpType: null,
      isHideTooltip: true,
      isDisabledDeposit: false,
    },
    {
      contractAddress: process.env.REACT_APP_FIX_APR_LOCK_VELO_VELO_30D_V1,
      rewardToken: fixAPRLockToken.VELO_TOKEN.code,
      id: fixAPRLockToken.VELO_TOKEN.code,
      staking: fixAPRLockToken.VELO_TOKEN,
      rewardExternalPrice: fixAPRToken.VELO_TOKEN.externalPrice,
      stakingExternalPrice: fixAPRToken.VELO_TOKEN.externalPrice,
      rewardSlug: fixAPRToken.VELO_TOKEN.slug,
      stakingSlug: fixAPRToken.VELO_TOKEN.slug,
      lpType: null,
      isHideTooltip: true,
      isDisabledDeposit: false,
    },
    {
      contractAddress: process.env.REACT_APP_FIX_APR_LOCK_VELO_VELO,
      rewardToken: fixAPRLockToken.VELO_TOKEN.code,
      id: fixAPRLockToken.VELO_TOKEN.code,
      staking: fixAPRLockToken.VELO_TOKEN,
      rewardExternalPrice: fixAPRToken.VELO_TOKEN.externalPrice,
      stakingExternalPrice: fixAPRToken.VELO_TOKEN.externalPrice,
      rewardSlug: fixAPRToken.VELO_TOKEN.slug,
      stakingSlug: fixAPRToken.VELO_TOKEN.slug,
      lpType: null,
      isHideTooltip: true,
      isDisabledDeposit: true,
    },
  ],
  earnOtherFixedAPRLockChangeRatio: [
    {
      contractAddress:
        process.env.REACT_APP_FIX_APR_LOCK_CHANGE_RATIO_USDV_VELO,
      rewardToken: fixAPRLockTokenChangeRatio.VELO_TOKEN.code,
      id: fixAPRLockTokenChangeRatio.USDV_TOKEN.code,
      staking: fixAPRLockTokenChangeRatio.USDV_TOKEN,
      rewardExternalPrice: fixAPRLockTokenChangeRatio.VELO_TOKEN.externalPrice,
      stakingPrice: fixAPRLockTokenChangeRatio.USDV_TOKEN.fixedPrice,
      rewardSlug: fixAPRToken.VELO_TOKEN.slug,
      lpType: null,
      isHideTooltip: true,
      periodFix: "14",
    },
  ],
  IDO: [
    // {
    //   contractAddress: process.env.REACT_APP_IDO_GGC1,
    //   spendingToken: IDOToken.USDT_TOKEN,
    //   id: IDOToken.GGC1_TOKEN.code,
    //   offeringToken: IDOToken.GGC1_TOKEN,
    //   lpType: null,
    // },
  ],
  bond: [
    {
      contractAddress: process.env.REACT_APP_BOND_BUSD_CONTRACT_ADDRESS,
      id: bondToken.BUSD_TOKEN.code,
      token: bondToken.BUSD_TOKEN,
      type: "stable",
    },
    {
      contractAddress: process.env.REACT_APP_BOND_EVUSD_CONTRACT_ADDRESS,
      id: bondToken.EVUSD_TOKEN.code,
      token: bondToken.EVUSD_TOKEN,
      type: "stable",
    },
  ],
  staking: {
    contractAddress: process.env.REACT_APP_STAKING_CONTRACT_ADDRESS,
    token: stakeToken.EVHY_TOKEN,
    rewardToken: stakeToken.SEVHY_TOKEN,
  },
  stakingHelper: {
    contractAddress: process.env.REACT_APP_STAKING_HELPER_CONTRACT_ADDRESS,
  },
  announcement: {
    twitter: process.env.REACT_APP_ANNOUNCEMENT_TWITTER_URL,
    medium: process.env.REACT_APP_ANNOUNCEMENT_MEDIUM_URL,
  },
  social: {
    telegram: process.env.REACT_APP_SOCIAL_TELEGRAM_URL,
    twitter: process.env.REACT_APP_SOCIAL_TWITTER_URL,
    medium: process.env.REACT_APP_SOCIAL_MEDIUM_URL,
  },
  velo: {
    homeUrl: process.env.REACT_APP_VELO_HOME_URL,
  },
  document: {
    home: process.env.REACT_APP_DOCUMENT_HOME_URL,
    learning: process.env.REACT_APP_DOCUMENT_LEARNING_URL,
    tokenomic: process.env.REACT_APP_DOCUMENT_TOKENOMIC_URL,
    roadmap: process.env.REACT_APP_DOCUMENT_ROADMAP_URL,
    liquidity: process.env.REACT_APP_DOCUMENT_LIQUIDITY_POOL_URL,
    amplifier: process.env.REACT_APP_DOCUMENT_AMP_URL,
    autoroute: process.env.REACT_APP_DOCUMENT_AUTO_ROUTE_URL,
    referral: process.env.REACT_APP_DOCUMENT_REFERRAL_URL,
  },
  externalPriceAPI: process.env.REACT_APP_EXTERNAL_PRICE_API,
  enableFeatureVelo: process.env.REACT_APP_ENABLE_FEATURE_VELO,
  solana: {
    goldrush: process.env.REACT_APP_GOLDRUSH_URL,
  },
  perpetual: {
    url: process.env.REACT_APP_PERPETUAL_URL,
  },
  binaryOption: [
    {
      contractAddress:
        process.env.REACT_APP_BINARY_OPTION_BTC_USD_CONTRACT_ADDRESS,
      oracleAddress: process.env.REACT_APP_PRICE_FEED_BTC_USD_CONTRACT_ADDRESS,
      betToken: token.VELO_TOKEN,
      oracleToken: oracleTokens.BTC_TOKEN,
      limit: 50,
      notiLimit: 32,
    },
  ],
  directExchange: [
    {
      contractAddress: process.env.REACT_APP_DIRECT_EXCHANGE_EVRY_VELO,
      originToken: token.EVRY_TOKEN,
      destinationToken: token.VELO_TOKEN,
      pairTokenImage: require("/src/assets/icons/token/velo-evry.svg").default,
      url: "https://www.velo.org/",
    },
    {
      contractAddress: process.env.REACT_APP_DIRECT_EXCHANGE_USDV_OMNI,
      originToken: token.MAIN_USDV_TOKEN,
      destinationToken: token.OMNI_TOKEN,
      pairTokenImage: require("/src/assets/icons/token/omni.svg").default,
      url: "https://www.velo.org/",
    },
  ],
  mlm: {
    backendEndpoint: process.env.REACT_APP_MLM_BACKEND_ENDPOINT,
  },
  networks,
  networkSol,
};

export default config;

import { useState, useCallback, useEffect } from "react";
import { BigNumber } from "bignumber.js";
import { useEthereum } from "../contexts/etherruemContext";
import config from "../config";
import { useWallet } from "../contexts/walletContext";
import useLiquidity from "./useLiquidityHelpers";
import useFarmCommon from "./useFarmCommon";
import { useTokenHelpers } from "./useTokenHelpers";
import { EarnOtherFixedAPR, NoFixApr } from "../interfaces/config";
import { FixAPRToken } from "../interfaces/token";
import { useUnifiedWallet } from "../providers/UnifiedWalletProvider";

type Events = {
  onTransactionHash: (txHash: string) => void;
  onReceipt: (receipt: any) => void;
  onError: (error: any, receipt: any) => void;
};

const useAprNoFixHelper = () => {
  const ethereum = useEthereum();
  const wallet = useWallet();
  const { getBalanceOfLP, getLpTotalSupply } = useLiquidity();
  const { getPriceTokenExternalApi, getMapIDofReserves, getPrice } =
    useFarmCommon();
  const { getAllowance, approveTokenToSpender } = useTokenHelpers();
  const [isReady, setIsReady] = useState(false);
  const { walletApi } = useUnifiedWallet();

  useEffect(() => {
    if (ethereum) {
      setIsReady(true);
    }
  }, [ethereum]);

  const claimLpToken = useCallback(
    async (
      contractAddress: string,
      account: string,
      events: Events
    ): Promise<any> => {
      try {
        const receipt = await ethereum.contracts.earnOtherNoFixedAPR[
          contractAddress
        ].methods
          .harvest()
          .send({ from: account })
          .on("transactionHash", (txHash: string) =>
            events.onTransactionHash(txHash)
          )
          .on("receipt", (receipt: any) => events.onReceipt(receipt))
          .on("error", (err: any, receipt: any) => {
            events.onError(err, receipt);
            throw err;
          });

        return receipt;
      } catch (e) {
        console.error("Error: claimLpToken", e);
        return null;
      }
    },
    [ethereum.contracts]
  );

  const depositLpToken = async (
    contractAddress: string,
    account: string,
    inputAmount: BigNumber,
    events: Events
  ): Promise<any> => {
    try {
      const receipt = await ethereum.contracts.earnOtherNoFixedAPR[
        contractAddress
      ].methods
        .deposit(inputAmount.toString())
        .send({ from: account })
        .on("transactionHash", (txHash: string) =>
          events.onTransactionHash(txHash)
        )
        .on("receipt", (receipt: any) => events.onReceipt(receipt))
        .on("error", (err: any, receipt: any) => {
          events.onError(err, receipt);
          throw err;
        });

      return receipt;
    } catch (e) {
      console.error("Error: depositLpToken", e);
      return null;
    }
  };

  const withdrawLpToken = async (
    contractAddress: string,
    account: string,
    inputAmount: BigNumber,
    events: Events
  ): Promise<any> => {
    try {
      const receipt = await ethereum.contracts.earnOtherNoFixedAPR[
        contractAddress
      ].methods
        .withdraw(inputAmount.toString())
        .send({ from: account })
        .on("transactionHash", (txHash: string) =>
          events.onTransactionHash(txHash)
        )
        .on("receipt", (receipt: any) => events.onReceipt(receipt))
        .on("error", (err: any, receipt: any) => {
          events.onError(err, receipt);
          throw err;
        });

      return receipt;
    } catch (e) {
      console.error("Error: withdrawLpToken", e);
      return null;
    }
  };

  const getAPR = async (contractAddress: string): Promise<BigNumber | null> => {
    try {
      const apr = await ethereum.contracts.earnOtherNoFixedAPR[
        contractAddress
      ].methods
        .apr()
        .call();
      return new BigNumber(apr);
    } catch (e) {
      console.error("Error: getAPR", e);
      return null;
    }
  };

  const getTotalStaked = async (
    contractAddress: string
  ): Promise<BigNumber | null> => {
    try {
      const totalStaked = await ethereum.contracts.earnOtherNoFixedAPR[
        contractAddress
      ].methods
        .totalStaked()
        .call();
      return new BigNumber(totalStaked);
    } catch (e) {
      console.error("Error: getTotalStaked", e);
      return null;
    }
  };

  const getRewardPerBlock = async (
    contractAddress: string
  ): Promise<BigNumber | null> => {
    try {
      const rewardPerBlock = await ethereum.contracts.earnOtherNoFixedAPR[
        contractAddress
      ].methods
        .rewardPerBlock()
        .call();
      return new BigNumber(rewardPerBlock);
    } catch (e) {
      console.error("Error: getRewardPerBlock", e);
      return null;
    }
  };

  const getLpTokenPrice = async (staking: FixAPRToken) => {
    if (staking.externalPrice) {
      return BigNumber(await getPriceTokenExternalApi(staking.slug ?? ""));
    } else if (staking.fixedPrice) {
      return BigNumber(staking.fixedPrice);
    } else if ("lpType" in staking) {
      return getLpPrice(staking);
    }
  };

  const getRewardPriceUSD = (rewardTokenData: FixAPRToken) => {
    if (rewardTokenData.externalPrice) {
      return getPriceTokenExternalApi(rewardTokenData.slug ?? "");
    } else if (rewardTokenData.fixedPrice) {
      return rewardTokenData.fixedPrice;
    } else {
      return getTokenPrice(rewardTokenData);
    }
  };

  const getTokenPrice = async (tokenData: FixAPRToken) => {
    // TODO: refactor mapIDofReserves to global state
    const pairIds = Object.keys(config.lp);
    const mapIDofReserves = await getMapIDofReserves(pairIds);
    const token = {
      [tokenData.code]: tokenData,
    };
    const tokenPrice = await getPrice(token, mapIDofReserves);

    return tokenPrice[tokenData.symbol];
  };

  const getLpPrice = async (staking: any) => {
    // TODO: refactor mapIDofReserves to global state?
    const pairIds = Object.keys(config.lp);
    const mapIDofReserves = await getMapIDofReserves(pairIds);
    const token0 = config.earnOtherToken[staking.token0.code];
    const token1 = config.earnOtherToken[staking.token1.code];
    const token = {
      [staking.token0.code]: token0,
      [staking.token1.code]: token1,
    };
    const tokenPrice = await getPrice(token, mapIDofReserves);

    const totalSupply = await getLpTotalSupply(staking.code);

    const reserveData = mapIDofReserves[staking.code];
    const token1Price = tokenPrice[staking.token0.symbol];
    const token2Price = tokenPrice[staking.token1.symbol];

    const reserveTotalUsd0 =
      reserveData.reserves[staking.token0.code].multipliedBy(token1Price);
    const reserveTotalUsd1 =
      reserveData.reserves[staking.token1.code].multipliedBy(token2Price);
    const reserveTotalUsd = reserveTotalUsd0.plus(reserveTotalUsd1);

    const lpPerUsd = reserveTotalUsd.div(totalSupply);

    return lpPerUsd;
  };

  const getPendingReward = async (
    contractAddress: string,
    account: string
  ): Promise<BigNumber | null> => {
    try {
      const pendingReward = await ethereum.contracts.earnOtherNoFixedAPR[
        contractAddress
      ].methods
        .pendingReward(account)
        .call();
      return new BigNumber(pendingReward);
    } catch (e) {
      console.error("Error: getPendingReward", e);
      return null;
    }
  };

  const getCap = async (contractAddress: string): Promise<BigNumber | null> => {
    try {
      const cap = await ethereum.contracts.earnOtherNoFixedAPR[
        contractAddress
      ].methods
        .cap()
        .call();
      return new BigNumber(cap);
    } catch (e) {
      console.error("Error: getCap", e);
      return null;
    }
  };

  const getIsLockWithdraw = async (
    contractAddress: string
  ): Promise<boolean | null> => {
    try {
      return await ethereum.contracts.earnOtherNoFixedAPR[
        contractAddress
      ].methods
        .isLockWithdraw()
        .call();
    } catch (e) {
      console.error("Error: getIsLockWithdraw", e);
      return null;
    }
  };

  const getUserInfo = async (
    contractAddress: string,
    account: string
  ): Promise<any> => {
    try {
      return await ethereum.contracts.earnOtherNoFixedAPR[
        contractAddress
      ].methods
        .userInfo(account)
        .call();
    } catch (e) {
      console.error("Error: getUserInfo", e);
      return null;
    }
  };

  const getStartEndBlock = async (
    contractAddress: string
  ): Promise<{ startBlock: string | null; endBlock: string | null }> => {
    try {
      const [startBlock, endBlock] = await Promise.all([
        ethereum.contracts.earnOtherNoFixedAPR[contractAddress].methods
          .startBlock()
          .call(),
        ethereum.contracts.earnOtherNoFixedAPR[contractAddress].methods
          .endBlock()
          .call(),
      ]);
      return { startBlock, endBlock };
    } catch (e) {
      console.error("Error: getStartEndBlock", e);
      return { startBlock: null, endBlock: null };
    }
  };

  const getRewardPerYearUSD = (rewardPerBlock: any) => {
    return BigNumber(rewardPerBlock).multipliedBy(config.blockPerDay * 365);
  };

  // const getFarmEarnOtherInfo = async (
  //   index: number,
  //   poolData?: EarnOtherFixedAPR
  // ): Promise<NoFixApr | null> => {
  //   try {
  //     poolData = poolData || config.earnOtherNoFixedAPR[index];
  //     if (!poolData) return null;

  //     const account = wallet.state.address;
  //     const rewardTokenData = config.earnOtherToken[poolData.rewardToken ?? ""];

  //     const promises = [
  //       getTotalStaked(poolData.contractAddress ?? ""),
  //       getRewardPerBlock(poolData.contractAddress ?? ""),
  //       getLpTokenPrice(poolData.staking ?? ""),
  //       getRewardPriceUSD(rewardTokenData),
  //     ];

  //     // const rewardExternalPricePromise = poolData.rewardExternalPrice
  //     //   ? [getPriceTokenExternalApi(poolData.rewardSlug ?? "")]
  //     //   : [Promise.resolve(null)];

  //     const extendPromises = account
  //       ? [
  //           getBalanceOfLP(poolData.id ?? "", account),
  //           getUserInfo(poolData.contractAddress ?? "", account),
  //           getPendingReward(poolData.contractAddress ?? "", account),
  //         ]
  //       : [];

  //     const [
  //       totalStaked,
  //       rewardPerBlock,
  //       lpPerUsd,
  //       rewardPrice,
  //       lpBalance,
  //       userInfo,
  //       pendingReward,
  //     ] = await Promise.all([...promises, ...extendPromises]);

  //     // const { startBlock, endBlock } = blockData;

  //     const rewardPerYearUSD =
  //       getRewardPerYearUSD(rewardPerBlock).multipliedBy(rewardPrice);
  //     const apr = rewardPerYearUSD.div(
  //       lpPerUsd.multipliedBy(BigNumber(totalStaked))
  //     );

  //     const result: NoFixApr = {
  //       ...poolData,
  //       reward: config.token[poolData.rewardToken ?? ""].name, // Adjust according to your tokenInfo structure
  //       totalStaked: new BigNumber(totalStaked),
  //       apr,
  //       lpPerUsd,
  //       earnedToken: pendingReward
  //         ? new BigNumber(pendingReward)
  //         : new BigNumber("0"),
  //       lpBalance: lpBalance ? new BigNumber(lpBalance) : new BigNumber("0"),
  //       balanceInPool: userInfo?.amount
  //         ? new BigNumber(userInfo.amount)
  //         : new BigNumber("0"),
  //       lastRewardBlock: userInfo?.lastRewardBlock || "0",
  //       rewardPrice,
  //     };

  //     return result;
  //   } catch (e) {
  //     console.log(`Err: getFarmFixedAPRInfo ${index}`, e);
  //     return null;
  //   }
  // };

  // const getAllFarmEarnOtherInfo = async (): Promise<NoFixApr[]> => {
  //   const { earnOtherNoFixedAPR } = config;

  //   const result = await earnOtherNoFixedAPR.reduce(
  //     async (
  //       prev: Promise<NoFixApr[]>,
  //       cur: EarnOtherFixedAPR,
  //       index: number
  //     ) => {
  //       let acc = await prev;
  //       const response = await getFarmEarnOtherInfo(index, cur);

  //       if (response) acc = [...acc, response];

  //       return acc;
  //     },
  //     Promise.resolve([])
  //   );

  //   return result;
  // };

  const getTotalValueLock = async (): Promise<BigNumber> => {
    const { earnOtherNoFixedAPR } = config;

    const tvl = await earnOtherNoFixedAPR.reduce(
      async (prev: Promise<BigNumber>, cur: any) => {
        let acc = await prev;
        const response = await getTotalStaked(cur.contractAddress);

        if (response) {
          let stakingPrice = cur.stakingPrice;
          if (!stakingPrice && cur.stakingExternalPrice) {
            stakingPrice = await getPriceTokenExternalApi(cur.stakingSlug);
          }
          acc = acc.plus(new BigNumber(response).multipliedBy(stakingPrice));
        }

        return acc;
      },
      Promise.resolve(new BigNumber("0"))
    );

    return tvl;
  };

  const depositLpTokenWithCheckAllowance = async (
    code: string,
    lpAddress: string,
    contractAddress: string,
    account: string,
    inputAmount: BigNumber,
    events: Events
  ): Promise<void> => {
    const spender = contractAddress;
    const amount = new BigNumber(inputAmount);

    if (ethereum.contracts) {
      const allowance = await getAllowance(code, account, spender, lpAddress);
      if (allowance && new BigNumber(allowance).isLessThan(amount)) {
        await approveTokenToSpender(lpAddress, account, spender, {
          onTransactionHash: (txHash) => {},
          onReceipt: ({ transactionHash, status }) => {},
          onError: (error: any) => {},
        });
      }

      await depositLpToken(contractAddress, account, inputAmount, events);
    }
  };

  const getFarmEarnOtherInfo = async (
    index: number,
    poolData?: EarnOtherFixedAPR
  ): Promise<NoFixApr | null> => {
    try {
      const { earnOtherNoFixedAPR } = config;
      const account = walletApi.account.current;
      if (account === undefined) return null;
      // if (!poolData && !earnOtherNoFixedAPR) return {};
      if (!poolData) poolData = earnOtherNoFixedAPR[index];
      // poolData = poolData || earnOtherNoFixedAPR[index];
      // if (!poolData) return {};
      const rewardTokenData = config.earnOtherToken[poolData.rewardToken ?? ""];

      const promises = [
        getTotalStaked(poolData.contractAddress ?? ""),
        getRewardPerBlock(poolData.contractAddress ?? ""),
        getLpTokenPrice(poolData.staking),
        getRewardPriceUSD(rewardTokenData),
      ];

      const extendPromises = account
        ? [
            getBalanceOfLP(poolData.id ?? "", account),
            getUserInfo(poolData.contractAddress ?? "", account),
            getPendingReward(poolData.contractAddress ?? "", account),
          ]
        : [];

      const [
        totalStaked,
        rewardPerBlock,
        lpPerUsd,
        rewardPrice,
        lpBalance,
        userInfo,
        pendingReward,
      ] = await Promise.all([...promises, ...extendPromises]);

      const rewardPerYearUSD =
        getRewardPerYearUSD(rewardPerBlock).multipliedBy(rewardPrice);
      const apr = rewardPerYearUSD.div(
        lpPerUsd.multipliedBy(BigNumber(totalStaked))
      );

      const result = {
        ...poolData,
        reward: config.token[poolData.rewardToken ?? ""].name,
        totalStaked: BigNumber(totalStaked),
        apr,
        lpPerUsd,
        earnedToken: pendingReward ? BigNumber(pendingReward) : BigNumber("0"),
        lpBalance: lpBalance ? BigNumber(lpBalance) : BigNumber("0"),
        balanceInPool: userInfo ? BigNumber(userInfo.amount) : BigNumber("0"),
        lastRewardBlock: userInfo?.lastRewardBlock || "0",
        rewardPrice,
      };

      return result;
    } catch (e) {
      console.log(`Err: getFarmInfo ${index}`, e);
      return null;
    }
  };

  const getAllFarmEarnOtherInfo = async () => {
    const { earnOtherNoFixedAPR } = config;

    const result = await earnOtherNoFixedAPR.reduce(
      async (prev: Promise<NoFixApr[]>, cur, index) => {
        let acc = await prev;

        const response = await getFarmEarnOtherInfo(index, cur);

        if (response) acc = [...acc, response];

        return acc;
      },
      Promise.resolve([])
    );

    return result;
  };

  return {
    isReady,
    claimLpToken,
    depositLpToken,
    withdrawLpToken,
    getAPR,
    getTotalStaked,
    getPendingReward,
    getCap,
    getIsLockWithdraw,
    getUserInfo,
    getStartEndBlock,
    getFarmEarnOtherInfo,
    getAllFarmEarnOtherInfo,
    getTotalValueLock,
    depositLpTokenWithCheckAllowance,
  };
};

export default useAprNoFixHelper;

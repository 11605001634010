import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import PageHeader from "../../components/pageHeader";
import Button from "../../components/button";
import config from "../../config";
import BigNumber from "bignumber.js";
import { useWallet } from "../../contexts/walletContext";
import useWalletHelpers from "../../hooks/useWalletHelpers";
import { digit, networkType } from "../../constants";
import {
  formatBigNumber,
  formatEther,
  formatNumberWithMinMaxDigits,
} from "../../utils/filter";
import { useTokenHelpers } from "../../hooks/useTokenHelpers";
import useDirectExchangeHelpers from "../../hooks/useDirectExchangeHelpers";
import { useMainConstant } from "../../contexts/mainContext";
import { useEthereum } from "../../contexts/etherruemContext";
import { useNotification } from "../../contexts/notificationContext";
import { toWei } from "../../utils/token";
import ConfirmDirectExchangeModal from "../../components/swap/confirmExchangModal";
import { useUnifiedWallet } from "../../providers/UnifiedWalletProvider";
import { DirectExchange as IDirectExchange } from "../../interfaces/config";

export interface DirectExchangeDetail {
  configExchange: IDirectExchange;
  poolBalances: BigNumber;
  myOriginTokenBalance: BigNumber;
  myDestinationTokenBalance: BigNumber;
  exchangeRate: BigNumber;
}

// const directExchangeDetail.configExchange = config.directExchange[0];

export interface Props {
  index: number;
}

const toPrecision = (value: string, digit: number = 8) => {
  return BigNumber(value).toFixed(digit, 1).toString();
};

const DirectExchange: React.FC<Props> = ({ index }) => {
  const [isPending, setIsPending] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  // const [timer, setTimer] = useState(null);
  // const [pause, setPause] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [displayBalanceTokenA, setDisplayBalanceTokenA] = useState("0.00");
  const [displayBalanceTokenB, setDisplayBalanceTokenB] = useState("0.00");
  // const [directExchangeDetail, setDirectExchangeDetail] = useState();

  // const wallet = useWallet();
  const { addTokenOnWallet } = useWalletHelpers();
  const { checkAllowanceToSpender } = useTokenHelpers();
  const { getAllExchangeInfo } = useDirectExchangeHelpers();
  const { approveTokenToSpender } = useTokenHelpers();

  const mainConstant = useMainConstant();
  const ethereum = useEthereum();
  const { setNotify } = useNotification();
  const { exchange } = useDirectExchangeHelpers();
  const { walletApi } = useUnifiedWallet();

  useEffect(() => {
    initialDirectExchangeDetail();
    const intervalId = setInterval(() => {
      fetchData();
    }, 5000);
    fetchData();

    return () => clearInterval(intervalId);
  }, [
    walletApi.account.current,
    ethereum?.contracts,
    ethereum?.contracts?.directExchange,
  ]);

  const initialDirectExchangeDetail = () => {
    return {
      configExchange: config.directExchange[index],
      poolBalances: BigNumber("0"),
      myOriginTokenBalance: BigNumber("0"),
      myDestinationTokenBalance: BigNumber("0"),
      exchangeRate: BigNumber("0"),
      pause: false,
    };
  };

  const [directExchangeDetail, setDirectExchangeDetail] =
    useState<DirectExchangeDetail>(initialDirectExchangeDetail());

  const fetchData = async () => {
    // const isValidChain = await walletApi.isValidChain();
    // console.log("fetchData isValidChain", isValidChain);
    // if (!isValidChain) {
    //   setDirectExchangeDetail(initialDirectExchangeDetail());
    //   return;
    // }
    await checkStatus();
    const [result]: any = await getAllExchangeInfo();
    setDirectExchangeDetail((prev) => {
      return { ...prev, ...result };
    });
    // if (result.paused) {
    //   $refs?.pausedModal?.open();
    // } else {
    //   $refs?.pausedModal?.close();
    // }
  };

  const checkStatus = useCallback(async () => {
    const account = walletApi.account.current;
    if (!account) return;
    try {
      const result = await checkAllowanceToSpender(
        directExchangeDetail.configExchange.originToken.code,
        account,
        directExchangeDetail.configExchange.contractAddress ?? ""
      );
      console.log("checkStatus", result);
      setIsApproved(result);
    } catch (err) {
      console.error(err);
    }
  }, [
    walletApi.account,
    directExchangeDetail.configExchange.originToken,
    directExchangeDetail.configExchange.contractAddress,
    checkAllowanceToSpender,
  ]);

  useEffect(() => {
    if (walletApi.account.current) {
      getBalances();
    } else {
      setDisplayBalanceTokenA("0.00");
      setDisplayBalanceTokenB("0.00");
    }
  }, [walletApi.account.current, directExchangeDetail]);

  const getBalances = async () => {
    try {
      console.log(
        "getBalances",
        directExchangeDetail.configExchange.originToken
      );
      if (
        directExchangeDetail.configExchange.originToken &&
        directExchangeDetail.configExchange.originToken?.symbol &&
        directExchangeDetail.configExchange.originToken?.symbol !== "" &&
        ethereum?.web3
      ) {
        console.log(
          "getBalances",
          directExchangeDetail.configExchange.originToken
        );
        // tokenA.balance = await tokenBalanceOf(tokenA.symbol)
        const balance = await tokenBalanceOf(
          directExchangeDetail.configExchange.originToken.code
        );
        console.log("getBalances", balance);
        if (balance) {
          setDisplayBalanceTokenA(
            toPrecision(
              ethereum?.web3.utils.fromWei(balance, "ether").toLocaleString(),
              4
            )
          );
        }
      }

      console.log(
        "getBalances",
        directExchangeDetail.configExchange.destinationToken
      );
      if (
        directExchangeDetail.configExchange.destinationToken &&
        directExchangeDetail.configExchange.destinationToken?.symbol &&
        directExchangeDetail.configExchange.destinationToken?.symbol !== "" &&
        ethereum?.web3
      ) {
        console.log(
          "getBalances",
          directExchangeDetail.configExchange.originToken
        );
        // tokenB.balance = await tokenBalanceOf(tokenB.symbol)
        const balance = await tokenBalanceOf(
          directExchangeDetail.configExchange.destinationToken.code
        );

        console.log("getBalances", balance);
        if (balance) {
          setDisplayBalanceTokenB(
            toPrecision(
              ethereum?.web3.utils.fromWei(balance, "ether").toLocaleString(),
              4
            )
          );
        }
      }
    } catch (error) {
      console.error("getBalances", error);
    }
  };

  const tokenBalanceOf = async (tokenSymbol: string) => {
    try {
      if (tokenSymbol === "BNB_TOKEN" && walletApi.account.current) {
        return await ethereum.web3?.eth.getBalance(walletApi.account.current);
      }
      console.log(
        "ethereum.contracts.tokens",
        tokenSymbol,
        ethereum.contracts.tokens
      );

      return await ethereum.contracts.tokens[`${tokenSymbol}`].methods
        .balanceOf(walletApi.account.current)
        .call();
    } catch {
      console.error("tokenBalanceOf", tokenSymbol);
    }
  };

  const addToken = () => {
    const { address, image, name } =
      directExchangeDetail.configExchange.destinationToken;

    addTokenOnWallet(
      address ?? "",
      name,
      image ?? "",
      digit.TOKEN_DIGIT,
      networkType.ERC20,
      {
        onReceipt: () => console.log("added!"),
        onError: (error) => console.log(error),
      }
    );
  };

  const destinationPerOrigin = useMemo(() => {
    return parseFloat(directExchangeDetail.exchangeRate.toString()) > 0
      ? BigNumber(1).div(directExchangeDetail.exchangeRate)
      : BigNumber("0");
  }, [directExchangeDetail?.exchangeRate]);

  const unlockWallet = () => {
    mainConstant.setIsShowConnectModal(true);
  };

  const isInSufficientPoolBalances = useMemo(() => {
    return directExchangeDetail.poolBalances.isZero();
  }, [directExchangeDetail?.poolBalances]);

  const onUpdateNotification = () => {
    return {
      onTransactionHash: (txHash: any) => {
        setNotify({
          status: "processing",
          txHash,
        });
      },
      onError: (_: any, receipt?: any) => {
        setIsPending(false);
        setNotify({
          status: "failed",
          txHash: receipt ? receipt.transactionHash : null,
        });
      },
      onReceipt: ({
        transactionHash,
        status,
      }: {
        transactionHash: any;
        status: boolean;
      }) => {
        setNotify({
          status: status ? "success" : "failed",
          txHash: transactionHash,
        });
        checkStatus().finally(() => {
          setIsPending(false);
        });
      },
    };
  };

  const onExchange = async (inputAmount: string) => {
    // const { confirmed, inputAmount } =
    //   await $refs.swapConfirmDirectExchange.open()
    // if (!confirmed) return
    try {
      setIsPending(true);
      const account = walletApi.account.current ?? "";
      await exchange(
        directExchangeDetail.configExchange.contractAddress ?? "",
        account,
        toWei(inputAmount),
        onUpdateNotification()
      );
      fetchData();
    } catch (err) {
      console.error(err);
    } finally {
      setIsPending(false);
    }
  };

  const approve = async () => {
    setIsPending(true);
    const account = walletApi.account.current ?? "";
    try {
      console.log("approve 1");
      await approveTokenToSpender(
        directExchangeDetail.configExchange.originToken.address ?? "",
        account,
        directExchangeDetail.configExchange.contractAddress ?? "",
        onUpdateNotification()
      );
      console.log("approve 2");
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="md:container p-0">
      <div className="xl:ml-32 xl:max-w-[976px]">
        <PageHeader
          title="Direct Exchange"
          subTitle="Convert your tokens directly with the fixed platform exchange rate"
        ></PageHeader>

        <div className="mx-4 mb-6 md:mx-0 p-4 bg-[#909090] bg-opacity-5 rounded-[2px] border border-[#19506F]">
          <div className="grid grid-rows-1 xl:grid-cols-2 gap-4">
            <div className="flex items-center">
              <img
                className="mr-2 h-8 w-8"
                src={directExchangeDetail.configExchange.destinationToken.image}
                alt={directExchangeDetail.configExchange.destinationToken.name}
              />
              <div>
                <div className="text-sm mb-1">
                  {directExchangeDetail.configExchange.destinationToken.name}
                </div>
                <div>
                  My balance:{" "}
                  <span className="text-number">{displayBalanceTokenB}</span>
                </div>
              </div>
            </div>
            <div className="hidden xl:flex items-center ml-auto">
              <div className="flex flex-col">
                <div className="text-sm text-velo-label">
                  {directExchangeDetail.configExchange.destinationToken.name}{" "}
                  available in pool
                </div>
                <div className="text-right mt-1 text-number">
                  {formatBigNumber(directExchangeDetail.poolBalances, 2)}
                </div>
              </div>
            </div>
            <div className="xl:hidden rounded-[2px] p-[10px] bg-[#909090] bg-opacity-5">
              <div className="text-sm text-velo-label">
                {directExchangeDetail.configExchange.destinationToken.name}{" "}
                available in pool
              </div>
              <div className="text-right text-number">
                {formatBigNumber(directExchangeDetail.poolBalances, 2)}
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 xl:grid-cols-3 gap-4 mt-4 xl:mt-6">
            <div className="rounded-sm p-2.5 bg-gray-500 bg-opacity-5">
              <div className="text-velo-label text-sm flex items-center">
                <img
                  className="mr-1 h-4 w-4"
                  src={directExchangeDetail.configExchange.originToken.image}
                  alt={directExchangeDetail.configExchange.originToken.name}
                />
                My {directExchangeDetail.configExchange.originToken.name}{" "}
                balance
              </div>
              <div className="text-right mt-1 text-number">
                {displayBalanceTokenA}
              </div>
            </div>

            <div className="rounded-sm p-2.5 bg-gray-500 bg-opacity-5">
              <div className="text-velo-label text-sm flex items-center">
                <img
                  className="mr-1 h-4 w-4"
                  src={
                    directExchangeDetail.configExchange.destinationToken.image
                  }
                  alt={
                    directExchangeDetail.configExchange.destinationToken.name
                  }
                />
                My {directExchangeDetail.configExchange.destinationToken.name}{" "}
                balance
              </div>
              <div className="text-right mt-1 text-number">
                {displayBalanceTokenB}
              </div>
            </div>

            <div className="rounded-sm p-2.5 bg-gray-500 bg-opacity-5">
              <div className="text-velo-label text-sm flex items-center">
                <img
                  className="mr-1 h-4"
                  src={directExchangeDetail.configExchange.pairTokenImage}
                  alt="Pair Token"
                />
                Price 1{" "}
                {directExchangeDetail.configExchange.destinationToken.name} per{" "}
                {directExchangeDetail.configExchange.originToken.name}
              </div>
              <div className="text-right mt-1 text-number">
                {formatNumberWithMinMaxDigits(
                  parseFloat(destinationPerOrigin.toString()),
                  4,
                  8
                )}
              </div>
            </div>

            <div className="flex items-center justify-center w-full xl:col-start-2">
              {/* className="w-full uppercase"  */}
              {/* <Button className="w-full uppercase" onClick={unlockWallet}>
                Unlock Wallet
              </Button> */}
              {!walletApi.isConnected ? (
                <Button className="w-full uppercase" onClick={unlockWallet}>
                  UNLOCK WALLET
                </Button>
              ) : isInSufficientPoolBalances ? (
                <Button className="w-full uppercase" disabled>
                  Out of{" "}
                  {directExchangeDetail.configExchange.destinationToken.name}
                </Button>
              ) : isPending ? (
                <Button disabled className="w-full uppercase">
                  Processing...
                </Button>
              ) : !isApproved ? (
                <Button className="w-full uppercase" onClick={approve}>
                  Approve {directExchangeDetail.configExchange.originToken.name}
                </Button>
              ) : (
                <Button
                  className="w-full uppercase"
                  onClick={() => setIsConfirmModalOpen(true)}
                >
                  Exchange
                </Button>
              )}
            </div>
          </div>

          <div className="flex items-center justify-center mt-5">
            <div
              className="flex items-center cursor-pointer"
              onClick={addToken}
            >
              <span className="text-lg text-transparent bg-clip-text bg-velo-primary-gradient4 mr-1">
                →
              </span>
              <div className="text-sm">
                Add {directExchangeDetail.configExchange.destinationToken.name}{" "}
                token to wallet
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Assuming there's a SwapConfirmDirectExchange component */}
      <ConfirmDirectExchangeModal
        // ref={swapConfirmDirectExchange}
        onDirectExchange={onExchange}
        isModalOpen={isConfirmModalOpen}
        setIsModalOpen={setIsConfirmModalOpen}
        directExchangeDetail={directExchangeDetail}
        configExchange={directExchangeDetail.configExchange}
        destinationPerOrigin={destinationPerOrigin}
      />
    </div>
  );
};

export default DirectExchange;

import React, {
  ReactNode,
  useState,
  createContext,
  useContext,
  useMemo,
  useCallback,
  useEffect,
} from "react";
import { useSolanaWalletApi } from "../hooks/walletConnector/solana/useSolanaWalletApi";
import { useEthWalletApi } from "../hooks/walletConnector/eth/useEthWalletApi";
import {
  ChainDetailType,
  DepositToken,
  WalletDetailType,
  WithdrawToken,
} from "../interfaces/wallet";
import environment from "../environment";
import message from "../lib/components/message";
// import { useTronWalletApi } from "../hooks/walletConnector/tron/useTronWalletApi";

interface UnifiedWalletProviderProps {
  children: ReactNode;
}

interface UnifiedContextType {
  chain: ChainDetailType.Item;
  setChain: (chain: ChainDetailType.Item) => void;
  walletApi: WalletDetailType.WalletAPI;
  disconnectEth: () => Promise<void>;
  disconnectSol: () => Promise<void>;
  // disconnectTron: () => Promise<void>;
  reconnect: (newChain: ChainDetailType.Item) => Promise<void>;
}

const getChainFromLocalStorage = (): ChainDetailType.Item => {
  const chain = localStorage.getItem("chain");
  if (chain && chain !== "undefined") {
    return JSON.parse(chain);
  }
  return environment.allowToLoginChains[0];
};

const UnifiedContext = createContext<UnifiedContextType | null>(null);

const UnifiedWalletProvider = ({ children }: UnifiedWalletProviderProps) => {
  const [chain, setChain] = useState<ChainDetailType.Item>(
    getChainFromLocalStorage()
  );
  const [tokenAddress, setTokenAddress] = useState<
    DepositToken | WithdrawToken | undefined
  >(undefined);
  const ethWallet = useEthWalletApi(chain, tokenAddress, setTokenAddress);
  const solanaWallet = useSolanaWalletApi(chain, tokenAddress, setTokenAddress);
  // const tronWallet = useTronWalletApi(chain, tokenAddress, setTokenAddress);
  // select wallet api based on chain
  const walletApi = useMemo(() => {
    console.log("wallet api chain", chain?.chainId);
    switch (chain?.chainId) {
      case 101:
      case 102:
        return solanaWallet;
      case 56789:
      case 97:
      case 56:
        return ethWallet;
      // case 1000:
      // case 1001:
      //   // return tronWallet;
      //   break;
      default:
        return ethWallet;
    }
    // }, [chain?.chainId, ethWallet, solanaWallet, tronWallet]);
  }, [chain?.chainId, ethWallet, solanaWallet]);

  const reconnect = useCallback(
    async (newChain: ChainDetailType.Item) => {
      const walletName = localStorage.getItem("latestWallet");
      console.log("reconnecting to", walletName);
      try {
        switch (newChain.chainId) {
          case 101:
          case 102:
            if (walletName) await solanaWallet.reconnect(walletName);
            break;
          case 56789:
          case 97:
          case 56:
            if (walletName) await ethWallet.reconnect(walletName);
            break;
          // case 1000:
          // case 1001:
          //   // if (walletName) await tronWallet.reconnect(walletName);
          //   break;
          default:
            if (walletName) await ethWallet.reconnect(walletName);
            break;
        }
      } catch (error) {
        console.error("reconnect error", error);
        message.error("Failed to reconnect wallet");
      }
    },
    // [ethWallet, solanaWallet, tronWallet]
    [ethWallet, solanaWallet]
  );

  const handleSetChain = useCallback((newChain: ChainDetailType.Item) => {
    console.log("setChain", newChain);
    localStorage.setItem("chain", JSON.stringify(newChain));
    setChain(newChain);
  }, []);

  // fetch balance by useEffect
  const doFetchBalance = useCallback(async () => {
    if (walletApi.isConnected) {
      await walletApi.fetchBalance();
    }
  }, [walletApi]);

  useEffect(() => {
    if (!tokenAddress) return;
    doFetchBalance();
  }, [walletApi, tokenAddress, doFetchBalance]);

  const value = useMemo(
    () => ({
      chain,
      setChain: handleSetChain,
      walletApi,
      disconnectEth: ethWallet.disconnect,
      disconnectSol: solanaWallet.disconnect,
      // disconnectTron: tronWallet.disconnect,
      reconnect,
    }),
    [
      chain,
      handleSetChain,
      walletApi,
      ethWallet.disconnect,
      solanaWallet.disconnect,
      // tronWallet.disconnect,
      reconnect,
    ]
  );

  return (
    <UnifiedContext.Provider value={value}>{children}</UnifiedContext.Provider>
  );
};

const useUnifiedWallet = () => {
  const context = useContext(UnifiedContext);
  if (context === null) {
    throw new Error("useWallet must be used within a UnifiedWalletProvider");
  }
  return context;
};

export { UnifiedWalletProvider, useUnifiedWallet };

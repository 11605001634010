import React, { useState, useRef } from "react";
import { Token } from "../../interfaces/token";
import Modal from "../modal";
import Table from "../table";
import TableRow from "../table/row";
import ToolTip from "../toolTip";
import Button from "../button";
import config from "../../config";
interface SwapInfoModalProps {
  isSwapConfirmModalOpen: boolean;
  tokenA: Token | null;
  tokenB: Token | null;
  amountA: string;
  amountB: string;
  tokenBPerTokenA: string;
  tokenAPerTokenB: string;
  minMaxTradingAmount: string;
  minMaxTradingText: string;
  priceImpact: number;
  priceImpactColorClass: string;
  fee: string;
  tooltipFee: string;
  amplifier?: string | null;
  liquidityPool: () => void;
  isProcessing: boolean;
  isPriceChanging: boolean;
  isDmmType: boolean;
  slippageTolerance: string;
  routeMap: string[];
  isAutoRoute: boolean;
  onConfirm: () => void;
  onAcceptPriceChange: () => void;
}

const SwapConfirmModal: React.FC<SwapInfoModalProps> = ({
  isSwapConfirmModalOpen,
  tokenA,
  tokenB,
  amountA,
  amountB,
  tokenBPerTokenA,
  tokenAPerTokenB,
  minMaxTradingAmount,
  minMaxTradingText,
  priceImpact,
  priceImpactColorClass,
  fee,
  tooltipFee,
  amplifier,
  liquidityPool,
  isProcessing,
  isPriceChanging,
  isDmmType,
  slippageTolerance,
  routeMap,
  isAutoRoute,
  onConfirm,
  onAcceptPriceChange,
}) => {
  const [isShowRate, setIsShowRate] = useState(true);
  const [isConfirm, setIsConfirm] = useState(true);

  const buttonText = () => {
    return isProcessing ? "Processing..." : "Confirm swap";
  };

  const transformMinMaxText = () => {
    return minMaxTradingText === "Min received" ? "Min received" : "Max sold";
  };

  const transformTextSymbol = () => {
    return minMaxTradingText === "Min received" ? tokenB?.name : tokenA?.name;
  };

  const transformOutputText = () => {
    return minMaxTradingText === "Min received"
      ? "Output is estimated to receive or the transaction will revert."
      : "Input is estimated to sell or the transaction will revert.";
  };

  const getAutoRouteAndAmpDocument = () => {
    return config.document.liquidity;
  };

  const getAutoRouteDocument = () => {
    return config.document.autoroute;
  };

  const isButtonDisable = () => {
    return isProcessing || isPriceChanging;
  };

  return (
    <Modal
      isShowDialog={isSwapConfirmModalOpen}
      //   ref={modalRef}
      onClose={onAcceptPriceChange}
      title="Swap information"
      widthClass="md:w-[832px] md:max-w-[832px]"
      footer={
        <Button
          className={`uppercase h-11 w-full md:w-[430px] font-semibold border-0 text-[18px] ${
            isButtonDisable() ? "text-velo-text-disable" : ""
          }`}
          disabled={isButtonDisable()}
          onClick={onConfirm}
        >
          {buttonText()}
        </Button>
      }
    >
      <div className="w-full flex flex-col gap-6 md:gap-4 overflow-y-scroll md:overflow-hidden">
        <div className="flex md:flex-row flex-col items-center md:items-start">
          {/* Display info confirm */}
          <div className="md:w-[366px] w-full mb-2 md:mb-0">
            <div className="rounded-[2px] border border-[#19506F] bg-[#19506F] bg-opacity-5 w-full min-h-[112px] p-5 flex justify-center items-center flex-col">
              <div className="flex flex-row mb-2">
                <div
                  className={`flex-none rounded-full h-6 w-6 overflow-hidden ${
                    !tokenA?.image ? "bg-white" : ""
                  }`}
                >
                  {tokenA?.image && (
                    <img
                      src={tokenA?.image}
                      alt={tokenA?.name}
                      className="w-6 h-6"
                    />
                  )}
                </div>
                <div className="flex items-center text-white font-normal text-base ml-1 leading-4">
                  {tokenA?.name}
                </div>
              </div>
              <span className="text-white text-base leading-4 pb-1">
                {amountA}
              </span>
            </div>

            {/* <div className="flex justify-center items-center my-5">
              <Icon>arrow_downward</Icon>
            </div> */}

            <div className="rounded-[2px] border border-[#19506F] bg-[#19506F] bg-opacity-5 w-full min-h-[112px] p-5 flex justify-center items-center flex-col">
              {isPriceChanging && (
                <div className="text-sm text-velo-orange mb-2">
                  {transformTextSymbol()} Price has been updated.
                </div>
              )}

              <div className="flex flex-row mb-1">
                <div
                  className={`flex-none rounded-full h-6 w-6 overflow-hidden ${
                    !tokenB?.image ? "bg-white" : ""
                  }`}
                >
                  {tokenB?.image && (
                    <img
                      src={tokenB?.image}
                      alt={tokenB?.name}
                      className="w-6 h-6"
                    />
                  )}
                </div>
                <div className="flex items-center text-white text-base ml-1 font-normal leading-4">
                  {tokenB?.name}
                </div>
              </div>

              <span
                className={`text-white text-base leading-4 pb-1 ${
                  isPriceChanging ? "text-velo-orange" : ""
                }`}
              >
                {amountB}
              </span>

              <div className="mb-2 flex items-center justify-center">
                {isPriceChanging ? (
                  <div className="flex flex-row text-sm">
                    You can{" "}
                    <div className="text-[#E64C51]">&nbsp;Accept&nbsp;</div> to
                    continue exchange.
                  </div>
                ) : (
                  <div className="text-xs font-normal text-center max-w-[268px]">
                    {transformOutputText()}
                  </div>
                )}
              </div>

              {isPriceChanging && (
                <Button
                  size="small"
                  className="uppercase md:w-[83px]"
                  onClick={onAcceptPriceChange}
                >
                  ADD
                </Button>
              )}
            </div>
          </div>

          {/* Display table */}
          <div className="md:w-[430px] w-full mb-16">
            <Table>
              <TableRow
                className="mb-3"
                leftSlot={
                  <>
                    <ToolTip message="Your transaction will revert if there is a large, unfavorable price movement before it is confirmed." />
                    <span className="text-xs text-velo-label">
                      {transformMinMaxText()}
                    </span>
                  </>
                }
                rightSlot={
                  <div className="text-xs">
                    {minMaxTradingAmount} {transformTextSymbol()}
                  </div>
                }
              ></TableRow>

              <TableRow
                className="my-3"
                leftSlot={
                  <>
                    <ToolTip message={tooltipFee} />
                    <span className="text-xs text-velo-label">Trading fee</span>
                  </>
                }
                rightSlot={
                  <div className="text-xs">
                    {fee} {tokenA?.name}
                  </div>
                }
              ></TableRow>

              <TableRow
                className="my-3"
                leftSlot={
                  <>
                    <ToolTip message="The difference between the market price and estimated price due to trade size." />
                    <span className="text-xs text-velo-label">
                      Price impact
                    </span>
                  </>
                }
                rightSlot={
                  <div
                    className={`text-right text-xs ${priceImpactColorClass}`}
                  >
                    {priceImpact > 99.99 ? 99.99 : priceImpact}%
                  </div>
                }
              ></TableRow>

              {isShowRate ? (
                <TableRow
                  className="my-3"
                  leftSlot={
                    <>
                      <div className="flex flex-row text-xs">
                        <img
                          src={tokenA?.image}
                          alt={tokenA?.name}
                          className="w-4 h-4 mr-1"
                        />
                        <div>
                          Rate{" "}
                          <span className="font-semibold text-velo-primary">
                            1 {tokenA?.name}
                          </span>{" "}
                          per
                        </div>
                      </div>
                    </>
                  }
                  rightSlot={
                    <>
                      {" "}
                      <span className="text-xs">
                        {tokenBPerTokenA} {tokenB?.name}
                      </span>
                      {/* <Icon onClick={swapDisplayRate}>swap_horiz</Icon> */}
                    </>
                  }
                ></TableRow>
              ) : (
                <TableRow
                  className="my-3"
                  leftSlot={
                    <>
                      {" "}
                      <div className="flex flex-row text-xs">
                        <img
                          src={tokenB?.image}
                          alt={tokenB?.name}
                          className="w-4 h-4 mr-1"
                        />
                        <div>
                          Inverse rate{" "}
                          <span className="font-semibold text-velo-primary">
                            1 {tokenB?.name}
                          </span>{" "}
                          per
                        </div>
                      </div>
                    </>
                  }
                  rightSlot={
                    <>
                      {" "}
                      <span className="text-xs">
                        {tokenAPerTokenB} {tokenA?.name}
                      </span>
                      {/* <Icon onClick={swapDisplayRate}>swap_horiz</Icon> */}
                    </>
                  }
                ></TableRow>
              )}

              {amplifier && (
                <TableRow
                  className="my-3"
                  leftSlot={
                    <>
                      <a
                        href={getAutoRouteAndAmpDocument()}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {/* <Icon>launch</Icon> */}
                        Amplifier
                      </a>
                    </>
                  }
                  rightSlot={
                    <>
                      <div className="text-right text-xs">{amplifier}</div>
                    </>
                  }
                ></TableRow>
              )}

              {isAutoRoute && (
                <TableRow
                  className="mt-3"
                  leftSlot={
                    <div className="flex flex-row text-xs">
                      <a
                        className="p-0 h-4 text-velo-primary flex items-center"
                        href={getAutoRouteDocument()}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {/* <Icon>launch</Icon> */}
                        Route
                      </a>
                    </div>
                  }
                  rightSlot={
                    <div className="text-xs text-right flex items-center justify-end">
                      {routeMap &&
                        routeMap.map((item, index) => (
                          <div key={index} className="flex items-center">
                            {item}
                            {/* {index + 1 < routeMap.length && <Icon>keyboard_arrow_right</Icon>} */}
                          </div>
                        ))}
                    </div>
                  }
                ></TableRow>
              )}
            </Table>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SwapConfirmModal;

import React, { useState, useRef, useEffect } from "react";
import MaterialIcon from "./icon";

interface ModalProps {
  isClosable?: boolean;
  isMobileClosable?: boolean;
  title?: string;
  onClose: () => void;
  widthClass?: string;
  outsideFrom?: string;
  children?: React.ReactNode;
  footer?: React.ReactNode;
  className?: string;
  isShowDialog: boolean;
}

const Modal: React.FC<ModalProps> = ({
  isClosable = true,
  isMobileClosable = true,
  title = "",
  onClose,
  widthClass = "w-1/4 max-w-1/4",
  outsideFrom = "",
  children,
  footer,
  className,
  isShowDialog,
}) => {
  const modalRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (isShowDialog) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto"; // Ensure overflow is reset on unmount
    };
  }, [isShowDialog]);

  return isShowDialog ? (
    <div
      ref={modalRef}
      className={`bg-transparent z-50 items-center ${className}`}
      data-testid="modal_dialog"
      data-from={outsideFrom}
    >
      {/* Desktop View */}
      <div className="hidden md:flex close-outside justify-center items-center fixed left-0 top-0 w-full h-full bg-[rgba(9,21,32,0.8)] backdrop-blur-[24px] bg-opacity-100 z-50 transition-opacity duration-300 opacity-100 items-center">
        <div
          className={`${widthClass} rounded-[2px] p-4 modal-bg flex-col relative border-[#19506F] border-[1px] bg-[#909090] bg-opacity-5 items-center`}
        >
          <div className="flex items-center w-full">
            {isClosable && (
              <MaterialIcon
                className="ml-auto fill-current text-white w-6 h-6 cursor-pointer"
                round={true}
                data-testid="close_modal_dialog_btn"
                onClick={() => onClose()}
              >
                close
              </MaterialIcon>
            )}
          </div>
          {/* Slot header */}
          {title && <div className="text-h5 uppercase mb-4">{title}</div>}
          {/* Slot content */}
          {children}
          <div className="mt-4 items-center w-full">{footer}</div>
        </div>
      </div>

      {/* Mobile View */}
      <div className="md:hidden">
        <div className="inset-0 z-50 fixed flex-col p-4 overflow-hidden pt-4 bg-[rgba(9,21,32,0.8)] backdrop-blur-[24px] flex md:hidden overflow-y-scroll">
          {isMobileClosable && (
            <div className="flex w-full">
              <img
                src={require("../assets/icons/general/close-icon.svg").default}
                className="ml-auto text-evry-primary"
                alt="close"
                onClick={() => onClose()}
              />
            </div>
          )}
          <div className="flex flex-col w-full">
            <div className="text-h5 mb-4 uppercase">{title}</div>
            {children}
          </div>
          <div className="mt-4 items-center">{footer}</div>
        </div>
      </div>
    </div>
  ) : null;
};

export default Modal;

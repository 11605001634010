// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/icons/home/bg-velo-intro.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.background-intro {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
.text-intro {
  text-shadow: 12px 12px 24px rgba(0, 0, 0, 0.7);
}
.text-background-transparent {
  color: white;
  text-shadow: 1px 1px #2eadd4, -1px -1px #2eadd4;
}
@supports (-webkit-text-stroke: 1px #2eadd4) {
  .text-background-transparent {
    color: transparent;
    -webkit-text-stroke: 1px #2eadd4;
    text-shadow: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/home/intro.css"],"names":[],"mappings":"AAAA;EACE,yDAAkE;AACpE;AACA;EACE,8CAA8C;AAChD;AACA;EACE,YAAY;EACZ,+CAA+C;AACjD;AACA;EACE;IACE,kBAAkB;IAClB,gCAAgC;IAChC,iBAAiB;EACnB;AACF","sourcesContent":[".background-intro {\n  background-image: url(\"../../assets/icons/home/bg-velo-intro.svg\");\n}\n.text-intro {\n  text-shadow: 12px 12px 24px rgba(0, 0, 0, 0.7);\n}\n.text-background-transparent {\n  color: white;\n  text-shadow: 1px 1px #2eadd4, -1px -1px #2eadd4;\n}\n@supports (-webkit-text-stroke: 1px #2eadd4) {\n  .text-background-transparent {\n    color: transparent;\n    -webkit-text-stroke: 1px #2eadd4;\n    text-shadow: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from "react";
import { Link } from "react-router-dom";
import HomeRecommendedYieldCard from "./card";
import { PairExtra } from "../../../interfaces/token";

interface HomeRecommendedYieldProps {
  className?: string;
  pairs: PairExtra[];
  reward?: string;
}

const RecommendYield: React.FC<HomeRecommendedYieldProps> = ({
  className,
  pairs,
  reward = "VELO",
}) => {
  return (
    <div className={className}>
      <div className="text-h4 mb-5 uppercase">Recommended Yield</div>

      <div className="xl:grid xl:grid-cols-3 xl:gap-6 justify-between">
        {pairs.map((pair, index) => (
          <Link key={`recommended-pair-${index}`} to="/farms/chain/live">
            <HomeRecommendedYieldCard pair={pair} reward={reward} />
          </Link>
        ))}
      </div>
    </div>
  );
};

export default RecommendYield;

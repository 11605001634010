import { Connector } from "wagmi";
import { ERC20AddTokenPropsType } from "../../../interfaces/token";
import { ChainDetailType } from "../../../interfaces/environment";
// import { provider as Provider } from "web3-core";
import { MetaMaskInpageProvider } from "@metamask/providers";

const useEthWalletAddToken = (
  chain: ChainDetailType.Item,
  connector: Connector | undefined,
  switchChain: (chainId: number) => Promise<boolean>
) => {
  const addECR20 = async (props: ERC20AddTokenPropsType) => {
    if (!connector) {
      throw new Error("Connector not found");
    }
    const currentChain = await connector.getChainId();
    if (currentChain !== chain.chainId) {
      await switchChain(chain.chainId);
    }
    try {
      const options = {
        address: props.address,
        symbol: props.symbol,
        decimals: props.decimals,
        image: props.image || "",
      };
      // console.log("useEthWallet: addECR20, options=", options);
      if (!connector) {
        // console.log("useEthWallet: addECR20, No connector found");
        return false;
      }
      const provider = (await connector.getProvider({
        chainId: chain?.chainId,
      })) as MetaMaskInpageProvider;
      if (!provider) {
        // console.log("useEthWallet: addECR20, No provider found");
        return false;
      }
      const isAdded = (await provider.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: options,
        },
      })) as boolean;
      if (!isAdded) {
        // console.log("useEthWallet: addECR20, Token not added");
        return false;
      }
      // console.log("useEthWallet: addECR20, Token added");
      return true;
    } catch (e) {
      console.log("useEthWallet: addECR20, Error on addECR20 : ", e);
      return false;
    }
  };

  return {
    addToken: addECR20,
  };
};

export default useEthWalletAddToken;

import React, {
  useState,
  useRef,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from "react";
import MaterialIcon from "../icon";
import Modal from "../modal";
import Button from "../button";
import { Link } from "react-router-dom";
import AmountInput from "../amountInput";
import { toEther, toWei } from "../../utils/token";
import { PairEarned, Token } from "../../interfaces/token";
import BigNumber from "bignumber.js";

const DepositWithdrawModal = forwardRef((props: {}, ref) => {
  const [topic, setTopic] = useState<string>("Deposit");
  const [id, setId] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [logo, setLogo] = useState<string>();
  const [token, setToken] = useState<Token>();
  const [lpBalance, setLpBalance] = useState<string>();
  const [inputAmount, setInputAmount] = useState<string>();
  const [isPending, setIsPending] = useState<boolean>(false);
  const [isInsufficientBalance, setIsInsufficientBalance] =
    useState<boolean>(false);
  const [resolve, setResolve] = useState<any>(null);
  const [reject, setReject] = useState<any>(null);
  const [isShowGetLP, setIsShowGetLP] = useState<boolean>(false);
  const [lpLink, setLpLink] = useState<string | null | undefined>(null);
  const [lpType, setLpType] = useState<string | null>(null);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const isInvalidAmount = useMemo(() => {
    return !inputAmount || Number(inputAmount) <= 0;
  }, [inputAmount]);

  // useMemo for `title`
  const title = useMemo(() => {
    return `${topic} your ${lpType ? "LP Token" : "token"}`;
  }, [topic, lpType]);

  useImperativeHandle(ref, () => ({
    open,
  }));

  const open = (
    pair: any,
    topic: string,
    balance: BigNumber,
    isShowGetLP: boolean,
    lpLink: string | null | undefined
  ) => {
    setTopic(topic);
    setIsShowGetLP(isShowGetLP);
    // setResolve(null);
    // setReject(null);
    setId(pair.id);
    setName(pair.staking.name);
    setLogo(pair.staking.image);
    setLpType(pair.lpType);

    if ("symbol" in pair.staking && pair.staking.symbol) {
      setToken(pair.staking);
    } else {
      setToken({ ...pair.staking, symbol: pair.staking.name });
    }

    setInputAmount(undefined);
    setLpBalance(toEther(balance).toString());
    setLpLink(lpLink);
    // this.inputAmount = null
    // this.lpBalance = this.$tokenUtils.toEther(balance).toString()
    // this.lpLink = lpLink

    setIsModalOpen(true);
    // this.$refs.modal.open();

    return new Promise((resolve, reject) => {
      setResolve(() => resolve);
      setReject(() => reject);
    });
  };

  const confirm = () => {
    resolve({
      inputAmount: toWei(inputAmount ?? "0"),
      // isClose: true,
    });
    setIsModalOpen(false);
  };

  return (
    <Modal
      isShowDialog={isModalOpen}
      widthClass="w-[360px]"
      title={title}
      onClose={() => setIsModalOpen(false)}
      footer={
        <div>
          {isInvalidAmount ? (
            <Button className="w-full uppercase" disabled>
              Enter amount
            </Button>
          ) : isInsufficientBalance ? (
            <Button className="w-full uppercase" disabled>
              Insufficient balance
            </Button>
          ) : (
            <Button
              className="w-full uppercase"
              data-testid="farm_confirm_deposit_withdraw_btn"
              onClick={confirm}
            >
              Confirm
            </Button>
          )}

          {isShowGetLP && lpLink && (
            <div>
              <Link
                to={lpLink}
                className="flex justify-center items-center w-full text-sm mt-4"
                data-testid={`farm_get_${id}_deposit_btn`}
              >
                Get {name}
                <MaterialIcon className="ml-1 text-velo-pink text-base font-bold">
                  arrow_forward
                </MaterialIcon>
              </Link>
            </div>
          )}
        </div>
      }
    >
      <div className="w-full flex flex-col gap-6 md:gap-4">
        <AmountInput
          token={token}
          //   onTokenChange={setToken}
          amount={inputAmount}
          onUpdateAmount={(v) => setInputAmount(v ?? "")}
          //   onAmountChange={setInputAmount}
          balance={lpBalance}
          borderClass=""
          bgColor="bg-[#909090] bg-opacity-5"
          testId="farm_deposit_withdraw_value"
          isInsufficient={isInsufficientBalance}
          //   onIsInsufficientBalanceChange={setIsInsufficientBalance}
        />
      </div>
    </Modal>
  );
});

export default DepositWithdrawModal;

import React from "react";

interface Props {
  className?: string;
}

const Partners: React.FC<Props> = ({ className }) => {
  const partnersList = [
    "lightnet",
    "kybernet",
    "Kejora",
    "UOB",
    "hanwha",
    "elip",
    "unipresident",
    "inception",
    "7bank",
    "everest",
    "DU",
    "Signum",
  ];

  return (
    <div className={className}>
      <div className="text-h4 mb-4 uppercase">Our Partners</div>
      <div className="border border-[#19506F] rounded-[2px] p-8 w-full justify-center">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-8 md:gap-6">
          {partnersList.map((partner) => (
            <div key={partner}>
              <img
                className="object-contain h-6 w-full"
                src={require(`../../assets/partners/${partner}.png`)} // Accessing default export for images
                alt={partner} // Adding alt text for accessibility
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Partners;

import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import Modal from "../modal";
import config from "../../config";
import { Network } from "../../interfaces/network";
import { useEthereum } from "../../contexts/etherruemContext";
import { useWallet } from "../../contexts/walletContext";
import { shortenAddress } from "../../utils/formatter";
import { useMainConstant } from "../../contexts/mainContext";
import { useUnifiedWallet } from "../../providers/UnifiedWalletProvider";
import { isMobile } from "react-device-detect";
import message from "../../lib/components/message";
import environment from "../../environment";

const WalletProvider = {
  UNIVERSE_WALLET: "Universe Wallet",
  METAMASK: "MetaMask",
  COIN98: "Coin98",
  BITGET: "Bitget Wallet",
  PHANTOM: "Phantom",
  TRONLINK: "TronLink",
};

interface Provider {
  name: string;
  image: string;
}

interface Props {
  onConnect: (providerInfo: any) => void;
  onConnectSol: (providerName: string) => void;
}

const chains = config.networks;
const chainSolana = config.networkSol;

const WalletConnectModal: React.FC<Props> = ({ onConnect, onConnectSol }) => {
  const [chainId, setChainId] = useState<number>(config.chainId);
  // const [chainsList, setChainsList] = useState<Network[]>([]);
  // const [currentChain, setCurrentChain] = useState<number>(0); // assume this comes from Redux store
  // const [address, setAddress] = useState<string>(""); // assume this comes from Redux store
  // const [isShowProviders, setIsShowProviders] = useState<boolean>(true);
  const { setChain, walletApi, disconnectEth, disconnectSol } =
    useUnifiedWallet();
  const [isFetchNonce, setIsFetchNonce] = useState<boolean>(false);
  const [latestWallet, setLatestWallet] = useState<string | null>(null);
  const [firstLoadingConnect, setFirstLoadingConnect] =
    useState<boolean>(false);

  // const supportWallets = useMemo(() => {
  //   const unstupportWallets = environment.getUnsupportedWalletByChainId(
  //     walletApi.chain?.chainId
  //   );

  //   const specialWallets = ["Universe Wallet"];
  //   return Object.values(WalletProvider).filter((wallet) => {
  //     for (const unstupportWallet of unstupportWallets?.walletNames || []) {
  //       if (
  //         unstupportWallet.toLowerCase().includes(wallet.toLowerCase()) ||
  //         specialWallets.includes(wallet)
  //       ) {
  //         return false;
  //       }
  //     }
  //     return true;
  //   });
  // }, [walletApi.chain]);

  // const ethereum = useEthereum();
  // const wallet = useWallet();

  const mainConstant = useMainConstant();

  const chainMappers = (chainId: number | string) => {
    return environment.allowToLoginChains.find((v) => v.chainId == chainId);
  };

  useEffect(() => {
    const chain = chainMappers(chainId);
    if (chain) setChain(chain);
  }, []);

  useEffect(() => {
    if (config.chainId) {
      setChainId(config.chainId);
    }
  }, [config.chainId]);

  // useEffect(() => {
  //   if (!config.networks) return;
  //   const networks = config.networks;
  //   const chains = Object.keys(networks).map((key) => {
  //     return networks[parseInt(key)];
  //   });
  //   setChainsList(chains);
  // }, [config.networks]);

  const chainsList = useMemo(() => {
    return [...Object.values(chains).sort((a, b) => a.id - b.id), chainSolana];
  }, [chains, chainSolana]);

  const chainName = useMemo(() => {
    return chainsList.find((o) => o.chainId === chainId)?.display;
  }, [chainsList, chainId]);

  const providers = useMemo(() => {
    return chainsList.find((o) => o.chainId === chainId)?.providers;
  }, [chainsList, chainId]);

  const currentChain = useMemo(() => {
    return walletApi.chain.chainId;
  }, [walletApi.chain.chainId]);

  const address = useMemo(() => {
    // if (!isEVM(chainId)) {
    //   return store.state.solwallet.address;
    // }
    // return store.state.wallet.address;
    return walletApi.account.current;
  }, [walletApi.account.current]);

  // const validChain = useMemo(() => {
  //   return store.state.wallet.validChain;
  // }, [store.state.wallet.validChain]);

  // const isValidConnectionEVM = useMemo(() => {
  //   return eth.isConnected && store.state.wallet.validChain;
  // }, [eth.isConnected, store.state.wallet.validChain]);

  // const isValidConnectionSOL = useMemo(() => {
  //   return sol.isConnected && store.state.solwallet.validChain;
  // }, [sol.isConnected, store.state.solwallet.validChain]);

  // const isShowProviders = useMemo(() => {
  //   if (isEVM(chainId)) {
  //     return !eth.isConnected;
  //   }
  //   return !sol.isConnected;
  // }, [isEVM, chainId, eth.isConnected, sol.isConnected]);

  // const isShowWrongNetwork = useMemo(() => {
  //   if (isEVM(chainId)) {
  //     return currentChain !== chainId;
  //   }
  //   return !store.state.solwallet.validChain;
  // }, [isEVM, chainId, currentChain, store.state.solwallet.validChain]);

  // Sample placeholder methods for Redux/global states
  // const isEVM = (id: number) =>
  //   Object.keys(process.env.networks || {}).includes(`${id}`);
  const isConnected = (chainId: number) =>
    currentChain === chainId && isValidConnectionEVM();
  const isValidConnectionEVM = () => true; // logic for EVM wallet connection
  const isValidConnectionSOL = () => true; // logic for SOL wallet connection

  const onSelectedChain = (chainId: number) => {
    // const chain = environment.getChainByChainId(chainId);
    // if (chain) setChain(chain);
    setChainId(chainId);
  };

  const isEVM = (chainId: number) => {
    return Object.keys(config.networks).includes(`${chainId}`);
  };

  const onDisconnect = () => {
    disconnectEth();
    disconnectSol();
    // if (isEVM(config.chainId)) {
    //   ethereum.disconnect();
    //   wallet.resetWallet();
    // this.$store.dispatch("wallet/resetWallet");
    // } else {
    // this.$sol.disconnect();
    // etheruem.dispatch("solwallet/resetWallet");
    // }
    // this.$emit("onDisconnect");
  };

  const setNetwork = (providerName: string) => {
    // try {
    //   if (ethereum.isConnected && ethereum.isWalletConnect()) {
    //     onDisconnect();
    //   } else {
    //     const provider = ethereum.getProvider(providerName);
    //     try {
    //       provider
    //         .request({
    //           method: "wallet_switchEthereumChain",
    //           params: [
    //             {
    //               chainId: `0x${chainId.toString(16)}`,
    //             },
    //           ],
    //         })
    //         .catch((e: any) => {
    //           console.log("e", e);
    //         });
    //       // this.$refs.modal.close()
    //       return true;
    //     } catch (e: any) {
    //       console.log("setNetwork error", e.code);
    //       if (e.code === 4001) {
    //         // user cancel transection
    //         return false;
    //       }
    //       provider
    //         .request({
    //           method: "wallet_addEthereumChain",
    //           params: [
    //             {
    //               chainId: `0x${chainId.toString(16)}`,
    //               chainName: config.chainNetworkName,
    //               nativeCurrency: {
    //                 name: config.chainCurrencySymbol,
    //                 symbol: config.chainCurrencySymbol,
    //                 decimals: 18,
    //               },
    //               rpcUrls: [config.chainRPC],
    //               blockExplorerUrls: [config.chainExplorerURL],
    //             },
    //           ],
    //         })
    //         .catch((e: any) => {
    //           console.log("e", e);
    //         });
    //       return false;
    //     }
    //   }
    // } catch (e) {
    //   console.log(e);
    // }
  };

  const handleConnectToWallet = async (providerName: string) => {
    const temp = providerName.toLowerCase();
    const isSolana = temp === "phantom";
    const providerInfo = chainsList.find((chain) => chain.chainId === chainId);
    const providerWithCurrent = {
      ...providerInfo,
      currentProvider: temp,
    };

    console.log("providerName", providerName);
    onConnectWallet(providerName);
    //   if (isSolana) {
    //   onConnectWallet(providerName);
    // } else {
    //   // let isSetNetwork = false
    //   if (providerName !== "walletconnect") {
    //     const isSetNetwork = await setNetwork(providerName);
    //     // updateBalance
    //   } else {
    //     onDisconnect();
    //   }
    //   onConnectWallet(providerName);
    // }
  };

  const handleCopyAddress = () => {
    navigator.clipboard.writeText(walletApi.account.current ?? "");
  };

  const handleExplorer = () => {
    const chain = chainsList.find((chain) => chain.chainId === chainId);
    if (chain) {
      const explorerUrl = isEVM(chainId)
        ? `${chain.chainExplorerURL}address/${walletApi.account.current}`
        : `${chain.chainExplorerURL}account/${walletApi.account.current}`;
      window.open(explorerUrl, "_blank")?.focus();
    }
  };

  // const setNetwork = async (providerName: string) => {
  //   try {
  //     // Set up the network switching logic based on the provider
  //     // This can include MetaMask or other wallet providers
  //   } catch (error) {
  //     console.error("Network switching failed", error);
  //   }

  const findWallet = useCallback(
    (walletName: string) => {
      console.log("walletApi.walletNames", walletApi.walletNames);
      if (!walletApi.walletNames) return undefined;
      return walletApi.walletNames.find((wallet) => {
        const nameFromHook = wallet.toLowerCase();
        const nameFromParam = walletName.toLowerCase();
        return nameFromHook.includes(nameFromParam);
      });
    },
    [walletApi.walletNames]
  );

  const onConnectWallet = useCallback(
    async (type: string) => {
      if (!window.ethereum && isMobile) {
        const dappUrl = window.location.href.split("//")[1].split("/")[0];
        let appDeepLink = "";
        if (type === WalletProvider.METAMASK) {
          appDeepLink = "https://metamask.app.link/dapp/" + dappUrl;
        }
        if (type === WalletProvider.COIN98) {
          appDeepLink = "https://coin98.com/dapp/" + dappUrl;
        }
        return window.open(appDeepLink, "_self");
      }

      const wallet = findWallet(type);
      console.log("onConnectWallet type", type);
      console.log("onConnectWallet wallet", wallet);
      if (!wallet) return;
      try {
        await walletApi.connect(wallet);
        setLatestWallet(wallet);
        setIsFetchNonce(true);
        setFirstLoadingConnect(true);
      } catch (error: any) {
        console.error("AuthModal: onConnectWallet, error", error);
        let errorMessage = "Failed to connect wallet";
        if (error instanceof Error && error.message.length < 48) {
          if (error.message.length + 27 < 60) {
            errorMessage = errorMessage + " : " + error.message;
          } else {
            errorMessage = error.message;
          }
        }

        message.error(errorMessage);
        setIsFetchNonce(false);
      }
    },
    [findWallet, walletApi]
  );

  useEffect(() => {
    if (walletApi.isConnected && firstLoadingConnect && isFetchNonce) {
      mainConstant.setIsShowConnectModal(false);
      setFirstLoadingConnect(false);
    }
  }, [firstLoadingConnect]);

  return (
    <Modal
      className="w-[360px]"
      isShowDialog={mainConstant.state.isConnectModalShow}
      onClose={() => mainConstant.setIsShowConnectModal(false)}
    >
      <div className="flex items-center w-full mb-4">
        <div className="text-white text-xl font-medium uppercase">
          Connect wallet
        </div>
      </div>
      <div className="flex flex-col justify-center gap-4">
        <div className="flex flex-col gap-4">
          <p>Select network</p>
          <div className="flex flex-row gap-4">
            {chainsList.map((item, i) => {
              return (
                <div
                  key={i}
                  className={`relative rounded-[2px] w-16 h-16 p-[1px] ${
                    chainId === item.chainId
                      ? "border border-velo-pink"
                      : "hover:border hover:border-velo-pink"
                  }`}
                  onClick={() => onSelectedChain(parseInt(item.chainId as any))}
                >
                  <div className="flex flex-col relative justify-center items-center rounded-[2px] w-full h-full cursor-pointer">
                    <img
                      className="w-8 object-contain"
                      src={item.image}
                      alt="wallet-icon"
                    />
                    {isConnected(parseInt(item.chainId as any)) && (
                      <img
                        className="w-[14px] h-[14px] absolute top-1 right-[10px]"
                        src={
                          require("../../assets/icons/general/check.svg")
                            .default
                        }
                        alt="check-icon"
                      />
                    )}
                    <p className="font-semibold text-white text-xs mt-1">
                      {item.code}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>

          <p>
            {!walletApi.isConnected
              ? "Select wallet provider"
              : `${
                  chainsList.find((chain) => chain.chainId === chainId)?.display
                } wallet address`}
          </p>

          {!walletApi.isConnected && (
            <div className="flex flex-row gap-4">
              {chainsList
                .find((chain) => chain.chainId === chainId)
                ?.providers.map((provider, index) => (
                  <div
                    key={index}
                    className="flex flex-col justify-between items-center w-[156px] h-[70px] p-2 rounded-[2px] border border-velo-pink cursor-pointer transition duration-75 hover:bg-velo-pink"
                    onClick={() => handleConnectToWallet(provider.name)}
                  >
                    <img
                      className="w-8 h-8"
                      src={provider.image}
                      alt="wallet-icon"
                    />
                    <p className="font-semibold text-white text-xs">
                      {provider.name}
                    </p>
                  </div>
                ))}
            </div>
          )}

          {walletApi.isConnected && (
            <div className="border border-velo-pink rounded-[2px] py-4 px-1">
              <p className="text-center overflow-ellipsis overflow-hidden text-xss font-semibold">
                {shortenAddress(walletApi.account.current, 12, 12)}
              </p>
            </div>
          )}

          {walletApi.isConnected && (
            <div className="mt-4 flex flex-row text-white justify-between">
              <div
                className="flex flex-col items-center cursor-pointer"
                onClick={handleCopyAddress}
              >
                <img
                  src={
                    require("/src/assets/icons/header/meta-mark-copy.svg")
                      .default
                  }
                  className="h-[18px] w-[18px]"
                />
                <p className="text-xss font-normal mt-1">Copy address</p>
              </div>
              <div
                className="flex flex-col items-center cursor-pointer"
                onClick={handleExplorer}
              >
                <img
                  src={
                    require("/src/assets/icons/header/meta-mark-explorer.svg")
                      .default
                  }
                  className="h-[18px] w-[18px]"
                />
                <p className="text-xss font-normal mt-1">View on Explorer</p>
              </div>
              <div
                className="flex flex-col items-center cursor-pointer"
                onClick={onDisconnect}
              >
                <img
                  src={
                    require("/src/assets/icons/header/meta-mark-logout.svg")
                      .default
                  }
                  className="h-[18px] w-[18px]"
                />
                <p className="text-xss font-normal mt-1">Disconnect</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default WalletConnectModal;

import React, { useState } from "react";
// import PageHeader from './PageHeader'; // Adjust the import path as necessary
// import Tab from './Tab'; // Adjust the import path as necessary
import { Outlet } from "react-router-dom"; // Using react-router for nested routes
import PageHeader from "../../components/pageHeader";
import Tab from "../../components/tab";

const Bridge: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState("");

  const tabs = [
    {
      name: "Send token",
      linkName: "velolabs-thebridge-send",
      link: "/velolabs/thebridge/send",
      value: 0,
      disabled: false,
    },
    {
      name: "Recovery",
      linkName: "velolabs-thebridge-recovery",
      link: "/velolabs/thebridge/recovery",
      value: 1,
      disabled: false,
    },
  ];

  return (
    <div className="md:container p-0">
      <div className="xl:ml-32 md:flex md:flex-col xl:max-w-[1024px]">
        <PageHeader title="The Bridge" subTitle="Powered by Wormhole">
          <div className="flex-row justify-end w-full hidden md:block">
            <Tab
              tabs={tabs}
              className="pt-5"
              tabWidth="w-[110px]"
              type="secondary"
              onSwitchTab={setSelectedTab} // Assuming Tab component can handle tab selection
            />
          </div>
        </PageHeader>
        <Tab
          tabs={tabs}
          className="px-4 md:hidden justify-center"
          tabWidth="w-full"
          type="secondary"
          onSwitchTab={setSelectedTab} // Assuming Tab component can handle tab selection
        />
        <Outlet />
      </div>
    </div>
  );
};

export default Bridge;

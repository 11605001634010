import React, { createContext, useReducer, useContext, ReactNode } from "react";

export interface NotificationStore {
  status: string;
  txHash: string;
  errorReason?: string;
  duration?: number;
  fullUrl?: string;
  chainType?: string;
}

interface NotificationState {
  data: Record<string, NotificationStore>;
}

interface NotificationAction {
  type: string;
  payload?: any;
}

interface NotificationContextType {
  state: NotificationState;
  setNotify: (notification: NotificationStore) => void;
  deleteNotify: (txHash: string) => void;
  clearAll: () => void;
}

// Initial state
const initialState: NotificationState = {
  data: {},
};

// Reducer function
const notificationReducer = (
  state: NotificationState,
  action: NotificationAction
): NotificationState => {
  switch (action.type) {
    case "SET_NOTIFY":
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.txHash]: action.payload,
        },
      };
    case "DELETE_NOTIFY":
      const { [action.payload.txHash]: _, ...newData } = state.data;
      return {
        ...state,
        data: newData,
      };
    case "CLEAR_ALL":
      return {
        ...state,
        data: {},
      };
    default:
      return state;
  }
};

// Create context
const NotificationContext = createContext<NotificationContextType | undefined>(
  undefined
);

// Provider component
export const NotificationProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(notificationReducer, initialState);

  // Actions
  const setNotify = (notification: NotificationStore) => {
    if (!notification.duration) notification.duration = 5000;
    if (!notification.chainType) notification.chainType = "BNB";
    dispatch({ type: "SET_NOTIFY", payload: notification });

    if (notification.status !== "processing") {
      setTimeout(() => {
        deleteNotify(notification.txHash);
      }, notification.duration);
    }
  };

  const deleteNotify = (txHash: string) => {
    dispatch({ type: "DELETE_NOTIFY", payload: { txHash } });
  };

  const clearAll = () => {
    dispatch({ type: "CLEAR_ALL" });
  };

  return (
    <NotificationContext.Provider
      value={{ state, setNotify, deleteNotify, clearAll }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

// Custom hook for using Notification context
export const useNotification = (): NotificationContextType => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error(
      "useNotification must be used within a NotificationProvider"
    );
  }
  return context;
};

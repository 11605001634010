import { useState } from "react";
import { ethers } from "ethers";
import BigNumber from "bignumber.js";
import { useEthereum } from "../contexts/etherruemContext";
import { useReadContract, useWriteContract } from "wagmi";
import {
  waitForTransactionReceipt,
} from "@wagmi/core";
import { useUnifiedWallet } from "../providers/UnifiedWalletProvider";
import { config, config as wagmiConfig } from "../../wagmi.config";
import ERC20 from "../../src/assets/abi/farm/ERC20.json";
import useLazyReadContractAsync from "./useLazyReadContractAsync";

const noOp = () => {};

interface UseTokenOperationsProps {
  checkAllowanceToSpender: (
    tokenSymbol: string,
    ownerAddress: string,
    spenderAddress: string
  ) => Promise<boolean>;
  checkAllowanceAmountToSpender: (
    tokenSymbol: string,
    ownerAddress: string,
    spenderAddress: string
  ) => Promise<string | null>;
  approveTokenToSpender: (
    tokenSymbol: string,
    ownerAddress: string,
    spenderAddress: string,
    callbacks?: Callbacks
  ) => Promise<void>;
  approveTokenAmountToSpender: (
    tokenSymbol: string,
    ownerAddress: string,
    spenderAddress: string,
    amount: string | number,
    callbacks?: Callbacks
  ) => Promise<void>;
  getBalanceOf: (tokenId: string, account: string) => Promise<string | null>;
  getAllowance: (
    tokenId: string,
    owner: string,
    spender: string,
    contractAddress: string
  ) => Promise<string | null>;
}

interface Callbacks {
  onError?: (error: any) => void;
  onReceipt?: (receipt: any) => void;
  onTransactionHash?: (hash: string) => void;
}

export const useTokenHelpers = (): UseTokenOperationsProps => {
  const ethereum = useEthereum();
  const { writeContractAsync } = useWriteContract();
  const { lazyReadContract } = useLazyReadContractAsync();
  const { walletApi } = useUnifiedWallet();
  // @ts-ignore
  // const { data: balance, refetch } = useReadContract({
  //   // ...wagmiConfig,
  //   address: "0xd190454201DC1EeCb3b75b4B540c03bc2bE1C80A",
  //   functionName: "allowance",
  //   args: [
  //     "0x8991E6926A5ce660CC074d24372e172BFCEE3e1b",
  //     " 0x8427E482D12E4207a978D0ee3DE4e715c50C0c7F",
  //   ],
  // });

  // console.log("balance", balance);

  const checkAllowanceToSpender = async (
    tokenSymbol: string,
    ownerAddress: string,
    spenderAddress: string
  ): Promise<boolean> => {
    // console.log("hello world2");
    console.log("checkAllowanceToSpender", {
      tokenSymbol,
      ownerAddress,
      spenderAddress,
    });
    // if (!ethereum.contracts) return false;
    if (tokenSymbol === "BNB_TOKEN") return true;
    // if (!ethereum.contracts.tokens[tokenSymbol]) return false;
    try {
      const allowance: any = await lazyReadContract(
        ERC20,
        ethereum.contracts.tokens[tokenSymbol]?.options?.address,
        "allowance",
        [ownerAddress, spenderAddress]
      );

      console.log("checkAllowanceToSpender", {
        allowance,
      });

      // const allowance = await ethereum.contracts.tokens[tokenSymbol].methods
      //   .allowance(ownerAddress, spenderAddress)
      //   .call();
      return !BigNumber(allowance).isEqualTo(0);
    } catch (error) {
      return false;
    }
  };

  const checkAllowanceAmountToSpender = async (
    tokenSymbol: string,
    ownerAddress: string,
    spenderAddress: string
  ): Promise<string | null> => {
    if (tokenSymbol === "BNB_TOKEN") return null;
    const allowance = await ethereum.contracts.tokens[tokenSymbol].methods
      .allowance(ownerAddress, spenderAddress)
      .call();
    // Replace with your dispatch or state handling
    // dispatch('bridge/setAllowance', { allowance });
    return allowance;
  };

  const approveTokenToSpender = async (
    tokenSymbol: string,
    ownerAddress: string,
    spenderAddress: string,
    {
      onError = () => {},
      onReceipt = noOp,
      onTransactionHash = noOp,
    }: Callbacks = {}
  ): Promise<void> => {
    try {
      console.log(
        "approveTokenToSpender",
        tokenSymbol,
        ownerAddress,
        spenderAddress
      );
      const hash = await writeContractAsync(
        // @ts-ignore
        {
          // abi: AutoRouter,
          // account: account.current,
          abi: ERC20,
          // @ts-ignore
          from: ownerAddress,
          // @ts-ignore
          address: tokenSymbol,
          functionName: "approve",
          args: [spenderAddress, ethers.MaxUint256],
        },
        {
          onSuccess(data: any, variables: any, context: any) {
            console.log("approveTokenToSpender: data", data);
            if (!data) {
              throw new Error("erc20 transfer failed");
            }
            return data;
          },
          onError: (error: any) => {
            console.log("approveTokenToSpender: error", error);
            throw error;
          },
        }
      );

      // Trigger transactionHash callback
      if (onTransactionHash) onTransactionHash(hash);

      // Wait for the transaction to be mined
      const receipt = await waitForTransactionReceipt(config, { hash });

      // Trigger receipt callback
      if (onReceipt) onReceipt({transactionHash: receipt.transactionHash, status: receipt.status === "success"});

      // await ethereum.contracts.tokens[tokenSymbol].methods
      //   .approve(spenderAddress, ethers.MaxUint256)
      //   .send({ from: ownerAddress })
      //   .on("transactionHash", onTransactionHash)
      //   .on("receipt", onReceipt);
    } catch (error) {
      console.log("approveTokenToSpender c: error", error);
      onError(error);
    }
  };

  const approveTokenAmountToSpender = async (
    tokenSymbol: string,
    ownerAddress: string,
    spenderAddress: string,
    amount: string | number,
    {
      onError = noOp,
      onReceipt = noOp,
      onTransactionHash = noOp,
    }: Callbacks = {}
  ): Promise<void> => {
    try {
      const approveAmount =
        amount === -1
          ? ethers.MaxUint256
          : ethereum.web3?.utils.toWei(amount, "ether");
      await ethereum.contracts.tokens[tokenSymbol].methods
        .approve(spenderAddress, approveAmount)
        .send({ from: ownerAddress })
        .on("transactionHash", onTransactionHash)
        .on("receipt", onReceipt);
    } catch (error) {
      onError(error);
    }
  };

  const getBalanceOf = async (tokenId: string, account: string) => {
    try {
      if (ethereum.contracts) {
        return await ethereum.contracts.tokens[tokenId].methods
          .balanceOf(account)
          .call();
      }
    } catch (error) {
      console.log("Err: getBalanceOf", error);
      return null;
    }
  };

  const getAllowance = async (
    tokenId: string,
    owner: string,
    spender: string,
    contractAddress: string
  ): Promise<string | null> => {
    try {
      console.log("contractAddress",contractAddress)
      if (tokenId !== "BNB_TOKEN") {
        const allowance: any = await lazyReadContract(
          ERC20,
          contractAddress,
          "allowance",
          [owner, spender]
        );

        return await ethereum.contracts.tokens[tokenId].methods
          .allowance(owner, spender)
          .call();
      }
      return null;
    } catch (error) {
      console.log("Err: getAllowance", error);
      return null;
    }
  };

  return {
    checkAllowanceToSpender,
    checkAllowanceAmountToSpender,
    approveTokenToSpender,
    approveTokenAmountToSpender,
    getBalanceOf,
    getAllowance,
  };
};

import React, { useState, useEffect } from "react";
import {
  setSlippage,
  useSlippageDispatch,
  useSlippageState,
} from "../../contexts/slippageContext";
import Button from "../button";
import InputText from "../input/text";

interface Props {}

const SettingPercentageInput: React.FC<Props> = () => {
  const { slippage } = useSlippageState();
  const dispatch = useSlippageDispatch();

  const [amount, setAmount] = useState<string>("0.0");
  const [errorMsg, setErrorMsg] = useState<string>("Transaction may fail");
  const [displayWarningClass, setDisplayWarningClass] = useState<string>("");
  const [isFocus, setIsFocus] = useState<boolean>(false);

  // Initialize amount with slippage value from state
  useEffect(() => {
    setAmount(slippage);
  }, [slippage]);

  const onUpdateAmount = (event: React.FocusEvent<HTMLInputElement>) => {
    let { value } = event.target;
    try {
      let floatValue = parseFloat(value);
      if (floatValue >= 49.99) {
        value = "49.99";
      } else if (floatValue < 0.01) {
        value = "0.01";
      } else if (value === "") {
        value = process.env.REACT_APP_SLIPPAGE_PERCENTAGE || "0.5"; // Assuming default is in .env
      }

      value = parseFloat(value).toFixed(2);
      setAmount(value);
      setSlippage(dispatch, value); // Update global slippage

      setIsFocus(false);
    } catch (error) {
      console.error("Error updating amount:", error);
    }
  };

  const setPercentage = (percent: string) => {
    const formattedPercent = adjustDigitNumber(percent);
    setAmount(formattedPercent);
    setSlippage(dispatch, formattedPercent);
    setIsFocus(true);
  };

  const checkWarning = (value: string) => {
    const floatValue = parseFloat(value);
    if (floatValue >= 5.01) {
      setErrorMsg("Transaction may be frontrun");
      setDisplayWarningClass("text-yellow-1");
    } else if (floatValue >= 0.05) {
      setErrorMsg("");
    } else {
      setErrorMsg("Transaction may fail");
      setDisplayWarningClass("text-yellow-1");
    }
  };

  const adjustDigitNumber = (number: string): string => {
    const reg = number.match(/^-?\d+(?:\.\d{0,2})?/);
    if (!reg) {
      // Handle case where no match is found (fallback behavior, like returning '0.00')
      return "0.00";
    }
    const a = reg[0];
    // const a = number.toString().match(reg)?.[0] || '';
    const dot = a.indexOf(".");
    if (dot === -1) {
      return a + "." + "0".repeat(2);
    }
    const b = 2 - (a.length - dot) + 1;
    return b > 0 ? a + "0".repeat(b) : a;
  };

  // Watch for changes in amount to trigger warnings
  useEffect(() => {
    checkWarning(amount);
  }, [amount]);

  return (
    <div>
      <div className="flex">
        <span className="flex flex-grow gap-2">
          <span
            className={`w-[64px] mt-1 flex flex-row px-2 slippage-inp-outer rounded-[2px] text-base border border-[#4E4E4E] ${
              isFocus ? "border-evry-primary" : ""
            }`}
          >
            <InputText
              type="number"
              placeholder={slippage}
              value={amount}
              onBlur={onUpdateAmount}
              onFocus={() => setIsFocus(true)}
              onKeyDown={(e) => e.key === " " && e.preventDefault()}
              className="w-full bg-transparent text-center flex-grow border-none py-1 outline-none overflow-hidden text-base text-white placeholder-gray-placeholder"
              data-testid="slippage_inp"
            />
          </span>

          <div className="flex gap-1 pt-1">
            <Button
              type={`${amount === "0.10" ? "solid" : "outline"}`}
              onClick={() => setPercentage("0.1")}
            >
              0.1%
            </Button>
            <Button
              type={`${amount === "0.50" ? "solid" : "outline"}`}
              onClick={() => setPercentage("0.5")}
            >
              0.5%
            </Button>
            <Button
              type={`${amount === "1.00" ? "solid" : "outline"}`}
              onClick={() => setPercentage("1.0")}
            >
              1.0%
            </Button>
          </div>
        </span>
      </div>

      <div className={`text-base font-thin pt-3 ${displayWarningClass}`}>
        {errorMsg}
      </div>
    </div>
  );
};

export default SettingPercentageInput;

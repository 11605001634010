import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useCallback,
} from "react";
import Web3, { Web3BaseProvider } from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";
import useContracts from "../hooks/useWeb3Contracts";
import config from "../config";
import { useWallet } from "./walletContext";
import { initContract } from "../utils/contract";

// Define the types for the Ethereum context
export interface EthereumContextType {
  walletDetected: boolean;
  isConnected: boolean;
  web3: Web3 | null;
  error: string | null;
  initWallet: (func: () => void) => void;
  connect: (providerInfo?: any) => Promise<void>;
  disconnect: () => void;
  getProvider: (providerName: string, providerInfo?: any) => any;
  // resetProvider: () => void;
  // contracts: any;
  contracts: any;
  isWalletConnect: () => boolean;
  currentChainId: number;
}

// Default context value
const EthereumContext = createContext<EthereumContextType | undefined>(
  undefined
);

interface Props {
  children: React.ReactNode; // Specify that children are React nodes
}

const chainProvider = new Web3.providers.HttpProvider(config.chainRPC ?? "");

const EthereumProvider: React.FC<Props> = ({ children }) => {
  const [walletDetected, setWalletDetected] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [web3, setWeb3] = useState<Web3>(new Web3(chainProvider));
  const [error, setError] = useState<string | null>(null);
  const [currentChainId, setCurrentChainId] = useState<number>(56);
  const [contracts, setContracts] = useState<any>(null);

  const wallet = useWallet();

  useEffect(() => {
    const initializeContracts = async () => {
      console.log("Initializing contracts...");
      // if (web3 && isConnected) {
      if (web3) {
        try {
          const networkId = await web3.eth.net.getId();
          const initializedContracts = initContract(
            web3,
            isConnected,
            Number(networkId)
          );
          console.log("Initialized contracts:", initializedContracts);
          setContracts(initializedContracts);
        } catch (error) {
          console.error("Error initializing contracts:", error);
        }
      }
    };

    initializeContracts();
  }, [web3, isConnected]);

  // const [chainProvider, setChainProvider] =
  //   useState<Web3BaseProvider<unknown> | null>(null);

  // useEffect(() => {
  //   if (config.chainRPC)
  //     setChainProvider(new Web3.providers.HttpProvider(config.chainRPC || ""));
  // }, [config.chainRPC]);

  // const { contracts } = useContracts(currentChainId);

  // const chainProvider = new Web3.providers.HttpProvider(
  //   process.env.REACT_APP_CHAIN_RPC || ""
  // );

  useEffect(() => {
    if (!web3) return;
    async function getCurrentChainId() {
      setCurrentChainId(Number(await web3?.eth.getChainId()));
    }

    getCurrentChainId();
  }, [web3]);

  useEffect(() => {
    if (chainProvider) setWeb3(new Web3(chainProvider));
  }, [chainProvider]);

  const initWallet = useCallback((func: () => void) => {
    if (!window.ethereum) {
      window.addEventListener("ethereum#initialized", func, { once: true });

      setTimeout(() => {
        func();
        setWalletDetected(true);
      }, 3000);
    } else {
      setTimeout(func, 1000);
      setWalletDetected(true);
    }
  }, []);

  const createListener = useCallback((provider: any, event: string) => {
    let timeout: NodeJS.Timeout | null = null;
    provider.on(event, (props: any) => {
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        // Emit events, this could be custom events with React
        console.log(event, props);
      }, 1000);
    });
  }, []);

  const walletConnect = () => {
    const walletconnect = JSON.parse(
      localStorage.getItem("walletconnect") || "{}"
    );
    return walletconnect;
  };

  const isWalletConnect = () => {
    const walletconnect = walletConnect();
    return walletconnect?.connected;
  };

  const getProvider = (providerName: string, providerInfo: any = null) => {
    if (!providerInfo && localStorage.getItem("walletconnect")) {
      providerInfo = JSON.parse(localStorage.getItem("walletconnect") || "{}");
    }

    if (providerInfo && providerName === "walletconnect") {
      const rpcUrl = `${providerInfo.rpcUrls[0]}${providerInfo.infuraId || ""}`;
      return new WalletConnectProvider({
        chainId: providerInfo.chainId,
        // network: providerInfo.networkName,
        rpc: { [providerInfo.chainId]: rpcUrl },
        infuraId: providerInfo.infuraId,
      });
    }

    if (window.ethereum) {
      return window.ethereum;
    }

    return null;
  };

  const connect = async (providerInfo: any = null) => {
    setError(null);
    const providerName = providerInfo?.currentProvider || "metamask";
    const provider = await getProvider(providerName, providerInfo);

    try {
      if (provider) {
        setWeb3(new Web3(provider));
        setWalletDetected(true);
        if (providerName === "walletconnect" && !provider.connected) {
          await provider.enable();
        } else {
          await provider.request({ method: "eth_requestAccounts" });
        }

        wallet.updateBalance();

        createListener(provider, "accountsChanged");
        createListener(provider, "chainChanged");
        createListener(provider, "disconnect");

        setIsConnected(true);
        localStorage.setItem("isLoggedIn", "true");
        console.log("Ethereum connected");
      } else {
        resetProvider();
        setError(`Please install ${providerName}!`);
        setIsConnected(false);
        setWalletDetected(false);
      }
    } catch (e: any) {
      setError(e?.message || "Error connecting to wallet");
      setIsConnected(false);
    }
  };

  const disconnect = useCallback(async () => {
    if (isWalletConnect()) {
      await web3.currentProvider?.disconnect();
    }
    resetProvider();
    setIsConnected(false);
    localStorage.removeItem("isLoggedIn");
  }, [web3]);

  const resetProvider = () => {
    setWeb3(new Web3(chainProvider));
  };

  useEffect(() => {
    connect();
  }, []);

  return (
    <EthereumContext.Provider
      value={{
        walletDetected,
        isConnected,
        web3,
        error,
        initWallet,
        connect,
        disconnect,
        getProvider,
        // resetProvider,
        // contracts,
        contracts,
        isWalletConnect,
        currentChainId,
      }}
    >
      {children}
    </EthereumContext.Provider>
  );
};

// Custom hook to use Ethereum context
const useEthereum = () => {
  const context = useContext(EthereumContext);
  if (!context) {
    throw new Error("useEthereum must be used within an EthereumProvider");
  }
  return context;
};

export { EthereumProvider, useEthereum };

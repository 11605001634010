import React from "react";

interface Props {
  className?: string;
}

const Audits: React.FC<Props> = ({ className }) => {
  const auditors = ["inspex", "slowmist", "peckshield"];

  return (
    <div className={className}>
      <div className="text-h4 mb-4 uppercase">Audited by</div>
      <div className="rounded-[2px] border border-[#19506F] text-evry-primary p-8 w-full justify-center">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-8 md:gap-6">
          {auditors.map((audit) => (
            <div key={audit} className="opacity-100">
              <img
                className="object-contain h-6 w-full"
                src={require(`../../assets/auditors/${audit}.png`)} // Accessing default export for images
                alt={audit} // Adding alt text for accessibility
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Audits;

import { useMemo, useCallback, useEffect, useState, useRef } from "react";
import {
  useConnect,
  Connector,
  useAccount,
  useDisconnect,
  useReconnect,
  ConnectorAlreadyConnectedError,
} from "wagmi";
import { ConnectorAlreadyConnectedErrorType } from "@wagmi/core";
import { ChainDetailType } from "../../../interfaces/environment";
import { chains as wagmiChain } from "../../../../wagmi.config";
import { UserRejectedRequestError } from "viem";

const useEthWalletConnection = (chain: ChainDetailType.Item) => {
  const { connectAsync, connectors } = useConnect();
  const addressRef = useRef<`0x${string}` | undefined>(undefined);
  const setAddressRef = useCallback((node: `0x${string}` | undefined) => {
    addressRef.current = node;
    if (node === undefined || node === null) {
      setHasAddress(false);
    } else {
      setHasAddress(true);
    }
  }, []);
  const [hasAddress, setHasAddress] = useState<boolean>(false);

  const { disconnectAsync } = useDisconnect();
  const { address: _address, connector: currentConnector } = useAccount();
  const { connectors: reconnectors, reconnectAsync } = useReconnect();
  const wallets = useMemo(() => {
    return connectors.map((connector) => connector.name);
  }, [connectors]);

  const setAddress = (address: `0x${string}`) => {
    setAddressRef(address);
    // setTriggerRef(!triggerRef);
    localStorage.setItem("evm_address", address as string);
  };

  const getCachedAddress = useCallback(async () => {
    const cacheAddress = localStorage.getItem("evm_address") as
      | `0x${string}`
      | null;
    if (cacheAddress) {
      return cacheAddress;
    }
  }, []);

  const getConnectorAddress = useCallback(async () => {
    const connectorAddress = await currentConnector?.getAccounts();
    if (connectorAddress && connectorAddress.length > 0) {
      return connectorAddress[0];
    }
  }, [currentConnector]);

  const initAddress = useCallback(async () => {
    var address = await getCachedAddress();
    if (address) {
      setAddressRef(address);
    }
    return addressRef.current;
  }, [getCachedAddress]);

  useEffect(() => {
    initAddress();
  }, []);

  useEffect(() => {
    if (_address) {
      setAddressRef(_address);
    }
  }, [_address]);

  const disconnect = useCallback(async () => {
    if (currentConnector) {
      await disconnectAsync({ connector: currentConnector });
      localStorage.removeItem("evm_address");
      setAddressRef(undefined);
    } else {
      console.error("useEthWalletConnection: disconnect, Connector not found");
    }
  }, [currentConnector, disconnectAsync]);

  const switchChain = useCallback(
    async (chainId: number, connector?: Connector) => {
      const chain = wagmiChain.find((chain) => chain.id === chainId);
      if (!chain) {
        throw new Error("Chain not found");
      }
      const _connector = currentConnector || connector;

      if (!_connector) {
        throw new Error("Connector not found");
      }

      if (!_connector.switchChain) {
        throw new Error("Connector does not support switchChain");
      }

      const payload = {
        chainId: chainId,
        addEthereumChainParameter: {
          chainName: chain.name,
          nativeCurrency: {
            name: chain.nativeCurrency.name,
            symbol: chain.nativeCurrency.symbol,
            decimals: chain.nativeCurrency.decimals,
          },
          rpcUrls: chain.rpcUrls.default.http,
          blockExplorerUrls: chain.blockExplorers
            ? [chain.blockExplorers.default.url]
            : undefined,
          iconUrls: [],
        },
      };
      const res = await _connector.switchChain(payload);
      // console.log("useEthWalletSwitchChain: switchChain, res", res);
      return !!res.id;
    },
    [currentConnector]
  );

  const connect = useCallback(
    async (walletName: string) => {
      // wait until wallet to be correct one
      const connector = connectors.find(
        (connector) => connector.name === walletName
      );
      if (connector) {
        if (currentConnector && currentConnector.name === walletName) {
          console.log("wallet already connected");
          await disconnectAsync({ connector: currentConnector });
        }

        try {
          const res = await connectAsync({
            chainId: chain.chainId,
            connector: connector,
          });
          console.log("useEthWalletConnection: connected , res", res);
          const _chainId = await connector.getChainId();
          console.log("useEthWalletConnection: chain", _chainId);
          if (chain.chainId !== _chainId) {
            throw new Error("Chain network not matched, please enable chain");
          }
          setAddress(res.accounts[0]);
          return res.accounts[0] as string;
        } catch (e: any) {
          // Type guard function to check if the error is an instance of ConnectorAlreadyConnectedError
          const isConnectorAlreadyConnectedError = (
            error: any
          ): error is ConnectorAlreadyConnectedError => {
            return error instanceof ConnectorAlreadyConnectedError;
          };

          // Type guard function to check if the error is an instance of UserRejectedRequestError
          const isUserRejectedRequestError = (
            error: any
          ): error is UserRejectedRequestError => {
            return error instanceof UserRejectedRequestError;
          };

          if (isConnectorAlreadyConnectedError(e)) {
            // console.log(
            //   "useEthWalletConnection: connect, already connected",
            //   e.shortMessage
            // );
            // console.log(
            //   "useEthWalletConnection: connect, already connected2",
            //   addressRef.current
            // );
            let _currentAddress = undefined;
            if (!addressRef.current) {
              var cacheAddress = await getCachedAddress();
              if (cacheAddress) {
                _currentAddress = cacheAddress;
              } else {
                _currentAddress = await getConnectorAddress();
              }
            } else {
              _currentAddress = addressRef.current;
            }

            if (!_currentAddress) {
              throw new Error("Address not found");
            }

            setAddress(_currentAddress);

            return _currentAddress;
          } else if (isUserRejectedRequestError(e)) {
            console.log(
              "useEthWalletConnection: connect, user rejected",
              e.shortMessage
            );
            throw new Error("User rejected request");
          } else {
            console.error("useEthWalletConnection: connect, error", e);
            throw new Error(e.message);
          }
        }
      } else {
        console.error("useEthWalletConnection: connect, Connector not found");
        throw new Error("Connector not found");
      }
    },
    [
      connectors,
      currentConnector,
      disconnectAsync,
      connectAsync,
      chain.chainId,
      initAddress,
    ]
  );

  const reconnectWallet = useCallback(
    async (walletName: string) => {
      const connector = reconnectors.find(
        (connector) => connector.name === walletName
      );
      if (connector) {
        console.log(
          "useEthWalletConnection: reconnectWallet, reconnecting to",
          connector.name
        );
        await reconnectAsync({ connectors: [connector] });
        setHasAddress(true);
      } else {
        console.error(
          "useEthWalletConnection: reconnectWallet, Connector not found"
        );
        setHasAddress(false);
      }
    },
    [reconnectors, reconnectAsync]
  );

  // useEffect(() => {
  //   console.log("useEthWalletConnection: useEffect, chain", chain);
  //   console.log(
  //     "useEthWalletConnection: useEffect, currentConnector",
  //     currentConnector
  //   );
  //   console.log(
  //     "useEthWalletConnection: useEffect, address",
  //     addressRef.current
  //   );
  //   console.log("useEthWalletConnection: useEffect, connectors", connectors);
  // }, [chain, currentConnector, connectors]);
  return {
    isConnected: !!currentConnector,
    walletName: currentConnector?.name,
    walletNames: wallets,
    account: addressRef,
    hasAddress,
    connect: connect,
    reconnect: reconnectWallet,
    disconnect: disconnect,
    selectedConnector: currentConnector,
    switchChain: switchChain,
  };
};

export default useEthWalletConnection;

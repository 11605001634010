import BigNumber from "bignumber.js";

// Helper function to format value with digit precision
const _formatValue = (value: BigNumber, digit: number): string => {
  let newValue: RegExpMatchArray | null = null;
  if (digit === 2) {
    newValue = value.toString(10).match(/^-?\d*\.?[0-9]?[0-9]?/);
  } else if (digit === 4) {
    newValue = value.toString(10).match(/^-?\d*\.?[0-9]?[0-9]?[0-9]?[0-9]?/);
  }

  return parseFloat(newValue?.[0] || "0").toLocaleString(undefined, {
    minimumFractionDigits: digit,
  });
};

// Helper function to format min and max digits
const _formatMinMaxDigitsValue = (
  value: BigNumber,
  min: number,
  max: number
): string => {
  const splitValue = value.toString().split(".");
  if (splitValue.length === 1 || splitValue[1].length <= min) {
    return parseFloat(
      value.toString(10).match(/^-?\d*\.?[0-9]?[0-9]?[0-9]?[0-9]?/)?.[0] || "0"
    ).toLocaleString(undefined, {
      minimumFractionDigits: 4,
    });
  } else {
    const regex = new RegExp(`^(\\d*\\.)([0-9]){${min},${max}}`, "gi");
    return new BigNumber(value.toString().match(regex)?.[0] || "0").toFormat();
  }
};

// Format Ether as a string with 4 decimal places
export const formatStrEther = (ether: string | number): string => {
  return _formatValue(new BigNumber(ether), 4);
};

// Format Wei to Ether and display as dollar with 2 decimal places
export const formatEtherDollar = (wei: BigNumber): string => {
  wei = wei || new BigNumber("0");
  const ether = wei.dividedBy(new BigNumber(10).pow(18));
  return _formatValue(ether, 2);
};

// Format Wei to Ether with custom decimal precision
export const formatEther = (wei: BigNumber, decimals: number = 18): string => {
  wei = wei || new BigNumber("0");
  const ether = wei.dividedBy(new BigNumber(10).pow(decimals));
  return _formatValue(ether, 4);
};

// Format a dollar amount with 2 decimal places
export const formatDollar = (amount: number | string): string => {
  return _formatValue(new BigNumber(amount), 2);
};

// Format a dollar string value with 2 decimal places
export const formatStrDollar = (amount: string | number): string => {
  return _formatValue(new BigNumber(amount), 2);
};

// Format BigNumber with abbreviation (B for billion, M for million, etc.)
export const formatBigNumber = (bn: BigNumber, decimals = 0): string => {
  let abbr = "";
  bn = bn || new BigNumber("0");

  if (bn.isGreaterThanOrEqualTo(100000000000)) {
    abbr = "B";
    return ">" + _formatValue(new BigNumber(99.99), 2) + abbr;
  }

  if (bn.isGreaterThanOrEqualTo(1000000000)) {
    abbr = "B";
    bn = bn.dividedBy(1000000000);
  } else if (bn.isGreaterThanOrEqualTo(1000000)) {
    abbr = "M";
    bn = bn.dividedBy(1000000);
  } else if (bn.isGreaterThanOrEqualTo(1000)) {
    abbr = "K";
    bn = bn.dividedBy(1000);
  }
  if (decimals > 0) return _formatValue(bn, decimals) + abbr;
  return _formatValue(bn, 4) + abbr;
};

// Format BigNumber as percentage with abbreviation
export const formatBigNumberPercentAbbr = (bn: BigNumber): string => {
  if (!bn) return "0.00%";
  let abbr = "";
  bn = bn.multipliedBy(100);

  if (bn.isGreaterThanOrEqualTo(100000000000)) {
    abbr = "B";
    return ">" + _formatValue(new BigNumber(99.99), 2) + abbr;
  }

  if (bn.isGreaterThanOrEqualTo(1000000000)) {
    abbr = "B";
    bn = bn.dividedBy(1000000000);
  } else if (bn.isGreaterThanOrEqualTo(1000000)) {
    abbr = "M";
    bn = bn.dividedBy(1000000);
  } else if (bn.isGreaterThanOrEqualTo(1000)) {
    abbr = "K";
    bn = bn.dividedBy(1000);
  }
  return _formatValue(bn, 2) + abbr;
};

// Format percentage amount with abbreviation
export const formatPercentAbbr = (amount: number): string => {
  let abbr = "";
  amount = amount * 100;

  if (amount >= 10000000000) {
    abbr = "B";
    return ">" + _formatValue(new BigNumber(99.99), 2) + abbr;
  }

  if (amount >= 1000000000) {
    abbr = "B";
    amount = amount / 1000000000;
  } else if (amount >= 1000000) {
    abbr = "M";
    amount = amount / 1000000;
  } else if (amount >= 1000) {
    abbr = "K";
    amount = amount / 1000;
  }
  return _formatValue(new BigNumber(amount), 2) + abbr;
};

// Format number with 2 decimal places
export const formatNumberWith2Digits = (amount: number): string => {
  return _formatValue(new BigNumber(amount), 2);
};

// Format number with 4 decimal places
export const formatNumberWith4Digits = (amount: number): string => {
  return _formatValue(new BigNumber(amount), 4);
};

// Format number with minimum and maximum decimal places
export const formatNumberWithMinMaxDigits = (
  amount: number,
  min: number,
  max: number
): string => {
  if (amount === 0) {
    return "0.0000";
  } else if (amount < 0) {
    return "N/A";
  }
  return _formatMinMaxDigitsValue(new BigNumber(amount), min, max);
};

import React, { useEffect, useMemo, useState } from "react";
import Table from "../table";
import TableRow from "../table/row";
import Button from "../button";
import Modal from "../modal";
import AmountInput from "../amountInput";
import { DirectExchangeDetail } from "../../routes/exchange/directExchange";
import BigNumber from "bignumber.js";
import { DirectExchange } from "../../interfaces/config";
import { useEthereum } from "../../contexts/etherruemContext";
import { useUnifiedWallet } from "../../providers/UnifiedWalletProvider";

// interface ConfigExchange {
//   originToken: { name: string; image: string };
//   destinationToken: { name: string; image: string };
//   pairTokenImage: string;
// }

interface Props {
  directExchangeDetail: DirectExchangeDetail;
  isApproved?: boolean;
  destinationPerOrigin?: BigNumber;
  configExchange: DirectExchange;
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  onDirectExchange: (inputAmount: string) => void;
}

const ConfirmDirectExchangeModal: React.FC<Props> = ({
  directExchangeDetail,
  isApproved = false,
  configExchange,
  destinationPerOrigin = BigNumber("0"),
  isModalOpen,
  setIsModalOpen,
  onDirectExchange,
}) => {
  const ethereum = useEthereum();
  const { walletApi } = useUnifiedWallet();
  const [inputAmount, setInputAmount] = useState<string>("");
  const [isConfirm, setIsConfirm] = useState<boolean>(false);
  const [myOriginTokenBalanceDisplay, setMyOriginTokenBalanceDisplay] =
    useState("0.00");
  const [isInsufficientBalance, setIsInsufficientBalance] =
    useState<boolean>(false);

  useEffect(() => {
    console.log("walletApi.account.current", walletApi.account.current);
    if (walletApi.hasAddress) getBalances();
  }, [walletApi.hasAddress, directExchangeDetail]);

  const getBalances = async () => {
    try {
      if (
        directExchangeDetail.configExchange.originToken &&
        directExchangeDetail.configExchange.originToken?.symbol &&
        directExchangeDetail.configExchange.originToken?.symbol !== "" &&
        ethereum?.web3
      ) {
        // tokenA.balance = await tokenBalanceOf(tokenA.symbol)
        const balance = await tokenBalanceOf(
          directExchangeDetail.configExchange.originToken.code
        );
        if (balance) {
          setMyOriginTokenBalanceDisplay(
            ethereum?.web3.utils.fromWei(balance, "ether").toLocaleString()
          );
        }
      }
    } catch (error) {
      console.error("getBalances", error);
    }
  };

  const isInvalidAmount = useMemo(() => {
    return !inputAmount || parseFloat(inputAmount) <= 0;
  }, [inputAmount]);

  const tokenBalanceOf = async (tokenSymbol: string) => {
    try {
      if (tokenSymbol === "BNB" && walletApi.account.current) {
        return await ethereum.web3?.eth.getBalance(walletApi.account.current);
      }
      return await ethereum.contracts.tokens[`${tokenSymbol}`].methods
        .balanceOf(walletApi.account.current)
        .call();
    } catch {
      console.error("tokenBalanceOf", tokenSymbol);
    }
  };

  const receiptToken = useMemo(() => {
    if (inputAmount) {
      return BigNumber(inputAmount).multipliedBy(
        directExchangeDetail.exchangeRate
      );
    } else {
      return 0;
    }
  }, [inputAmount, directExchangeDetail?.exchangeRate]);

  const isInsufficientPool = useMemo(() => {
    return directExchangeDetail.poolBalances.isLessThan(
      BigNumber(receiptToken)
    );
  }, [directExchangeDetail?.poolBalances]);

  const acceptPoolSizeChange = () => {
    const newBalance = directExchangeDetail.poolBalances
      .div(directExchangeDetail.exchangeRate)
      .toString();
    const result = BigNumber(myOriginTokenBalanceDisplay).isLessThan(
      BigNumber(newBalance)
    )
      ? BigNumber(myOriginTokenBalanceDisplay).toString()
      : newBalance;

    setInputAmount(result);
  };

  const onConfirmExchange = () => {
    onDirectExchange(inputAmount);
    setIsModalOpen(false);
    // resolve({
    //   confirmed: true,
    //   inputAmount: inputAmount,
    // });
    // $refs.modal.close();
  };

  //   const close = () => {
  //     resolve({
  //       confirmed: false,
  //     })
  //   };

  //   const open = () => {
  //     $refs.modal.open();

  //     return new Promise((resolve, reject) => {
  //       resolve = resolve;
  //       reject = reject;
  //     });
  //   };

  return (
    <Modal
      isShowDialog={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      widthClass="md:w-[360px] md:max-w-[360px] rounded-2xl"
      title={`Direct exchange to ${configExchange.destinationToken.name}`}
    >
      <div className="w-full flex flex-col gap-6 md:gap-4 overflow-y-scroll md:overflow-hidden">
        <div className="flex flex-col gap-4">
          <div className="flex flex-row">
            <AmountInput
              className="grid w-full"
              bgColor="bg-[#909090] bg-opacity-5"
              token={configExchange.originToken}
              balance={myOriginTokenBalanceDisplay.toString()}
              amount={inputAmount}
              onUpdateAmount={(v) => {
                setInputAmount(v ? v : "");
              }}
              //   setAmount={setInputAmount}
              testId="evryhyper"
              isInsufficient={isInsufficientBalance}
            />
          </div>

          {isInsufficientPool && (
            <div className="border border-evry-yellow rounded-[2px] p-4 flex flex-col">
              <p className="text-xs text-center mb-4">
                {`${configExchange.destinationToken.name} token is not enough for exchanging.`}
                <br />
                You have partially exchanged from your exchange value.
              </p>
              <div className="flex justify-center">
                <Button
                  className="h-9 px-4 font-normal border-0 text-base"
                  size="small"
                  onClick={acceptPoolSizeChange}
                >
                  Update your amount
                </Button>
              </div>
            </div>
          )}

          <div className="flex md:flex-row flex-col items-center md:items-start">
            <Table
              className="p-0 w-full text-xs font-normal"
              colorClass="bg-none"
            >
              <TableRow className="rounded-none mb-2 p-0" colorClass="bg-none">
                <div className="p-0 w-full text-base">You will receive</div>
              </TableRow>

              <TableRow
                className="mb-2 py-2"
                colorClass="bg-[#909090] bg-opacity-5"
                leftSlot={
                  <div className="flex flex-col w-full">
                    <div className="flex-row flex">
                      <img
                        className="w-4 mr-2"
                        src={configExchange.destinationToken.image}
                        alt="Token"
                      />
                      <div className="flex items-center h-5 text-[#B5B5B5]">
                        Receive {configExchange.destinationToken.name}
                      </div>
                    </div>
                  </div>
                }
                rightSlot={
                  <div className="text-number-small">
                    {receiptToken.toFixed(4)}
                  </div>
                }
              ></TableRow>

              <TableRow
                className="mb-2 py-2"
                colorClass="bg-[#909090] bg-opacity-5"
                leftSlot={
                  <div className="flex flex-col w-full">
                    <div className="flex-row flex">
                      <img
                        className="w-7 mr-2"
                        src={configExchange.pairTokenImage}
                        alt="Pair Token"
                      />
                      <div className="flex items-center h-5 text-[#B5B5B5]">
                        Price 1 {configExchange.destinationToken.name} per{" "}
                        {configExchange.originToken.name}
                      </div>
                    </div>
                  </div>
                }
                rightSlot={
                  <div className="text-number-small">
                    {destinationPerOrigin.toFixed(4)}
                  </div>
                }
              ></TableRow>

              <TableRow
                className="py-2"
                colorClass="bg-[#909090] bg-opacity-5"
                leftSlot={
                  <div className="flex flex-col w-full">
                    <div className="flex-row flex">
                      <img
                        className="w-4 mr-2"
                        src={configExchange.destinationToken.image}
                        alt="Pool Token"
                      />
                      <div className="flex items-center h-5 text-[#B5B5B5]">
                        {configExchange.destinationToken.name} Available in pool
                      </div>
                    </div>
                  </div>
                }
                rightSlot={
                  <div className="text-number-small">
                    {directExchangeDetail.poolBalances.toFixed(4)}
                  </div>
                }
              ></TableRow>
            </Table>
          </div>
        </div>
      </div>

      <div>
        {isInvalidAmount ? (
          <Button
            className="w-full h-11 border-none px-0 justify-center font-normal uppercase"
            disabled
          >
            <p className="text-base">Enter amount</p>
          </Button>
        ) : isInsufficientBalance ? (
          <Button
            className="w-full h-11 border-none px-0 justify-center font-normal uppercase"
            disabled
          >
            <p className="text-base">
              Insufficient {configExchange.originToken.name}
            </p>
          </Button>
        ) : isInsufficientPool ? (
          <Button
            className="w-full h-11 border-none px-0 justify-center font-normal uppercase"
            disabled
          >
            <p className="text-base">
              Insufficient {configExchange.destinationToken.name}
            </p>
          </Button>
        ) : (
          <Button
            className="w-full h-11 border-none px-0 justify-center font-normal uppercase"
            onClick={onConfirmExchange}
          >
            <p className="text-base font-normal">Confirm exchange</p>
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default ConfirmDirectExchangeModal;

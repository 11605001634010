import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import duration from "dayjs/plugin/duration";

dayjs.extend(utc);
dayjs.extend(isBetween);
dayjs.extend(isSameOrBefore);
dayjs.extend(duration);

const minutePerMilliSec = 60000;

function _getDate() {
  return new Date();
}

function _toSec(ms: number): string {
  return ms.toString().substring(0, 10);
}

function now(): string {
  return _toSec(_getDate().getTime());
}

function addMinute(minute: number): string {
  const dtNext = _getDate().getTime() + minute * minutePerMilliSec;
  return _toSec(dtNext);
}

function getDate(value?: number | string, format = "") {
  return value ? dayjs(value, format) : dayjs();
}

function period(timeStart: number, timeEnd: number): number {
  const startTime = dayjs.unix(timeStart);
  const endTime = dayjs.unix(timeEnd);
  const period = endTime.diff(startTime, "day", true);
  return period <= 0 ? 0 : Math.ceil(period);
}

function countDownDateTime(
  timeStart: number,
  timeEnd: number,
  format: string
): string {
  const startTime = dayjs.unix(timeStart);
  const endTime = dayjs.unix(timeEnd);
  let millisec = endTime.diff(startTime);
  millisec = millisec > 0 ? millisec : 0;
  return dayjs.duration(millisec).format(format);
}

function getDateByUnix(datetime?: number): dayjs.Dayjs {
  return datetime ? dayjs.unix(datetime) : dayjs();
}
function getUnixFromBlock(
  targetBlock: number | bigint,
  currentBlock: number | bigint,
  secPerBlock: number
): number {
  // Convert BigInt to number if either of the blocks is a BigInt
  const targetBlockNum =
    typeof targetBlock === "bigint" ? Number(targetBlock) : targetBlock;
  const currentBlockNum =
    typeof currentBlock === "bigint" ? Number(currentBlock) : currentBlock;

  const diffBlock = targetBlockNum - currentBlockNum;
  const totalSec = Math.abs(diffBlock) * secPerBlock;
  const currentDate = dayjs();
  let result: dayjs.Dayjs | null = null;

  if (diffBlock < 0) {
    result = currentDate.subtract(totalSec, "second");
  } else {
    result = currentDate.add(totalSec, "second");
  }

  return result?.unix() || 0;
}

export {
  now,
  addMinute,
  period,
  getDate,
  getDateByUnix,
  getUnixFromBlock,
  countDownDateTime,
};

import React from "react";

interface ButtonProps {
  type?: "solid" | "outline" | "text";
  size?: "medium" | "small";
  state?: "primary" | "secondary";
  prependIcon?: string;
  appendIcon?: string;
  disabled?: boolean;
  paddingInside?: string;
  externalClass?: string;
  onClick?: () => void;
  children?: React.ReactNode;
  className?: string;
}

const Button: React.FC<ButtonProps> = ({
  type = "solid",
  size = "medium",
  state = "primary",
  prependIcon = "",
  appendIcon = "",
  disabled = false,
  paddingInside = "",
  externalClass = "",
  onClick,
  children,
  className,
}) => {
  const handleClick = () => {
    if (!disabled && onClick) {
      onClick();
    }
  };

  const mapCSSIcon = () => {
    if (type === "text") {
      if (state === "primary") {
        return "text-transparent bg-clip-text bg-velo-primary-gradient";
      } else if (disabled) {
        return "text-velo-secondary";
      }
    } else {
      return disabled ? "text-velo-disable-2" : "";
    }
  };

  const mapCSSBg = () => {
    switch (type) {
      case "solid":
        return disabled
          ? "bg-velo-disable-2"
          : state === "primary"
          ? "bg-velo-pink hover:bg-opacity-90"
          : state === "secondary" && !prependIcon && !appendIcon
          ? "bg-velo-branding-white"
          : "bg-velo-secondary";
      case "outline":
        return disabled
          ? "border border-velo-disable-2"
          : state === "primary"
          ? "border border-velo-pink hover:bg-velo-pink"
          : "border border-white";
      default:
        return "";
    }
  };

  const mapCSSText = () => {
    switch (type) {
      case "solid":
        return state === "primary"
          ? "text-velo-wisp-pink"
          : state === "secondary" && !prependIcon && !appendIcon
          ? "text-transparent bg-clip-text bg-velo-primary-gradient"
          : "text-velo-disable-2";
      case "outline":
        return state === "primary" || state === "secondary"
          ? "text-velo-pink group-hover:text-white"
          : "text-velo-disable-2";
      default:
        return state === "primary" && !prependIcon && !appendIcon
          ? "text-transparent bg-clip-text bg-velo-primary-gradient"
          : "text-white";
    }
  };

  return (
    <div className={`inline-block ${className}`} onClick={handleClick}>
      <div
        className={`group relative m-auto min-w-[62px] rounded-[2px] cursor-pointer items-center justify-center flex transition duration-75 ease-linear ${mapCSSBg()} ${
          disabled ? "cursor-not-allowed" : ""
        } ${
          size === "medium" ? "h-10" : "h-7"
        } ${paddingInside} ${externalClass}`}
      >
        <div
          className={`flex flex-row gap-1 text-2xl leading-none font-normal transition duration-75 ease-linear ${mapCSSText()}`}
        >
          {prependIcon && (
            <Icon round className={mapCSSIcon()}>
              {prependIcon}
            </Icon>
          )}
          <p
            className={`flex items-center ${
              size === "medium" ? "text-base" : "text-sm"
            } ${disabled && type === "outline" ? "text-velo-disable-2" : ""} ${
              disabled && type === "solid" ? "text-[#848484]" : ""
            }`}
          >
            {children}
          </p>
          {appendIcon && (
            <Icon
              round
              className={`${mapCSSIcon()} ${
                size === "medium" ? "" : "text-base"
              }`}
            >
              {appendIcon}
            </Icon>
          )}
        </div>
      </div>
    </div>
  );
};

const Icon: React.FC<{
  round: boolean;
  className?: string;
  children: React.ReactNode;
}> = ({ round, className, children }) => (
  <span className={`icon ${round ? "rounded-full" : ""} ${className}`}>
    {children}
  </span>
);

export default Button;

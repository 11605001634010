import React from "react";
import FeedTwitter from "../feed/twitter";
import FeedMedium from "../feed/medium";
import config from "../../config";

interface Props {
  className?: string;
}

const Announcements: React.FC<Props> = ({ className }) => {
  return (
    <div className={className}>
      <div className="text-h4 mb-4 uppercase">Announcements</div>
      <div className="rounded-[2px] border border-[#19506F] p-4 grid grid-flow-row auto-rows-max gap-4 w-full">
        <div className="rounded">
          <FeedTwitter url={config.announcement.twitter} />
        </div>
        <div className="rounded">
          <FeedMedium url={config.announcement.medium} />
        </div>
      </div>
    </div>
  );
};

export default Announcements;

import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useWallet, useConnection } from "@solana/wallet-adapter-react";
import {
  WalletNotConnectedError,
  SignerWalletAdapterProps,
  WalletName,
} from "@solana/wallet-adapter-base";
import { SolanaSignInInput } from "@solana/wallet-standard-features";
import {
  PublicKey,
  Transaction,
  Connection,
  TransactionInstruction,
  LAMPORTS_PER_SOL,
  SystemProgram,
} from "@solana/web3.js";
import {
  createTransferCheckedInstruction,
  createAssociatedTokenAccountInstruction,
  getAssociatedTokenAddress,
  getAccount,
  getMint,
} from "@solana/spl-token";
import { Adapter, WalletReadyState } from "@solana/wallet-adapter-base";
import { getWallets } from "@wallet-standard/app";
import encode from "bs58";
import { ChainDetailType } from "../../../interfaces/environment";
import { DepositToken, WithdrawToken } from "../../../interfaces/wallet";

const useSolanaWalletInternal = (
  chain: ChainDetailType.Item,
  token: DepositToken | WithdrawToken | undefined
) => {
  const { connection } = useConnection();
  const {
    connect: _connect,
    disconnect: _disconnect,
    select: _select,
    wallet: _wallet,
    wallets: _wallets,
    connected: _connected,
    publicKey: _publicKey,
    signMessage: _signMessage,
    signTransaction: _signTransaction,
    signIn: _signIn,
  } = useWallet();

  const accountRef = useRef<string | undefined>(undefined);
  useEffect(() => {
    accountRef.current = _publicKey?.toBase58();
  }, [_publicKey]);

  const connectPromiseResolver = useRef<{
    resolve: (value: any) => void;
    reject: (reason?: any) => void;
  } | null>(null);
  const [accountChangeWallet, setAccountChangeWallet] =
    useState<WalletName<string> | null>(null);
  const [isDoConnect, setIsDoConnect] = useState<boolean>(false);
  const [isDoSign, setIsDoSign] = useState<boolean>(false);
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [isNeedSignIn, setIsNeedSignIn] = useState<boolean>(false);
  const [isDoDisconnect, setIsDoDisconnect] = useState<boolean>(false);
  const [isAccoundChanged, setIsAccoundChanged] = useState<boolean>(false);
  const [hasAddress, setHasAddress] = useState<boolean>(false);

  const [balance, setBalance] = useState<string>("");

  const connect = useCallback(
    async (walletName: string) => {
      return new Promise<string>((resolve, reject) => {
        // console.log("Attempting to connect to wallet:", walletName);
        const connector = _wallets.find(
          (wallet) => wallet.adapter.name === walletName
        );
        // console.log("connect: connector", connector);
        if (!connector) {
          console.error("Wallet not found:", walletName);
          return null;
        }

        _select(connector.adapter.name);
        setAccountChangeWallet(connector.adapter.name);
        // console.log("connect: is connect", connector.adapter.connected);
        // console.log("connect: isDoConnect", isDoConnect);
        // console.log("connect: isNeedSignIn", isNeedSignIn);

        setIsDoConnect(true);
        setIsNeedSignIn(true);
        connectPromiseResolver.current = { resolve, reject };

        return connectPromiseResolver.current;
      });
    },
    [_select, _wallets]
  );

  const reconnect = useCallback(
    async (walletName: string) => {
      if (!_wallet) {
        const _adapter = _wallets.find(
          (wallet) => wallet.adapter.name === walletName
        );
        // console.log("sol reconnect", _adapter);
        if (_adapter) {
          _select(_adapter.adapter.name);
          await _adapter.adapter.autoConnect();
        } else {
          return;
        }
      }
      setIsNeedSignIn(false);
      setIsDoConnect(true);
    },
    [_select, _wallet, _wallets]
  );

  const disconnect = useCallback(async () => {
    setIsDoDisconnect(true);
    _select(null);
  }, [_select]);

  const signMessage = useCallback(
    async (message: string) => {
      // console.log("wallet", _wallet);
      if (!_publicKey || !_connected) throw new Error("Wallet not connected");
      const encodedMsg = new TextEncoder().encode(message);
      if (!_signMessage) throw new Error("Sign message not supported");
      const signedMessage = await _signMessage(encodedMsg);
      if (!signedMessage) {
        console.error("useSolanaSignMessage: Failed to sign message");
        throw new Error("Failed to sign message");
      }
      const bs58 = encode.encode(signedMessage);
      return bs58;
    },
    [_connected, _publicKey, _signMessage]
  );

  const configureAndSendCurrentTransaction = async (
    transaction: Transaction,
    connection: Connection,
    feePayer: PublicKey,
    signTransaction: SignerWalletAdapterProps["signTransaction"]
  ) => {
    const blockHash = await connection.getLatestBlockhash();
    transaction.feePayer = feePayer;
    transaction.recentBlockhash = blockHash.blockhash;
    const signed = await signTransaction(transaction);
    const signature = await connection.sendRawTransaction(signed.serialize());
    return signature;
  };

  const handlePayment = useCallback(
    async (address: string, amount: number) => {
      try {
        if (!_publicKey || !_signTransaction) {
          throw new WalletNotConnectedError();
        }
        const recipient = new PublicKey(address);
        const transaction = new Transaction().add(
          SystemProgram.transfer({
            fromPubkey: _publicKey,
            toPubkey: recipient,
            lamports: amount * LAMPORTS_PER_SOL,
          })
        );
        const signature = await configureAndSendCurrentTransaction(
          transaction,
          connection,
          _publicKey,
          _signTransaction
        );
        return !!signature;
      } catch (error) {
        console.error("handlePayment error", error);
        throw error;
      }
    },
    [_publicKey, _signTransaction, connection]
  );

  const handleSplPayment = useCallback(
    async (address: string, amount: number) => {
      try {
        if (!_publicKey || !_signTransaction) {
          throw new WalletNotConnectedError();
        }
        const mintToken = new PublicKey(token?.address as string);
        const recipientAddress = new PublicKey(address);

        const tokenInfo = await getMint(connection, mintToken);

        const transactionInstructions: TransactionInstruction[] = [];
        const associatedTokenFrom = await getAssociatedTokenAddress(
          mintToken,
          _publicKey
        );
        const fromAccount = await getAccount(connection, associatedTokenFrom);
        const associatedTokenTo = await getAssociatedTokenAddress(
          mintToken,
          recipientAddress
        );
        if (!(await connection.getAccountInfo(associatedTokenTo))) {
          transactionInstructions.push(
            createAssociatedTokenAccountInstruction(
              _publicKey,
              associatedTokenTo,
              recipientAddress,
              mintToken
            )
          );
        }
        transactionInstructions.push(
          createTransferCheckedInstruction(
            fromAccount.address, // source
            mintToken, // mint
            associatedTokenTo, // dest
            _publicKey,
            amount * Math.pow(10, tokenInfo.decimals),
            tokenInfo.decimals
          )
        );
        const transaction = new Transaction().add(...transactionInstructions);
        const signature = await configureAndSendCurrentTransaction(
          transaction,
          connection,
          _publicKey,
          _signTransaction
        );
        // signature is transaction address, you can confirm your transaction on 'https://explorer.solana.com/?cluster=devnet'
        return !!signature;
      } catch (error) {
        console.error("handlePayment error", error);
        throw error;
      }
    },
    [_publicKey, _signTransaction, connection, token]
  );

  const transfer = useCallback(
    async (address: string, amount: number) => {
      // console.log(
      //   "useSolanaConnection: transfer",
      //   address,
      //   amount,
      //   tokenAddress
      // );
      if (token?.address !== "11111111111111111111111111111111") {
        return handleSplPayment(address, amount);
      } else {
        return handlePayment(address, amount);
      }
    },
    [handlePayment, handleSplPayment, token]
  );

  const fetchBalance = useCallback(async () => {
    // fetch balance
    // console.log("useSolanaConnection: fetchBalance");
    let balance = 0;
    if (!_publicKey || !token?.address) {
      // console.log("fetchBalance: no public key or token address");
      setBalance(balance.toString());
      return;
    }
    // console.log("tokenAddress", tokenAddress);
    try {
      if (token?.token === "SOL") {
        // SOL
        // console.log("fetchBalance: SOL");
        // console.log("fetchBalance: _publicKey", _publicKey);
        balance = await connection.getBalance(_publicKey, "confirmed");
        balance = balance / LAMPORTS_PER_SOL;
        // console.log("fetchBalance: balance", balance);
      } else {
        // SPL
        try {
          const mintToken = new PublicKey(token?.address as string);
          const associatedToken = await getAssociatedTokenAddress(
            mintToken,
            _publicKey
          );
          // console.log("associatedToken", associatedToken);
          const tokenAccountBalance = await connection.getTokenAccountBalance(
            associatedToken
          );
          balance =
            Number(tokenAccountBalance.value.amount) /
            Math.pow(10, tokenAccountBalance.value.decimals);
        } catch (error) {
          console.error("fetchBalance error", error);
        }
      }

      setBalance(balance.toString());
    } catch (error) {
      console.error("fetchBalance error", error);
    }
  }, [_publicKey, connection, token]);

  useEffect(() => {
    const doConnectWallet = async () => {
      console.log(
        "doConnectWallet, isdoo connenct, wallet connect",
        isDoConnect,
        _wallet?.adapter.connected
      );
      if (!isDoConnect || !_wallet) return;
      setIsDoConnect(false);
      try {
        // handle case when user change account and it auto connect
        const latestPublicKey = localStorage.getItem("latestPublicKey");
        // console.log("connect: latestPublicKey", latestPublicKey);
        // console.log(
        //   "connect: _wallet.adapter.connected",
        //   _wallet.adapter.connected
        // );
        if (_wallet.adapter.connected) {
          if (latestPublicKey) {
            if (_wallet.adapter.publicKey?.toBase58() === latestPublicKey) {
              setIsConnected(false);
              setHasAddress(false);
              setIsDoSign(true);
              return;
            }
          }

          // it's an account change so do single disconnect then connect
          // console.log("Account changed");
          setIsAccoundChanged(true);
          setIsDoDisconnect(true);
          return;
        }
        await _connect();
        await _wallet.adapter.autoConnect();
        setIsDoSign(true);
      } catch (error) {
        console.error("Failed to connect to wallet:", error);
        setIsDoSign(false);
        setIsConnected(false);
        setHasAddress(false);
        setIsDoConnect(false);
        connectPromiseResolver.current &&
          connectPromiseResolver.current.reject(error);
      }
    };
    doConnectWallet();
  }, [isDoConnect, _connect, _signIn, _publicKey, _wallet]);

  useEffect(() => {
    const doDisconnectWallet = async () => {
      if (!isDoDisconnect) return;
      if (!_connected) {
        setIsDoDisconnect(false);
        return;
      }
      setIsDoDisconnect(false);
      try {
        if (isAccoundChanged) {
          setIsAccoundChanged(false);
          await _wallet?.adapter.disconnect();
          setIsConnected(false);
          setHasAddress(false);
          console.log("accountChangeWallet", accountChangeWallet);
          _select(accountChangeWallet);
          setIsDoConnect(true);
          return;
        }
        await _disconnect();
        setIsConnected(false);
        setHasAddress(false);
        // console.log("Disconnected wallet");
      } catch (error) {
        console.error("Failed to disconnect wallet:", error);
      }
    };
    doDisconnectWallet();
  }, [
    isDoDisconnect,
    _connected,
    _disconnect,
    isAccoundChanged,
    _wallet?.adapter,
    _select,
    accountChangeWallet,
  ]);

  useEffect(() => {
    const doSignIn = async () => {
      if (isDoSign) {
        setIsDoSign(false);
        if (!_publicKey) {
          console.error("Failed to connect to wallet:");
          connectPromiseResolver.current &&
            connectPromiseResolver.current.reject(
              "Failed to connect to wallet"
            );
          return null;
        }

        localStorage.setItem("latestPublicKey", _publicKey.toBase58());
        try {
          if (isNeedSignIn && _signIn) {
            const domain = window.location.origin;
            var payload: SolanaSignInInput;

            if (_wallet?.adapter.name === "Coin98") {
              payload = {
                domain: domain,
              };
            } else {
              payload = {};
            }
            await _signIn(payload);
          }
          // console.log("Signed in successfully");
          // console.log("publicKey", _publicKey.toBase58());
          // console.log("wallet", _wallet?.adapter.name);
          setIsConnected(true);
          setHasAddress(true);
          connectPromiseResolver.current &&
            connectPromiseResolver.current.resolve(_publicKey.toBase58());
        } catch (error) {
          console.error("Failed to sign in:", error);
          connectPromiseResolver.current &&
            connectPromiseResolver.current.reject(error);
          setIsConnected(false);
        }
      }
    };
    doSignIn();
  }, [
    isDoSign,
    _signIn,
    _publicKey,
    disconnect,
    _wallet?.adapter.name,
    isNeedSignIn,
  ]);

  // useEffect(() => {
  //   console.log("--------------------");
  //   console.log("useSolanaConnection: useEffect isConnected", isConnected);
  //   console.log("useSolanaConnection: useEffect address", accountRef.current);
  //   console.log("useSolanaConnection: useEffect _publicKey", _publicKey);
  //   console.log("useSolanaConnection: useEffect _wallet", _wallet);
  //   console.log("useSolanaConnection: useEffect _wallets", _wallets);
  //   console.log("useSolanaConnection: useEffect _connected", _connected);
  //   console.log("useSolanaConnection: useEffect isDoConnect", isDoConnect);
  //   console.log("useSolanaConnection: useEffect isNeedSignIn", isNeedSignIn);
  //   console.log("useSolanaConnection: useEffect isDoSign", isDoSign);
  //   console.log(
  //     "useSolanaConnection: useEffect isDoDisconnect",
  //     isDoDisconnect
  //   );
  //   console.log(
  //     "useSolanaConnection: useEffect isAccoundChanged",
  //     isAccoundChanged
  //   );
  //   console.log("--------------------");
  // }, [
  //   isConnected,
  //   _wallets,
  //   _publicKey,
  //   _wallet,
  //   _connected,
  //   isDoConnect,
  //   isNeedSignIn,
  //   isDoSign,
  //   isDoDisconnect,
  //   isAccoundChanged,
  // ]);

  return {
    isConnected: isConnected,
    walletName: _wallet?.adapter.name,
    walletNames: _wallets
      .filter((wallet) => wallet.readyState === WalletReadyState.Installed)
      .map((wallet) => wallet.adapter.name),
    account: accountRef,
    connect: connect,
    reconnect: reconnect,
    hasAddress,
    disconnect: disconnect,
    selectedConnector: _wallet,
    balance,
    signMessage,
    transfer: transfer,
    fetchBalance,
  };
};

export default useSolanaWalletInternal;

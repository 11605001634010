import React from "react";

const ButtonWrapper: React.FC<{
  children: React.ReactNode;
  className?: string;
}> = ({ children, className }) => {
  return (
    <div className={className}>
      <div className="md:hidden bg-gradient-to-b from-transparent to-gray-900 w-full h-10 fixed justify-center flex md:p-0 pl-4 pr-4 bottom-14 left-0 z-30" />
      <div className="w-full container mx-auto left-0 right-0 fixed md:relative mt-16 justify-center flex px-4 sm:px-0 bottom-14 z-30">
        {children}
      </div>
    </div>
  );
};

export default ButtonWrapper;

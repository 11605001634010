import { useAccount, useSendTransaction, useWriteContract } from "wagmi";
import { ethers } from "ethers";
import { parseEther } from "viem";
import {
  ChainDetailType,
  DepositToken,
  WithdrawToken,
} from "../../../interfaces/wallet";
import { MutableRefObject, useCallback } from "react";

export const ERC20Abi = [
  {
    constant: true,
    inputs: [],
    name: "name",
    outputs: [
      {
        name: "",
        type: "string",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      {
        name: "_spender",
        type: "address",
      },
      {
        name: "_value",
        type: "uint256",
      },
    ],
    name: "approve",
    outputs: [
      {
        name: "",
        type: "bool",
      },
    ],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "totalSupply",
    outputs: [
      {
        name: "",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      {
        name: "_from",
        type: "address",
      },
      {
        name: "_to",
        type: "address",
      },
      {
        name: "_value",
        type: "uint256",
      },
    ],
    name: "transferFrom",
    outputs: [
      {
        name: "",
        type: "bool",
      },
    ],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "decimals",
    outputs: [
      {
        name: "",
        type: "uint8",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "_owner",
        type: "address",
      },
    ],
    name: "balanceOf",
    outputs: [
      {
        name: "balance",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "symbol",
    outputs: [
      {
        name: "",
        type: "string",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      {
        name: "_to",
        type: "address",
      },
      {
        name: "_value",
        type: "uint256",
      },
    ],
    name: "transfer",
    outputs: [
      {
        name: "",
        type: "bool",
      },
    ],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "_owner",
        type: "address",
      },
      {
        name: "_spender",
        type: "address",
      },
    ],
    name: "allowance",
    outputs: [
      {
        name: "",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    payable: true,
    stateMutability: "payable",
    type: "fallback",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        name: "spender",
        type: "address",
      },
      {
        indexed: false,
        name: "value",
        type: "uint256",
      },
    ],
    name: "Approval",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        name: "from",
        type: "address",
      },
      {
        indexed: true,
        name: "to",
        type: "address",
      },
      {
        indexed: false,
        name: "value",
        type: "uint256",
      },
    ],
    name: "Transfer",
    type: "event",
  },
];

const useEthWalletTransfer = (
  chain: ChainDetailType.Item,
  account: MutableRefObject<`0x${string}` | undefined>,
  token: DepositToken | WithdrawToken | undefined,
  switchChain: (chainId: number) => Promise<boolean>
) => {
  const { sendTransactionAsync } = useSendTransaction();
  const { writeContractAsync } = useWriteContract();
  const { connector: currentConnector } = useAccount();
  const nativeTransfer = useCallback(
    async (toAddress: string, val: number) => {
      const res = await sendTransactionAsync(
        {
          account: account.current,
          to: toAddress as `0x${string}`,
          value: parseEther(val.toString()),
        },
        {
          onSuccess(data, variables, context) {
            if (!data) {
              throw new Error("nativeTransfer failed");
            }
            return data;
          },
          onError: (error) => {
            console.log("useEthWalletTransfer: nativeTransfer, error", error);
            throw error;
          },
        }
      );

      return res;
    },
    [sendTransactionAsync]
  );

  const erc20Transfer = useCallback(
    async (toAddress: string, val: number) => {
      // console.log("erc20Transfer", {
      //   abi: ERC20Abi,
      //   account: account.current,
      //   address: token?.address as `0x${string}`,
      //   functionName: "transfer",
      //   args: [toAddress, ethers.parseEther(val.toString())],
      // });
      const res = await writeContractAsync(
        // @ts-ignore
        {
          abi: ERC20Abi,
          account: account.current,
          address: token?.address as `0x${string}`,
          functionName: "transfer",
          args: [toAddress, ethers.parseEther(val.toString())],
        },
        {
          onSuccess(data: any, variables: any, context: any) {
            if (!data) {
              throw new Error("erc20 transfer failed");
            }
            return data;
          },
          onError: (error: any) => {
            console.log("useEthWalletTransfer: erc20 transfer, error", error);
            throw error;
          },
        }
      );

      return res;
    },
    [account, token, writeContractAsync]
  );

  const transfer = async (toAddress: string, val: number) => {
    if (!account.current) {
      throw new Error("account is required");
    }
    if (!token) {
      throw new Error("token is required");
    }
    if (!currentConnector) {
      throw new Error("Connector not found");
    }
    const currentChain = await currentConnector.getChainId();
    if (currentChain !== chain.chainId) {
      await switchChain(chain.chainId);
    }

    var res: any;
    if (token.token === chain.currencySymbol) {
      res = await nativeTransfer(toAddress, val);
    } else {
      res = await erc20Transfer(toAddress, val);
    }

    return !!res;
  };

  return {
    transfer,
  };
};

export default useEthWalletTransfer;

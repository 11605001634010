import React, { useState, useEffect } from "react";
import {
  setTrxDeadline,
  useSlippageDispatch,
  useSlippageState,
} from "../../contexts/slippageContext";
import InputText from "../input/text";

interface RootState {
  configuration: {
    trxDeadline: string;
  };
}

const SettingTransactionDeadlineInput: React.FC = () => {
  const { trxDeadline } = useSlippageState();
  const dispatch = useSlippageDispatch();
  //   const dispatch = useDispatch();
  //   const trxDeadline = useSelector((state: RootState) => state.configuration.trxDeadline);

  const [amount, setAmount] = useState<string>("1");
  const [isFocus, setIsFocus] = useState<boolean>(false);

  // Set initial amount based on trxDeadline from store
  useEffect(() => {
    setAmount(trxDeadline || "1");
  }, [trxDeadline]);

  const onUpdateAmount = (e: React.FocusEvent<HTMLInputElement>) => {
    let value = e.target.value;

    if (parseFloat(value) > 99) {
      value = "99";
    } else if (parseFloat(value) <= 0) {
      value = "1";
    } else if (value === "") {
      value = process.env.REACT_APP_TX_DEADLINE_DEFAULT || "1"; // Use environment variable or fallback to '1'
    }

    setAmount(Math.ceil(parseFloat(value)).toString());

    setTrxDeadline(dispatch, Math.ceil(parseFloat(value)).toString());
    setIsFocus(false);
  };

  const onlyNumberWithoutDot = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === ".") {
      e.preventDefault(); // Prevent dots from being entered
    }
  };

  return (
    <div>
      <div className="flex">
        <span className="flex flex-grow">
          <span
            className={`w-[64px] mt-1 flex flex-row slippage-inp-outer rounded-[2px] text-base border ${
              isFocus ? "border-evry-primary" : "border-[#4E4E4E]"
            }`}
          >
            <InputText
              data-testid="trx_deadline_inp"
              type="number"
              placeholder="20"
              className="w-full bg-transparent text-center flex-grow border-none py-1 outline-none overflow-hidden text-base text-white placeholder-gray-placeholder"
              // className="flex-grow border-none text-center py-2 outline-none overflow-hidden text-base text-white placeholder-gray-placeholder"
              value={amount}
              onBlur={onUpdateAmount}
              onKeyDown={onlyNumberWithoutDot}
              onFocus={() => setIsFocus(true)}
            />
          </span>
          <span className="flex justify-center items-center ml-4 mt-2 text-base">
            Minute(s)
          </span>
        </span>
      </div>
    </div>
  );
};

export default SettingTransactionDeadlineInput;

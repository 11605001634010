import { ChainDetailType } from "../../../interfaces/environment";
import {
  DepositToken,
  WalletDetailType,
  WithdrawToken,
} from "../../../interfaces/wallet";
import useSolanaWalletInternal from "./useSolanaWalletInternal";

export function useSolanaWalletApi(
  chain: ChainDetailType.Item,
  token: DepositToken | WithdrawToken | undefined,
  setToken: (token: DepositToken | WithdrawToken) => void
): WalletDetailType.WalletAPI {
  const {
    isConnected,
    walletName,
    walletNames,
    account,
    connect,
    reconnect,
    hasAddress,
    disconnect,
    signMessage,
    transfer,
    balance,
    fetchBalance,
  } = useSolanaWalletInternal(chain, token);
  return {
    chain,
    isConnected,
    walletName,
    walletNames,
    connect,
    reconnect,
    hasAddress,
    account,
    disconnect,
    balance: balance,
    setBalanceToken: setToken,
    fetchBalance: fetchBalance,
    transfer: transfer,
    signMessage: signMessage,
    switchChain: async () => false,
    addToken: async () => false,
  };
}

import React from "react";
import { Link } from "react-router-dom"; // Assuming you're using React Router for navigation

const Footer = () => {
  return (
    <footer className="h-20 flex justify-center items-center footer">
      <Link to="/" className="text-velo-primary text-base font-normal">
        © Velo Labs © 2022
      </Link>
    </footer>
  );
};

export default Footer;

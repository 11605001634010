import React from "react";
import MaterialIcon from "../icon";

const ButtonGoToTop = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div
      className="absolute bottom-0 right-4 block md:hidden cursor-pointer"
      onClick={scrollToTop}
    >
      <div className="flex flex-col justify-center items-center bg-[#2EADD4] w-12 h-12 rounded-full">
        <MaterialIcon className="text-2xl text-white font-bold">
          arrow_upward
        </MaterialIcon>
      </div>
    </div>
  );
};

export default ButtonGoToTop;

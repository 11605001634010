import BigNumber from "bignumber.js";
import config from "../config";
import {config as wagmiConfig }from "../../wagmi.config";
import useLiquidity from "./useLiquidityHelpers";
import {
  FarmToken,
  FixAPRToken,
  LiquidityPool,
  Pair,
} from "../interfaces/token";
import {
  waitForTransactionReceipt,
} from "@wagmi/core";
import { useParams } from "react-router-dom";
import { useEthereum } from "../contexts/etherruemContext";
import { ethers } from "ethers";
import { useWriteContract } from "wagmi";
import useLazyReadContractAsync from "./useLazyReadContractAsync";
import ERC20 from "../../src/assets/abi/farm/ERC20.json";

export interface MapIDofReserves extends LiquidityPool {
  reserves: Record<string, BigNumber>;
  ratios: Record<string, BigNumber>;
}

const useFarmCommon = () => {
  const { getListReserveInPool, getToken0AndToken1InLp } = useLiquidity();
  const { chains } = useParams<{ chains: string }>();
  const ethereum = useEthereum();
  const { writeContractAsync } = useWriteContract();
  const { lazyReadContract } = useLazyReadContractAsync();

  const getChainId = (code: string) => {
    const { networks } = config;
    const result = Object.keys(networks).find((o) => {
      return networks[Number(o)].code.toLowerCase() === code.toLowerCase();
    });
    return result;
  };

  const chainId = () => {
    if (!chains) return;
    return getChainId(chains);
  };

  const getMapIDofReserves = async (pairIds: string[]) => {
    const reservePools = await getListReserveInPool(pairIds);
    const mapIDofReserves: Record<string, MapIDofReserves> = {};
    await Promise.all(
      reservePools.map(async (reservePool, idx) => {
        let pair = config.lp[pairIds[idx]];
        if (pair === undefined) {
          pair = config.dmmLP[pairIds[idx]];
        }
        if (!pair.address) {
          return {};
        }
        const reserve0 = BigNumber(reservePool._reserve0);
        const reserve1 = BigNumber(reservePool._reserve1);
        const ratios: Record<string, BigNumber> = {};
        const reserves: Record<string, BigNumber> = {};
        const tokens = await getToken0AndToken1InLp(pair.code);

        if (tokens.token0 && tokens.token1) {
          ratios[tokens.token0.code] = reserve0.div(reserve1);
          ratios[tokens.token1.code] = reserve1.div(reserve0);
          reserves[tokens.token0.code] = reserve0;
          reserves[tokens.token1.code] = reserve1;
        }
        mapIDofReserves[pair.code] = {
          ...pair,
          reserves,
          ratios,
        };
        return {};
      })
    );

    return mapIDofReserves;
  };

  const getPriceTokenExternalApi = async (token: string) => {
    let priceSet: any;
    if (!priceSet) {
      try {
        const url = config.externalPriceAPI + token;
        const xmlHttp = new XMLHttpRequest();
        xmlHttp.open("GET", url, false);
        await xmlHttp.send(null);
        priceSet = JSON.parse(xmlHttp.responseText);
        // console.log(new Date(), "set token price cache");
        setTimeout(() => {
          priceSet = undefined;
          // console.log(new Date(), "clear token price cache");
        }, 10000);
      } catch (e) {
        console.error("getPriceTokenExternalApi", e);
      }
    }
    return priceSet?.market_data?.current_price?.usd || 0;
  };

  const getReserveByPairCode = (
    mapIDofReserves: Record<string, MapIDofReserves>,
    symbol1: string,
    symbol2: string
  ) => {
    return (
      mapIDofReserves[`${symbol1}_${symbol2}`] ||
      mapIDofReserves[`${symbol2}_${symbol1}`]
    );
  };

  const getPrice = async (
    tokens: Record<string, any>,
    mapIDofReserves: Record<string, MapIDofReserves>
  ) => {
    const tokenPrice: Record<string, number> = {};
    for (const id in tokens) {
      let targetCode = tokens[id].symbol;
      let targetPrice = 1;
      // check if external else for loop
      if (tokens[id].externalPrice) {
        targetPrice = await getPriceTokenExternalApi(tokens[id].slug ?? "");
      }
      // else if (tokens[id].fixedPrice) {
      //     targetPrice = tokens[id].fixedPrice;
      //   }
      else {
        for (const route in tokens[id].priceRoute) {
          const reserve = getReserveByPairCode(
            mapIDofReserves,
            targetCode,
            tokens[id].priceRoute[route]
          );
          if (reserve === undefined) {
            // console.log(
            //   `❗ not found reserved for ${targetCode}-${tokens[id].priceRoute[route]}`
            // );
            continue;
          }
          const ratio = reserve.ratios[`${tokens[id].priceRoute[route]}_TOKEN`];
          targetCode = tokens[id].priceRoute[route];
          targetPrice = targetPrice * Number(ratio);
        }
      }
      tokenPrice[tokens[id].symbol] = targetPrice;
    }
    return tokenPrice;
  };

  const getLiquidityLink = (staking: any) => {
    if (staking?.lpType) {
      return `/pool/add?tokenA=${staking.token0.code}&tokenB=${staking.token1.code}`;
    } else if (staking?.fixedPrice || staking?.externalPrice) {
      return null;
    } else {
      return `/exchange/swap?tokenB=${staking.code}`;
    }
  };

  const approveToken = async (
    contractAddress: string,
    tokenAddress: string,
    account: string,
    events: any
  ) => {
    try {
      // const id = chainId();
      const hash = await writeContractAsync(
        // @ts-ignore
        {
          // abi: AutoRouter,
          // account: account.current,
          abi: ERC20,
          // @ts-ignore
          from: account,
          // @ts-ignore
          address: tokenAddress,
          functionName: "approve",
          args: [contractAddress, ethers.MaxUint256],
        },
        {
          onSuccess(data: any, variables: any, context: any) {
            console.log("approveToken: data", data);
            if (!data) {
              throw new Error("approveToken: data is null");
            }
            return data;
          },
          onError: (error: any) => {
            console.log("approveToken: error", error);
            throw error;
          },
        }
      );
       // Trigger transactionHash callback
       if (events.onTransactionHash) events.onTransactionHash(hash);

       // Wait for the transaction to be mined
       const receipt = await waitForTransactionReceipt(wagmiConfig, { hash });
 
       // Trigger receipt callback
       if (events.onReceipt) events.onReceipt({transactionHash: receipt.transactionHash, status: receipt.status === "success"});
 
      // if (ethereum.contracts != null && id) {
      //   const tokenContract =
      //     ethereum.contracts[id]?.tokens?.[tokenCode] ||
      //     ethereum.contracts.tokens[tokenCode];
      //   const result = await tokenContract.methods
      //     .approve(contractAddress, ethers.MaxUint256)
      //     .send({ from: account })
      //     .on("transactionHash", (txHash: any) => {
      //       events.onTransactionHash(txHash);
      //     })
      //     .on("receipt", (receipt: any) => {
      //       events.onReceipt(receipt);
      //     })
      //     .on("error", (err: any, receipt: any) => {
      //       events.onError(err, receipt);
      //       throw err;
      //     });

      //   return result;
      // }
      return { transactionHash: receipt.transactionHash, status: receipt.status === "success" };
    } catch (error) {
      console.error("approveToken", error);
      events.onError(error);
      return { transactionHash: "", status: false };
    }
  };

  return {
    getMapIDofReserves,
    getPriceTokenExternalApi,
    getReserveByPairCode,
    getPrice,
    getLiquidityLink,
    chainId,
    approveToken,
  };
};

export default useFarmCommon;

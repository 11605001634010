import React, { useState } from "react";
import MaterialIcon from "./icon";

interface Props {
  message?: string;
  width?: string;
  widthModal?: string;
  fontSize?: string;
  color?: string;
  onModal?: boolean;
  iconClass?: string;
  iconSize?: string;
  margin?: string;
  customWidth?: string;
  customTranslateX?: string;
  customTranslateY?: string;
  className?: string;
}

const Tooltip: React.FC<Props> = ({
  message = "",
  width = "96",
  widthModal = "base-pading",
  fontSize = "text-sm",
  color = "",
  onModal = false,
  iconClass = "text-lg",
  iconSize = "lg",
  margin = "1",
  customWidth = "",
  customTranslateX = "",
  customTranslateY = "",
  className,
}) => {
  const [hover, setHover] = useState(false);

  return (
    <div className={`flex items-center justify-center ${className}`}>
      <div className="z-0 inline-flex">
        <div
          className="bg-transparent cursor-pointer"
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <MaterialIcon
            className={`mx-${margin} text-${iconSize} ${
              color ? `text-${color}` : ""
            } ${iconClass} flex items-center`}
          >
            help_outline
          </MaterialIcon>
        </div>

        {hover && (
          <div className="animate-fade-in text-sm">
            <span
              className={`
                ${
                  onModal
                    ? "left-2/4 md:left-1/4 md:ml-1"
                    : "left-2/4 md:left-auto"
                }
                ${
                  onModal
                    ? `w-base-pading md:w-${widthModal}`
                    : `w-base-pading md:w-${width} ${customWidth}`
                }
                bg-velo-wisp-pink text-velo-secondary
                absolute z-10 p-4 mt-1 ${fontSize}
                transform -translate-x-1/2 md:-translate-x-1/4 ${customTranslateX} -translate-y-full ${customTranslateY}
                rounded-[5px] shadow-lg
              `}
              dangerouslySetInnerHTML={{ __html: message }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Tooltip;

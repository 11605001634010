import { useMemo, useState } from "react";
import { getDate, getDateByUnix, period as getPeriod } from "../utils/datetime";
import dayjs from "dayjs";

interface Prop {
  onForceCountDown: () => void;
  startTime?: number;
  endTime?: number;
  periodFix?: number;
}

const usePeriodHelpers = ({
  onForceCountDown,
  startTime = 1635397706,
  endTime = 1638259544,
  periodFix = 0,
}: Prop) => {
  const [timer, setTimer] = useState<any>("");
  const [countDown, setCountdown] = useState<number>(0);
  // const [isFetching, setIsFetching] = useState<boolean>(false);

  const period = useMemo(() => {
    return getPeriod(startTime, endTime);
  }, [startTime, endTime]);

  const currentPeriod = useMemo(() => {
    const nowUnix = getDateByUnix().unix();
    const startPeriod = nowUnix > startTime ? nowUnix : startTime;

    const period = getPeriod(startPeriod, endTime);
    return period <= 0 ? 0 : period;
  }, [startTime, endTime]);

  const periodRange = useMemo(() => {
    const now = getDate();
    const startTimeDate = getDateByUnix(startTime);
    const endTimeDate = periodFix
      ? dayjs(startTimeDate).add(periodFix, "day")
      : getDateByUnix(endTime);

    if (
      now.isSameOrBefore(startTimeDate.subtract(24, "hour")) ||
      now.isBetween(startTimeDate, endTimeDate)
    ) {
      const startPeriod = startTimeDate.format("D MMM YYYY");
      const endPeriod = endTimeDate.format("D MMM YYYY");
      return `${startPeriod} - ${endPeriod}`;
    } else if (
      now.isBetween(startTimeDate, startTimeDate.subtract(24, "hour"))
    ) {
      if (timer) clearTimeout(timer);

      const diffInSeconds = startTimeDate.diff(now, "second");
      setCountdown(diffInSeconds);
      setTimer(setTimeout(() => setCountdown(diffInSeconds - 1), 1000));

      return dayjs.duration(countDown * 1000).format("HH : mm : ss");
    } else {
      return "Period has ended";
    }
  }, [startTime, endTime, periodFix, countDown, timer]);

  const isShowPeriodCountdown = useMemo(() => {
    const nowUnix = getDateByUnix().unix();
    return (!countDown || countDown <= 0) && nowUnix >= startTime;
  }, [countDown, startTime]);

  const countDownTimer = (second: number) => {
    setCountdown(second);
    if (second <= 0) {
      onForceCountDown();
    }
    setTimer(
      setTimeout(() => {
        setCountdown((prev) => prev - 1);
      }, 1000)
    );
  };
  const clearTimer = () => {
    clearTimeout(timer);
    setTimer("");
  };

  return {
    getPeriod,
    currentPeriod,
    periodRange,
    isShowPeriodCountdown,
    countDownTimer,
    clearTimer,
  };
};

export default usePeriodHelpers;

import React from "react";
import { Link } from "react-router-dom";
// Uncomment the following line if you implement the NetworkSelectButton component
// import NetworkSelectButton from './NetworkSelectButton';
import WalletConnectButton from "../wallet/ConnectButton";
import veloLogo from "../../images/header/velo-logo.svg";

interface Props {
  isReferral: boolean;
  walletAddress: string;
  onConnectWallet: () => void;
  onConnectNetwork: () => void;
}

const Header: React.FC<Props> = ({
  isReferral,
  walletAddress,
  onConnectWallet,
  onConnectNetwork,
}) => {
  return (
    <nav className="h-20 px-4 md:px-6 justify-between items-center flex">
      <div className="flex items-center md:space-x-4">
        <Link to="/">
          <img
            className="cursor-pointer h-4 md:h-6"
            src={require("../../assets/header/velo-logo.svg").default}
            alt="velo-finance"
          />
        </Link>
      </div>

      {!isReferral && (
        <div className="justify-items-end">
          <div className="flex h-6 md:h-8 space-x-2">
            <WalletConnectButton
              // showTooltip={!eth.isConnected || walletAddress === ""}
              data-testid="connect_to_wallet_btn"
              onClickWallet={onConnectWallet}
            />
            {/* Uncomment and implement NetworkSelectButton if needed */}
            {/* {eth.isConnected && walletAddress.length > 0 && (
              <NetworkSelectButton
                isWrong={!store.state.wallet.validChain}
                data-testid="wallet_network_btn"
                onClick={onConnectNetwork}
              />
            )} */}
          </div>
        </div>
      )}
    </nav>
  );
};

export default Header;

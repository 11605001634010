import React from "react";
import { PairExtra } from "../../../interfaces/token";

interface HomeRecommendedYieldCardProps {
  pair: PairExtra;
  reward?: string;
}

const HomeRecommendedYieldCard: React.FC<HomeRecommendedYieldCardProps> = ({
  pair,
  reward = "VELO",
}) => {
  const formatBigNumberPercentAbbr = (value: number) => {
    // Implement your formatting logic here, or use an existing utility function
    return value.toFixed(2); // Example implementation
  };

  return (
    <div className="h-[87px] w-full mb-2 xl:mb-0 rounded-[2px] p-4 flex items-center cursor-pointer border border-[#19506F]">
      <div className="min-w-[56px] xl:min-w-min mr-2">
        <img className="h-8" src={pair.staking.image} alt="recommended-icon" />
      </div>
      <div>
        <div className="text-velo-wisp-pink mb-1">{pair.staking.name}</div>
        <div className="text-velo-wisp-pink text-sm opacity-50">
          Earn {reward}
        </div>
      </div>
      <div className="ml-auto text-velo-wisp-pink text-sm mr-1 xl:mr-5">
        APR: {formatBigNumberPercentAbbr(pair.apr * 100)}%
      </div>
      {/* <Icon className="text-velo-wisp-pink">navigate_next</Icon> */}
    </div>
  );
};

export default HomeRecommendedYieldCard;

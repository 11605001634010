import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  BrowserRouter,
  createBrowserRouter,
  Route,
  Routes,
} from "react-router-dom";
import { EthereumProvider } from "./contexts/etherruemContext";
import MainLayout from "./components/layouts/main";
import Home from "./routes/index";
import Swap from "./routes/exchange/swap";
import DirectExchange from "./routes/exchange/directExchange";
import ExchangeOmni from "./routes/exchange/exchangeOmni";
import Pool from "./routes/pool";
import Bridge from "./routes/velolabs";
import { WalletProvider } from "./contexts/walletContext";
import { FarmProvider } from "./contexts/farmContext";
import { SlippageProvider } from "./contexts/slippageContext";
import FarmLive from "./routes/farms/live";
import { NotificationProvider } from "./contexts/notificationContext";
import { MainConstantProvider } from "./contexts/mainContext";
import AddLiquidity from "./routes/pool/add";
import FarmExpired from "./routes/farms/expired";
import EthWalletProvider from "./providers/EthWalletProvider";
import SolanaWalletProvider from "./providers/SolanaWalletProvider";
import { UnifiedWalletProvider } from "./providers/UnifiedWalletProvider";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <SlippageProvider>
        <EthWalletProvider>
          <SolanaWalletProvider>
            <UnifiedWalletProvider>
              <WalletProvider>
                <EthereumProvider>
                  <FarmProvider>
                    <NotificationProvider>
                      <MainConstantProvider>
                        <MainLayout>
                          <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/exchange/swap" element={<Swap />} />
                            <Route
                              path="/exchange/directexchange"
                              element={<DirectExchange index={0} />}
                            />
                            <Route
                              path="/exchange/omni"
                              element={<ExchangeOmni index={1} />}
                            />
                            <Route path="/pool" element={<Pool />} />
                            <Route
                              path="/pool/add"
                              element={<AddLiquidity />}
                            />
                            <Route
                              path="/velolabs/thebridge"
                              element={<Bridge />}
                            />
                            <Route
                              path="/farms/:chains/live"
                              element={<FarmLive />}
                            />
                            <Route
                              path="/farms/:chains/expired"
                              element={<FarmExpired />}
                            />
                          </Routes>
                        </MainLayout>
                      </MainConstantProvider>
                    </NotificationProvider>
                  </FarmProvider>
                </EthereumProvider>
              </WalletProvider>
            </UnifiedWalletProvider>
          </SolanaWalletProvider>
        </EthWalletProvider>
      </SlippageProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

import React, { useCallback, useEffect, useRef, useState } from "react";
// import FarmHelpers from '~/mixins/farm-helpers';
// import EarnOtherFixedAPRLockHelpers from '~/mixins/earn-other-fixed-apr-lock-helpers';
// import EarnOtherFixedAPRHelpers from '~/mixins/earn-other-fixed-apr-helpers';
// import EarnOtherFixedAPRLockWithChangeableRatioHelpers from '~/mixins/earn-other-fixed-apr-lock-with-changeable-ratio-helpers';
import Intro from "../components/home/intro";
import RecommendYield from "../components/home/recommendYield/index";
import TotalValueLocked from "../components/home/totalValueLocked";
import Announcements from "../components/home/annoucements";
import ListedOn from "../components/home/listedOn";
import Partners from "../components/home/partners";
import Audits from "../components/home/audits";
import ButtonGoToTop from "../components/button/goToTop";
import config from "../config";
import { useFarm } from "../contexts/farmContext";
import { NewPair, Pair, PairExtra } from "../interfaces/token";
import { Farm } from "../interfaces/network";
import BigNumber from "bignumber.js";
import useAprHelper from "../hooks/useAprHelpers";
import useAprFixLockHelper from "../hooks/useAprFixLockHelpers";
import useAprFixLockWithChangeableRatio from "../hooks/useAprFixLockWithChangableRatioHelpers";

const Home = () => {
  const [marketCap, setMarketCap] = useState(0);
  const [totalValueLocked, setTotalValueLocked] = useState(0);
  const [totalSupply, setTotalSupply] = useState(0);
  const [rewardPerBlock, setRewardPerBlock] = useState(0);
  const [farmInfo, setFarmInfo] = useState<any>({});

  const [pairs, setPairs] = useState<PairExtra[]>([]);
  const [recommendedPairs, setRecommendedPairs] = useState<PairExtra[]>([]);
  const [farm, setFarm] = useState<Farm>();

  const { farmEnv, getFarmInfo, getTotalSupplyExternalApi } = useFarm();
  const { getTotalValueLock } = useAprHelper();
  const { getTotalValueLock: getTotalValueFixLock } = useAprFixLockHelper();
  const { getTotalValueLock: getTotalValueFixLockWithChangeableRatio } =
    useAprFixLockWithChangeableRatio();

  const farmHelper = useFarm();

  useEffect(() => {
    if (!farmEnv) return;
    const temp = farmEnv.find((o) => o.rewardToken.code === "VELO_TOKEN");
    setFarm(temp);
  }, [farmEnv]);

  // const farm = () => {
  //   return farmEnv.find((o) => o.rewardToken.code === "VELO_TOKEN");
  // };

  // useEffect(() => {
  //   const x = farm?.pairs.map((pair) => ({
  //     ...pair,
  //     apr: farmInfo?.pairExtras?.[pair.id]?.apr || 0,
  //   }));
  //   setPairs(x);
  // },[])

  useEffect(() => {
    setRecommendedPairs(
      pairs
        .filter((pair) => pair.recommendedOrder !== undefined)
        .sort((a, b) => (a.recommendedOrder ?? 0) - (b.recommendedOrder ?? 0))
    );
  }, [pairs]);

  const getPairs = useCallback((farm: any, farmInfo: any) => {
    const results = farm?.pairs.map((pair: any) => {
      const result: PairExtra = {
        ...pair,
        apr: farmInfo?.pairExtras?.[pair.id]?.apr || BigNumber("0"),
      };
      return result;
    });

    return results ?? [];
  }, []);

  // useEffect(() => {
  //   fetchDetails();
  // },[])

  const fetchDetailsOldFarm = useCallback(async () => {
    if (!farmEnv) return BigNumber("0");
    const farm_result = farmEnv.find(
      (o) => o.rewardToken.code === "EVRY_TOKEN"
    );

    const pairs = farm_result?.pairs.map((pair) => ({
      ...pair,
    }));
    const pairArr = [];
    let tvl = BigNumber("0");
    if (!farm_result) return tvl;
    const { pairExtras } = await getFarmInfo(farm_result, true);
    for (const [id, pairExtra] of Object.entries(pairExtras)) {
      const pair = pairs?.find((pair) => pair.id === id);
      pairArr.push({
        id,
        apr: pairExtra.apr,
        name: pair?.staking.name,
        image: pair?.staking.image,
      });

      tvl = tvl.plus(pairExtra.totalStaked.multipliedBy(pairExtra.lpPerUsd));
    }
    return tvl;
  }, [farmEnv, getFarmInfo]);

  const run = useRef(false);

  const fetchDetails = useCallback(async () => {
    if (!farm) return;
    if (!farmHelper) return;
    if (pairs.length === 0) return;

    if (!farmHelper.isReadyForGetFarmInfo) return;

    if (run.current) return;
    run.current = true;
    const farmInfoData = await farmHelper.getFarmInfo(farm);

    setFarmInfo(farmInfoData);
    setRewardPerBlock(
      parseFloat(farmInfoData.rewardPerBlock.div(10 ** 18).toString())
    );

    const supply = await getTotalSupplyExternalApi("velo");
    setTotalSupply(supply);
    setMarketCap(supply * parseFloat(farmInfoData.rewardPerUsd.toString()));

    let tvl = BigNumber("0");
    const pairArr = [];

    for (const [id, pairExtra] of Object.entries(farmInfoData.pairExtras)) {
      const pair = pairs.find((pair) => pair.id === id);
      pairArr.push({
        id,
        apr: pairExtra.apr,
        name: pair?.staking.name,
        image: pair?.staking.image,
      });
      tvl = tvl.plus(pairExtra.totalStaked.multipliedBy(pairExtra.lpPerUsd));
    }

    const totalValueFixLock = await getTotalValueFixLock();

    const oldFarmTvl = await fetchDetailsOldFarm();
    const tvlList = await Promise.all([
      totalValueFixLock,
      getTotalValueLock(),
      getTotalValueFixLockWithChangeableRatio(),
      tvl,
      oldFarmTvl,
    ]);

    setTotalValueLocked(
      tvlList.reduce((a, b) => a + parseFloat(b.div(10 ** 18).toString()), 0)
    );
  }, [
    farm,
    farmHelper,
    fetchDetailsOldFarm,
    getTotalSupplyExternalApi,
    getTotalValueLock,
    getTotalValueFixLock,
    getTotalValueFixLockWithChangeableRatio,
    pairs,
  ]);

  useEffect(() => {
    fetchDetails();

    const interval = setInterval(() => {
      fetchDetails();
    }, 1000);

    return () => clearInterval(interval);
  }, [farmHelper, farm, pairs, fetchDetails]);

  useEffect(() => {
    // setPairs(getPairs());

    if (farm && farmInfo) {
      setPairs(getPairs(farm, farmInfo));
    }
  }, [farm, farmInfo, getPairs]);

  return (
    <div>
      <Intro />
      <RecommendYield
        className="pt-5 xl:pt-0 mb-12 relative z-10"
        pairs={recommendedPairs}
        reward={farm?.rewardToken.name}
      />
      <TotalValueLocked
        className="relative col-span-full lg:col-auto"
        token={farm?.rewardToken}
        totalValueLocked={totalValueLocked}
        totalSupply={totalSupply}
        marketCap={marketCap}
        rewardPerBlock={rewardPerBlock}
      />
      <div className="col-span-2 grid grid-cols-1 lg:grid-cols-2 gap-4 my-20">
        <Announcements className="p-0 md:pb-0 col-span-full lg:col-auto" />
        <div>
          <ListedOn className="p-0 md:pl-8 mb-6 md:mb-0 mt-8 md:mt-0" />
          <Partners className="p-0 md:pl-8 md:pt-0 my-8 md:mb-0" />
          <Audits className="p-0 md:pl-8 md:pt-0 my-8 md:mb-0" />
        </div>
      </div>
      <ButtonGoToTop />
    </div>
  );
};

export default Home;

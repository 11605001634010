import React, { createContext, useReducer, useContext, ReactNode } from "react";

interface MainState {
  isConnectModalShow: boolean;
}

interface MainAction {
  type: string;
  payload?: any;
}

interface MainContextType {
  state: MainState;
  setIsShowConnectModal: (isShow: boolean) => void;
}

// Initial state
const initialState: MainState = {
  isConnectModalShow: false,
};

// Reducer function
const mainReducer = (state: MainState, action: MainAction): MainState => {
  switch (action.type) {
    case "SET_CONNECT_MODAL":
      return {
        ...state,
        isConnectModalShow: action.payload,
      };
    default:
      return state;
  }
};

// Create context
const MainContext = createContext<MainContextType | undefined>(undefined);

// Provider component
export const MainConstantProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(mainReducer, initialState);

  const setIsShowConnectModal = (isShow: boolean) => {
    dispatch({ type: "SET_CONNECT_MODAL", payload: isShow });
  };

  return (
    <MainContext.Provider value={{ state, setIsShowConnectModal }}>
      {children}
    </MainContext.Provider>
  );
};

// Custom hook for using Main context
export const useMainConstant = (): MainContextType => {
  const context = useContext(MainContext);
  if (!context) {
    throw new Error("useMain must be used within a MainProvider");
  }
  return context;
};

import React from "react";

interface Props {
  position?: "top" | "bottom" | "";
  colorClass?: string;
  children?: React.ReactNode;
  className?: string;
}

const Table: React.FC<Props> = ({
  position = "",
  colorClass = "",
  children,
  className,
}) => {
  const positionClass = () => {
    return position === "top"
      ? "rounded-t-[2px] md:pb-4"
      : position === "bottom"
      ? "rounded-b-[2px] pt-2 md:pt-4"
      : "rounded-[2px]";
  };
  return (
    <div
      className={`p-4 md:rounded-[2px] bg-[#19506F] bg-opacity-5 ${className} ${
        colorClass ||
        "border border-[#19506F] md:first:border-b md:last:border-t"
      } ${positionClass()}`}
    >
      {children}
    </div>
  );
};

export default Table;

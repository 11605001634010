import { useCallback, useEffect, useRef } from "react";
import { useReadContract, useWriteContract } from "wagmi";
import { readContract } from "@wagmi/core";
import { config } from "../../wagmi.config";

const useLazyReadContractAsync = () => {
  const lazyReadContract = 
    async (abi: any, address: string, functionName: string, args: any[]) => {
      try {
        // @ts-ignore
        const result = await readContract(config, {
          // @ts-ignore
          abi,
          address: address as `0x${string}`,
          functionName,
          args,
        });
        return result;
      } catch (error) {
        console.error("lazyReadContract", error);
        return null;
      }
    }

  return { lazyReadContract };
};

export default useLazyReadContractAsync;

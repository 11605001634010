import React from "react";

interface IconProps {
  outline?: boolean;
  round?: boolean;
  onClick?: () => void;
  children: React.ReactNode;
  className?: string;
}

const MaterialIcon: React.FC<IconProps> = ({
  outline = false,
  round = false,
  onClick,
  children,
  className,
}) => {
  return (
    <i
      className={`material-icons ${outline ? "material-icons-outlined" : ""} ${
        round ? "material-icons-round" : ""
      } ${className}`}
      onClick={onClick}
    >
      {children}
    </i>
  );
};

export default MaterialIcon;

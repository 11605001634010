import React, { useState, useRef } from "react";
// import SettingPercentageInput from "./SettingPercentageInput"; // Custom Component
// import SettingTransactionDeadlineInput from "./SettingTransactionDeadlineInput"; // Custom Component
import Modal from "../modal";
import ToolTip from "../toolTip";
import SettingPercentageInput from "./percentageInput";
import SettingTransactionDeadlineInput from "./transactionDeadlineInput";

// Interface for state (if needed)
interface ReceiveBalance {
  [key: string]: number;
}

interface SettingsModalProps {
  openModal: () => void;
  closeModal: () => void;
  isModalOpen: boolean;
}

const SettingsModal: React.FC<SettingsModalProps> = ({
  openModal,
  closeModal,
  isModalOpen,
}) => {
  const [lpToken, setLpToken] = useState<string | null>(null);
  const [isApproved, setIsApproved] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [isPendingMap] = useState<Map<string, boolean>>(new Map());
  const [transactionMap] = useState<Map<string, any>>(new Map());
  const [receiveBalance, setReceiveBalance] = useState<ReceiveBalance>({});
  const [inputAmount, setInputAmount] = useState<number | null>(null);

  // const [isOpenModal, setIsOpenModal] = useState(false);

  // const open = () => {
  //   setIsOpenModal(true);
  //   openModal();
  // };

  // const close = () => {
  //   setIsOpenModal(false);
  // };

  const validateInput = () => {
    console.log("validateInput");
  };

  return (
    <Modal
      widthClass="w-[362px]"
      title="Settings"
      isShowDialog={isModalOpen}
      onClose={closeModal}
    >
      <div className="w-full flex flex-col gap-6 md:gap-4">
        <div className="tab-content border-l-2 border-none bg-transparent justify-center leading-normal">
          <div className="flex flex-col flex-auto">
            {/* Slippage Tolerance */}
            <div className="flex flex-row">
              <ToolTip
                message={`Your transaction will revert if the 
                  price changes unfavorably by 
                  more than this percentage. The
                  %slippage could range from 
                  <b>0.01% to 49.99%.</b>`}
                onModal={true}
                color="velo-primary"
                margin="0"
                iconClass="text-xs"
              />
              <p className="flex text-xs ml-2 text-white items-center">
                Select slippage tolerance
              </p>
            </div>

            <div className="flex pt-2">
              <SettingPercentageInput />
            </div>

            {/* Transaction Deadline */}
            <div className="flex flex-row pt-4">
              <ToolTip
                message={`Your transaction will revert if it is
                spending more than this long`}
                onModal={true}
                color="velo-primary"
                margin="0"
                iconClass="text-xs"
              />
              <p className="flex text-xs ml-2 text-white items-center">
                Transaction deadline
              </p>
            </div>

            <div className="flex pt-2">
              <SettingTransactionDeadlineInput />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SettingsModal;

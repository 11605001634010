import React, { ReactNode } from "react";

interface PageHeaderProps {
  title?: string;
  subTitle?: string;
  subTitle2?: string;
  subTitle2ClassName?: string;
  children?: ReactNode; // This will allow passing any slot content as children
  isTop?: boolean;
  isRight?: boolean;
}

const PageHeader: React.FC<PageHeaderProps> = ({
  title = "",
  subTitle = "",
  subTitle2 = "",
  subTitle2ClassName,
  children,
  isTop = false,
  isRight = false,
}) => {
  // Separate children for the top and right slots
  // const topSlot = React.Children.toArray(children).find(
  //   (child) => (child as React.ReactElement).type === "top"
  // );

  // const rightSlot = React.Children.toArray(children).find(
  //   (child) => (child as React.ReactElement).type === "right"
  // );

  return (
    <div className="flex flex-row px-4 pt-4 items-center md:px-0 mb-8">
      <div>
        {isTop && <div className="md:mb-8 mb-4">{children}</div>}
        <div
          data-testid="swap_header_txt"
          className="text-h1 uppercase md:mt-1"
        >
          {title}
        </div>
        <div className="text-xs font-normal md:text-base md:mt-1">
          {subTitle}
        </div>
        {subTitle2 && (
          <div
            className={`text-xs font-normal md:text-base md:mt-1 ${subTitle2ClassName}`}
          >
            {subTitle2}
          </div>
        )}
      </div>
      {isRight && <div className="ml-auto">{children}</div>}
    </div>
  );
};

export default PageHeader;

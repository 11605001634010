import React, { useMemo } from "react";
import Modal from "../modal";
import TableRow from "../table/row";
import ToolTip from "../toolTip";
import Button from "../button";
import { formatNumberWithMinMaxDigits } from "../../utils/filter";
import { Token } from "../../interfaces/token";

interface Props {
  lpToken: {
    name: string;
    image: string;
  };
  tokenA: Token | undefined;
  tokenB: Token | undefined;
  lpAmount: string;
  supplyAmountTokenA: string; // Assuming toPrecision returns a string
  supplyAmountTokenB: string;
  tokenAPerTokenB: string;
  tokenBPerTokenA: string;
  shareOfPool: string;
  isDMMType: boolean;
  amplifier: string;
  onConfirm: () => void;
  isOpen: boolean;
  onClose: () => void;
}

const AddModal: React.FC<Props> = ({
  lpToken,
  tokenA,
  tokenB,
  lpAmount,
  supplyAmountTokenA,
  supplyAmountTokenB,
  tokenAPerTokenB,
  tokenBPerTokenA,
  shareOfPool,
  isDMMType,
  amplifier,
  onConfirm,
  isOpen,
  onClose,
}) => {
  const tooltip = useMemo(() => {
    return `
    <div class="flex flex-row justify-between">
      <p>Pool ${tokenA?.symbol}</p>
      <p>${formatNumberWithMinMaxDigits(
        parseFloat(supplyAmountTokenA),
        4,
        8
      )}</p>
    </div>
    <div class="flex flex-row justify-between">
      <p>Pool ${tokenB?.symbol}</p>
      <p>${formatNumberWithMinMaxDigits(
        parseFloat(supplyAmountTokenB),
        4,
        8
      )}</p>
    </div>
    `;
  }, [tokenA, tokenB, supplyAmountTokenA, supplyAmountTokenB]);

  return (
    <Modal
      isShowDialog={isOpen}
      onClose={onClose}
      widthClass="w-[360px]"
      title="Confirm supply liquidity"
      footer={
        <Button className="w-full uppercase" onClick={onConfirm}>
          Confirm supply liquidity
        </Button>
      }
    >
      <div className="w-full flex flex-col gap-6 md:gap-4">
        <div className="flex flex-col gap-2">
          <p className="text-sm font-medium">You’ll get LP {lpToken?.name}</p>
          <div className="text-xs flex flex-col gap-2">
            <TableRow
              className="p-2"
              colorClass="bg-[#909090] bg-opacity-5"
              leftSlot={
                <div className="flex gap-1 items-center text-white">
                  <img
                    src={lpToken?.image}
                    className="h-4"
                    alt={`${lpToken?.name} logo`}
                  />
                  <p>LP {lpToken?.name}</p>
                  <ToolTip
                    className="mr-1"
                    onModal
                    iconSize="base"
                    iconClass="leading-none text-transparent bg-clip-text bg-velo-primary-gradient4"
                    message={tooltip}
                  />
                </div>
              }
              rightSlot={
                <p>
                  {formatNumberWithMinMaxDigits(parseFloat(lpAmount), 4, 8)}
                </p>
              }
            ></TableRow>
            <TableRow
              className="p-2"
              colorClass="bg-[#909090] bg-opacity-5"
              leftSlot={<p>Share of pool</p>}
              rightSlot={<p>{shareOfPool}%</p>}
            ></TableRow>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddModal;

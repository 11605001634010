import { ChainDetailType } from "../../../interfaces/environment";
import {
  DepositToken,
  WalletDetailType,
  WithdrawToken,
} from "../../../interfaces/wallet";
import useEthWalletConnection from "./useEthWalletConnection";
import useEthWalletBalance from "./useEthWalletBalance";
import useEthWalletTransfer from "./useEthWalletTransfer";
import useEthWalletSignMessage from "./useEthWalletSignMessage";
import useEthWalletAddToken from "./useEthWalletAddToken";

export function useEthWalletApi(
  chain: ChainDetailType.Item,
  token: DepositToken | WithdrawToken | undefined,
  setToken: (token: DepositToken | WithdrawToken) => void
): WalletDetailType.WalletAPI {
  const {
    isConnected,
    walletName,
    walletNames,
    account,
    hasAddress,
    connect,
    reconnect,
    disconnect,
    selectedConnector,
    switchChain,
  } = useEthWalletConnection(chain);
  const { balance, fetchBalance } = useEthWalletBalance(chain, account, token);
  const { transfer } = useEthWalletTransfer(chain, account, token, switchChain);
  const { signMessage } = useEthWalletSignMessage(account);
  const { addToken } = useEthWalletAddToken(
    chain,
    selectedConnector,
    switchChain
  );

  return {
    chain,
    isConnected,
    walletName,
    walletNames,
    hasAddress,
    connect,
    reconnect: reconnect,
    account,
    disconnect,
    balance,
    setBalanceToken: setToken,
    fetchBalance,
    transfer,
    signMessage,
    switchChain,
    addToken,
  };
}

import React, { createContext, useReducer, useContext, ReactNode } from "react";

// Define the state type
interface State {
  slippage: string;
  trxDeadline: string;
}

// Define the action types
type Action =
  | { type: "SET_SLIPPAGE"; payload: { slippage: string } }
  | { type: "SET_TRX_DEADLINE"; payload: { trxDeadline: string } };

// Initial state, similar to Vuex's `state`
const initialState: State = {
  slippage:
    localStorage.getItem("slippage") ||
    process.env.REACT_APP_SLIPPAGE_PERCENTAGE ||
    "",
  trxDeadline:
    localStorage.getItem("trxDeadline") ||
    process.env.REACT_APP_TX_DEADLINE_DEFAULT ||
    "",
};

// Reducer function to handle state changes (similar to Vuex mutations)
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "SET_SLIPPAGE":
      localStorage.setItem("slippage", action.payload.slippage);
      return { ...state, slippage: action.payload.slippage };

    case "SET_TRX_DEADLINE":
      localStorage.setItem("trxDeadline", action.payload.trxDeadline);
      return { ...state, trxDeadline: action.payload.trxDeadline };

    default:
      return state;
  }
};

// Create context for state and dispatch
const SlippageContext = createContext<{
  state: State;
  dispatch: React.Dispatch<Action>;
} | null>(null);

// Provider component
export const SlippageProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <SlippageContext.Provider value={{ state, dispatch }}>
      {children}
    </SlippageContext.Provider>
  );
};

// Custom hooks for accessing state and dispatch
export const useSlippageState = () => {
  const context = useContext(SlippageContext);
  if (!context) {
    throw new Error("useSlippageState must be used within a SlippageProvider");
  }
  return context.state;
};

export const useSlippageDispatch = () => {
  const context = useContext(SlippageContext);
  if (!context) {
    throw new Error(
      "useSlippageDispatch must be used within a SlippageProvider"
    );
  }
  return context.dispatch;
};

// Actions (similar to Vuex actions)
export const setSlippage = (
  dispatch: React.Dispatch<Action>,
  slippage: string
) => {
  dispatch({ type: "SET_SLIPPAGE", payload: { slippage } });
};

export const setTrxDeadline = (
  dispatch: React.Dispatch<Action>,
  trxDeadline: string
) => {
  dispatch({ type: "SET_TRX_DEADLINE", payload: { trxDeadline } });
};

// Getters (state selectors)
export const getSlippage = (state: State): string =>
  state.slippage || process.env.REACT_APP_SLIPPAGE_PERCENTAGE || "";

export const getTrxDeadline = (state: State): string =>
  state.trxDeadline || process.env.REACT_APP_TX_DEADLINE_DEFAULT || "";

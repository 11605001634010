import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FarmToken, PairEarned } from "../../interfaces/token";
import config from "../../config";
import { useEthereum } from "../../contexts/etherruemContext";
import { useWallet } from "../../contexts/walletContext";
import BigNumber from "bignumber.js";
import useFarmCommon from "../../hooks/useFarmCommon";
import Button from "../button";
import MaterialIcon from "../icon";
import TableRow from "../table/row";
import { useFarm } from "../../contexts/farmContext";
import { useNotification } from "../../contexts/notificationContext";
import {
  formatBigNumberPercentAbbr,
  formatEther,
  formatEtherDollar,
} from "../../utils/filter";
import { useMainConstant } from "../../contexts/mainContext";
import { useUnifiedWallet } from "../../providers/UnifiedWalletProvider";

interface Props {
  pair: PairEarned | undefined;
  apr: BigNumber;
  expired?: boolean;
  scanURL: string;
  contractAddress: string | undefined | null;
  totalStaked: BigNumber;
  evryPerUsd: BigNumber;
  lpPerUsd: BigNumber;
  reward: FarmToken | undefined | null;
  className?: string;
  openDepositWithdrawModal: (
    pair: PairEarned,
    topic: string,
    balance: BigNumber,
    isShowGetLP: boolean
  ) => any;
  openClaimModal: (lpToken: PairEarned, earnedToken: BigNumber) => any;
  openROIModal: (id: string) => any;
}

const liquidityDOC = config.document.liquidity;

// const poolID = 1;

const LpToken: React.FC<Props> = ({
  pair,
  contractAddress,
  apr,
  expired = false,
  scanURL,
  totalStaked,
  evryPerUsd,
  lpPerUsd,
  reward,
  className,
  openDepositWithdrawModal,
  openClaimModal,
  openROIModal,
}) => {
  const [isPending, setIsPending] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  // const [account, setAccount] = useState("");
  // const [tokenContract, setTokenContract] = useState("");
  const [lpBalance, setLpBalance] = useState(0);
  const [earnedToken, setEarnedToken] = useState<BigNumber>();
  // const [inputAmount, setInputAmount] = useState(0);

  const ethereum = useEthereum();
  // const wallet = useWallet();
  const {
    getBalanceOf,
    getPendingReward,
    isApprovedAllowance,
    getUserInfo,
    withdrawLpToken,
    depositLpTokenWithCheckAllowance,
    claimLpToken,
  } = useFarm();

  const { chainId, approveToken } = useFarmCommon();
  const { setNotify } = useNotification();
  const { setIsShowConnectModal } = useMainConstant();
  const { walletApi, chain } = useUnifiedWallet();

  useEffect(() => {
    if (!walletApi.account.current || !ethereum.isConnected) return;
    const check = async () => {
      await fetchAmountDetail();
    };
    check();
  }, [walletApi.account.current, ethereum.isConnected, contractAddress, pair]);

  //==============================COMPUTED==============================

  const hasStaking = useMemo(() => {
    return totalStaked && totalStaked.isGreaterThan(BigNumber("0"));
  }, [totalStaked]);

  const isValidConnection = useMemo(() => {
    const id = chainId();
    return (
      id && walletApi.isConnected && chain.chainId && +id === chain.chainId
    );
  }, [chainId, walletApi.isConnected, chain.chainId]);

  const isStaking = useMemo(() => {
    return lpBalance > 0;
  }, [lpBalance]);

  const calculatedRewardEarned = () => {
    const token = earnedToken || BigNumber("0");
    return token.multipliedBy(evryPerUsd);
  };

  const calculatedTotalStaked = () => {
    const stake = totalStaked || BigNumber("0");
    return stake.multipliedBy(lpPerUsd);
  };

  const isPair = () => {
    return pair?.lpType === "AMM" || pair?.lpType === "DMM";
  };

  //==============================METHODS==============================

  const onDepositClick = ({ amount }: { amount: any }) => {
    // $emit('update:token')
  };

  const unlockWallet = () => {
    setIsShowConnectModal(true);
    // $bus.$emit('unlock-wallet', chainId)
  };

  const fetchAmountDetail = useCallback(async () => {
    if (!contractAddress || !pair) return;
    const account = walletApi.account.current ?? "";
    const [pendingReward, { amount }] = await Promise.all([
      getPendingReward(contractAddress, pair.poolID, account),
      getUserInfo(contractAddress, pair.poolID, account),
    ]);

    setEarnedToken(pendingReward);
    setLpBalance(parseFloat(amount?.toString() ?? "0"));
    // TODO: refactor later
    if (amount && config.token[pair?.staking.code]?.decimals) {
      const decimals =
        18 - (config.token[pair?.staking.code ?? ""].decimals ?? 0);
      setLpBalance(parseFloat(amount.div(`1e${decimals}`).toString()));
    }
  }, [contractAddress, pair, walletApi.account, getPendingReward, getUserInfo]);

  useEffect(() => {
    if (pair && pair.staking.address && contractAddress && walletApi.account)
      refresh();
  }, [pair, contractAddress, walletApi.account]);

  const refresh = useCallback(async () => {
    setEarnedToken(undefined);
    setLpBalance(0);
    setIsPending(true);
    setIsApproved(false);
    if (!isValidConnection) {
      setIsPending(false);
      return;
    }
    const account = walletApi.account.current || "";

    const isAllow = await isApprovedAllowance(
      account,
      contractAddress ?? "",
      pair?.staking.address ?? ""
    );
    setIsApproved(isAllow);
    if (!isAllow) {
      setIsPending(false);
      return;
    }
    await fetchAmountDetail();
    setIsPending(false);
  }, [
    pair,
    isValidConnection,
    walletApi.account,
    isApprovedAllowance,
    contractAddress,
    fetchAmountDetail,
  ]);

  const approve = async () => {
    if (!contractAddress || !pair) return;
    setIsPending(true);
    const account = walletApi.account.current ?? "";

    try {
      // console.log("approve", {
      //   contractAddress,
      //   pAddress: pair?.staking.address,
      //   account,
      // });
      const receipt = await approveToken(
        contractAddress,
        pair?.staking.address ?? "",
        account,
        onUpdateNotification()
      );
      console.log("approve receipt", receipt);
    } catch (err) {
      console.error("approve", err);
    }
  };

  const openROI = () => {
    if (expired || !pair?.staking.code) return;
    openROIModal(pair?.staking.code);
  };

  const withdraw = async () => {
    try {
      if (!contractAddress || !pair) return;
      let inputResult;
      if (!expired) {
        const { inputAmount, isClose } = await openDepositWithdrawModal(
          pair,
          "Withdraw",
          BigNumber(lpBalance),
          false
        );
        inputResult = BigNumber(inputAmount);
        if (isClose) return;
      } else {
        inputResult = BigNumber(lpBalance);
      }
      setIsPending(true);
      const account = walletApi.account.current ?? "";
      // TODO: refactor later
      if (config.token[pair?.staking.code]?.decimals) {
        const decimals =
          18 - (config.token[pair?.staking.code ?? ""].decimals ?? 0);
        inputResult = inputResult.div(`1e${decimals}`);
      }
      await withdrawLpToken(
        contractAddress,
        pair.poolID,
        account,
        inputResult,
        onUpdateNotification()
      );
      refresh();
    } catch (err) {
      console.error(err);
      setIsPending(false);
    }
  };

  const onUpdateNotification = () => {
    return {
      onTransactionHash: (txHash: any) => {
        const payload = {
          status: "processing",
          txHash,
        };
        setNotify(payload);
      },
      onError: (_: any, receipt: any) => {
        const payload = {
          status: "failed",
          txHash: receipt ? receipt.transactionHash : null,
        };
        setNotify(payload);
        setIsPending(false);
      },
      onReceipt: ({
        transactionHash,
        status,
      }: {
        transactionHash: any;
        status: any;
      }) => {
        setNotify({
          status: status ? "success" : "failed",
          txHash: transactionHash,
        });
        refresh().finally(() => {
          setIsPending(false);
        });
      },
    };
  };

  const deposit = async () => {
    try {
      if (!contractAddress || !pair) return;
      const account = walletApi.account.current ?? "";
      // TODO: refactor later
      let balance = BigNumber(
        await getBalanceOf(pair?.staking.code ?? "", account)
      );
      if (config.token[pair?.staking.code ?? ""]?.decimals) {
        const decimals =
          18 - (config.token[pair?.staking.code ?? ""].decimals ?? 0);
        balance = balance.multipliedBy(`1e${decimals}`);
      }
      const { inputAmount, isClose } = await openDepositWithdrawModal(
        pair,
        "Deposit",
        balance,
        true
      );

      if (isClose) return;
      setIsPending(true);
      let inputResult = inputAmount;
      if (config.token[pair?.staking.code ?? ""]?.decimals) {
        const decimals =
          18 - (config.token[pair?.staking.code ?? ""].decimals ?? 0);
        inputResult = inputAmount.div(`1e${decimals}`);
      }
      await depositLpTokenWithCheckAllowance(
        pair?.staking.code ?? "",
        pair?.staking.address ?? "",
        contractAddress ?? "",
        pair.poolID,
        account,
        inputResult.toFixed(0),
        onUpdateNotification()
      );
      refresh();
    } catch (err) {
      console.error(err);
      setIsPending(false);
    }
  };

  const claim = async () => {
    try {
      if (!contractAddress || !pair || !earnedToken) return;
      const { confirmed } = await openClaimModal(pair, earnedToken);
      if (!confirmed) return;
      setIsPending(true);
      const account = walletApi.account.current ?? "";
      await claimLpToken(
        contractAddress,
        pair.poolID,
        account,
        onUpdateNotification()
      );
      refresh();
    } catch (err) {
      console.error(err);
      setIsPending(false);
    }
  };

  return (
    <div
      className={`w-auto h-auto animate__animated animate__fadeInUp ${className}`}
    >
      <div
        // id={id}
        className="rounded-[2px] bg-[#909090] bg-opacity-5 text-velo-wisp-pink border border-[#19506F]"
      >
        <div className="p-4">
          <div className="flex flex-auto gap-2 items-center">
            <img className="h-8" src={pair?.staking.image} alt="wallet-icon" />
            <div className="flex-auto flex flex-col">
              <div className="flex flex-col gap-1">
                <div className="text-h6">{pair?.staking.name}</div>
                {expired ? (
                  <div className="flex justify-start text-sm">
                    <div>Expired</div>
                  </div>
                ) : (
                  <div className="text-sm">
                    <div>Earn: {reward?.name}</div>
                  </div>
                )}
              </div>
            </div>
            <div className="flex-auto flex flex-col h-[42px]">
              <div className="flex flex-col items-end gap-1">
                <div className="flex text-sm font-normal text-velo-label">
                  APR
                </div>
                <div className="flex text-sm font-normal">
                  <div className="flex font-medium justify-center">
                    <div className="text-number mr-1">
                      {expired ? "0%" : `${formatBigNumberPercentAbbr(apr)}%`}
                    </div>
                    <div
                      id="button_farm_open_roi"
                      className="cursor-pointer"
                      onClick={openROI}
                    >
                      <MaterialIcon className="text-base relative text-velo-label -right-0.5">
                        calculate
                      </MaterialIcon>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={`flex flex-col justify-between p-4 h-[110px] rounded-[2px] mt-4 bg-[#909090] bg-opacity-5 ${
              isStaking ? "border border-velo-pink" : ""
            }`}
          >
            <div className="flex w-full justify-between">
              <div className="flex items-center">
                <img
                  className="h-4 mr-1"
                  src={reward?.image}
                  alt="wallet-icon"
                />
                <div className="text-sm text-velo-label">
                  Total {reward?.name} Earned
                </div>
              </div>
              <Button
                // id="button_farm_claim"
                size="small"
                type={isStaking ? "solid" : "outline"}
                disabled={!isStaking || expired}
                data-testid={`farm_claim_${pair?.id}_btn`}
                onClick={() => isStaking && !expired && claim()}
              >
                Claim
              </Button>
            </div>

            <div>
              <div
                className={`text-number ${
                  isApproved && isStaking ? "text-white" : "text-[#8D8D8D]"
                }`}
              >
                {formatEther(earnedToken ?? BigNumber("0"))}
              </div>
              <div
                className={`text-number-small ${
                  isApproved && isStaking ? "text-white" : "text-[#8D8D8D]"
                }`}
              >
                ~$
                {formatEtherDollar(calculatedRewardEarned())}
              </div>
            </div>
          </div>

          <TableRow
            className="w-full px-4 py-4 mt-4"
            leftSlot={
              <div
                slot="left"
                className="flex gap-1 items-center text-velo-label text-sm"
              >
                <img src={pair?.staking.image} className="h-4" />
                <p>{`${pair?.staking.name} ${isPair() ? "LP" : ""} Stake`}</p>
              </div>
            }
            rightSlot={
              <div
                slot="right"
                className={`text-number ${
                  isApproved && isStaking ? "" : "text-velo-label"
                }`}
              >
                {formatEther(BigNumber(lpBalance))}
              </div>
            }
          ></TableRow>
        </div>

        <div className="px-4">
          {(!isStaking || !isApproved) && expired && isValidConnection ? (
            <Button
              // id="button_farm_expired"
              className="w-full"
              disabled={expired}
            >
              Pool has expired
            </Button>
          ) : !isApproved ? (
            <>
              {!isValidConnection && !isPending && (
                <Button
                  // id="button_farm_unlock_wallet"
                  data-testid={`farm_unlock_wallet_${pair?.id}_btn`}
                  className="w-full uppercase"
                  onClick={unlockWallet}
                >
                  UNLOCK WALLET
                </Button>
              )}
              {isValidConnection && !isPending && (
                <Button
                  // id="button_farm_approve"
                  className="w-full uppercase"
                  data-testid={`farm_approve_${pair?.id}_btn`}
                  onClick={approve}
                >
                  APPROVE
                </Button>
              )}
              {isPending && (
                <Button
                  // id="button_farm_processing"
                  data-testid="farm_processing_btn"
                  className="w-full uppercase"
                  disabled
                >
                  Processing...
                </Button>
              )}
            </>
          ) : (
            <div className="flex whitespace-nowrap gap-3">
              {!isPending && isStaking && (
                <>
                  {expired ? (
                    <Button
                      // id="button_expired_farm_withdraw"
                      className="w-full"
                      data-testid={`farm_withdraw_and_claim_btn`}
                      onClick={withdraw}
                    >
                      Withdraw and Claim
                    </Button>
                  ) : (
                    <>
                      <Button
                        // id="button_farm_deposit"
                        className="w-full uppercase"
                        data-testid={`farm_deposit_${pair?.id}_btn`}
                        onClick={deposit}
                      >
                        Deposit
                      </Button>
                      <Button
                        // id="button_farm_withdraw"
                        className="w-full uppercase"
                        type="outline"
                        data-testid={`farm_withdraw_${pair?.id}_btn`}
                        onClick={withdraw}
                      >
                        Withdraw
                      </Button>
                    </>
                  )}
                </>
              )}
              {!isPending && !isStaking && (
                <Button
                  // id="button_farm_stake"
                  className="w-full uppercase"
                  data-testid={`farm_stake_${pair?.id}_btn`}
                  onClick={deposit}
                >
                  Deposit
                </Button>
              )}
              {isPending && (
                <Button
                  // id="button_farm_processing"
                  data-testid="farm_processing_btn"
                  className="w-full uppercase"
                  disabled
                >
                  Processing...
                </Button>
              )}
            </div>
          )}
        </div>

        <div className="flex flex-col justify-between m-4 text-sm h-[56px]">
          <div className="flex flex-nowrap items-center h-7">
            <div className="mr-2 text-velo-disable-2">Total staked:</div>
            {hasStaking ? (
              <div className="text-number">
                ${formatEtherDollar(calculatedTotalStaked())}
              </div>
            ) : (
              <div className="text-sm font-bold">N/A</div>
            )}
          </div>
          <div className="flex items-center justify-between h-7">
            <a
              className="flex items-center"
              href={`${scanURL}address/${pair?.staking.address}`}
              target="_blank"
              data-testid={`farm_view_contract_${pair?.id}_btn`}
              rel="noreferrer"
            >
              <MaterialIcon className="text-velo-primary text-sm mr-1">
                open_in_new
              </MaterialIcon>
              <span className="text-sm">View contract</span>
            </a>
            {pair?.lpLink ? (
              <a className="flex items-center" href={pair?.lpLink ?? ""}>
                <span className="text-sm">Get {pair?.staking.name}</span>
                <MaterialIcon className="text-velo-primary text-sm ml-1">
                  east
                </MaterialIcon>
              </a>
            ) : (
              <p className="flex items-center text-gray-300 invisible">
                <span className="text-sm">Get {pair?.staking.name}</span>
                <MaterialIcon className="text-sm mr-1">east</MaterialIcon>
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LpToken;

import React, { createContext, useContext, useEffect, useReducer } from "react";
import { ethers } from "ethers";
import config from "../config";

// Initial state, similar to Vuex's state
interface WalletState {
  address: string;
  balance: string;
  validChain: boolean;
  currentChain: number | null;
}

const initialState: WalletState = {
  address: "",
  balance: "",
  validChain: false,
  currentChain: null,
};

// Action types
type WalletAction =
  | { type: "SET_WALLET"; payload: WalletState }
  | { type: "RESET_WALLET" };

// Reducer function, similar to Vuex's mutations
const walletReducer = (
  state: WalletState,
  action: WalletAction
): WalletState => {
  switch (action.type) {
    case "SET_WALLET":
      return {
        ...state,
        ...action.payload,
      };
    case "RESET_WALLET":
      return initialState;
    default:
      return state;
  }
};

// Context type definition
interface WalletContextType {
  state: WalletState;
  setWallet: (data: WalletState) => void;
  resetWallet: () => void;
  updateBalance: () => Promise<void>;
  getLoggedIn: () => boolean;
  getSigner: () => Promise<ethers.Signer | undefined>;
  isValidChain: () => Promise<boolean>;
}

// Create context
const WalletContext = createContext<WalletContextType | undefined>(undefined);

// Provider component
export const WalletProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(walletReducer, initialState);

  // useEffect(() => {
  //   const startPooling = async () => {
  //     console.log("startPooling");
  //     const intervalId = setInterval(async () => {
  //       if (getLoggedIn()) {
  //         await updateBalance();
  //       }
  //     }, 10000); // Adjust pooling interval as needed
  //     if (getLoggedIn()) {
  //       await updateBalance();
  //     }
  //     return () => clearInterval(intervalId); // Cleanup on component unmount
  //   };

  //   startPooling();
  // }, []);

  const setWallet = (walletData: WalletState) => {
    dispatch({ type: "SET_WALLET", payload: walletData });
    localStorage.setItem("isLoggedIn", "true");
  };

  const resetWallet = () => {
    dispatch({ type: "RESET_WALLET" });
    localStorage.removeItem("isLoggedIn");
  };

  const getLoggedIn = (): boolean => {
    return localStorage.getItem("isLoggedIn") === "true";
  };

  const updateBalance = async () => {
    const provider = getWeb3Provider();
    if (!provider) return;
    const accounts = await provider.listAccounts();
    let selectedAccount = accounts[0];

    if (!selectedAccount) return;

    if (getLoggedIn()) {
      const [validChain, accountBalance, chainId] = await Promise.all([
        isValidChain(),
        provider.getBalance(selectedAccount),
        provider.getNetwork().then((network) => network.chainId),
      ]);
      const balance = ethers.formatEther(accountBalance);
      setWallet({
        address: selectedAccount.address,
        balance,
        validChain,
        currentChain: Number(chainId),
      });
    } else {
      resetWallet();
    }
  };

  const isValidChain = async (): Promise<boolean> => {
    const provider = getWeb3Provider();
    if (!provider) return false;
    const chainId = await provider
      .getNetwork()
      .then((network) => network.chainId);
    const networks = config.networks;

    return Object.keys(networks).includes(chainId.toString());
  };

  const getSigner = async () => {
    const provider = getWeb3Provider();
    if (!provider) return;
    await provider.send("eth_requestAccounts", []);
    return provider.getSigner();
  };

  const getWeb3Provider = () => {
    if (window.ethereum) {
      return new ethers.BrowserProvider(window.ethereum, "any");
    }
    // throw new Error("No Ethereum provider found");
  };

  return (
    <WalletContext.Provider
      value={{
        state,
        setWallet,
        resetWallet,
        updateBalance,
        getLoggedIn,
        getSigner,
        isValidChain,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};

// Custom hook to use wallet context
export const useWallet = (): WalletContextType => {
  const context = useContext(WalletContext);
  if (!context) {
    throw new Error("useWallet must be used within a WalletProvider");
  }
  return context;
};

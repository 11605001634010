const lpType = {
  DMM_TYPE: "DMM",
  AMM_TYPE: "AMM",
};
const durationTime = {
  LONG_TIME: 15000,
  MIDDLE_TIME: 10000,
  SHORT_TIME: 5000,
  UPDATE_BALANCE: 30000,
  FETCH_PRICE: 10000,
};

const networkType = {
  ERC20: "ERC20",
  BEP2: "BEP2",
};

const digit = {
  TOKEN_DIGIT: 18,
};

export { lpType, durationTime, networkType, digit };

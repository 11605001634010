import BigNumber from "bignumber.js";
import { useEthereum } from "../contexts/etherruemContext";
import { useTokenHelpers } from "./useTokenHelpers";
import { useContractWrite, useWriteContract } from "wagmi";
import AutoRouter from "../assets/abi/swap/AutoRouter.json";
import config from "../config";

const noOp = () => {};
const noOp1 = (v: any) => {};
const noOp2 = (v: any) => {};

const autorouteAddress = config.autoRouterContractAddress?.startsWith("0x")
  ? config.autoRouterContractAddress
  : "0x0000000000000000000000000000000000000000";

const useSwapHelpers = () => {
  const ethereum = useEthereum();
  const { writeContractAsync } = useWriteContract();

  const { approveTokenToSpender, getAllowance } = useTokenHelpers();

  const myFixed = (x: string, d: number) => {
    if (!d) return BigNumber(x).toFixed(d);
    return BigNumber(x)
      .toFixed(d)
      .replace(/\.?0+$/, "");
  };

  const tooltipDynamicFee = (tradingFee: string, symbol: string) => {
    const tradingFeeStr = `(${myFixed(tradingFee, 18)} ${symbol})`;
    const percentLPStr = "80%";
    const percentPlatformStr = "20%";
    const toolTip = `A portion of each trade ${tradingFeeStr.bold()} which 
        <br/> ${percentLPStr.bold()} will go to liquidity providers(LPs) and 
        <br/> ${percentPlatformStr.bold()} will go to platform.`;
    return toolTip;
  };

  const swapExactNativeForTokens = async (
    nativeAmount: string,
    amountOutMin: string,
    tokenAddressInList: string[],
    ownerAddress: string,
    trxDeadline: string,
    poolType: string,
    dmmPoolAddresses: string[],
    { onError = noOp, onReceipt = noOp2, onTransactionHash = noOp1 }
  ) => {
    await writeContractAsync(
      // @ts-ignore
      {
        abi: AutoRouter,
        // account: account.current,
        value: BigInt(nativeAmount),
        // @ts-ignore
        address: autorouteAddress,
        functionName: "swapExactNativeForTokens",
        args: [
          amountOutMin,
          tokenAddressInList,
          poolType,
          dmmPoolAddresses,
          ownerAddress,
          trxDeadline,
        ],
      },
      {
        onSuccess(data: any) {
          onReceipt({ transactionHash: data, status: true });
          console.log("swap success", data);
          if (!data) {
            throw new Error("erc20 transfer failed");
          }
          return data;
        },
        onError: (error: any) => {
          onError();
          console.log("useEthWalletTransfer: erc20 transfer, error", error);
          throw error;
        },
      }
    );
    // await ethereum.contracts.autoRouter.methods
    //   .swapExactNativeForTokens(
    //     amountOutMin,
    //     tokenAddressInList,
    //     poolType,
    //     dmmPoolAddresses,
    //     ownerAddress,
    //     trxDeadline
    //   )
    //   .send({ from: ownerAddress, value: nativeAmount })
    //   .on("transactionHash", onTransactionHash)
    //   .on("receipt", onReceipt)
    //   .catch(onError);
  };

  const swapExactTokensForNative = async (
    nativeAmount: any,
    amountOutMin: any,
    tokenAddressInList: string[],
    ownerAddress: string,
    trxDeadline: string,
    poolType: string,
    dmmPoolAddresses: string[],
    { onError = noOp, onReceipt = noOp2, onTransactionHash = noOp1 }
  ) => {
    await writeContractAsync(
      // @ts-ignore
      {
        abi: AutoRouter,
        // account: account.current,
        // @ts-ignore
        from: ownerAddress,
        // @ts-ignore
        address: autorouteAddress,
        functionName: "swapExactTokensForNative",
        args: [
          nativeAmount,
          amountOutMin,
          tokenAddressInList,
          poolType,
          dmmPoolAddresses,
          ownerAddress,
          trxDeadline,
        ],
      },
      {
        onSuccess(data: any) {
          onReceipt({ transactionHash: data, status: true });
          console.log("swap success", data);
          if (!data) {
            throw new Error("erc20 transfer failed");
          }
          return data;
        },
        onError: (error: any) => {
          onError();
          console.log("useEthWalletTransfer: erc20 transfer, error", error);
          throw error;
        },
      }
    );
    // console.log("swapExactTokensForNative ethereum", ethereum);
    // await ethereum.contracts.autoRouter.methods
    //   .swapExactTokensForNative(
    //     nativeAmount,
    //     amountOutMin,
    //     tokenAddressInList,
    //     poolType,
    //     dmmPoolAddresses,
    //     ownerAddress,
    //     trxDeadline
    //   )
    //   .send({ from: ownerAddress })
    //   .on("transactionHash", onTransactionHash)
    //   .on("receipt", onReceipt)
    //   .catch((e: any, a: any) => console.log("error", e, a));
  };

  const swapExactTokensForTokens = async (
    amountIn: string,
    amountOutMin: string,
    tokenAddressInList: string[],
    ownerAddress: string,
    trxDeadline: string,
    poolType: string,
    dmmPoolAddresses: string[],
    { onError = noOp, onReceipt = noOp2, onTransactionHash = noOp1 }
  ) => {
    await writeContractAsync(
      // @ts-ignore
      {
        abi: AutoRouter,
        // account: account.current,
        // @ts-ignore
        from: ownerAddress,
        // @ts-ignore
        address: autorouteAddress,
        functionName: "swapExactTokensForTokens",
        args: [
          amountIn,
          amountOutMin,
          tokenAddressInList,
          poolType,
          dmmPoolAddresses,
          ownerAddress,
          trxDeadline,
        ],
      },
      {
        onSuccess(data: any) {
          onReceipt({ transactionHash: data, status: true });
          console.log("swap success", data);
          if (!data) {
            throw new Error("erc20 transfer failed");
          }
          return data;
        },
        onError: (error: any) => {
          onError();
          console.log("useEthWalletTransfer: erc20 transfer, error", error);
          throw error;
        },
      }
    );
    // await ethereum.contracts.autoRouter.methods
    //   .swapExactTokensForTokens(
    //     amountIn,
    //     amountOutMin,
    //     tokenAddressInList,
    //     poolType,
    //     dmmPoolAddresses,
    //     ownerAddress,
    //     trxDeadline
    //   )
    //   .send({ from: ownerAddress })
    //   .on("transactionHash", onTransactionHash)
    //   .on("receipt", onReceipt)
    //   .catch(onError);
  };

  const getAllowanceBeforeSwap = async (
    code: string,
    contractAddress: string,
    spender: string,
    account: string,
    inputAmount: string
  ) => {
    const inputAmountWei = ethereum.web3?.utils.toWei(inputAmount, "ether");
    const amount = BigNumber(inputAmountWei ?? "0");

    if (ethereum.contracts != null) {
      const allowance = await getAllowance(
        code,
        account,
        spender,
        contractAddress
      );

      if (allowance) {
        return BigNumber(allowance).isGreaterThanOrEqualTo(amount);
      } else {
        return false;
      }
    }
    return false;
  };

  const checkAllowanceBeforeSwap = async (
    code: string,
    contractAddress: string,
    account: string,
    inputAmount: string
  ) => {
    const spender = contractAddress;
    const inputAmountWei = ethereum.web3?.utils.toWei(inputAmount, "ether");
    const amount = BigNumber(inputAmountWei ?? "0");

    if (ethereum.contracts != null) {
      const allowance = await getAllowance(
        code,
        account,
        spender,
        contractAddress
      );
      if (allowance && BigNumber(allowance).isLessThan(amount)) {
        await approveTokenToSpender(code, account, spender, {
          onTransactionHash: (txHash) => {},
          onReceipt: ({ transactionHash, status }) => {},
          onError: (receipt) => {},
        });
      }
    }
  };

  const swapNativeForExactTokens = async (
    amountOut: string,
    nativeAmount: string,
    tokenAddressInList: string[],
    ownerAddress: string,
    trxDeadline: string,
    poolType: string,
    dmmPoolAddresses: string[],
    { onError = noOp, onReceipt = noOp2, onTransactionHash = noOp1 }
  ) => {
    await writeContractAsync(
      // @ts-ignore
      {
        abi: AutoRouter,
        // account: account.current,
        // @ts-ignore
        from: ownerAddress,
        // @ts-ignore
        address: autorouteAddress,
        functionName: "swapNativeForExactTokens",
        args: [
          amountOut,
          tokenAddressInList,
          poolType,
          dmmPoolAddresses,
          ownerAddress,
          trxDeadline,
        ],
      },
      {
        onSuccess(data: any) {
          onReceipt({ transactionHash: data, status: true });
          console.log("swap success", data);
          if (!data) {
            throw new Error("erc20 transfer failed");
          }
          return data;
        },
        onError: (error: any) => {
          onError();
          console.log("useEthWalletTransfer: erc20 transfer, error", error);
          throw error;
        },
      }
    );
    // await ethereum.contracts.autoRouter.methods
    //   .swapNativeForExactTokens(
    //     amountOut,
    //     tokenAddressInList,
    //     poolType,
    //     dmmPoolAddresses,
    //     ownerAddress,
    //     trxDeadline
    //   )
    //   .send({ from: ownerAddress, value: nativeAmount })
    //   .on("transactionHash", onTransactionHash)
    //   .on("receipt", onReceipt)
    //   .catch(onError);
  };

  const swapTokensForExactNative = async (
    amountOut: string,
    amountInMax: string,
    tokenAddressInList: string[],
    ownerAddress: string,
    trxDeadline: string,
    poolType: string,
    dmmPoolAddresses: string[],
    { onError = noOp, onReceipt = noOp2, onTransactionHash = noOp1 }
  ) => {
    await writeContractAsync(
      // @ts-ignore
      {
        abi: AutoRouter,
        // account: account.current,
        // @ts-ignore
        from: ownerAddress,
        // @ts-ignore
        address: autorouteAddress,
        functionName: "swapTokensForExactNative",
        args: [
          amountOut,
          amountInMax,
          tokenAddressInList,
          poolType,
          dmmPoolAddresses,
          ownerAddress,
          trxDeadline,
        ],
      },
      {
        onSuccess(data: any) {
          onReceipt({ transactionHash: data, status: true });
          console.log("swap success", data);
          if (!data) {
            throw new Error("erc20 transfer failed");
          }
          return data;
        },
        onError: (error: any) => {
          onError();
          console.log("useEthWalletTransfer: erc20 transfer, error", error);
          throw error;
        },
      }
    );
    // await ethereum.contracts.autoRouter.methods
    //   .swapTokensForExactNative(
    //     amountOut,
    //     amountInMax,
    //     tokenAddressInList,
    //     poolType,
    //     dmmPoolAddresses,
    //     ownerAddress,
    //     trxDeadline
    //   )
    //   .send({ from: ownerAddress })
    //   .on("transactionHash", onTransactionHash)
    //   .on("receipt", onReceipt)
    //   .catch(onError);
  };

  const swapTokensForExactTokens = async (
    amountOut: string,
    amountInMax: string,
    tokenAddressInList: string[],
    ownerAddress: string,
    trxDeadline: string,
    poolType: string,
    dmmPoolAddresses: string[],
    { onError = noOp, onReceipt = noOp2, onTransactionHash = noOp1 }
  ) => {
    await writeContractAsync(
      // @ts-ignore
      {
        abi: AutoRouter,
        // account: account.current,
        // @ts-ignore
        from: ownerAddress,
        // @ts-ignore
        address: autorouteAddress,
        functionName: "swapTokensForExactTokens",
        args: [
          amountOut,
          amountInMax,
          tokenAddressInList,
          poolType,
          dmmPoolAddresses,
          ownerAddress,
          trxDeadline,
        ],
      },
      {
        onSuccess(data: any) {
          onReceipt({ transactionHash: data, status: true });
          console.log("swap success", data);
          if (!data) {
            throw new Error("erc20 transfer failed");
          }
          return data;
        },
        onError: (error: any) => {
          onError();
          console.log("useEthWalletTransfer: erc20 transfer, error", error);
          throw error;
        },
      }
    );
    // await ethereum.contracts.autoRouter.methods
    //   .swapTokensForExactTokens(
    //     amountOut,
    //     amountInMax,
    //     tokenAddressInList,
    //     poolType,
    //     dmmPoolAddresses,
    //     ownerAddress,
    //     trxDeadline
    //   )
    //   .send({ from: ownerAddress })
    //   .on("transactionHash", onTransactionHash)
    //   .on("receipt", onReceipt)
    //   .catch(onError);
  };

  const getAMPBPS = async (lpName: string) => {
    return await ethereum.contracts.tokens[lpName].methods.ampBps().call();
  };

  return {
    tooltipDynamicFee,
    swapExactNativeForTokens,
    swapExactTokensForNative,
    swapExactTokensForTokens,
    checkAllowanceBeforeSwap,
    getAllowanceBeforeSwap,
    swapNativeForExactTokens,
    swapTokensForExactNative,
    swapTokensForExactTokens,
    getAMPBPS,
  };
};

export default useSwapHelpers;

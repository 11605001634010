import { ReactNode, useMemo } from "react";
import {
  WalletAdapterNetwork,
} from "@solana/wallet-adapter-base";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { clusterApiUrl } from "@solana/web3.js";
import { BitgetWalletAdapter, Coin98WalletAdapter, PhantomWalletAdapter } from "@solana/wallet-adapter-wallets";

interface SolanaWalletProviderProps {
  children: ReactNode;
}
const SolanaWalletProvider = ({ children }: SolanaWalletProviderProps) => {
  
  // Solanar wallet setup
  const buildEnv = process.env.REACT_APP_BUILD_ENV || "";
  const currentSolEndPoint =
    buildEnv === "master"
      ? WalletAdapterNetwork.Mainnet
      : WalletAdapterNetwork.Devnet;
  const solEndpoint = clusterApiUrl(currentSolEndPoint);
   const allowedSolWallets = useMemo(() => [
    new Coin98WalletAdapter(),
    // new PhantomWalletAdapter(),
    // new BitgetWalletAdapter(),
   ], []);

  return (
    <ConnectionProvider
      endpoint={solEndpoint}
      config={{ disableRetryOnRateLimit: true }}
    >
      <WalletProvider
        wallets={allowedSolWallets}
        autoConnect={false}
      >
        {children}
      </WalletProvider>
    </ConnectionProvider>
  );
};

export default SolanaWalletProvider;

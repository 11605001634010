import React from "react";

interface Props {
  className?: string;
}

const ListedOn: React.FC<Props> = ({ className }) => {
  const tokenList = [
    "okx",
    "indodax",
    "bitazza",
    "mexc",
    "bitfinex",
    "gate",
    "vcc",
    "kucoin",
    "bithumb",
    "poloniex",
  ];

  return (
    <div className={className}>
      <div className="text-h4 mb-4 uppercase">Listed on</div>
      <div className="border border-[#19506F] rounded-[2px] p-8 w-full justify-center">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-8 md:gap-6">
          {tokenList.map((token) => (
            <div key={token} className="opacity-100">
              <img
                className="object-contain h-6 w-full"
                src={require(`../../assets/listing/${token}.svg`)} // Use .default to access the default export
                alt={token} // It's a good practice to add alt text for accessibility
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ListedOn;

import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { lpExtra } from "../../routes/pool";
import BigNumber from "bignumber.js";
import Modal from "../modal";
import AmountInput from "../amountInput";
import TableRow from "../table/row";
import ToolTip from "../toolTip";
import Button from "../button";
import { useWallet } from "../../contexts/walletContext";
import { durationTime, lpType } from "../../constants";
import { useEthereum } from "../../contexts/etherruemContext";
import { useTokenHelpers } from "../../hooks/useTokenHelpers";
import { useNotification } from "../../contexts/notificationContext";
import { toWei } from "../../utils/token";
import useLiquidity from "../../hooks/useLiquidityHelpers";
import useErrorHelpers from "../../hooks/useErrorHelpers";
import { Token } from "../../interfaces/token";

interface RemoveLiquidityProps {
  onConfirmRemove: (
    lpTokenID: string,
    pendingStatus: boolean,
    state?: string
  ) => void;
}

const RemoveModal = forwardRef((props: RemoveLiquidityProps, ref) => {
  const { onConfirmRemove } = props;
  //   const [inputAmount, setInputAmount] = useState<number>(0);

  const [lpToken, setLpToken] = useState<lpExtra>();
  const [isApproved, setIsApproved] = useState<boolean>(false);
  const [isPending, setIsPending] = useState<boolean>(false);
  const isPendingMap = new Map();
  const transactionMap = new Map();
  const [receiveBalance, setReceiveBalance] = useState<Record<string, any>>({});
  const [isInsufficientBalance, setIsInsufficientBalance] =
    useState<boolean>(false);
  const [inputAmount, setInputAmount] = useState<string | null>(null);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [txHash, setTxHash] = useState<any>();

  const wallet = useWallet();
  const ethereum = useEthereum();

  const { approveTokenToSpender } = useTokenHelpers();
  const { setNotify } = useNotification();
  const { getErrorReason } = useErrorHelpers();
  const {
    slippageCalculation,
    removeLiquidityETHOnAMM,
    removeLiquidityOnAMM,
    removeLiquidityOnDMM,
  } = useLiquidity();

  const valueField = (value: BigNumber) => {
    if (!value || value.isEqualTo(0) || value.isNaN()) return "0.0000";
    return value.toFixed(8);
  };

  const tooltipToken0PerToken1 = useMemo(() => {
    if (!lpToken) return "";
    return `price 1 ${lpToken.token0.name} per ${valueField(
      receiveBalance.token1PerToken0
    )} ${lpToken.token1.name}`;
  }, [lpToken, receiveBalance]);

  const tooltipToken1PerToken0 = useMemo(() => {
    if (!lpToken) return "";
    return `price 1 ${lpToken.token1.name} per ${valueField(
      receiveBalance.token0PerToken1
    )} ${lpToken.token0.name}`;
  }, [lpToken, receiveBalance]);

  const isInvalidAmount = useMemo(() => {
    return !inputAmount || parseFloat(inputAmount) <= 0;
  }, [inputAmount]);

  const submitButtonText = useMemo(() => {
    if (isInvalidAmount) {
      return "Enter amount";
    }

    if (isInsufficientBalance) {
      return "Insufficient LP token";
    }

    if (!isApproved && !isPending) {
      return "Approve";
    }

    if (isApproved && !isPending) {
      return "Confirm remove liquidity";
    }

    return "Processing...";
  }, [isInvalidAmount, isInsufficientBalance, isApproved, isPending]);

  const convertedToken = useMemo(() => {
    if (!lpToken) return;
    const token: Token = { ...lpToken, symbol: "" };
    return token;
  }, [lpToken]);

  useEffect(() => {
    callReceiveTokenBalance();
  }, [inputAmount]);

  useImperativeHandle(ref, () => ({
    open,
  }));

  const open = (lpToken: lpExtra) => {
    // Equivalent to Vue's `$nextTick`
    setTimeout(() => {
      // Set the lpToken and other states
      setLpToken({ ...lpToken });
      setIsApproved(lpToken.isAllowance);

      setIsModalOpen(true);
      // Open modal using the ref
      //   modalRef.current?.open();

      // Reset input amount and balance state
      setInputAmount(null);
      setIsInsufficientBalance(false);

      // Call additional functions
      getPendingStatus();
      callReceiveTokenBalance();
    }, 0);
  };

  const getPendingStatus = () => {
    if (lpToken?.id && isPendingMap.has(lpToken?.id)) {
      setIsPending(isPendingMap.get(lpToken.id) ?? false);
    } else {
      setIsPending(false);
      //   isPending = false
    }
  };

  const callReceiveTokenBalance = () => {
    // setReceiveBalance({});
    // receiveBalance = {};
    if (inputAmount) {
      const weightFromInputAmount = BigNumber(
        toPrecision(parseFloat(inputAmount))
      ).div(BigNumber(lpToken?.lpBalance ?? "0"));

      const estimateToken0Amount = weightFromInputAmount.times(
        BigNumber(lpToken?.token0Amount ?? "0")
      );

      const estimateToken1Amount = weightFromInputAmount.times(
        BigNumber(lpToken?.token1Amount ?? "0")
      );

      const token0PerToken1 = estimateToken0Amount.div(estimateToken1Amount);

      const token1PerToken0 = estimateToken1Amount.div(estimateToken0Amount);

      setReceiveBalance({
        estimateToken0Amount: estimateToken0Amount,
        estimateToken1Amount: estimateToken1Amount,
        token0PerToken1: token0PerToken1,
        token1PerToken0: token1PerToken0,
      });
    } else {
      setReceiveBalance({});
    }
  };

  const handleClickConfirm = () => {
    if (!isApproved) {
      approve();
    }
    if (isApproved) {
      confirmRemove();
    }
  };

  const approve = () => {
    if (!lpToken) return;
    setIsPending(true);

    isPendingMap.set(lpToken?.id, true);
    // const tokenSymbol = lpToken?.code.replace("/", "_");
    const tokenAddress = lpToken?.address ?? "";
    const ownerAddress = wallet.state.address;
    // // default router is AMM
    let routerAddress = ethereum.contracts.ammRouter._address;
    if (lpToken?.lpType === lpType.DMM_TYPE) {
      routerAddress = ethereum.contracts.dmmRouter._address;
    }

    approveTokenToSpender(tokenAddress, ownerAddress, routerAddress, {
      onTransactionHash: (txHash) => {
        transactionMap.set(txHash, lpToken.id);
        onConfirmRemove(lpToken.id, true);
        // $emit("onConfirmRemove", lpToken.id, true);
        const payload = {
          status: "processing",
          txHash,
          duration: durationTime.MIDDLE_TIME,
        };
        setNotify(payload);
      },
      onReceipt: (receipt) => {
        setIsPending(false);
        const lpTokenID = transactionMap.get(receipt.transactionHash);
        transactionMap.delete(receipt.transactionHash);
        onConfirmRemove(lpToken.id, false, "approve");

        // $emit("onConfirmRemove", lpTokenID, false, "approve");
        if (receipt.status && lpToken.id === lpTokenID) {
          setIsApproved(true);
        }
        isPendingMap.set(lpTokenID, false);

        const payload = {
          status: "success",
          txHash: receipt.transactionHash,
          duration: durationTime.MIDDLE_TIME,
        };
        setNotify(payload);
      },
      onError: (err: any, receipt?: any) => {
        const payload = {
          status: "failed",
          txHash: receipt ? receipt.transactionHash : null,
        };
        setNotify(payload);

        setIsPending(false);
        transactionMap.delete(err.transactionHash);
        isPendingMap.set(lpToken.id, false);
      },
    });
  };

  const getDeadline = () => {
    const now = new Date();
    now.setMinutes(now.getMinutes() + 20);
    return now.getTime();
  };

  const confirmRemove = () => {
    if (!lpToken || !inputAmount) return;
    const deadline = getDeadline();
    const liquidity = toWei(toPrecision(parseFloat(inputAmount))).toString(10);
    const minAmountToken0 = toWei(
      slippageCalculation(receiveBalance.estimateToken0Amount)
    ).toString(10);
    const minAmountToken1 = toWei(
      slippageCalculation(receiveBalance.estimateToken1Amount)
    ).toString(10);
    const token0Address = lpToken.token0.address;
    const token1Address = lpToken.token1.address;
    const ownerAddress = wallet.state.address;

    setIsPending(true);

    isPendingMap.set(lpToken.code, true);

    if (lpToken.token0.symbol === "BNB") {
      removeLiquidityETH(
        token1Address ?? "",
        liquidity,
        minAmountToken1,
        minAmountToken0,
        deadline.toString()
      );
    } else if (lpToken.token1.symbol === "BNB") {
      removeLiquidityETH(
        lpToken.token0.address ?? "",
        liquidity,
        minAmountToken0,
        minAmountToken1,
        deadline.toString()
      );
    }

    // Without BNB And AMM , DMM Pools
    else if (lpToken.lpType === lpType.DMM_TYPE) {
      lpToken.lpType === lpType.DMM_TYPE &&
        removeLiquidityPoolOnDMM(
          token0Address ?? "",
          token1Address ?? "",
          lpToken.address ?? "",
          liquidity,
          minAmountToken0,
          minAmountToken1,
          ownerAddress,
          deadline.toString()
        );
    } else if (lpToken.lpType === lpType.AMM_TYPE) {
      lpToken.lpType === lpType.AMM_TYPE &&
        removeLiquidityPoolOnAMM(
          token0Address ?? "",
          token1Address ?? "",
          liquidity,
          minAmountToken0,
          minAmountToken1,
          ownerAddress,
          deadline.toString()
        );
    } else {
      console.log("Unsupport other LP");
    }
  };

  const removeLiquidityETH = (
    tokenAddress: string,
    liquidity: string,
    amountTokenMin: string,
    amountNativeMin: string,
    deadline: string
  ) => {
    removeLiquidityETHOnAMM(
      tokenAddress,
      liquidity,
      amountTokenMin,
      amountNativeMin,
      deadline,
      wallet.state.address,
      {
        onTransactionHash: (txHash) => {
          setTxHash(txHash);
          transactionMap.set(txHash, lpToken?.id);
          onConfirmRemove(lpToken?.id ?? "", true);

          //   $emit("onConfirmRemove", lpToken.id, true);

          setIsPending(false);
          isPendingMap.set(lpToken?.id, true);
          close();
          const payload = {
            status: "processing",
            txHash,
            time: durationTime.MIDDLE_TIME,
          };
          setNotify(payload);
        },
        onReceipt: (receipt) => {
          const payload = {
            status: receipt.status ? "success" : "failed",
            txHash: receipt.transactionHash,
            time: durationTime.MIDDLE_TIME,
          };
          setNotify(payload);

          const lpTokenID = transactionMap.get(receipt.transactionHash);
          onConfirmRemove(lpToken?.id ?? "", false);

          //   $emit("onConfirmRemove", lpTokenID, false);
          transactionMap.delete(receipt.transactionHash);

          setIsPending(false);
          isPendingMap.set(lpToken?.id, false);

          onFinishCallback();
        },
        onError: async (_) => {
          const reason = await getErrorReason(txHash);
          const payload = {
            status: "failed",
            errorReason: reason,
            txHash: txHash,
            time: durationTime.MIDDLE_TIME,
          };
          setNotify(payload);

          const lpTokenID = transactionMap.get(txHash);

          onConfirmRemove(lpTokenID, false);
          //   $emit("onConfirmRemove", lpTokenID, false);
          transactionMap.delete(txHash);

          setIsPending(false);
          isPendingMap.set(lpToken?.id, false);

          onFinishCallback();
        },
      }
    );
  };

  const removeLiquidityPoolOnAMM = (
    tokenAAddress: string,
    tokenBAddress: string,
    liquidity: string,
    amountAMin: string,
    amountBMin: string,
    ownerAddress: string,
    deadline: string
  ) => {
    removeLiquidityOnAMM(
      tokenAAddress,
      tokenBAddress,
      liquidity,
      amountAMin,
      amountBMin,
      ownerAddress,
      deadline,
      {
        onTransactionHash: (txHash) => {
          setTxHash(txHash);
          transactionMap.set(txHash, lpToken?.id);
          onConfirmRemove(lpToken?.id ?? "", true);
          //   $emit('onConfirmRemove', lpToken?.id, true)

          setIsPending(false);
          isPendingMap.set(lpToken?.id, true);

          close();

          const payload = {
            status: "processing",
            txHash,
            time: durationTime.LONG_TIME,
          };
          setNotify(payload);
        },
        onReceipt: (receipt) => {
          const payload = {
            status: receipt.status ? "success" : "failed",
            txHash: receipt.transactionHash,
            time: durationTime.LONG_TIME,
          };
          setNotify(payload);

          const lpTokenID = transactionMap.get(receipt.transactionHash);
          onConfirmRemove(lpTokenID, false);
          //   $emit('onConfirmRemove', lpTokenID, false)

          transactionMap.delete(receipt.transactionHash);
          setIsPending(false);
          isPendingMap.set(lpToken?.id, false);

          onFinishCallback();
        },
        onError: async (_) => {
          const reason = await getErrorReason(txHash);
          const payload = {
            status: "failed",
            errorReason: reason,
            txHash: txHash,
            time: durationTime.LONG_TIME,
          };
          setNotify(payload);

          const lpTokenID = transactionMap.get(txHash);

          onConfirmRemove(lpTokenID, false);
          //   $emit('onConfirmRemove', lpTokenID, false)
          transactionMap.delete(txHash);

          setIsPending(false);
          isPendingMap.set(lpToken?.id, false);

          onFinishCallback();
        },
      }
    );
  };

  const removeLiquidityPoolOnDMM = (
    tokenAAddress: string,
    tokenBAddress: string,
    poolAddress: string,
    liquidity: string,
    amountAMin: string,
    amountBMin: string,
    ownerAddress: string,
    deadline: string
  ) => {
    removeLiquidityOnDMM(
      tokenAAddress,
      tokenBAddress,
      poolAddress,
      liquidity,
      amountAMin,
      amountBMin,
      ownerAddress,
      deadline,
      {
        onTransactionHash: (txHash) => {
          setTxHash(txHash);
          transactionMap.set(txHash, lpToken?.id);
          onConfirmRemove(lpToken?.id ?? "", true);
          //   $emit('onConfirmRemove', lpToken?.id, true)

          setIsPending(false);
          isPendingMap.set(lpToken?.id, true);

          close();

          const payload = {
            status: "processing",
            txHash,
            time: durationTime.LONG_TIME,
          };
          setNotify(payload);
        },
        onReceipt: (receipt) => {
          const payload = {
            status: receipt.status ? "success" : "failed",
            txHash: receipt.transactionHash,
            time: durationTime.LONG_TIME,
          };
          setNotify(payload);

          const lpTokenID = transactionMap.get(receipt.transactionHash);
          onConfirmRemove(lpTokenID, false);
          //   $emit('onConfirmRemove', lpTokenID, false)

          transactionMap.delete(receipt.transactionHash);
          setIsPending(false);
          isPendingMap.set(lpToken?.id, false);

          onFinishCallback();
        },
        onError: async (_) => {
          const reason = await getErrorReason(txHash);
          const payload = {
            status: "failed",
            errorReason: reason,
            txHash: txHash,
            time: durationTime.LONG_TIME,
          };
          setNotify(payload);

          const lpTokenID = transactionMap.get(txHash);

          onConfirmRemove(lpTokenID, false);
          //   $emit('onConfirmRemove', lpTokenID, false)
          transactionMap.delete(txHash);

          setIsPending(false);
          isPendingMap.set(lpToken?.id, false);

          onFinishCallback();
        },
      }
    );
  };

  const onFinishCallback = () => {
    wallet.updateBalance();
  };

  const close = () => {
    setIsModalOpen(false);
  };
  const onUpdateAmount = ({
    target: { value },
  }: {
    target: { value: number };
  }) => {
    setIsInsufficientBalance(
      value < 0 || Boolean(lpToken?.lpBalance && value > lpToken?.lpBalance)
    );
    // $emit("update:isInsufficientBalance", isInsufficientBalance);
    // $emit("update:amount", BigNumber(value).toNumber());
  };
  const setMaxLpAmount = () => {
    // $emit("update:amount", lpToken.lpBalance);
  };
  const toPrecision = (value: number, digit = 18) => {
    return BigNumber(value).toFixed(digit, 1).toString();
  };

  return (
    <Modal
      isShowDialog={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      widthClass="w-[360px]"
      title="Remove Liquidity"
      footer={
        <div className="w-full">
          <Button
            className="w-full uppercase"
            //   dataTestId="remove_pool_confirm_btn"
            disabled={isInsufficientBalance || isPending || isInvalidAmount}
            onClick={handleClickConfirm}
          >
            {submitButtonText}
          </Button>
        </div>
      }
    >
      <div className="w-full flex flex-col gap-6 md:gap-4">
        {lpToken && (
          <div className="grid w-full">
            <AmountInput
              className="bg-[#19506F] bg-opacity-5"
              testId="remove_pool"
              token={convertedToken}
              amount={inputAmount ? inputAmount : ""}
              onUpdateAmount={setInputAmount}
              balance={lpToken.lpBalance.toString()}
              isInsufficient={isInsufficientBalance}
            />
          </div>
        )}
        <div className="flex flex-col gap-2 text-xs">
          <p className="text-sm font-medium">You will receive</p>
          <TableRow
            className="py-[10px] flex gap-1"
            colorClass="bg-[#909090] bg-opacity-5"
            leftSlot={
              <div className="flex gap-1">
                <img
                  src={lpToken?.token0.image}
                  className="w-4"
                  alt={lpToken?.token0.name}
                />
                <div className="flex items-center font-normal">
                  {lpToken?.token0.name}
                </div>
                <ToolTip
                  onModal
                  iconSize="base"
                  iconClass="leading-none text-transparent bg-clip-text bg-velo-primary-gradient"
                  message={tooltipToken0PerToken1}
                />
              </div>
            }
            rightSlot={
              <div className="text-right font-medium">
                {receiveBalance?.estimateToken0Amount &&
                  Number(receiveBalance.estimateToken0Amount)
                    .toFixed(8)
                    .toString()}
              </div>
            }
          ></TableRow>

          <TableRow
            className="py-[10px] flex gap-1"
            colorClass="bg-[#909090] bg-opacity-5"
            leftSlot={
              <div className="flex gap-1">
                <img
                  src={lpToken?.token1.image}
                  className="w-4"
                  alt={lpToken?.token1.name}
                />
                <div className="flex items-center font-normal">
                  {lpToken?.token1.name}
                </div>
                <ToolTip
                  onModal
                  iconSize="base"
                  iconClass="leading-none text-transparent bg-clip-text bg-velo-primary-gradient"
                  message={tooltipToken1PerToken0}
                />
              </div>
            }
            rightSlot={
              <div className="text-right font-medium">
                {receiveBalance?.estimateToken1Amount &&
                  Number(receiveBalance.estimateToken1Amount)
                    .toFixed(8)
                    .toString()}
              </div>
            }
          ></TableRow>

          <TableRow
            className="py-[10px] flex gap-1"
            colorClass="bg-[#909090] bg-opacity-5"
            leftSlot={<div className="font-normal">Your pool share</div>}
            rightSlot={
              <div className="text-right font-medium">
                {lpToken?.percentOfPoolShare &&
                  Number(lpToken?.percentOfPoolShare).toFixed(2).toString()}
                %
              </div>
            }
          ></TableRow>
        </div>
      </div>
    </Modal>
  );
});

export default RemoveModal;

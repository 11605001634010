import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from "react";
// Assuming you have API functions
import { BigNumber } from "bignumber.js";
import { useWallet } from "../../contexts/walletContext";
import useAprHelper from "../../hooks/useAprHelpers";
import useBlockTime from "../../hooks/useBlockTime";
import FarmFixAPR from "../../components/farm/fixAPR";
import {
  getDate,
  getDateByUnix,
  getUnixFromBlock,
  period,
} from "../../utils/datetime";
import { BlockTimeGenerationResult } from "../../interfaces/block";
import {
  FixApr,
  FixAprExtra,
  FixAprLive,
  FixAprLock,
  FixAprLockWthChangeableRatio,
  NoFixApr,
} from "../../interfaces/config";
import useFarmCommon from "../../hooks/useFarmCommon";
import { PairExtras, useFarm } from "../../contexts/farmContext";
import FarmLayout from "./layout";
import LpToken from "../../components/farm/lpToken";
import { PairEarned } from "../../interfaces/token";
import config from "../../config";
import { useEthereum } from "../../contexts/etherruemContext";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DepositWithdrawModal from "../../components/farm/depositWithdrawModal";
import WithdrawClaimModal from "../../components/farm/withdrawClaimModal";
import ROIModal from "../../components/farm/ROIModal";
import useAprFixLockHelper from "../../hooks/useAprFixLockHelpers";
import useAprFixLockWithChangeableRatio from "../../hooks/useAprFixLockWithChangableRatioHelpers";
import useAprNoFixHelper from "../../hooks/useAprNoFixHelpers";
import ButtonGoToTop from "../../components/button/goToTop";
import FarmFixAPRLock from "../../components/farm/fixAPRLock";
import FarmFixAprLockWithChangeableRatio from "../../components/farm/fixAprLockWithChangeableRatio";

const FarmExpired: React.FC = () => {
  const location = useLocation();
  const params = useParams<{ chains: string }>();
  const navigate = useNavigate();
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const depositWithDrawModalRef = useRef<{
    open: (
      pair: PairEarned,
      topic: string,
      balance: BigNumber,
      isShowGetLP: boolean,
      lpLink: string | null | undefined
    ) => any;
  }>(null);
  const farmLockConfirmModal = useRef<{
    open: (pair: any, period: number) => any;
  }>(null);
  const farmClaimModal = useRef<{
    open: (pair: PairEarned, rewardBalance?: BigNumber) => any;
  }>(null);
  const farmROIModal = useRef<{
    open: (
      pair: PairEarned,
      apr: any,
      rewardPerUsd: any,
      lpLink: string
    ) => any;
  }>(null);

  const [fixedAPRLock, setFixedAPRLock] = useState<FixAprLock[]>([]);
  const [fixedAPR, setFixedAPR] = useState<FixApr[]>([]);
  const [fixAPRLockWithChangeableRatio, setFixAPRLockWithChangeableRatio] =
    useState<FixAprLockWthChangeableRatio[]>([]);
  const [pairs, setPairs] = useState<PairEarned[]>([]);
  const [earnOther, setEarnOther] = useState<NoFixApr[]>([]);
  const [earnGovernanceToken, setEarnGovernanceToken] = useState<any[]>([]);
  const [pairExtras, setPairExtras] = useState<PairExtras>({});
  const [rewardPerUsd, setRewardPerUsd] = useState(new BigNumber(0));

  const [blockData, setBlockData] = useState<BlockTimeGenerationResult | null>(
    null
  );
  const [isWithdraw, setIsWithdraw] = useState(false);

  const { calculateBlockTimeGeneration } = useBlockTime();
  const { getLiquidityLink, chainId } = useFarmCommon();

  const wallet = useWallet();

  const { farmEnv } = useFarm();
  const ethereum = useEthereum();

  const {
    getAllFarmFixedAPRInfo,
    getFarmFixedAPRInfo,
    isReady: isAprHelperReady,
  } = useAprHelper();
  const {
    getAllFarmFixedAPRLockInfo,
    getFarmFixedAPRLockInfo,
    isReady: isAprLockInfoReady,
  } = useAprFixLockHelper();
  const {
    getAllFarmFixedAPRLockWithChangeableRatioInfo,
    getFarmFixedAPRLockWithChangeableRatioInfo,
    isReady: isAprFixLockReady,
  } = useAprFixLockWithChangeableRatio();
  const { getFarmInfo, isReadyForGetFarmInfo } = useFarm();

  const getFarm = useMemo(() => {
    if (!farmEnv || !farmEnv.length) {
      // console.error("fetchAllDetails farmEnv is not initialized or empty");
      return null;
    }
    return farmEnv;
  }, [farmEnv]);

  // useEffect(() => {onFetchFixedAPR()},[])

  useEffect(() => {
    if (!farmEnv || !ethereum || !ethereum.contracts || !ethereum.web3) return;
    if (
      isReadyForGetFarmInfo &&
      isAprFixLockReady &&
      isAprHelperReady &&
      isAprLockInfoReady
    ) {
      fetchAllDetails();
    }

    const interval = setInterval(() => fetchAllDetails(), 5000);

    return () => clearInterval(interval);
  }, [
    getFarm,
    ethereum,
    farmEnv,
    isReadyForGetFarmInfo,
    isAprFixLockReady,
    isAprHelperReady,
    isAprLockInfoReady,
  ]);

  useEffect(() => {
    if (location.pathname.includes("/farms")) {
      const whitelistChain = Object.values(config.networks).map((o: any) =>
        o.code.toLowerCase()
      );

      if (!params.chains) {
        navigate("/error", { replace: true });
        return;
      }

      if (!whitelistChain.includes(params.chains)) {
        const defaultChain = config.networks[config.chainId].code.toLowerCase();
        navigate(`/farms/${defaultChain}/live`, { replace: true });
      }
    }
  }, [location.pathname, params.chains, navigate]);

  const chainExplorerURL = useMemo(() => {
    const id = chainId();
    if (!id) return;
    return config.networks[Number(id)]?.chainExplorerURL;
  }, [chainId]);

  const mappingData = (data: any): FixAprExtra => {
    return {
      ...data,
      lpLink: getLiquidityLink(data?.staking),
      startTime: getUnixFromBlock(
        data?.startBlock,
        blockData?.currentBlock ?? 1,
        blockData?.blockTimeGeneration ?? 1
      ),
      endTime: getUnixFromBlock(
        data?.endBlock,
        blockData?.currentBlock ?? 1,
        blockData?.blockTimeGeneration ?? 1
      ),
    };
  };

  const isExpired = async (data: FixAprLive) => {
    const now = getDate();
    const endTime = getDateByUnix(data.endTime);

    return endTime.isSameOrBefore(now);
  };

  const fixedAPRLockData = useMemo(() => {
    return fixedAPRLock.map(mappingData).filter((data) => {
      const now = getDate();
      const endTime = getDateByUnix(data.endTime);

      return endTime.isSameOrBefore(now);
    });
  }, [fixedAPRLock]);

  const fixAPRLockWithChangeableRatioData = useMemo(() => {
    return fixAPRLockWithChangeableRatio.map(mappingData).filter(isExpired);
  }, [fixAPRLockWithChangeableRatio]);

  const onFetchFixedAPR = async (index: number) => {
    const isValidChain = await wallet.isValidChain();
    if (!isValidChain) return;
    const [newData, blockData] = await Promise.all([
      getFarmFixedAPRInfo(index),
      calculateBlockTimeGeneration(),
    ]);

    if (blockData) setBlockData(blockData);
    if (newData)
      setFixedAPR((prev) => prev.splice(index, 1, mappingData(newData)));
  };

  const onFetchFixedAPRLock = async (
    index: number,
    contractAddress: string
  ) => {
    const isValidChain = await wallet.isValidChain();
    if (!isValidChain) return;
    const [newData, blockData] = await Promise.all([
      getFarmFixedAPRLockInfo(contractAddress),
      calculateBlockTimeGeneration(),
    ]);
    if (blockData) setBlockData(blockData);
    if (newData)
      setFixedAPR((prev) => prev.splice(index, 1, mappingData(newData)));
  };

  const onFetchFixedAPRLockWithChangeableRatio = async (index: number) => {
    const isValidChain = await wallet.isValidChain();
    if (!isValidChain) return;
    const [newData, blockData] = await Promise.all([
      getFarmFixedAPRLockWithChangeableRatioInfo(index),
      calculateBlockTimeGeneration(),
    ]);
    if (blockData) setBlockData(blockData);
    if (newData)
      setFixedAPR((prev) => prev.splice(index, 1, mappingData(newData)));
  };

  const run = useRef(false);

  const fetchAllDetails = async () => {
    if (run.current) return;
    run.current = true;

    // const chainValid = await wallet.isValidChain();
    // if (!chainValid) {
    //   setFixedAPRLock([]);
    //   setFixAPRLockWithChangeableRatio([]);
    //   setFixedAPR([]);
    //   setEarnOther([]);
    //   setEarnGovernanceToken([]);
    //   return;
    // }

    const filteredFarm = getFarm?.find(
      (o) => o.rewardToken.code === "EVRY_TOKEN"
    );
    if (!filteredFarm) return;
    const farmInfo = await getFarmInfo(filteredFarm, true);

    setPairExtras(farmInfo.pairExtras);
    setRewardPerUsd(farmInfo.rewardPerUsd);

    const [
      fetchedFixedAPRLock,
      fixAPRLockWithChangeableRatio,
      fetchedFixedAPR,
      calculatedBlockTimeGeneration,
    ] = await Promise.all([
      getAllFarmFixedAPRLockInfo(),
      getAllFarmFixedAPRLockWithChangeableRatioInfo(),
      getAllFarmFixedAPRInfo(),
      calculateBlockTimeGeneration(),
    ]);

    setFixedAPRLock(fetchedFixedAPRLock);
    setFixAPRLockWithChangeableRatio(fixAPRLockWithChangeableRatio);
    setFixedAPR(fetchedFixedAPR);
    setBlockData(calculatedBlockTimeGeneration);

    fetchPairs();
  };

  const fetchPairs = () => {
    if (!getFarm) return;
    const pairList = getFarm.find((o) => o.rewardToken.code === "EVRY_TOKEN");
    if (!pairList) return;

    const pair = pairList.pairs.map((pair) => ({
      ...pair,
      lpLink: getLiquidityLink(pair?.staking),
    }));
    setPairs(pair);
  };

  // useEffect(() => {
  //   timerRef.current = setInterval(() => {
  //     fetchAllDetails();
  //   }, 60000);

  //   return () => {
  //     if (timerRef.current) {
  //       clearInterval(timerRef.current);
  //     }
  //   };
  // }, []);

  const onRedirect = (pair: any) => {
    navigate(pair.lpLink);
  };

  const openROIModal = (id: string) => {
    if (!farmROIModal.current) return;
    const pair = earnGovernanceToken.find((pair) => pair.id === id);
    const apr = pairExtras[id].apr;
    return farmROIModal.current.open(pair, apr, rewardPerUsd, pair.lpLink);
  };

  const openDepositWithdrawModal = (
    pair: PairEarned,
    topic: string,
    balance: BigNumber,
    isShowGetLP: boolean
  ) => {
    if (depositWithDrawModalRef.current)
      return depositWithDrawModalRef.current.open(
        pair,
        topic,
        balance,
        isShowGetLP,
        pair.lpLink
      );
  };

  const openClaimModal = (pair: PairEarned, rewardBalance: BigNumber) => {
    if (!farmClaimModal.current) return;
    return farmClaimModal.current.open(pair, rewardBalance);
  };

  return (
    <FarmLayout>
      <div className="md:container">
        <div className="flex flex-col mx-auto justify-center">
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 md:gap-8 animate__animated animate__fadeInUp pt-6 md:pt-0 px-4 md:px-0 pb-14">
            {/* {fixedAPRData.map((pool, index) => {
              return (
                <FarmFixAPR
                  key={`pool-fixed-apr-${index}`}
                  pool={pool}
                  openLockConfirmModal={openLockConfirmModal}
                  openWithdrawClaimModal={openWithdrawClaimModal}
                  openDepositWithdrawModal={openDepositWithdrawModal}
                  // openROIModal={openROIModal}
                  rewardProp={getFarm && getFarm[index]?.rewardToken} // Adjust reward if needed
                  onFetchFixedAPR={() => onFetchFixedAPR(index)}
                />
              );
            })} */}
            {fixedAPRLockData.map((pool, index) => {
              return (
                <FarmFixAPRLock
                  key={`pool-fixed-apr-lock-${index}`}
                  pool={pool}
                  openWithdrawClaimModal={setIsWithdraw}
                  openLockConfirmModal={setIsWithdraw}
                  openDepositWithdrawModal={setIsWithdraw}
                  reward={
                    getFarm &&
                    getFarm.find((o) => o.rewardToken.code === "VELO_TOKEN")
                      ?.rewardToken
                  }
                  onFetchFixedAPRLock={() =>
                    onFetchFixedAPRLock(index, pool.contractAddress || "")
                  }
                />
              );
            })}
            {fixAPRLockWithChangeableRatioData.map((pool, index) => {
              return (
                <FarmFixAprLockWithChangeableRatio
                  key={`pool-fixed-apr-lock-with-change-ratio-${index}`}
                  pool={pool}
                  openWithdrawClaimModal={setIsWithdraw}
                  openLockConfirmModal={setIsWithdraw}
                  openDepositWithdrawModal={setIsWithdraw}
                  reward={
                    getFarm &&
                    getFarm.find((o) => o.rewardToken.code === "VELO_TOKEN")
                      ?.rewardToken
                  }
                  onFetchFixedAPRLock={() =>
                    onFetchFixedAPRLockWithChangeableRatio(index)
                  }
                />
              );
            })}
            {/* <FarmFixAPR
              key={`pool-fixed-apr-${index}`}
              {...pool}
              openWithdrawClaimModal={setIsWithdraw}
              openLockConfirmModal={setIsWithdraw}
              openDepositWithdrawModal={setIsWithdraw}
              reward={"VELO_TOKEN"} // Adjust reward if needed
              onFetchFixedAPR={() => fetchFixedAPR()}
            /> */}
            {/* {fixedAPR.map((pool, index) => (
            <FarmFixAPR
              key={`pool-fixed-apr-${index}`}
              {...pool}
              openWithdrawClaimModal={setIsWithdraw}
              openLockConfirmModal={setIsWithdraw}
              openDepositWithdrawModal={setIsWithdraw}
              reward={"VELO_TOKEN"} // Adjust reward if needed
              onFetchFixedAPR={() => fetchFixedAPR()}
            />
            ))} */}
            {/* 
            {fixedAPRLock.map((pool, index) => (
            <FarmFixAPRLock
              key={`pool-fixed-apr-lock-${index}`}
              {...pool}
              openWithdrawClaimModal={setIsWithdraw}
              openLockConfirmModal={setIsWithdraw}
              openDepositWithdrawModal={setIsWithdraw}
              reward={"VELO_TOKEN"} // Adjust reward if needed
              onFetchFixedAPRLock={() => fetchFixedAPRLock()}
            />
            ))} */}

            {/* {fixAPRLockWithChangeableRatio.map((pool, index) => (
            <FarmFixAprLockWithChangeableRatio
              key={`pool-fixed-apr-lock-with-change-ratio-${index}`}
              {...pool}
              openWithdrawClaimModal={setIsWithdraw}
              openLockConfirmModal={setIsWithdraw}
              openDepositWithdrawModal={setIsWithdraw}
              reward={"VELO_TOKEN"} // Adjust reward if needed
              onFetchFixedAPRLockWithChangeableRatio={() => fetchFixedAPRLock()}
            />
            ))} */}

            {pairs.map((pair, id) => (
              <div key={id} className="flex items-end">
                <LpToken
                  className="flex-1"
                  pair={pair}
                  reward={
                    getFarm &&
                    getFarm.find((o) => o.rewardToken.code === "EVRY_TOKEN")
                      ?.rewardToken
                  } // Adjust reward if needed
                  contractAddress={
                    getFarm &&
                    getFarm.find((o) => o.rewardToken.code === "EVRY_TOKEN")
                      ?.contractAddress
                  }
                  expired={pair.expired}
                  totalStaked={pairExtras[pair.id]?.totalStaked}
                  apr={pairExtras[pair.id]?.apr}
                  lpPerUsd={pairExtras[pair.id]?.lpPerUsd}
                  evryPerUsd={rewardPerUsd}
                  openDepositWithdrawModal={openDepositWithdrawModal}
                  openClaimModal={openClaimModal}
                  openROIModal={openROIModal}
                  scanURL={chainExplorerURL ?? config.chainExplorerURL ?? ""}
                />
              </div>
            ))}

            {/* {(fixedAPRLock.length ||
              fixedAPR.length ||
              earnOther.length ||
              earnGovernanceToken.length) && <ButtonGoToTop />} */}
          </div>
        </div>

        <DepositWithdrawModal ref={depositWithDrawModalRef} />
        <WithdrawClaimModal isWithdraw={isWithdraw} ref={farmClaimModal} />
        {/*<FarmLockConfirmModal ref={null} />*/}
        <ROIModal onRedirect={onRedirect} ref={farmROIModal} />
      </div>
    </FarmLayout>
  );
};

export default FarmExpired;

import BN from "bignumber.js";

export const toWei = (ether: string) => {
  return BN(ether).multipliedBy(Math.pow(10, 18)).decimalPlaces(0);
};

export const toEther = (wei: BN) => {
  return wei.div(Math.pow(10, 18));
};

export const strToNumber = (str: string) => {
  return BN(str).toNumber();
};

export const strToBigNumber = (wei = "0") => {
  return BN(wei);
};

import React from "react";
import Button from "./button";
import { Token } from "../interfaces/token";

interface Props {
  options: Token[];
  value: string;
  testId: string;
  isShowAddTokenWallet: boolean;
  onClickAddToken: (token: Token) => void;
  onClick: (token: Token) => void;
}

const TokenSelect: React.FC<Props> = ({
  options,
  value,
  testId,
  isShowAddTokenWallet,
  onClickAddToken,
  onClick,
}) => {
  return (
    <div className="flex flex-col gap-8 pb-2">
      {options.map((token) => (
        <div
          key={token.symbol}
          data-testid={`${token.code.toLowerCase()}_item`}
          className="h-10 flex items-center relative"
        >
          <div className="flex items-center flex-1 h-10 w-48 mr-4 rounded">
            <div className="-ml-1">
              <div
                className={`w-6px h-6px ${
                  value === token.symbol ? "rounded-full bg-[#E22970]" : ""
                }`}
              />
            </div>
            <div
              className="flex flex-1 items-center ml-3"
              data-testid={`${testId}_select_${token.code.toLowerCase()}_btn`}
            >
              <img
                data-testid={`${testId}_select_${token.code.toLowerCase()}_img`}
                src={token.image}
                alt={token.name}
                className="h-6 w-6"
              />
              <div className="flex flex-col ml-4">
                <div
                  data-testid={`${testId}_select_token_${token.code.toLowerCase()}_txt`}
                  className="text-base text-white"
                >
                  {token.name.toUpperCase()}
                </div>
                {isShowAddTokenWallet && (
                  <div
                    className={`flex flex-row leading-none items-center tracking-wide ${
                      token.symbol === "BNB"
                        ? "text-velo-disable-2 cursor-not-allowed"
                        : "text-white cursor-pointer"
                    }`}
                    onClick={() => onClickAddToken(token)}
                  >
                    <div className="text-xl font-normal">{"+  "}</div>
                    <div
                      data-testid={`${testId}_add_to_wallet_${token.code.toLowerCase()}_txt`}
                      className="text-sm"
                    >
                      {token.symbol === "BNB"
                        ? "Added by default"
                        : "Add to wallet"}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <Button
            disabled={value === token.symbol}
            size="small"
            type="outline"
            className="w-[108px] shrink-0"
            onClick={() => onClick(token)}
          >
            Select
          </Button>
        </div>
      ))}
    </div>
  );
};

export default TokenSelect;

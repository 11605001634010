import React from "react";
import { Token } from "../../interfaces/token";

interface TokenInfoProps {
  token?: Token;
  totalValueLocked?: number; // Change to appropriate type if necessary
  marketCap?: number; // Change to appropriate type if necessary
  totalSupply?: number; // Change to appropriate type if necessary
  rewardPerBlock?: number; // Change to appropriate type if necessary
  className?: string;
}

const TotalValueLocked: React.FC<TokenInfoProps> = ({
  token = { name: "TOKEN NAME" },
  totalValueLocked = 0,
  marketCap = 0,
  totalSupply = 0,
  rewardPerBlock = 0,
  className,
}) => {
  const formatEtherDollar = (value: number) => {
    // Implement your formatting logic here
    return value.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  };

  const formatDollar = (value: number) => {
    // Implement your formatting logic here
    return value.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  };

  const formatNumberWithMinMaxDigits = (value: number) => {
    // Implement your formatting logic here
    return value.toLocaleString();
  };

  return (
    <div className={`w-full ${className}`}>
      <div className="grid grid-cols-3 gap-2 p-0 md:pl-20 md:pr-18 md:grid-cols-4">
        <div className="col-span-3 md:col-span-1 relative flex justify-center">
          <img
            className="max-w-full w-80 md:m-0 relative z-10 md:w-full"
            src={require("/src/assets/icons/tvl/velo-token-tvl.svg").default}
            alt="Token"
          />
          <img
            className="w-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 scale-150"
            src={
              require("/src/assets/icons/tvl/velo-token-tvl-accent.svg").default
            }
            alt="Token Accent"
          />
        </div>
        <div className="col-span-3 md:col-span-3 md:ml-20 px-4 md:px-0">
          <div className="text-[24px] mt-10 mb-[22px] uppercase">
            The {token.name} Token
          </div>
          <div className="font">
            {token.name} tokens are utility tokens designed to transfer value on
            the Velo network and ensure stable settlement. The {token.name}{" "}
            token’s utility lies in its double feature as both collateral and as
            an entrance requirement to the Velo Ecosystem.
            <a
              href="https://www.velo.org/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-velo-primary block md:inline"
            >
              Read more
            </a>
          </div>
          <div className="grid grid-cols-2 gap-2 mt-[35px]">
            <div className="col-span-2 md:col-span-1 mb-[30px] md:mb-0">
              <div className="mb-2 bg-clip-text text-transparent bg-velo-primary-gradient font-semibold">
                Total value lock
              </div>
              <div className="text-[24px]">
                {formatEtherDollar(totalValueLocked)}
              </div>
            </div>
            <div className="col-span-2 md:col-span-1 mb-[30px] md:mb-0">
              <div className="mb-2 bg-clip-text text-transparent bg-velo-primary-gradient font-semibold">
                Total supply
              </div>
              <div className="text-[24px]">
                {formatNumberWithMinMaxDigits(totalSupply)}
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-2 mt0 md:mt-[18px]">
            <div className="col-span-2 md:col-span-1 mb-[30px] md:mb-0">
              <div className="mb-2 bg-clip-text text-transparent bg-velo-primary-gradient font-semibold">
                Fully diluted market cap
              </div>
              <div className="text-[24px]">{formatDollar(marketCap)}</div>
            </div>
            <div className="col-span-2 md:col-span-1 mb-[30px] md:mb-0">
              <div className="mb-2 bg-clip-text text-transparent bg-velo-primary-gradient font-semibold">
                Farm release per block
              </div>
              <div className="text-[24px]">{rewardPerBlock}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TotalValueLocked;

import React, { useEffect, useMemo } from "react";
import BigNumber from "bignumber.js";
import usePeriodHelpers from "../../hooks/usePeriodHelpers";
import ToolTip from "../toolTip";
import { formatBigNumber } from "../../utils/filter";

interface Props {
  isLockWithdraw?: boolean;
  isDisabledDeposit?: boolean;
  startTime?: number;
  endTime?: number;
  limited?: BigNumber;
  staked?: BigNumber;
  periodFix?: string | number;
  onForceCountDown: () => void;
}

const PeriodLockPool: React.FC<Props> = ({
  isLockWithdraw = true,
  isDisabledDeposit = true,
  limited = new BigNumber(0),
  staked = new BigNumber(0),
  startTime = 0,
  endTime = 0,
  onForceCountDown,
  periodFix,
}) => {
  const limitedFormat = useMemo(() => formatBigNumber(limited), [limited]);
  const stakedFormat = useMemo(() => formatBigNumber(staked), [staked]);

  const { periodRange, currentPeriod } = usePeriodHelpers({
    onForceCountDown,
    startTime,
    endTime,
  });

  return (
    <div className="flex bg-[#19506F] bg-opacity-5 border border-[#19506F] rounded-t-[2px]">
      <div className="grid p-4">
        <div className="flex items-center">
          {isLockWithdraw ? "Locked" : "Staking End in"}: {currentPeriod} Days
          {isLockWithdraw && (
            <ToolTip
              className="ml-2"
              onModal={true}
              widthModal="[296px]"
              iconClass="leading-none text-[#2EADD4]"
              message={`
              <div class='pb-2'>Deposited token is locked until this period end</div>
              <div class='pb-2'>
                <span class='text-velo-pink'>${
                  periodFix ? "Subscription period" : "Pool period"
                }: </span></br>
                ${
                  periodFix
                    ? `${periodFix} Days ${
                        isDisabledDeposit ? `(Period has ended)` : ""
                      }`
                    : `${periodRange}`
                }
              </div>
              <div>
                <span class='text-velo-pink'>Limited: </span></br>
                ${limitedFormat} (${stakedFormat} staked)
              </div>
              `}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PeriodLockPool;

import React from "react";
import config from "../../config";

interface Props {
  className?: string;
  tokenPerUsd: number;
  isCollapse?: boolean;
}

const TokenPrice: React.FC<Props> = ({
  className,
  tokenPerUsd,
  isCollapse = false,
}) => {
  // Assuming you have a function to format the number
  const formatNumberWith4Digits = (number: number) => {
    return number.toFixed(4); // Adjust this as per your needs
  };

  const token = config.newFarmToken.VELO_TOKEN;

  return (
    <div className={className}>
      <div
        className={`flex items-center justify-center sm:justify-start ${
          isCollapse ? "flex-col" : ""
        }`}
      >
        <img
          src={token.image}
          className={`h-9 w-9 ${!isCollapse ? "mr-2" : ""}`}
          alt={token.name}
        />
        <div>
          <div
            className={`text-white font-semibold text-number ${
              isCollapse ? "text-number-small mt-1" : ""
            }`}
          >
            ${formatNumberWith4Digits(tokenPerUsd)}
          </div>
          {!isCollapse && (
            <div className="text-white text-xs hidden sm:block">
              Get {token.name}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TokenPrice;

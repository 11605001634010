import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FarmToken } from "../../interfaces/token";
import config from "../../config";
import { useEthereum } from "../../contexts/etherruemContext";
import { useWallet } from "../../contexts/walletContext";
import BigNumber from "bignumber.js";
import useFarmCommon from "../../hooks/useFarmCommon";
import Button from "../button";
import MaterialIcon from "../icon";
import TableRow from "../table/row";
import { useFarm } from "../../contexts/farmContext";
import { useNotification } from "../../contexts/notificationContext";
import {
  formatBigNumberPercentAbbr,
  formatEther,
  formatEtherDollar,
} from "../../utils/filter";
import { useMainConstant } from "../../contexts/mainContext";
import { NoFixAprExtra } from "../../interfaces/config";
import useAprNoFixHelper from "../../hooks/useAprNoFixHelpers";
import { useTokenHelpers } from "../../hooks/useTokenHelpers";
import { useUnifiedWallet } from "../../providers/UnifiedWalletProvider";

interface Props {
  pool: NoFixAprExtra | undefined;
  // apr: BigNumber;
  // expired?: boolean;
  // scanURL: string;
  // contractAddress: string | undefined | null;
  // totalStaked: BigNumber;
  // evryPerUsd: BigNumber;
  // lpPerUsd: BigNumber;
  reward: FarmToken | undefined | null;
  className?: string;
  openWithdrawClaimModal: (pair: any, isWithdraw: boolean) => any;
  openDepositWithdrawModal: (
    pool: any,
    topic: string,
    balance: BigNumber,
    isShowGetLP: boolean
  ) => any;
  openROIModal: (id: string) => any;
  onFetchEarnOther: () => void;
}

const liquidityDOC = config.document.liquidity;

// const poolID = 1;

const EarnOther: React.FC<Props> = ({
  pool,
  // apr,
  // expired = false,
  // scanURL,
  reward,
  className,
  openDepositWithdrawModal,
  openWithdrawClaimModal,
  openROIModal,
}) => {
  const [isPending, setIsPending] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [account, setAccount] = useState("");
  // const [tokenContract, setTokenContract] = useState("");
  // const [lpBalance, setLpBalance] = useState(0);
  // const [earnedToken, setEarnedToken] = useState<BigNumber>();
  const [inputAmount, setInputAmount] = useState(0);
  const [isCollapse, setIsCollapse] = useState(false);

  const ethereum = useEthereum();
  // const wallet = useWallet();
  const { getBalanceOf, getPendingReward, isApprovedAllowance, getUserInfo } =
    useFarm();

  const { chainId, approveToken } = useFarmCommon();
  const { setNotify } = useNotification();
  const { setIsShowConnectModal } = useMainConstant();
  const { depositLpTokenWithCheckAllowance, claimLpToken, withdrawLpToken } =
    useAprNoFixHelper();

  const { checkAllowanceToSpender } = useTokenHelpers();
  const { walletApi } = useUnifiedWallet();

  // useEffect(() => {
  //   if (!walletApi.account.current || !ethereum.isConnected) return;
  //   const check = async () => {
  //     await fetchAmountDetail();
  //   };
  //   check();
  // }, [walletApi.account.current, ethereum.isConnected, contractAddress, pool]);

  //==============================COMPUTED==============================

  const hasStaking = useMemo(() => {
    return pool?.totalStaked && pool?.totalStaked.isGreaterThan(BigNumber("0"));
  }, [pool?.totalStaked]);

  const isValidConnection = useMemo(() => {
    return walletApi.isConnected;
  }, [walletApi.isConnected]);

  const isStaking = useMemo(() => {
    return pool?.balanceInPool?.isGreaterThan(0);
  }, [pool?.balanceInPool]);

  const calculatedRewardEarned = useMemo(() => {
    const token = pool?.earnedToken || BigNumber("0");
    const rewardPrice = BigNumber(pool?.rewardPrice);
    return token.multipliedBy(rewardPrice);
  }, [pool?.earnedToken, pool?.rewardPrice]);

  const calculatedTotalStaked = useMemo(() => {
    const stake = pool?.totalStaked || BigNumber("0");
    return stake.multipliedBy(pool?.lpPerUsd ?? 1);
  }, [pool?.totalStaked, pool?.lpPerUsd]);

  const isPair = useMemo(() => {
    return pool?.lpType === "AMM" || pool?.lpType === "DMM";
  }, [pool?.lpType]);

  //==============================METHODS==============================

  const checkStatus = useCallback(async () => {
    setIsPending(true);
    setIsApproved(false);
    if (!isValidConnection) {
      setIsPending(false);
      return;
    }

    const account = walletApi.account.current ?? "";
    try {
      const isAllow = await checkAllowanceToSpender(
        pool?.id ?? "",
        account,
        pool?.contractAddress ?? ""
      );

      setIsApproved(isAllow);

      if (!isApproved) {
        setIsPending(false);
        return;
      }
      setIsPending(false);
    } catch (err) {
      setIsApproved(false);
      setIsPending(false);
    }
  }, [
    checkAllowanceToSpender,
    isApproved,
    isValidConnection,
    pool?.contractAddress,
    pool?.id,
    walletApi.account,
  ]);

  const onDepositClick = ({ amount }: { amount: any }) => {
    // $emit('update:token')
  };

  const unlockWallet = () => {
    setIsShowConnectModal(true);
    // $bus.$emit('unlock-wallet', chainId)
  };

  const refresh = useCallback(async () => {
    checkStatus();
    // setEarnedToken(undefined);
    // setLpBalance(0);
    // setIsPending(true);
    // setIsApproved(false);
    // if (!isValidConnection()) {
    //   setIsPending(false);
    //   return;
    // }
    // const account = walletApi.account.current ?? "";
    // const isAllow = await isApprovedAllowance(
    //   account,
    //   contractAddress ?? "",
    //   pool?.staking.code ?? ""
    // );
    // setIsApproved(isAllow);
    // if (!isAllow) {
    //   setIsPending(false);
    //   return;
    // }
    // await fetchAmountDetail();
    // setIsPending(false);
  }, [checkStatus]);

  useEffect(() => {
    if (pool && walletApi.account.current) refresh();
  }, [pool, walletApi.account.current]);

  const approve = async () => {
    if (!pool?.contractAddress || !pool) return;
    setIsPending(true);
    const account = walletApi.account.current ?? "";

    try {
      await approveToken(
        pool?.contractAddress ?? "",
        pool?.staking.code ?? "",
        account,
        onUpdateNotification()
      );
    } catch (err) {
      console.error("approve", err);
    }
  };

  const openROI = () => {
    if (!pool?.staking.code) return;
    openROIModal(pool?.staking.code);
  };

  const withdraw = async () => {
    try {
      const { inputAmount, isClose } = await openDepositWithdrawModal(
        pool,
        "Withdraw",
        pool?.balanceInPool ?? BigNumber("0"),
        false
      );

      if (isClose) return;

      setIsPending(true);
      const account = walletApi.account.current ?? "";
      await withdrawLpToken(
        pool?.contractAddress ?? "",
        account,
        inputAmount,
        onUpdateNotification()
      );
      refresh();
    } catch (err) {
      console.error(err);
      setIsPending(false);
    }
  };

  const onUpdateNotification = () => {
    return {
      onTransactionHash: (txHash: any) => {
        const payload = {
          status: "processing",
          txHash,
        };
        setNotify(payload);
      },
      onError: (_: any, receipt: any) => {
        const payload = {
          status: "failed",
          txHash: receipt ? receipt.transactionHash : null,
        };
        setNotify(payload);
        setIsPending(false);
      },
      onReceipt: ({
        transactionHash,
        status,
      }: {
        transactionHash: any;
        status: any;
      }) => {
        setNotify({
          status: status ? "success" : "failed",
          txHash: transactionHash,
        });
        refresh().finally(() => {
          setIsPending(false);
        });
      },
    };
  };

  const deposit = async () => {
    try {
      const account = walletApi.account.current ?? "";
      const { inputAmount, isClose } = await openDepositWithdrawModal(
        pool,
        "Deposit",
        pool?.balanceInPool ?? BigNumber("0"),
        true
      );
      if (isClose) return;
      setIsPending(true);
      await depositLpTokenWithCheckAllowance(
        pool?.staking.code ?? "",
        pool?.staking.address ?? "",
        pool?.contractAddress ?? "",
        account,
        inputAmount,
        onUpdateNotification()
      );
      refresh();
    } catch (err) {
      console.error(err);
      setIsPending(false);
    }
  };

  const claim = async () => {
    try {
      if (!pool?.contractAddress || !pool) return;
      const { confirmed } = await openWithdrawClaimModal(pool, false);
      if (!confirmed) return;
      setIsPending(true);
      const account = walletApi.account.current ?? "";
      await claimLpToken(
        pool?.contractAddress,
        account,
        onUpdateNotification()
      );
      refresh();
    } catch (err) {
      console.error(err);
      setIsPending(false);
    }
  };

  return (
    <div className="w-full h-auto animate__animated animate__fadeInUp">
      <div
        id={pool?.id}
        className="rounded-2xl bg-velo-bg-card bg-opacity-5 border text-velo-wisp-pink border-white border-opacity-20"
      >
        <div className="p-4">
          <div className="flex flex-auto gap-2 items-center">
            <img className="h-8" src={pool?.staking.image} alt="wallet-icon" />
            <div className="flex-auto flex flex-col">
              <div className="flex flex-col gap-1">
                <div className="text-h6">{pool?.staking.name}</div>
                <div className="text-sm">Earn: {reward?.name}</div>
              </div>
            </div>
            <div className="flex-auto flex flex-col h-[42px]">
              <div className="flex flex-col items-end gap-1">
                <div className="flex text-sm font-normal text-velo-label">
                  APR
                </div>
                <div className="flex text-sm font-normal">
                  <div className="flex font-medium justify-center">
                    <div className="text-number mr-1">
                      {pool?.apr && formatBigNumberPercentAbbr(pool?.apr)}%
                    </div>
                    <div
                      id="button_farm_open_roi"
                      className="cursor-pointer"
                      onClick={openROI}
                    >
                      <MaterialIcon className="text-base relative text-velo-label -right-0.5">
                        calculate
                      </MaterialIcon>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={`flex flex-col justify-between p-4 h-[110px] rounded-lg mt-4 ${
              isStaking
                ? "bg-velo-primary-gradient3"
                : "bg-velo-disable-2 bg-opacity-5"
            }`}
          >
            <div className="flex w-full justify-between">
              <div className="flex items-center">
                <img
                  className="h-4 mr-1"
                  src={reward?.image}
                  alt="wallet-icon"
                />
                <div className="text-sm text-velo-label font-bold">
                  Total {reward?.name} Earned
                </div>
              </div>
              <Button
                // id="button_farm_claim"
                type="outline"
                appendIcon="arrow_forward"
                size="small"
                disabled={!isStaking}
                onClick={isStaking ? claim : undefined}
              >
                Claim
              </Button>
            </div>

            <div>
              <div
                className={`text-number ${
                  isApproved && isStaking ? "text-white" : "text-velo-disable-2"
                }`}
              >
                {pool?.earnedToken && formatEther(pool?.earnedToken)}
              </div>
              <div
                className={`text-number-small ${
                  isApproved && isStaking ? "text-white" : "text-velo-disable-2"
                }`}
              >
                ~${formatEtherDollar(calculatedRewardEarned)}
              </div>
            </div>
          </div>

          <TableRow className="w-full px-4 py-4 mt-4">
            <div className="flex gap-1 items-center text-velo-label text-sm">
              <img
                src={pool?.staking.image}
                className="h-4"
                alt="staking-icon"
              />
              <p>{`${pool?.staking.name} ${isPair ? "LP" : ""} Stake`}</p>
            </div>
            <div
              className={`text-number ${
                isApproved && isStaking ? "" : "text-velo-label"
              }`}
            >
              {pool?.balanceInPool && formatEther(pool?.balanceInPool)}
            </div>
          </TableRow>
        </div>

        <div className="px-4">
          {!isApproved ? (
            <>
              {!isValidConnection && !isPending && (
                <Button
                  // id="button_farm_unlock_wallet"
                  className="w-full"
                  onClick={unlockWallet}
                >
                  UNLOCK WALLET
                </Button>
              )}
              {isValidConnection && !isPending && (
                <Button
                  // id="button_farm_approve"
                  className="w-full"
                  onClick={approve}
                >
                  APPROVE
                </Button>
              )}
              {isPending && (
                <Button
                  // id="button_farm_processing"
                  className="w-full"
                  disabled
                >
                  Processing...
                </Button>
              )}
            </>
          ) : (
            <div className="flex whitespace-nowrap gap-3">
              {!isPending && isStaking && (
                <>
                  <Button
                    // id="button_farm_deposit"
                    className="w-full"
                    onClick={deposit}
                  >
                    Deposit
                  </Button>
                  <Button
                    // id="button_farm_withdraw"
                    className="w-full"
                    type="outline"
                    onClick={withdraw}
                  >
                    Withdraw
                  </Button>
                </>
              )}
              {!isPending && !isStaking && (
                <Button
                  // id="button_farm_stake"
                  className="w-full"
                  onClick={deposit}
                >
                  Deposit
                </Button>
              )}
              {isPending && (
                <Button
                  // id="button_farm_processing"
                  className="w-full"
                  disabled
                >
                  Processing...
                </Button>
              )}
            </div>
          )}
        </div>

        <div className="flex flex-col justify-between m-4 text-sm h-[56px]">
          <div className="flex flex-nowrap items-center h-7">
            <div className="mr-2 text-velo-disable-2">Total staked:</div>
            {hasStaking ? (
              <div className="text-number">
                ${formatEtherDollar(calculatedTotalStaked)}
              </div>
            ) : (
              <div className="text-sm font-bold">N/A</div>
            )}
          </div>
          <div className="flex items-center justify-between h-7">
            <a
              className="flex items-center"
              href={`${config.chainExplorerURL}address/${pool?.staking.address}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <MaterialIcon className="text-velo-primary text-sm mr-1">
                open_in_new
              </MaterialIcon>
              <span className="text-sm">View contract</span>
            </a>
            {pool?.lpLink && (
              <a className="flex items-center" href={pool?.lpLink}>
                <span className="text-sm">Get {pool?.staking.name}</span>
                <MaterialIcon className="text-velo-primary text-sm ml-1">
                  east
                </MaterialIcon>
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EarnOther;

import { networkType } from "../constants";
import { useEthereum } from "../contexts/etherruemContext";
import { watchAsset } from "@wagmi/core";
import { config } from "../../wagmi.config";

const useWalletHelpers = () => {
  const addTokenOnWallet = async (
    address: string,
    symbol: string,
    image: string,
    decimals: number = 18,
    type: string = networkType.ERC20,
    { onError = (err: any) => {}, onReceipt = () => {} }
  ) => {
    const host =
      typeof window !== "undefined"
        ? window.location.origin
        : "https://evry.finance";

    try {
      await watchAsset(config, {
        type: "ERC20",
        options: {
          address,
          symbol,
          decimals,
          image: `${host}${image}`,
        },
      });
      onReceipt();
    } catch (err) {
      onError(err);
    }
  };

  return { addTokenOnWallet };
};

export default useWalletHelpers;

import React, { useEffect, useMemo, useRef, useState } from "react";

import Sidebar from "../sidebar/index";
import { Menu } from "../../interfaces/menu";
import Header from "../header/index";
import config from "../../config";
import Footer from "../footbar/index";
import { checkIsMobile } from "../../utils/helper";
import WalletConnectModal from "../wallet/connectModal";
import { useEthereum } from "../../contexts/etherruemContext";
import useFarmCommon from "../../hooks/useFarmCommon";
import { useMainConstant } from "../../contexts/mainContext";
import NotificationBox from "../notification/Box";
import { useNotification } from "../../contexts/notificationContext";

interface MainLayoutProps {
  children: React.ReactNode;
}

const menus: Menu[] = [
  {
    title: "Universe",
    icon: "universe-icon.png",
    path: "https://universe.velofinance.io",
    externalLink: true,
  },
  {
    title: "Exchange",
    icon: "swap-icon.svg",
    path: "/exchange/swap",
    activePath: ["/exchange/swap", "/exchange/directexchange"],
    id: "menu_swap",
    subMenus: [
      {
        title: "Swap",
        path: "/exchange/swap",
      },
      {
        title: "Direct Exchange",
        path: "/exchange/directexchange",
      },
      {
        title: "Get Omni Points",
        path: "/exchange/omni",
      },
    ],
    menu: true,
  },
  {
    title: "Pool",
    icon: "pool-icon.svg",
    path: "/pool",
    activePath: ["/pool"],
    id: "menu_pool",
    menu: true,
  },
  {
    title: "Farming",
    icon: "farm-icon.svg",
    path: "/farms/chain/live",
    activePath: [
      "/farms/chain/live",
      "/farms/bnb/live",
      "/farms/sol/live",
      "/farms/bnb/expired",
      "/farms/sol/expired",
    ],
    id: "menu_farms",
    menu: true,
  },
  // {
  //   title: "VELO Labs",
  //   icon: "labs-icon.svg",
  //   id: "menu_velolab",
  //   subMenus: [
  //     {
  //       title: "The Bridge",
  //       path: "/velolabs/thebridge/send",
  //     },
  //   ],
  //   menu: false,
  // },
  {
    title: "Documentation",
    icon: "doc-icon.svg",
    path: config.document.home,
    id: "menu_doc",
    externalLink: true,
  },
];

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  const headerRef = useRef(null);
  const [isOpenMenu, setIsOpenMenu] = useState(true);
  const [tokenPerUsd, setTokenPerUsd] = useState<number>(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isHideContent, setIsHideContent] = useState(false);

  const { getMapIDofReserves, getPrice } = useFarmCommon();
  const ethereum = useEthereum();
  const mainConstant = useMainConstant();
  const notification = useNotification();

  useEffect(() => {
    window.addEventListener("resize", onResetSidebar);

    return () => {
      window.removeEventListener("resize", onResetSidebar);
    };
  }, []);

  useEffect(() => {
    if (!ethereum.contracts || !config.lp) return;

    fetchTokenPrice();

    const intervalId = setInterval(() => {
      fetchTokenPrice();
    }, 5000);

    return () => clearInterval(intervalId);
  }, [config.lp, ethereum.contracts]);

  const isMobile = useMemo(() => windowWidth < 769, [windowWidth]);

  useEffect(() => {
    console.log("isMobile watch", isMobile);
    setIsOpenMenu(!isMobile);
  }, [isMobile]);

  useEffect(() => {
    console.log("isOpenMenu watch", isOpenMenu);
    console.log("isMobile watch", isMobile);
    if (!isMobile) {
      setIsHideContent(true);
      return;
    }
    setIsHideContent(!isOpenMenu);
    // if (isOpenMenu) {
    //   document.body.style.overflow = "hidden";
    // } else {
    //   document.body.style.overflow = "unset";
    // }
  }, [isOpenMenu, isMobile]);

  const onResetSidebar = () => {
    setWindowWidth(window.innerWidth);
  };

  const fetchTokenPrice = async () => {
    const pairIds = Object.keys(config.lp);
    const mapIDofReserves = await getMapIDofReserves(pairIds);
    const token = {
      VELO_TOKEN: config.newFarmToken.VELO_TOKEN,
    };
    const tokenPrice = await getPrice(token, mapIDofReserves);
    if (!isNaN(tokenPrice.VELO)) setTokenPerUsd(tokenPrice.VELO);
  };

  return (
    <div className="w-full relative bg-velo-bg max-w-full overflow-hidden">
      <div className="min-h-screen">
        <div className="relative z-10 flex flex-col">
          <Header
            // ref={headerRef}
            isReferral={false}
            walletAddress=""
            onConnectWallet={() => mainConstant.setIsShowConnectModal(true)}
            onConnectNetwork={() => {}}
          />
          <WalletConnectModal
            onConnect={ethereum.connect}
            onConnectSol={() => {}}
            // onDisconnect={etheruem.disconnect}
          />
          <div className="absolute z-50 flex flex-col justify-end w-full md:right-6 top-16">
            {Object.entries(notification.state.data).map(
              ([hash, data]: [any, any]) => {
                return (
                  <NotificationBox
                    key={`notify-${hash}`}
                    className="self-end mx-auto mb-2 md:mx-0"
                    txHash={hash}
                    status={data.status}
                    fullUrl={data.fullUrl}
                    chainType={data.chainType}
                    // errorReason={data.errorReason}
                  />
                );
              }
            )}
          </div>
          <div className="md:flex justify-center self-start w-full">
            <Sidebar
              className="fixed md:static top-0 z-40"
              onEmitOpen={() => {}}
              social={config.social}
              setIsOpenMenu={setIsOpenMenu}
              tokenPerUsd={tokenPerUsd}
              isOpenMenu={isOpenMenu}
              menus={menus}
              isMobile={isMobile}
              // onNavigation={() => isMobile && setIsOpenMenu(false)}
            />
            {isHideContent && (
              <div className="md:container md:mt-0 overscroll-auto px-4">
                {children}
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default MainLayout;

import React from "react";
import Button from "./button";
import { useNavigate } from "react-router-dom";

interface Tab {
  name: string;
  link: string;
  linkName: string;
  disabled: boolean;
  value: number;
}

interface TabsProps {
  tabs: Tab[];
  type?: "primary" | "secondary";
  tabWidth?: string;
  tabHeight?: string;
  onSwitchTab?: (link: string) => void;
  className: string;
}

const Tabs: React.FC<TabsProps> = ({
  tabs,
  type = "primary",
  tabWidth = "",
  tabHeight = "",
  onSwitchTab,
  className,
}) => {
  const navigate = useNavigate();

  const isActive = (tab: Tab) => {
    const currentPath = window.location.pathname;
    const currentRouteName = tab.linkName;
    return currentPath === currentRouteName; // Assuming `link` is a pathname
  };

  const replaceClass = (link: string) => {
    return link.replace("/", "").replaceAll("/", "-");
  };

  const switchTab = (link: string) => {
    navigate(link);
  };

  return (
    <div className={className}>
      <div className={`flex md:justify-end z-30 gap-4`}>
        {tabs.map((tab, index) => (
          <div
            key={`tab-${index}`}
            className={`${tabWidth} ${tabHeight} relative text-center flex items-center justify-center min-w-[104px]`}
          >
            {tab.disabled ? (
              <Button className="" disabled>
                <p className="text-sm">{tab.name}</p>
              </Button>
            ) : (
              <Button
                className={`w-full rounded-none rounded-l border-none transition-all uppercase`}
                type={isActive(tab) ? "solid" : "outline"}
                onClick={() => switchTab(tab.link)}
              >
                <p className="text-sm">{tab.name}</p>
              </Button>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Tabs;

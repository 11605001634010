import React, { useEffect, useState } from "react";
import moment from "moment";

interface Props {
  url?: string;
}

const FeedMedium: React.FC<Props> = ({
  url = process.env.REACT_APP_ANNOUNCEMENT_MEDIUM_URL,
}) => {
  const [title, setTitle] = useState<string | null>(null);
  const [content, setContent] = useState<string | null>(null);
  const [thumbnail, setThumbnail] = useState<string | null>(null);
  const [author, setAuthor] = useState<string | null>(null);
  const [pubDate, setPubDate] = useState<string | null>(null);
  const [link, setLink] = useState<string | null>(null);
  const [isError, setIsError] = useState<boolean>(false);
  const [latestPost, setLatestPost] = useState<any>(null);

  const formattedPubDate = pubDate ? moment(pubDate).format("MMMM DD") : "";

  useEffect(() => {
    const loadLatestPost = async () => {
      try {
        const nonce = Date.now(); // Replace with your own method to get Unix timestamp if necessary
        const response = await fetch(`${url}%3Fd%3D${nonce}`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        } else {
          const dataResponse = await response.json();
          if (dataResponse.status !== "ok") {
            throw new Error("Network response was not ok");
          } else {
            setLatestPost(dataResponse);
          }
        }
      } catch {
        try {
          const response = await fetch(url ?? "");
          if (!response.ok) {
            throw new Error("Network response was not ok");
          } else {
            const dataResponse = await response.json();
            setLatestPost(dataResponse);
          }
        } catch (error) {
          setIsError(true);
          console.log("loadLatestPost: ", error);
        }
      }
    };

    loadLatestPost();
  }, [url]);

  useEffect(() => {
    if (latestPost === null || latestPost === undefined) return;
    if (latestPost?.items?.[0]) {
      const item = latestPost.items[0];
      setTitle(item.title);
      const rawContent = item.content;
      setContent(rawContent.replace(/<[^>]*>/g, "").substring(0, 100) + "...");
      setThumbnail(item.thumbnail);
      setAuthor(item.author);
      setLink(item.link);
      setPubDate(item.pubDate);
    }
  }, [latestPost]);

  return (
    <div className={isError ? "invisible h-0" : "visible"}>
      <a href={link || "#"} target="_blank" rel="noopener noreferrer">
        <div className="flex flex-row bg-black rounded-xl mt-1 md:min-h-[224px]">
          <div className="w-1/3 p-4 rounded-md">
            {thumbnail && thumbnail !== "" && (
              <img
                className="text-white"
                src={thumbnail || ""}
                alt="thumbnail"
              />
            )}
          </div>
          <div className="w-2/3 space-y-1 text-white p-4 relative">
            <div className="absolute right-3 top-3 medium-icon h-4 w-4 opacity-70" />
            <div className="text-xs text-gray-400">{author}</div>
            <div className="font-bold text-xs md:text-xl">{title}</div>
            <div className="text-xs text-gray-400">{formattedPubDate}</div>
            <div className="text-xs text-gray-400 pt-2 content-medium">
              {content}
            </div>
            <div className="text-xs text-evry-primary">View on Medium</div>
          </div>
        </div>
      </a>
    </div>
  );
};

export default FeedMedium;

import BigNumber from "bignumber.js";
import config from "../config";
import { lpType } from "../constants";
import { useEthereum } from "../contexts/etherruemContext";
import { LiquidityPool, Token } from "../interfaces/token";

import {
  Token as swapToken,
  ChainId,
  TokenAmount,
  Pair,
  Trade,
  Percent,
  currencyEquals,
  CurrencyAmount,
} from "@pancakeswap/sdk";
import JSBI from "jsbi";

const MAX_HOPS = 5;
const ZERO_PERCENT = new Percent("0");
const ONE_HUNDRED_PERCENT = new Percent("1");
const BETTER_TRADE_LESS_HOPS_THRESHOLD = new Percent(
  JSBI.BigInt(50).toString(),
  JSBI.BigInt(10000).toString()
);
const BASE_FEE = new Percent(
  JSBI.BigInt(25).toString(),
  JSBI.BigInt(10000).toString()
);
const INPUT_FRACTION_AFTER_FEE = ONE_HUNDRED_PERCENT.subtract(BASE_FEE);

const DEFAULT_DMM_ADDRRESS = "0x0000000000000000000000000000000000000040";

const chainId = config?.chainId === 97 ? ChainId.TESTNET : ChainId.MAINNET;

const useRouteHelpers = () => {
  const ethereum = useEthereum();

  const _getAllPair = () => {
    return { ...config.lp, ...config.dmmLP };
  };

  const _getDMMPair = () => {
    return config.dmmLP;
  };

  const _getReserveByLpName = async (name: string) => {
    const x = await ethereum.contracts.tokens[name].methods
      .getReserves()
      .call();
    return x;
    // return new Promise((resolve) => {
    //   const reserves = ethereum.contracts.tokens[name].methods
    //     .getReserves()
    //     .call();

    //   console.log("findRoute _getReserveByLpName reserves", reserves);
    //   resolve(reserves);
    // });
  };

  const _getReserveByLpNameOnDMM = (name: string) => {
    return new Promise((resolve) => {
      const reserves = ethereum.contracts.tokens[name].methods
        .getTradeInfo()
        .call();

      resolve(reserves);
    });
  };

  const _getToken0 = (lpName: string) => {
    return new Promise((resolve) => {
      const token0 = ethereum.contracts.tokens[lpName].methods.token0().call();

      resolve(token0);
    });
  };

  const _getToken1 = (lpName: string) => {
    return new Promise((resolve) => {
      const token0 = ethereum.contracts.tokens[lpName].methods.token1().call();

      resolve(token0);
    });
  };

  const _wrappedToken = (token: Token, digits: number = 18) => {
    return new swapToken(
      chainId,
      token.address ?? "",
      digits,
      token.symbol,
      token.name
    );
  };

  const _wrappedTokenAmount = (token: Token, amount: any) => {
    const _amount = JSBI.BigInt(amount);

    const tokenObject = _wrappedToken(token);

    const x = new TokenAmount(tokenObject, _amount.toString());

    return x;
  };

  const _transformPairOnDMM = async (lp: LiquidityPool, key: string) => {
    const reserves: any = await _getReserveByLpNameOnDMM(key);

    const token0Address = await _getToken0(key);
    const token1Address = await _getToken1(key);

    let pair;
    if (
      lp?.token0?.address?.toLowerCase() ===
        String(token0Address).toLowerCase() &&
      lp?.token1?.address?.toLowerCase() === String(token1Address).toLowerCase()
    ) {
      // pair = new Pair(
      //   _wrappedTokenAmount(
      //     lp.token0,
      //     JSBI.BigInt(String(reserves._vReserve0))
      //   ),
      //   _wrappedTokenAmount(lp.token1, JSBI.BigInt(String(reserves._vReserve1)))
      pair = new Pair(
        _wrappedTokenAmount(
          lp.token0,
          JSBI.BigInt(parseFloat(reserves._vReserve0))
        ),
        _wrappedTokenAmount(
          lp.token1,
          JSBI.BigInt(parseFloat(reserves._vReserve1))
        )
      );
    } else if (
      lp?.token0?.address?.toLowerCase() ===
        String(token0Address).toLowerCase() &&
      lp?.token1?.address?.toLowerCase() === String(token1Address).toLowerCase()
    ) {
      // pair = new Pair(
      //   _wrappedTokenAmount(
      //     lp.token0,
      //     JSBI.BigInt(String(reserves._vReserve1))
      //   ),
      //   _wrappedTokenAmount(lp.token1, JSBI.BigInt(String(reserves._vReserve0)))
      // );
      pair = new Pair(
        _wrappedTokenAmount(
          lp.token0,
          JSBI.BigInt(parseFloat(reserves._vReserve1))
        ),
        _wrappedTokenAmount(
          lp.token1,
          JSBI.BigInt(parseFloat(reserves._vReserve0))
        )
      );
    }

    return pair;
  };

  const _transformPairOnAMM = async (lp: LiquidityPool, key: string) => {
    const reserves: any = await _getReserveByLpName(key);

    const token0Address = await _getToken0(key);
    const token1Address = await _getToken1(key);

    let pair;
    if (
      lp.token0.address?.toLowerCase() ===
        String(token0Address).toLowerCase() &&
      lp.token1.address?.toLowerCase() === String(token1Address).toLowerCase()
    ) {
      // const a = _wrappedTokenAmount(
      //   lp.token0,
      //   JSBI.BigInt(String(reserves._reserve0))
      // );
      // const b = _wrappedTokenAmount(
      //   lp.token1,
      //   JSBI.BigInt(String(reserves._reserve1))
      // );
      const a = _wrappedTokenAmount(
        lp.token0,
        JSBI.BigInt(parseFloat(reserves._reserve0))
      );
      const b = _wrappedTokenAmount(
        lp.token1,
        JSBI.BigInt(parseFloat(reserves._reserve1))
      );
      pair = new Pair(a, b);
    } else if (
      lp.token0.address?.toLowerCase() ===
        String(token1Address).toLowerCase() &&
      lp.token1.address?.toLowerCase() === String(token0Address).toLowerCase()
    ) {
      // const c = _wrappedTokenAmount(
      //   lp.token0,
      //   JSBI.BigInt(String(reserves._reserve1))
      // );
      // const d = _wrappedTokenAmount(
      //   lp.token1,
      //   JSBI.BigInt(String(reserves._reserve0))
      // );
      const c = _wrappedTokenAmount(
        lp.token0,
        JSBI.BigInt(parseFloat(reserves._reserve1))
      );
      const d = _wrappedTokenAmount(
        lp.token1,
        JSBI.BigInt(parseFloat(reserves._reserve0))
      );
      pair = new Pair(c, d);
    }

    return pair;
  };

  const _transformPairs = async () => {
    const pairs = [];
    const liquidityPools = _getAllPair();
    for (const [key] of Object.entries(liquidityPools)) {
      let pair;
      const lp = liquidityPools[key];

      if (!lp.address) {
        continue;
      }
      if (lp.lpType === lpType.DMM_TYPE) {
        pair = await _transformPairOnDMM(lp, key);
      } else {
        pair = await _transformPairOnAMM(lp, key);
      }

      pairs.push(pair);
    }

    return pairs;
  };

  const _isSingleHopOnly = () => {
    return false;
  };

  const _isTradeBetter = (
    tradeA: Trade | null,
    tradeB: Trade | null,
    minimumDelta = ZERO_PERCENT
  ) => {
    if (tradeA && !tradeB) return false;
    if (tradeB && !tradeA) return true;
    if (!tradeA || !tradeB) return undefined;

    if (
      tradeA.tradeType !== tradeB.tradeType ||
      !currencyEquals(
        tradeA.inputAmount.currency,
        tradeB.inputAmount.currency
      ) ||
      !currencyEquals(
        tradeB.outputAmount.currency,
        tradeB.outputAmount.currency
      )
    ) {
      throw new Error("Trades are not comparable");
    }

    if (minimumDelta.equalTo(ZERO_PERCENT)) {
      return tradeA.executionPrice.lessThan(tradeB.executionPrice);
    }

    return tradeA.executionPrice.raw
      .multiply(minimumDelta.add(ONE_HUNDRED_PERCENT))
      .lessThan(tradeB.executionPrice);
  };

  const _getBestTradeSoFar = (
    allpairs: Pair[],
    currencyAmountIn: any,
    currencyOut: swapToken,
    MAX_HOPS: number
  ) => {
    let bestTradeSoFar = null;
    for (let i = 1; i <= MAX_HOPS; i++) {
      const currentTrade = Trade.bestTradeExactIn(
        allpairs,
        currencyAmountIn,
        currencyOut,
        { maxHops: i, maxNumResults: 1 }
      )[0];
      console.log("getRoute currentTrade", currentTrade);

      if (
        _isTradeBetter(
          bestTradeSoFar,
          currentTrade,
          BETTER_TRADE_LESS_HOPS_THRESHOLD
        )
      ) {
        console.log("getRoute is bestTradeSoFar", bestTradeSoFar);
        bestTradeSoFar = currentTrade;
      }
    }
    return bestTradeSoFar;
  };

  const getRoute = async (
    inputToken: Token,
    outputToken: Token,
    amountTokenA: number
  ) => {
    console.log("getRoute inputToken", inputToken);
    console.count("getRoute");
    let bestTradeSoFar = null;

    const allPairs = await _transformPairs();
    const currencyOut = _wrappedToken(outputToken);
    const singleHopOnly = _isSingleHopOnly();
    const checkAmountTokenLeft =
      amountTokenA && amountTokenA > 0 ? amountTokenA : 1;

    console.log("getRoute checkAmountTokenLeft", checkAmountTokenLeft);

    const currencyAmountIn = _wrappedTokenAmount(
      inputToken,
      BigNumber(checkAmountTokenLeft * 1e18)
        .toFixed(0)
        .toString()
    );

    console.log("getRoute currencyAmountIn", currencyAmountIn);
    console.log("getRoute currencyOut", currencyOut);
    console.log("getRoute allPairs", allPairs);
    if (currencyAmountIn && currencyOut && allPairs.length > 0) {
      if (singleHopOnly) {
        return Trade.bestTradeExactIn(
          allPairs.filter((pair): pair is Pair => !!pair),
          currencyAmountIn,
          currencyOut,
          {
            maxHops: 1,
            maxNumResults: 1,
          }
        )[0];
      }

      bestTradeSoFar = _getBestTradeSoFar(
        allPairs.filter((pair): pair is Pair => !!pair),
        currencyAmountIn,
        currencyOut,
        MAX_HOPS
      );
    }

    console.log("getRoute bestTradeSoFar", bestTradeSoFar);
    return bestTradeSoFar;
  };

  const _getRouteMap = async (path: swapToken[]) => {
    const routePathText = Array.from(path)
      .map(({ symbol }) => {
        return symbol;
      })
      .join(" > ");

    const routePathTextArray = Array.from(path).map(({ symbol }) => {
      return symbol;
    });

    const routePathArray = Array.from(path).map(({ address }) => {
      return address;
    });

    return {
      routePathText,
      routePathTextArray,
      routePathArray,
      routePathArrayTokenMap: path,
    };
  };

  const _convertPathToTokenName = (path: string[]) => {
    // console.log("_convertPathToTokenName path", path, typeof path);
    if (!path) {
      return [];
    }
    const paths = path?.map((value) => value.toLowerCase());
    const { token: tokenList } = config;
    const pathToToken = [];
    for (const token in tokenList) {
      const { address, symbol } = tokenList[token];
      if (address) {
        const addressIndexInPath = paths.indexOf(address.toLowerCase());
        if (
          addressIndexInPath >= 0 &&
          pathToToken[addressIndexInPath] === undefined
        ) {
          pathToToken[addressIndexInPath] = symbol.toUpperCase();
        }
      }
    }
    return pathToToken;
  };

  const getPairData = (path: string[], options: any = {}) => {
    const { onlyType = false } = options;
    const allPairs = _getAllPair();
    const tokenNames = _convertPathToTokenName(path);
    const poolsType = [];

    for (let i = 0; i < tokenNames.length - 1; i++) {
      const token0 = tokenNames[i];
      const token1 = tokenNames[i + 1];
      let lpName = "";
      let lpType = "";
      let address = "";
      if (allPairs[`${token0}_${token1}`] !== undefined) {
        lpName = `${token0}_${token1}`;
        lpType = allPairs[`${token0}_${token1}`].lpType.toUpperCase();
        address = allPairs[`${token0}_${token1}`].address ?? "";
      } else if (allPairs[`${token1}_${token0}`] !== undefined) {
        lpName = `${token1}_${token0}`;
        lpType = allPairs[`${token1}_${token0}`].lpType.toUpperCase();
        address = allPairs[`${token1}_${token0}`].address ?? "";
      }

      const tempData = onlyType ? lpType : { lpName, lpType, address };
      poolsType.push(tempData);
    }

    return poolsType;
  };

  const getDMMPoolAddresses = (path: string[]) => {
    const pairData = getPairData(path);
    const dmmPoolAddresses = [];
    for (let i = 0; i < pairData.length; i++) {
      const pair = pairData[i];
      if (typeof pair !== "string" && pair?.lpType === "DMM") {
        const { address } = pair;
        dmmPoolAddresses.push(address);
      }
    }

    if (dmmPoolAddresses.length < 1) {
      dmmPoolAddresses.push(DEFAULT_DMM_ADDRRESS);
    }

    return dmmPoolAddresses;
  };

  const _getAmount = async (amount: string, path: string[], method: string) => {
    const contracts = ethereum.contracts;
    const poolTypes = getPairData(path, { onlyType: true });
    const dmmPoolAddresses = getDMMPoolAddresses(path);

    let result = [];
    if (contracts) {
      result = await contracts.autoRouter.methods[method](
        amount,
        path,
        poolTypes,
        dmmPoolAddresses
      ).call();

      const tokens = ["tokenA", "tokenB", "tokenC", "tokenD", "tokenE"];
      const payload: any = {};

      for (let i = 0; i < result.length; i++) {
        payload[`${tokens[i]}`] = {
          value: BigNumber(result[i]).div(1e18).toString(),
          address: path[i],
        };
      }
      return payload;
    }

    return 0;
  };

  const _getAmountOut = (amount: string, path: string[]) => {
    return _getAmount(amount, path, "getAmountsOut");
  };

  const _getAmountIn = (amount: string, path: string[]) => {
    return _getAmount(amount, path, "getAmountsIn");
  };

  const getEstimationAmount = async (
    amount: number,
    sideOfInput: string,
    bestTradeSoFar: Trade
  ) => {
    let maximumAmountIn = "0.00";
    let minimumAmountOut = "0.00";

    const routes = await _getRouteMap(bestTradeSoFar.route.path);
    if (sideOfInput === "tokenA") {
      const amountAWei = ethereum.web3?.utils.toWei(amount, "ether") ?? "";
      const AmountOut: any = await _getAmountOut(
        amountAWei,
        routes.routePathArray
      );
      const lastToken =
        AmountOut[Object.keys(AmountOut)[Object.keys(AmountOut).length - 1]];
      maximumAmountIn = AmountOut.tokenA.value;
      minimumAmountOut = lastToken.value;
    } else if (sideOfInput === "tokenB") {
      const amountBWei = ethereum.web3?.utils.toWei(amount, "ether") ?? "";
      const AmountIn: any = await _getAmountIn(
        amountBWei,
        routes.routePathArray
      );
      const lastToken =
        AmountIn[Object.keys(AmountIn)[Object.keys(AmountIn).length - 1]];
      maximumAmountIn = lastToken.value;
      minimumAmountOut = AmountIn.tokenA.value;
    }

    return {
      maximumAmountIn,
      minimumAmountOut,
    };
  };

  const _isAutoRouteAMMAndDMM = (pair: Pair) => {
    let isAutoRouteBothRouter = false;
    let symbol = "";

    const token1 = pair.token1.symbol;
    const token0 = pair.token0.symbol;

    const dmmLpPair = _getDMMPair();
    Object.keys(dmmLpPair).forEach((key) => {
      const { token0: dmmToken0, token1: dmmToken1 } = dmmLpPair[key];

      if (token0 === dmmToken0.symbol && token1 === dmmToken1.symbol) {
        isAutoRouteBothRouter = true;
        symbol = `${token0}_${token1}`;
      } else if (token1 === dmmToken0.symbol && token0 === dmmToken1.symbol) {
        isAutoRouteBothRouter = true;
        symbol = `${token1}_${token0}`;
      }
    });

    return { isAutoRouteBothRouter, symbol };
  };

  const _getAMPBPS = async (trade: Trade) => {
    const foundPairDMM = trade.route.pairs
      .map((pair) => {
        const { isAutoRouteBothRouter, symbol } = _isAutoRouteAMMAndDMM(pair);
        return { isAutoRouteBothRouter, symbol };
      })
      .find((item) => item.isAutoRouteBothRouter === true);

    if (foundPairDMM) {
      const amp = await ethereum.contracts.tokens[
        `${foundPairDMM.symbol}`
      ].methods
        .ampBps()
        .call();

      return BigNumber(amp).div(10000).toString();
    }

    return null;
  };

  const _computeRealizedLpFee = async (trade: Trade) => {
    let realizedLPFee;

    if (trade && trade.route.pairs) {
      let currentFee = ONE_HUNDRED_PERCENT;

      for (let index = 0; index < trade.route.pairs.length; index++) {
        const pair = trade.route.pairs[index];

        const { isAutoRouteBothRouter, symbol } = _isAutoRouteAMMAndDMM(pair);

        if (isAutoRouteBothRouter) {
          const { feeInPrecision }: any = await _getReserveByLpNameOnDMM(
            symbol
          );

          const dmmFee = parseFloat(feeInPrecision) / 1e18;
          const DMM_BASE_FEE = new Percent(
            JSBI.BigInt((dmmFee * 10000).toFixed(0)).toString(),
            JSBI.BigInt(10000).toString()
          );

          const INPUT_FRACTION_AFTER_FEE_ON_AMM =
            ONE_HUNDRED_PERCENT.subtract(DMM_BASE_FEE);
          currentFee = currentFee.multiply(INPUT_FRACTION_AFTER_FEE_ON_AMM);
        } else {
          currentFee = currentFee.multiply(INPUT_FRACTION_AFTER_FEE);
        }
      }

      realizedLPFee = ONE_HUNDRED_PERCENT.subtract(currentFee);
    }

    return realizedLPFee;
  };

  const _computeTradingFee = async (trade: Trade) => {
    const realizedLPFee = await _computeRealizedLpFee(trade);

    const tradingFee =
      realizedLPFee &&
      (trade.inputAmount instanceof TokenAmount
        ? new TokenAmount(
            trade.inputAmount.token,
            realizedLPFee.multiply(trade.inputAmount.raw).quotient
          )
        : CurrencyAmount.ether(
            realizedLPFee.multiply(trade.inputAmount.raw).quotient
          ));

    return tradingFee ? tradingFee.toSignificant() : tradingFee;
  };

  const _computePriceImpact = (trade: Trade, estimateOutputAmount: string) => {
    let priceImpact;

    if (trade && trade.route.pairs) {
      const foundPairDMM = trade.route.pairs
        .map((pair) => {
          const { isAutoRouteBothRouter } = _isAutoRouteAMMAndDMM(pair);
          return isAutoRouteBothRouter;
        })
        .find((item) => item === true);

      if (foundPairDMM) {
        const exactQuote = trade.route.midPrice.raw.multiply(
          trade.inputAmount.raw
        );

        const slippage = exactQuote
          .subtract(
            JSBI.BigInt(parseFloat(estimateOutputAmount) * 1e18).toString()
          )
          .divide(exactQuote);

        priceImpact = new Percent(slippage.numerator, slippage.denominator);
      } else {
        priceImpact = trade.priceImpact;
      }
    }

    return priceImpact?.toSignificant();
  };

  const findRoute = async (
    inputToken: Token,
    outputToken: Token,
    amountTokenA: number,
    amountTokenB: number,
    sideOfInput: string
  ) => {
    console.log("findRoute inputToken", inputToken);
    console.count("findRoute");
    let bestTradeSoFar = null;
    let routes: any = {};
    let maximumAmountIn = "0.00";
    let minimumAmountOut = "0.00";
    let priceImpact = "0.00";
    let tradingFee = "0.00";
    let amplifier = null;
    try {
      console.log("start");
      const allPairs = await _transformPairs();
      console.log("findRoute allPairs", allPairs);
      const currencyOut = _wrappedToken(outputToken);
      console.log("findRoute currencyOut", currencyOut);
      const singleHopOnly = _isSingleHopOnly();
      console.log("findRoute singleHopOnly", singleHopOnly);
      const checkAmountTokenLeft =
        amountTokenA && amountTokenA > 0 ? amountTokenA : 1;

      const currencyAmountIn = _wrappedTokenAmount(
        inputToken,
        BigNumber(checkAmountTokenLeft * 1e18)
          .toFixed(0)
          .toString()
      );

      console.log("findRoute currencyAmountIn", currencyAmountIn);
      console.log("findRoute currencyOut", currencyOut);
      console.log("findRoute allPairs", allPairs);

      if (currencyAmountIn && currencyOut && allPairs.length > 0) {
        if (singleHopOnly) {
          return Trade.bestTradeExactIn(
            allPairs.filter((pair): pair is Pair => !!pair),
            currencyAmountIn,
            currencyOut,
            {
              maxHops: 1,
              maxNumResults: 1,
            }
          )[0];
        }

        // search through trades with varying hops, find best trade out of them
        bestTradeSoFar = _getBestTradeSoFar(
          allPairs.filter((pair): pair is Pair => !!pair),
          currencyAmountIn,
          currencyOut,
          MAX_HOPS
        );
      }

      console.log("findRoute bestTradeSoFar", bestTradeSoFar);

      if (bestTradeSoFar) {
        // swap route map
        routes = await _getRouteMap(bestTradeSoFar.route.path);

        const estimationAmount = await getEstimationAmount(
          sideOfInput === "tokenA" ? amountTokenA : amountTokenB,
          sideOfInput,
          bestTradeSoFar
        );
        minimumAmountOut = estimationAmount.minimumAmountOut;
        maximumAmountIn = estimationAmount.maximumAmountIn;

        const poolTypes = getPairData(routes.routePathArray, {
          onlyType: true,
        });

        if (poolTypes.includes("DMM")) {
          amplifier = await _getAMPBPS(bestTradeSoFar);
        }
        tradingFee = (await _computeTradingFee(bestTradeSoFar)) ?? "0.00";
        priceImpact =
          (await _computePriceImpact(
            bestTradeSoFar,
            sideOfInput === "tokenA" ? minimumAmountOut : maximumAmountIn
          )) ?? "0.00";
      }

      return {
        allpairs: allPairs,
        bestTradeSoFar,
        inputToken,
        outputToken,
        maximumAmountIn,
        minimumAmountOut,
        tradingFee,
        ...routes,
        priceImpact,
        amplifier,
      };
    } catch (error) {
      console.error("findRoute error", error);
      return {
        allpairs: [],
        bestTradeSoFar,
        inputToken,
        outputToken,
        maximumAmountIn,
        minimumAmountOut,
        tradingFee,
        ...routes,
        priceImpact,
        amplifier,
      };
    }
  };

  return {
    getRoute,
    findRoute,
    getEstimationAmount,
    getPairData,
    getDMMPoolAddresses,
  };
};

export default useRouteHelpers;

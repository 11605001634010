import { http, createConfig } from "wagmi";
// import environment from "./environment";
import { Chain } from "viem";
// import { ChainDetailType } from "./types/wallet";
import { injected } from "wagmi/connectors";
// import { metaMask } from "./providers/adapter/eth/metamask";
import environment from "./src/environment";
import { ChainDetailType } from "./src/interfaces/environment";

// create chains list from environment
const parseChainData = (
  chainData: ChainDetailType.Item[]
): readonly [Chain, ...Chain[]] => {
  const chains = chainData.map((data) => {
    const chain: Chain = {
      id: data.chainId,
      name: data.name,
      nativeCurrency: {
        name: data.currencyName,
        symbol: data.currencySymbol,
        decimals: data.currencyDecimal,
      },
      rpcUrls: {
        default: {
          http: data.rpcUrls,
        },
      },
      blockExplorers: {
        default: {
          name: `${data.name} Explorer`,
          url: data.explorerUrls[0],
        },
      },
      contracts: {},
    };
    return chain;
  });

  // filter null chains
  const filteredChains = chains.filter((chain) => chain !== undefined);

  if (filteredChains.length === 0) {
    throw new Error("Chain data cannot be empty");
  }

  return filteredChains as unknown as readonly [Chain, ...Chain[]];
};
const parseTransport = (chain: Chain) => {
  return {
    [chain.id]: http(chain.rpcUrls.default.http[0] as string),
  };
};

export const chains = parseChainData(environment.allowToLoginChains);
const transports = chains
  .map(parseTransport)
  .reduce((acc, val) => ({ ...acc, ...val }), {});

export const config = createConfig({
  chains: chains,
  connectors: [
    // injected({
    //   target() {
    //     return {
    //       id: "bitgetWallet",
    //       name: "Bitget Wallet",
    //       provider: window.bitkeep?.ethereum,
    //     };
    //   },
    // }),
  ],
  multiInjectedProviderDiscovery: true,
  syncConnectedChain: true, // sync connected chainId with the connector
  ssr: false,
  transports: transports,
});

declare module "wagmi" {
  interface Register {
    config: typeof config;
  }
}

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTokenHelpers } from "../../hooks/useTokenHelpers";
import { useEthereum } from "../../contexts/etherruemContext";
import { useWallet } from "../../contexts/walletContext";
import { FarmToken, PairEarned } from "../../interfaces/token";
import { FixAprExtra, FixAprLive } from "../../interfaces/config";
import Button from "../button";
import TableRow from "../table/row";
import FarmPeriodLockPool from "./periodLockPool";
import {
  formatBigNumberPercentAbbr,
  formatEther,
  formatEtherDollar,
} from "../../utils/filter";
import config from "../../config";
import MaterialIcon from "../icon";
import { useMainConstant } from "../../contexts/mainContext";
import BigNumber from "bignumber.js";
import { getDate, getDateByUnix } from "../../utils/datetime";
import { useNotification } from "../../contexts/notificationContext";
import useFarmCommon from "../../hooks/useFarmCommon";
import { Link } from "react-router-dom";
import useAprFixLockWithChangeableRatio from "../../hooks/useAprFixLockWithChangableRatioHelpers";

interface Props {
  pool: FixAprExtra;
  reward: FarmToken | undefined | null;
  // lpLink: string | null;
  onFetchFixedAPRLock: () => Promise<void>;
  openLockConfirmModal: (pair: any) => any;
  openWithdrawClaimModal: (pair: any, isWithdraw: boolean) => any;
  openDepositWithdrawModal: (
    pair: any,
    topic: string,
    balance: BigNumber,
    isShowGetLP: boolean
  ) => any;
  // openWithdrawClaimModal: (pool: FixAprLive) => void;
}

// Component
const FarmFixAprLockWithChangeableRatio: React.FC<Props> = ({
  pool,
  reward,
  onFetchFixedAPRLock,
  openLockConfirmModal,
  openWithdrawClaimModal,
  openDepositWithdrawModal,
}) => {
  const [scanURL, setScanURL] = useState(config.chainExplorerURL);
  const [isPending, setIsPending] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [account, setAccount] = useState("");
  const [tokenContract, setTokenContract] = useState("");
  const [inputAmount, setInputAmount] = useState("");
  const [earnedTokenDisplay, setEarnedTokenDisplay] = useState("");

  const ethereum = useEthereum();
  const wallet = useWallet();

  const { setIsShowConnectModal } = useMainConstant();
  const { checkAllowanceToSpender } = useTokenHelpers();
  const { setNotify } = useNotification();
  const { depositLpTokenWithCheckAllowance, withdrawLpToken, claimLpToken } =
    useAprFixLockWithChangeableRatio();
  const { approveToken } = useFarmCommon();

  const isRewardAvailable = useMemo(() => {
    if (pool.staking.code === pool.rewardToken) {
      return pool.rewardBalance.isGreaterThanOrEqualTo(
        pool.rewardDebt.plus(pool.totalStaked)
      );
    }

    return pool.rewardBalance.isGreaterThanOrEqualTo(pool.rewardDebt);
  }, []);

  // const nowStaked = useMemo(() => {
  //   return totalStaked.div(1e18)
  // },[])
  // const limited = useMemo(() => {
  //   return cap.div(1e18)
  // },[])

  // const isReachLimit = useMemo(() => {
  //   return totalStaked.isGreaterThanOrEqualTo(cap)
  // },[])

  const limited = useMemo(() => pool.cap.dividedBy(1e18), [pool.cap]);

  const nowStaked = useMemo(
    () => pool.totalStaked.dividedBy(1e18),
    [pool.totalStaked]
  );

  const hasStaking = useMemo(
    () => pool.totalStaked.isGreaterThan(0),
    [pool.totalStaked]
  );

  const isValidConnection = useMemo(
    () => ethereum.isConnected && wallet.isValidChain,
    [ethereum, wallet.isValidChain]
  );

  const isStaking = useMemo(
    () => pool.balanceInPool?.isGreaterThan(0),
    [pool.balanceInPool]
  );

  const calculatedRewardEarned = useMemo(() => {
    const earned = new BigNumber(pool.earnedToken || "0");
    const reward = new BigNumber(pool.rewardPrice || "0");
    return earned.multipliedBy(reward);
  }, [pool.earnedToken, pool.rewardPrice]);

  const calculatedTotalStaked = useMemo(() => {
    const staked = new BigNumber(pool.totalStaked || "0");
    return staked.multipliedBy(pool.lpPerUsd);
  }, [pool.totalStaked, pool.lpPerUsd]);

  const isPair = useMemo(
    () => pool.lpType === "AMM" || pool.lpType === "DMM",
    [pool.lpType]
  );

  const isPeriodStart = useMemo(() => {
    const now = getDate();
    const start = getDateByUnix(pool.startTime);
    return start.isSameOrBefore(now);
  }, [pool.startTime]);

  const isPeriodEnd = useMemo(() => {
    const now = getDate();
    const endTime = getDateByUnix(pool.endTime);
    return endTime.isSameOrBefore(now);
  }, [pool.startTime]);

  const isReachLimit = useMemo(
    () => pool.totalStaked.isGreaterThanOrEqualTo(pool.cap),
    [pool.totalStaked, pool.cap]
  );

  const showApprove = useMemo(() => {
    if (isPeriodEnd) return isStaking && !isApproved;
    return !isApproved;
  }, [isPeriodEnd, isStaking, isApproved]);

  const isActive = useMemo(() => {
    return (isApproved || isStaking) && isPeriodStart;
  }, [isApproved, isStaking, isPeriodStart]);

  const onUpdateNotification = () => {
    return {
      onTransactionHash: (txHash: any) => {
        const payload = {
          status: "processing",
          txHash,
        };
        setNotify(payload);
      },
      onError: (_: any, receipt: any) => {
        const payload = {
          status: "failed",
          txHash: receipt ? receipt.transactionHash : null,
        };
        setNotify(payload);
        setIsPending(false);
      },
      onReceipt: ({
        transactionHash,
        status,
      }: {
        transactionHash: any;
        status: any;
      }) => {
        setNotify({
          status: status ? "success" : "failed",
          txHash: transactionHash,
        });
        refresh().finally(() => {
          setIsPending(false);
        });
      },
    };
  };

  const checkStatus = useCallback(async () => {
    setIsPending(true);
    setIsApproved(false);
    if (!isValidConnection) {
      setIsPending(false);
      return;
    }

    const account = wallet.state.address;
    try {
      const isAllow = await checkAllowanceToSpender(
        pool?.id ?? "",
        account,
        pool?.contractAddress ?? ""
      );

      setIsApproved(isAllow);

      if (!isApproved) {
        setIsPending(false);
        return;
      }
      setIsPending(false);
    } catch (err) {
      console.log("error checkStatus", err);
      setIsApproved(false);
      setIsPending(false);
    }
  }, [
    isValidConnection,
    wallet.state.address,
    checkAllowanceToSpender,
    pool.id,
    pool.contractAddress,
  ]);

  useEffect(() => {
    if (pool && pool.contractAddress && wallet.state.address) refresh();
  }, [pool]);

  const refresh = useCallback(async () => {
    await checkStatus();
    onFetchFixedAPRLock();
  }, [checkStatus, onFetchFixedAPRLock]);

  const deposit = async () => {
    try {
      if (!pool.contractAddress || !pool) return;
      const account = wallet.state.address;

      const { inputAmount, isClose } = await openDepositWithdrawModal(
        pool,
        "Deposit",
        pool.lpBalance,
        true
      );

      if (isClose) return;
      setIsPending(true);
      await depositLpTokenWithCheckAllowance(
        pool.staking.code ?? "",
        pool?.staking.address ?? "",
        pool.contractAddress ?? "",
        account,
        inputAmount,
        onUpdateNotification()
      );
      refresh();
    } catch (err) {
      console.error(err);
      setIsPending(false);
    }
  };

  const confirmLock = async () => {
    if (pool.isLockWithdraw) {
      const { confirmed } = await openLockConfirmModal(pool);
      if (!confirmed) return;
    }
    deposit();
  };
  const claim = async () => {
    try {
      if (!pool.contractAddress) return;
      const { confirmed } = await openWithdrawClaimModal(pool, false);
      if (!confirmed) return;
      setIsPending(true);
      const account = wallet.state.address;
      await claimLpToken(pool.contractAddress, account, onUpdateNotification());
      refresh();
    } catch (err) {
      console.error(err);
      setIsPending(false);
    }
  };

  const approve = async () => {
    if (!pool.contractAddress || !pool) return;
    setIsPending(true);
    const account = wallet.state.address;

    try {
      await approveToken(
        pool.contractAddress,
        pool?.staking.code,
        account,
        onUpdateNotification()
      );
    } catch (err) {
      console.error("approve", err);
    }
    setIsPending(false);
  };

  const withdrawAndClaim = async () => {
    try {
      const { confirmed } = await openWithdrawClaimModal(this, true);
      if (!confirmed) return;
      setIsPending(true);
      const account = wallet.state.address;
      await withdrawLpToken(
        pool.contractAddress ?? "",
        account,
        pool.balanceInPool ?? "",
        onUpdateNotification()
      );
      refresh();
    } catch (err) {
      console.error(err);
      setIsPending(false);
    }
  };

  return (
    <div className="flex items-end">
      <div className="w-full h-auto animate__animated animate__fadeInUp">
        <FarmPeriodLockPool
          // periodFix={pool.periodFix}
          limited={limited}
          staked={nowStaked}
          // isDisabledDeposit={pool.isDisabledDeposit}
          onForceCountDown={() => {} /* Trigger parent fetch fixed APR lock */}
        />
        <div
          id="my-component"
          className="bg-[#19506F] bg-opacity-5 border border-[#19506F] rounded-b-[2px] border-t-0"
        >
          <div className="p-4">
            <div className="flex flex-auto gap-2 items-center">
              <img className="h-8" src={pool.staking.image} alt="wallet-icon" />
              <div className="flex-auto flex flex-col">
                <div className="flex flex-col gap-1">
                  <div className="text-h6">{pool.staking.name}</div>
                  <div className="text-sm">
                    <div>Earn: {reward?.name}</div>
                  </div>
                </div>
              </div>
              <div className="flex-auto flex flex-col h-[42px]">
                <div className="flex flex-col items-end gap-1">
                  <div className="flex text-sm font-normal text-velo-label">
                    Fixed APR
                  </div>
                  <div className="flex text-sm font-normal">
                    <div className="flex font-medium justify-center">
                      <div className="text-base mr-1">
                        <span>{formatBigNumberPercentAbbr(pool.apr)}%</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col justify-between p-4 h-[110px] rounded-lg mt-4 bg-[#909090] bg-opacity-5">
              <div className="flex items-center">
                {reward?.image && (
                  <img
                    className="h-4 mr-1"
                    src={reward.image}
                    alt="wallet-icon"
                  />
                )}
                <div className="text-sm text-velo-label flex">
                  Total {reward?.name} Earned
                  <img
                    src={require("../../assets/icons/general/lock.svg").default}
                    className="ml-2 h-4"
                  />
                </div>
              </div>
              <div>
                <div
                  className={`text-number ${
                    isApproved && isStaking
                      ? "text-white"
                      : "text-velo-disable-2"
                  }`}
                >
                  {formatEther(pool.earnedToken)}
                </div>
                <div
                  className={`text-number-small mt-1 ${
                    isApproved && isStaking
                      ? "text-white"
                      : "text-velo-disable-2"
                  }`}
                >
                  ~${formatEtherDollar(calculatedRewardEarned)}
                </div>
              </div>
            </div>

            <TableRow
              className="w-full px-4 py-4 mt-4"
              leftSlot={
                <div className="flex gap-1 items-center text-velo-label text-sm">
                  <img src={pool.staking.image} className="h-4" />
                  <p>{`${pool.staking.name}${isPair ? " LP" : ""} Stake`}</p>
                </div>
              }
              rightSlot={
                <div
                  className={`text-number ${
                    isApproved && isStaking ? "" : "text-velo-label"
                  }`}
                >
                  {formatEther(pool.balanceInPool)}
                </div>
              }
            ></TableRow>
          </div>

          <div className="px-4">
            {/* Buttons Section */}
            {!isValidConnection ? (
              <Button
                // id="button_farm_unlock_wallet"
                className="w-full"
                // onClick={unlockWallet}
                onClick={() => setIsShowConnectModal(true)}
              >
                UNLOCK WALLET
              </Button>
            ) : !isPeriodStart ? (
              <Button className="w-full" disabled>
                APPROVE
              </Button>
            ) : isPeriodEnd ? (
              isStaking ? (
                <Button className="w-full" onClick={withdrawAndClaim}>
                  Withdraw and Claim
                </Button>
              ) : (
                <Button
                  // id="button_farm_period_end"
                  className="w-full"
                  disabled
                >
                  Period has ended
                </Button>
              )
            ) : isPending ? (
              <Button
                // id="button_farm_processing"
                className="w-full"
                disabled
              >
                Processing...
              </Button>
            ) : !isApproved ? (
              <Button
                // id="button_farm_approve"
                className="w-full"
                onClick={approve}
              >
                APPROVE
              </Button>
            ) : !isPending && isStaking && !isReachLimit ? (
              <Button
                // id="button_farm_deposit"
                className="w-full"
                onClick={confirmLock}
              >
                Deposit
              </Button>
            ) : (
              <Button
                // id="button_farm_disabled"
                className="w-full"
                disabled
              >
                Pool is full
              </Button>
            )}
          </div>

          <div className="flex flex-col justify-between m-4 text-sm h-[56px]">
            <div className="flex flex-nowrap items-center h-7">
              <div className="mr-2 text-velo-disable-2">Total staked:</div>
              {hasStaking ? (
                <div className="text-number">
                  ${formatEtherDollar(calculatedTotalStaked)}
                </div>
              ) : (
                <div className="text-sm font-medium">N/A</div>
              )}
            </div>
            <div className="flex items-center justify-between h-7">
              <a
                className="flex items-center"
                href={`${scanURL}address/${pool.contractAddress}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <MaterialIcon className="text-velo-primary text-sm mr-1">
                  open_in_new
                </MaterialIcon>
                <span className="text-sm">View contract</span>
              </a>
              {pool.lpLink && (
                <Link className="flex items-center" to={pool.lpLink}>
                  <span>Get {pool.staking.name}</span>
                  <MaterialIcon className="text-velo-primary text-sm ml-1">
                    east
                  </MaterialIcon>
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FarmFixAprLockWithChangeableRatio;

import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from "react";
// Assuming you have API functions
import { BigNumber } from "bignumber.js";
import { useWallet } from "../../contexts/walletContext";
import useAprHelper from "../../hooks/useAprHelpers";
import useBlockTime from "../../hooks/useBlockTime";
import FarmFixAPR from "../../components/farm/fixAPR";
import {
  getDate,
  getDateByUnix,
  getUnixFromBlock,
  period,
} from "../../utils/datetime";
import { BlockTimeGenerationResult } from "../../interfaces/block";
import {
  EarnOtherFixedAPR,
  FixApr,
  FixAprExtra,
  FixAprLive,
  FixAprLock,
  FixAprLockWthChangeableRatio,
  NoFixApr,
  NoFixAprExtra,
} from "../../interfaces/config";
import useFarmCommon from "../../hooks/useFarmCommon";
import { PairExtras, useFarm } from "../../contexts/farmContext";
import FarmLayout from "./layout";
import LpToken from "../../components/farm/lpToken";
import { PairEarned } from "../../interfaces/token";
import config from "../../config";
import { useEthereum } from "../../contexts/etherruemContext";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DepositWithdrawModal from "../../components/farm/depositWithdrawModal";
import WithdrawClaimModal from "../../components/farm/withdrawClaimModal";
import ROIModal from "../../components/farm/ROIModal";
import useAprFixLockHelper from "../../hooks/useAprFixLockHelpers";
import useAprFixLockWithChangeableRatio from "../../hooks/useAprFixLockWithChangableRatioHelpers";
import useAprNoFixHelper from "../../hooks/useAprNoFixHelpers";
import ButtonGoToTop from "../../components/button/goToTop";
import FarmFixAPRLock from "../../components/farm/fixAPRLock";
import FarmFixAprLockWithChangeableRatio from "../../components/farm/fixAprLockWithChangeableRatio";
import EarnOther from "../../components/farm/earnOther";
import LockConfirmModal from "../../components/farm/lockConfirmModal";
import { Skeleton } from "@mui/material";

const FarmLive: React.FC = () => {
  const location = useLocation();
  const params = useParams<{ chains: string }>();
  const navigate = useNavigate();

  const depositWithDrawModalRef = useRef<{
    open: (
      pair: any,
      topic: string,
      balance: BigNumber,
      isShowGetLP: boolean,
      lpLink: string | null | undefined
    ) => any;
  }>(null);
  const farmLockConfirmModal = useRef<{
    open: (pair: any, period: number) => any;
  }>(null);
  const farmClaimModal = useRef<{
    open: (
      pair: PairEarned,
      rewardBalance?: BigNumber,
      balance?: BigNumber
    ) => any;
  }>(null);
  const farmROIModal = useRef<{
    open: (
      pair: PairEarned,
      apr: any,
      rewardPerUsd: any,
      lpLink: string
    ) => any;
  }>(null);

  const [fixedAPRLock, setFixedAPRLock] = useState<FixAprLock[] | null>(null);
  const [fixedAPR, setFixedAPR] = useState<FixApr[]>([]);
  const [fixAPRLockWithChangeableRatio, setFixAPRLockWithChangeableRatio] =
    useState<FixAprLockWthChangeableRatio[]>([]);
  const [earnOther, setEarnOther] = useState<NoFixApr[]>([]);
  const [earnGovernanceToken, setEarnGovernanceToken] = useState<any[]>([]);
  // const [earnGovernanceTokenOmni, setEarnGovernanceTokenOmni] = useState<any[]>(
  //   []
  // );
  const [pairExtras, setPairExtras] = useState<PairExtras>({});
  const [pairExtrasOmni, setPairExtrasOmni] = useState<PairExtras>({});
  const [rewardPerUsd, setRewardPerUsd] = useState(new BigNumber(0));
  const [rewardPerUsdOmni, setRewardPerUsdOmni] = useState(new BigNumber(0));

  const [isLoadingFixedAprLockDone, setIsLoadingFixedAprLockDone] =
    useState(true);

  const [isLoadingOtherDone, setIsLoadingOtherDone] = useState(true);

  const [blockData, setBlockData] = useState<BlockTimeGenerationResult | null>(
    null
  );

  const [isWithdraw, setIsWithdraw] = useState(false);
  const [isLockConfirm, setIsLockConfirm] = useState(false);

  const { calculateBlockTimeGeneration } = useBlockTime();
  const { getLiquidityLink, chainId } = useFarmCommon();

  const wallet = useWallet();

  const { farmEnv } = useFarm();
  const ethereum = useEthereum();

  const {
    getAllFarmEarnOtherInfo,
    getFarmEarnOtherInfo,
    isReady: isAprNoFixHelperReady,
  } = useAprNoFixHelper();
  const {
    getAllFarmFixedAPRInfo,
    getFarmFixedAPRInfo,
    isReady: isAprHelperReady,
  } = useAprHelper();
  const {
    getAllFarmFixedAPRLockInfoLive,
    getFarmFixedAPRLockInfo,
    isReady: isAprLockInfoReady,
  } = useAprFixLockHelper();
  const {
    getAllFarmFixedAPRLockWithChangeableRatioInfo,
    getFarmFixedAPRLockWithChangeableRatioInfo,
    isReady: isAprFixLockReady,
  } = useAprFixLockWithChangeableRatio();
  const { getFarmInfo, isReadyForGetFarmInfo } = useFarm();

  const getFarm = useMemo(() => {
    if (!farmEnv || !farmEnv.length) {
      // console.error("fetchAllDetails farmEnv is not initialized or empty");
      return null;
    }
    const x = farmEnv.find((o) => o.rewardToken?.code === "VELO_TOKEN");
    return x;
  }, [farmEnv]);

  const getFarmOmni = useMemo(() => {
    if (!farmEnv || !farmEnv.length) {
      // console.error("fetchAllDetails farmEnv is not initialized or empty");
      return null;
    }
    const x = farmEnv.find((o) => o.rewardToken?.code === "OMNI_TOKEN");
    return x;
  }, [farmEnv]);

  // useEffect(() => {onFetchFixedAPR()},[])

  useEffect(() => {
    if (!farmEnv || !ethereum || !ethereum.contracts || !ethereum.web3) return;
    if (
      isReadyForGetFarmInfo &&
      isAprFixLockReady &&
      isAprHelperReady &&
      isAprLockInfoReady &&
      isAprNoFixHelperReady
    ) {
      fetchAllDetails();
    }

    // const interval = setInterval(async () => await fetchAllDetails(), 5000);

    // return () => clearInterval(interval);
  }, [
    getFarm,
    ethereum,
    farmEnv,
    isReadyForGetFarmInfo,
    isAprFixLockReady,
    isAprHelperReady,
    isAprLockInfoReady,
    isAprNoFixHelperReady,
  ]);

  useEffect(() => {
    if (location.pathname.includes("/farms")) {
      const whitelistChain = Object.values(config.networks).map((o: any) =>
        o.code.toLowerCase()
      );

      if (!params.chains) {
        navigate("/error", { replace: true });
        return;
      }

      if (!whitelistChain.includes(params.chains)) {
        const defaultChain = config.networks[config.chainId].code.toLowerCase();
        navigate(`/farms/${defaultChain}/live`, { replace: true });
      }
    }
  }, [location.pathname, params.chains, navigate]);

  const chainExplorerURL = useMemo(() => {
    const id = chainId();
    if (!id) return;
    return config.networks[Number(id)]?.chainExplorerURL;
  }, [chainId()]);

  const mappingData = (data: any): FixAprExtra => {
    return {
      ...data,
      lpLink: getLiquidityLink(data?.staking),
      startTime: getUnixFromBlock(
        data?.startBlock,
        blockData?.currentBlock ?? 1,
        blockData?.blockTimeGeneration ?? 1
      ),
      endTime: getUnixFromBlock(
        data?.endBlock,
        blockData?.currentBlock ?? 1,
        blockData?.blockTimeGeneration ?? 1
      ),
    };
  };

  const isLive = (data: any, number = 1) => {
    if (!data.endTime) return false;
    const now = getDate();
    const endTime = getDateByUnix(data.endTime);
    return !endTime.isSameOrBefore(now);
  };

  const fixedAPRLockData = useMemo(() => {
    if (!fixedAPRLock) return [];
    return fixedAPRLock.map(mappingData).filter(isLive);
  }, [fixedAPRLock]);

  const fixAPRLockWithChangeableRatioData = useMemo(() => {
    return fixAPRLockWithChangeableRatio.map(mappingData).filter(isLive);
  }, [fixAPRLockWithChangeableRatio]);

  const fixedAPRData = useMemo(() => {
    return fixedAPR.map(mappingData).filter(isLive);
  }, [fixedAPR]);

  const earnOtherData: NoFixAprExtra[] = useMemo(() => {
    return earnOther.map((o) => ({
      ...o,
      lpLink: getLiquidityLink(o?.staking),
    }));
  }, [earnOther]);

  const onFetchFixedAPR = async (index: number) => {
    const isValidChain = await wallet.isValidChain();
    if (!isValidChain) return;
    const [newData, blockData] = await Promise.all([
      getFarmFixedAPRInfo(index),
      calculateBlockTimeGeneration(),
    ]);

    if (blockData) setBlockData(blockData);
    if (newData)
      setFixedAPR((prev) => prev.splice(index, 1, mappingData(newData)));
  };

  const onFetchStartFixedAPRLock = async () => {
    const isValidChain = await wallet.isValidChain();
    if (!isValidChain) return;
    const [data, blockData] = await Promise.all([
      getAllFarmFixedAPRLockInfoLive(),
      calculateBlockTimeGeneration(),
    ]);
    if (blockData && blockData.blockTimeGeneration !== null) {
      setBlockData(blockData);
    }
    if (data) {
      setFixedAPRLock(data);
    }
  };

  const onFetchFixedAPRLock = async (
    index: number,
    contractAddress: string
  ) => {
    const isValidChain = await wallet.isValidChain();
    if (!isValidChain) return;
    const [newData, blockData] = await Promise.all([
      getFarmFixedAPRLockInfo(contractAddress),
      calculateBlockTimeGeneration(),
    ]);
    if (blockData && blockData.blockTimeGeneration !== null)
      setBlockData(blockData);
    if (newData && newData.endBlock !== null && newData.startBlock !== null) {
      setFixedAPRLock((prev) => {
        if (prev == null) return [];
        const aprLockIndex = prev.filter(
          (item) => item.contractAddress !== newData.contractAddress
        );
        aprLockIndex.splice(index, 0, mappingData(newData));
        return aprLockIndex;
      });
    }
  };

  const onFetchFixedAPRLockWithChangeableRatio = async (index: number) => {
    const isValidChain = await wallet.isValidChain();
    if (!isValidChain) return;
    const [newData, blockData] = await Promise.all([
      getFarmFixedAPRLockWithChangeableRatioInfo(index),
      calculateBlockTimeGeneration(),
    ]);
    if (blockData) setBlockData(blockData);
    if (newData)
      setFixedAPR((prev) => prev.splice(index, 1, mappingData(newData)));
  };
  const onFetchEarnOther = async (index: number) => {
    const isValidChain = await wallet.isValidChain();
    if (!isValidChain) return;
    const [newData, blockData] = await Promise.all([
      getFarmEarnOtherInfo(index),
      calculateBlockTimeGeneration(),
    ]);
    if (blockData) setBlockData(blockData);
    if (newData) setEarnOther((prev) => prev.splice(index, 1, newData));
  };

  const run = useRef(false);

  const fetchAllDetails = async () => {
    if (!wallet) return;
    if (run.current) return;
    run.current = true;
    console.log("fetchAllDetails 1");
    setIsLoadingFixedAprLockDone(true);
    const chainValid = await wallet.isValidChain();
    if (!chainValid) {
      setFixedAPRLock([]);
      setFixAPRLockWithChangeableRatio([]);
      setFixedAPR([]);
      setEarnOther([]);
      setEarnGovernanceToken([]);
      return;
    }
    await onFetchStartFixedAPRLock();
    setIsLoadingFixedAprLockDone(false);
  };

  useEffect(() => {
    const fetchOtherDetails = async () => {
      console.log("fetchAllDetails 2");
      try {
        const [
          fixAPRLockWithChangeableRatio,
          fetchedFixedAPR,
          fetchedEarnOther,
        ] = await Promise.all([
          getAllFarmFixedAPRLockWithChangeableRatioInfo(),
          getAllFarmFixedAPRInfo(),
          getAllFarmEarnOtherInfo(),
        ]);

        setFixAPRLockWithChangeableRatio(fixAPRLockWithChangeableRatio);
        setFixedAPR(fetchedFixedAPR);
        setEarnOther(fetchedEarnOther);

        const resultFarm = getFarm;
        if (!getFarm || !getFarmOmni) {
          setIsLoadingOtherDone(false);
          return;
        }

        const [earnGovernanceToken] = await Promise.all([getFarmInfo(getFarm)]);

        // let earnGovernanceTokenOmni;

        // try {
        //   earnGovernanceTokenOmni = await getFarmInfo(getFarmOmni);
        // } catch (e) {
        //   earnGovernanceTokenOmni = {
        //     pairExtras: {},
        //     rewardPerUsd: BigNumber("0"),
        //     rewardPerBlock: BigNumber("0"),
        //   };
        // }

        setPairExtras(earnGovernanceToken.pairExtras);
        // setPairExtrasOmni(earnGovernanceTokenOmni.pairExtras);
        setRewardPerUsd(earnGovernanceToken.rewardPerUsd);
        // setRewardPerUsdOmni(earnGovernanceTokenOmni.rewardPerUsd);
        const pairList = getFarm?.pairs;
        const parListMap: PairEarned[] =
          pairList?.map((pair) => ({
            ...pair,
            reward: getFarm?.rewardToken.name,
            lpLink: getLiquidityLink(pair?.staking),
          })) ?? [];
        setEarnGovernanceToken(parListMap);

        // const pairListOmni = getFarmOmni?.pairs;
        // const parListMapOmni: PairEarned[] =
        //   pairListOmni?.map((pair) => ({
        //     ...pair,
        //     reward: getFarmOmni?.rewardToken.name,
        //     lpLink: getLiquidityLink(pair?.staking),
        //   })) ?? [];
        // setEarnGovernanceTokenOmni(parListMapOmni);

        setIsLoadingOtherDone(false);
      } catch (e) {
        console.log("error", e);
        setIsLoadingOtherDone(false);
      }
    };

    if (!isLoadingFixedAprLockDone && fixedAPRLock !== null) {
      fetchOtherDetails();
    }
  }, [isLoadingFixedAprLockDone, fixedAPRLock]);

  // useEffect(() => {
  //   timerRef.current = setInterval(() => {
  //     fetchAllDetails();
  //   }, 60000);

  //   return () => {
  //     if (timerRef.current) {
  //       clearInterval(timerRef.current);
  //     }
  //   };
  // }, []);

  const onRedirect = (pair: any) => {
    navigate(pair.lpLink);
  };

  const openROIModal = (id: string) => {
    if (!farmROIModal.current) return;
    const pair = earnGovernanceToken.find((pair) => pair.id === id);
    const apr = pairExtras[id].apr;
    return farmROIModal.current.open(pair, apr, rewardPerUsd, pair.lpLink);
  };

  const openLockConfirmModal = (pair: any) => {
    if (!farmLockConfirmModal.current) return;
    const nowUnix = getDateByUnix().unix();
    const result = period(nowUnix, pair.endTime);
    return farmLockConfirmModal.current.open(pair, result);
  };

  const openDepositWithdrawModal = (
    pair: PairEarned,
    topic: string,
    balance: BigNumber,
    isShowGetLP: boolean
  ) => {
    if (depositWithDrawModalRef.current)
      return depositWithDrawModalRef.current.open(
        pair,
        topic,
        balance,
        isShowGetLP,
        pair.lpLink
      );
  };

  const openWithdrawClaimModal = (
    pair: any,
    isWithdraw: boolean,
    rewardBalance: BigNumber,
    balance: BigNumber
  ) => {
    if (!farmClaimModal.current) return;
    setIsWithdraw(isWithdraw);
    return farmClaimModal.current.open(pair, rewardBalance, balance);
  };

  const openClaimModal = (pair: PairEarned, rewardBalance: BigNumber) => {
    if (!farmClaimModal.current) return;
    return farmClaimModal.current.open(pair, rewardBalance);
  };

  return (
    <FarmLayout>
      <div className="md:container">
        <div className="flex flex-col mx-auto justify-center">
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 md:gap-8 animate__animated animate__fadeInUp pt-6 md:pt-0 px-4 md:px-0 pb-14">
            {fixedAPRData &&
              fixedAPRData.map((pool, index) => {
                return (
                  <FarmFixAPR
                    key={`pool-fixed-apr-${index}`}
                    pool={pool}
                    openLockConfirmModal={openLockConfirmModal}
                    openWithdrawClaimModal={openWithdrawClaimModal}
                    openDepositWithdrawModal={openDepositWithdrawModal}
                    // openROIModal={openROIModal}
                    rewardProp={getFarm?.rewardToken} // Adjust reward if needed
                    onFetchFixedAPR={() => onFetchFixedAPR(index)}
                  />
                );
              })}
            {fixedAPRLockData &&
              fixedAPRLockData.map((pool, index) => {
                return (
                  <FarmFixAPRLock
                    key={`pool-fixed-apr-lock-${index}`}
                    pool={pool}
                    openWithdrawClaimModal={openWithdrawClaimModal}
                    openLockConfirmModal={openLockConfirmModal}
                    openDepositWithdrawModal={openDepositWithdrawModal}
                    reward={getFarm?.rewardToken} // Adjust reward if needed
                    onFetchFixedAPRLock={() =>
                      onFetchFixedAPRLock(index, pool.contractAddress || "")
                    }
                  />
                );
              })}
            {fixAPRLockWithChangeableRatioData &&
              fixAPRLockWithChangeableRatioData.map((pool, index) => {
                return (
                  <FarmFixAprLockWithChangeableRatio
                    key={`pool-fixed-apr-lock-with-change-ratio-${index}`}
                    pool={pool}
                    openWithdrawClaimModal={openWithdrawClaimModal}
                    openLockConfirmModal={openLockConfirmModal}
                    openDepositWithdrawModal={openDepositWithdrawModal}
                    reward={getFarm?.rewardToken} // Adjust reward if needed
                    onFetchFixedAPRLock={() =>
                      onFetchFixedAPRLockWithChangeableRatio(index)
                    }
                  />
                );
              })}

            {earnOtherData &&
              earnOtherData.map((pool, index) => (
                <EarnOther
                  className="self-end"
                  key={`pool-earn-other-apr${index}`}
                  pool={pool}
                  openWithdrawClaimModal={setIsWithdraw}
                  openDepositWithdrawModal={setIsWithdraw}
                  openROIModal={openROIModal}
                  reward={getFarm?.rewardToken} // Adjust reward if needed
                  onFetchEarnOther={() => onFetchEarnOther(index)}
                />
              ))}

            {/* {earnGovernanceTokenOmni.map((pair, id) => (
              <div key={id} className="flex items-end">
                <LpToken
                  className="flex-1"
                  pair={pair}
                  reward={getFarmOmni?.rewardToken} // Adjust reward if needed
                  contractAddress={getFarmOmni?.contractAddress}
                  totalStaked={pairExtrasOmni[pair.id]?.totalStaked}
                  apr={pairExtrasOmni[pair.id]?.apr}
                  lpPerUsd={pairExtrasOmni[pair.id]?.lpPerUsd}
                  evryPerUsd={rewardPerUsdOmni}
                  openDepositWithdrawModal={openDepositWithdrawModal}
                  openClaimModal={openClaimModal}
                  openROIModal={openROIModal}
                  scanURL={chainExplorerURL ?? config.chainExplorerURL ?? ""}
                />
              </div>
            ))} */}

            {earnGovernanceToken.length > 0 ? (
              earnGovernanceToken.map((pair, id) => (
                <div key={id} className="flex items-end">
                  <LpToken
                    className="flex-1"
                    pair={pair}
                    reward={getFarm?.rewardToken} // Adjust reward if needed
                    contractAddress={getFarm?.contractAddress}
                    totalStaked={pairExtras[pair.id]?.totalStaked}
                    apr={pairExtras[pair.id]?.apr}
                    lpPerUsd={pairExtras[pair.id]?.lpPerUsd}
                    evryPerUsd={rewardPerUsd}
                    openDepositWithdrawModal={openDepositWithdrawModal}
                    openClaimModal={openClaimModal}
                    openROIModal={openROIModal}
                    scanURL={chainExplorerURL ?? config.chainExplorerURL ?? ""}
                  />
                </div>
              ))
            ) : !isLoadingFixedAprLockDone && isLoadingOtherDone ? (
              getFarm?.pairs.map((pair, id) => (
                <div className="flex w-full h-full min-h-[466px]">
                  <Skeleton
                    variant="rectangular"
                    width={"100%"}
                    height={"100%"}
                  />
                </div>
              ))
            ) : (
              <></>
            )}

            {/* {(fixedAPRLock.length ||
              fixedAPR.length ||
              earnOther.length ||
              earnGovernanceToken.length) && <ButtonGoToTop />} */}
          </div>
        </div>

        <DepositWithdrawModal ref={depositWithDrawModalRef} />
        <WithdrawClaimModal isWithdraw={isWithdraw} ref={farmClaimModal} />
        <LockConfirmModal
          isLockConfirm={isLockConfirm}
          ref={farmLockConfirmModal}
        />
        {/*<FarmLockConfirmModal ref={null} />*/}
        <ROIModal onRedirect={onRedirect} ref={farmROIModal} />
      </div>
    </FarmLayout>
  );
};

export default FarmLive;

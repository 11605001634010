import React from "react";
import SidebarList from "./List";
import SidebarTokenPrice from "./TokenPrice";
import SidebarButtomMenu from "./BottomMenu";
import { Link } from "react-router-dom";
import { Social } from "../../interfaces/config";
import { Menu } from "../../interfaces/menu";

interface Props {
  className?: string;
  isOpenMenu: boolean;
  setIsOpenMenu: (isOpen: boolean) => void;
  menus: Menu[]; // Replace `any` with the appropriate menu type if you have a more specific type definition
  tokenPerUsd: number;
  social: Social;
  onEmitOpen: () => void;
  isMobile: boolean;
}

const Sidebar: React.FC<Props> = ({
  className,
  isOpenMenu,
  setIsOpenMenu,
  menus,
  tokenPerUsd,
  social,
  onEmitOpen,
  isMobile,
}) => {
  return (
    <div className={className}>
      {isOpenMenu || !isMobile ? (
        <div
          id="sidebar-desktop"
          className="w-full transition-all h-full fixed md:relative z-40 overflow-hidden md:w-48 flex-col md:flex items-center"
        >
          <div
            className={`pt-7 pb-24 md:pt-0 w-auto md:w-40 px-[26px] md:px-0 h-full bg-cover ${
              isMobile && "bg-mobile"
            }`}
            // style={{
            //   backgroundImage:
            //     require("../../assets/icons/home/bg_menu_mobile.svg").default,
            // }}
          >
            <div className="black pb-10 md:hidden flex flex-row justify-between items-center">
              <Link to="/" onClick={() => setIsOpenMenu(!isOpenMenu)}>
                <img
                  className="cursor-pointer h-4 md:h-6"
                  src={require("../../assets/header/velo-logo.svg").default}
                  alt="velo-finance"
                  // onClick={() => setIsOpenMenu(!isOpenMenu)}
                />
              </Link>
              <img
                src={
                  require("../../assets/icons/general/close-icon.svg").default
                }
                className="ml-2 text-evry-primary cursor-pointer"
                // onClick={() => setIsOpenMenu(!isOpenMenu)}
              />
            </div>
            <div className="h-full flex flex-col justify-between md:justify-start">
              <SidebarList
                // id="sidebar-list"
                menus={menus}
                setIsOpenMenu={setIsOpenMenu}
                isMobile={isMobile}
                // isOpenMenu={isOpenMenu}
                // onNavigation={onNavigation}
              />
              <div className="flex flex-row justify-between md:flex-col gap-6 pt-6 md:pt-0">
                <SidebarTokenPrice
                  className="border border-[#19506F] rounded-[2px] px-4 py-3 w-40"
                  // token={{ image: "", name: "VELO" }}
                  tokenPerUsd={tokenPerUsd}
                />
                <div className="w-[109px] flex flex-col gap-2">
                  <p className="text-base">Follow us</p>
                  <div className="flex flex-row justify-between">
                    <a
                      href={social.telegram}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={
                          require("../../assets/icons/social/telegram.svg")
                            .default
                        }
                        alt="telegram"
                      />
                    </a>
                    <a
                      href={social.medium}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={
                          require("../../assets/icons/social/medium.svg")
                            .default
                        }
                        alt="medium"
                      />
                    </a>
                    <a
                      href={social.twitter}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={
                          require("../../assets/icons/social/twitter.svg")
                            .default
                        }
                        alt="twitter"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <SidebarButtomMenu
          className="md:hidden"
          isOpenMenu={isOpenMenu}
          menus={menus}
          onEmit={setIsOpenMenu}
        />
      )}
    </div>
  );
};

export default Sidebar;

import { MutableRefObject, useCallback, useMemo } from "react";
import { useBalance } from "wagmi";
import {
  ChainDetailType,
  DepositToken,
  WithdrawToken,
} from "../../../interfaces/wallet";

const useEthWalletBalance = (
  chain: ChainDetailType.Item,
  address: MutableRefObject<`0x${string}` | undefined>,
  token: DepositToken | WithdrawToken | undefined
) => {
  const { data: erc20Balance, refetch: erc20Refetch } = useBalance({
    chainId: chain.chainId,
    address: address.current,
    token: token?.address as `0x{string}`,
  });
  const { data: nativeBalance, refetch: nativeRefetch } = useBalance({
    chainId: chain.chainId,
    address: address.current,
  });

  const balance = useMemo(() => {
    if (token) {
      return erc20Balance;
    }
    return nativeBalance;
  }, [erc20Balance, nativeBalance, token]);

  const refetch = useCallback(() => {
    if (token) {
      return erc20Refetch();
    }
    return nativeRefetch();
  }, [erc20Refetch, nativeRefetch, token]);

  const fetchBalance = useCallback(async () => {
    console.log("fetching balance");

    const res = await refetch();
    console.log("balance fetched", res);
    console.log("chain", chain);
    console.log("address", address.current);
    console.log("token", token);
  }, [address, chain, refetch, token]);

  return {
    balance: balance?.formatted,
    fetchBalance,
  };
};

export default useEthWalletBalance;

import React, { useMemo } from "react";
import ProgressBar from "../progressBar/index";
import Icon from "../icon/index";
import MaterialIcon from "../icon/index";
import config from "../../config";
import { useNotification } from "../../contexts/notificationContext";

interface Props {
  status: string;
  fullUrl?: string;
  scanURL?: string;
  txHash?: string;
  chainType?: string;
  className?: string;
}

const NotificationBox: React.FC<Props> = ({
  status,
  fullUrl,
  txHash,
  chainType = "BNB",
  scanURL = config.chainExplorerURL,
  className,
}) => {
  const notification = useNotification();

  const isShowLink = useMemo(() => {
    let isShow = true;
    if (
      fullUrl === "NOLINK" ||
      ((!txHash || txHash === "undefined" || txHash === "null") &&
        (!fullUrl || fullUrl === ""))
    ) {
      isShow = false;
    }
    return isShow;
  }, [fullUrl, txHash]);

  const isSuccess = useMemo(() => {
    return status === "success";
  }, [status]);

  const close = () => {
    if (txHash) notification.deleteNotify(txHash);
  };

  return (
    <div className={`w-full md:w-auto relative ${className}`}>
      {status === "processing" ? (
        <>
          <div className="hidden md:block px-24 py-3 bg-transparent focus:outline-none text-velo-wisp-pink border border-velo-pink rounded-[2px] items-center">
            <div className="mb-1 text-xs font-thin">Processing</div>
            <ProgressBar />
          </div>
          <div className="md:hidden">
            <ProgressBar />
          </div>
        </>
      ) : (
        <div
          className={`bg-velo-wisp-pink text-sm font-normal flex flex-row h-8 px-2 rounded-[2px] gap-2 items-center justify-between mx-4 md:mx-0 ${
            isSuccess ? "text-velo-green" : "text-velo-orange"
          }`}
        >
          <div className="flex flex-row gap-1 items-center">
            {/* <Icon outline className="text-sm">
              {isSuccess ? "task_alt" : "cancel"}
            </Icon> */}
            <span>Transaction {isSuccess ? "completed" : "failed"}</span>
            {isShowLink && (
              <a
                href={fullUrl ? fullUrl : `${scanURL}tx/${txHash}`}
                className="flex items-center gap-1"
                target="_blank"
                rel="noopener noreferrer"
              >
                {/* <Icon className="text-velo-secondary text-sm">open_in_new</Icon> */}
                <span className="text-velo-secondary">
                  View on {chainType === "BNB" ? "BscScan" : "SolScan"}
                </span>
              </a>
            )}
          </div>
          <span onClick={close}>
            <MaterialIcon className="text-velo-secondary text-lg cursor-pointer">
              close
            </MaterialIcon>
          </span>
        </div>
      )}
    </div>
  );
};

export default NotificationBox;

// src/components/HomeIntro.tsx
import React from "react";
import "./intro.css"; // Import the CSS for this component
import config from "../../config";

const Intro: React.FC = () => {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-12 xl:grid-cols-12">
      <div className="lg:col-span-12 xl:col-span-12">
        <button
          className="text-sm bg-velo-pink rounded-[2px] p-2"
          style={{ cursor: "default" }}
          disabled
        >
          🔔 We’re saying goodbye to the old USDV. 🔔 Current holders, please
          exchange the token for VELO in the regular AMM pool (VELO/O-USDV
          pair). To participate in the new USDV for trading, please use{" "}
          <a
            className="underline"
            href="https://universe.velofinance.io/otc"
            target="_blank"
            rel="noopener noreferrer"
          >
            Universe OTC
          </a>{" "}
          instead.
        </button>
      </div>
      <div className="py-4 lg:col-span-6 xl:col-span-7">
        <div>
          <p className="xl:text-7xl lg:text-5xl sm:text-4xl leading-tight text-intro uppercase">
            Supercharge Money{" "}
            <span className="text-background-transparent font-semibold">
              Velo
            </span>
            city with the Power of{" "}
            <span className="text-background-transparent font-semibold">
              Web3+
            </span>
          </p>
        </div>
        <div className="mt-10 cursor-pointer">
          <a
            href={config.velo.homeUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="text-base h-[56px] w-full max-w-[256px] focus:outline-none bg-velo-pink rounded-[2px] uppercase">
              Learn more about Velo
            </button>
          </a>
        </div>
      </div>
      <div className="h-80 md:h-96 min-h-fit lg:col-span-6 xl:col-span-5 order-first lg:order-last md:pr-24">
        <div className="h-full background-intro bg-contain bg-center lg:bg-right-bottom bg-no-repeat" />
      </div>
    </div>
  );
};

export default Intro;

import React, { ReactNode } from "react";

interface Props {
  colorClass?: string;
  leftSlot?: ReactNode;
  rightSlot?: ReactNode;
  children?: ReactNode;
  className?: string;
}

const TableRow: React.FC<Props> = ({
  colorClass = "",
  leftSlot,
  rightSlot,
  children,
  className,
}) => {
  const hasLeftSlot = !!leftSlot;
  const hasRightSlot = !!rightSlot;

  return (
    <div
      className={`p-2 rounded-[2px] flex flex-col ${className} ${
        colorClass || "bg-[#919191] bg-opacity-5"
      }`}
    >
      {hasLeftSlot && <div className="flex items-center">{leftSlot}</div>}
      {children}
      {hasRightSlot && (
        <div
          className={`flex items-center justify-end ${
            hasLeftSlot ? "mt-1" : ""
          }`}
        >
          {rightSlot}
        </div>
      )}
    </div>
  );
};

export default TableRow;

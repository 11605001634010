import React, { useEffect, useMemo, useRef, useState } from "react";
import PageHeader from "../../components/pageHeader";
import RightSlot from "../../components/slots/rightSlot";
import AmountInput from "../../components/amountInput";
import ButtonWrapper from "../../components/button/wrapper";
import Button from "../../components/button";
import { Token } from "../../interfaces/token";
import { useWallet } from "../../contexts/walletContext";
import config from "../../config";
import { useTokenHelpers } from "../../hooks/useTokenHelpers";
import { useEthereum } from "../../contexts/etherruemContext";
import BigNumber from "bignumber.js";
import useRouteHelpers from "../../hooks/useRouteHelpers";
import SettingModal from "../../components/setting/settingModal";
import Table from "../../components/table";
import TableRow from "../../components/table/row";
import ToolTip from "../../components/toolTip";
import { useSlippageState } from "../../contexts/slippageContext";
import { durationTime, lpType } from "../../constants";
import SwapConfirmModal from "../../components/swap/confirmModal";
import useSwapHelpers from "../../hooks/useSwapHelpers";
import { addMinute } from "../../utils/datetime";
import {
  NotificationStore,
  useNotification,
} from "../../contexts/notificationContext";
import useErrorHelpers from "../../hooks/useErrorHelpers";
import { useUnifiedWallet } from "../../providers/UnifiedWalletProvider";
import { ethers } from "ethers";
import Icon from "../../components/icon";
import { useSearchParams } from "react-router-dom";

const Swap: React.FC = () => {
  // const settingModalRef = useRef<HTMLDivElement | null>(null);
  // const swapConfirmModalRef = useRef<HTMLDivElement | null>(null);

  const [paramsPath] = useSearchParams();

  const [tokenA, setTokenA] = useState<Token | null>(null);
  const [tokenB, setTokenB] = useState<Token | null>(null);

  const [onFocusA, setOnFocusA] = useState<boolean>(false);
  const [onFocusB, setOnFocusB] = useState<boolean>(false);

  const [tokenListA, setTokenListA] = useState<Token[]>([]);
  const [tokenListB, setTokenListB] = useState<Token[]>([]);

  const [supplyAmountTokenA, setSupplyAmountTokenA] = useState<string>("");
  const [supplyAmountTokenB, setSupplyAmountTokenB] = useState<string>("");
  const [isReadySwap, setIsReadySwap] = useState(0);

  const [isInsufficientBalance, setIsInsufficientBalance] =
    useState<boolean>(false);

  const [minMaxTradingText, setMinMaxTradingText] = useState<string>("");
  // const [minMaxTradingAmount, setMinMaxTradingAmount] = useState<string>("");

  const [tradingFee, setTradingFee] = useState<string>("0.0");
  const [priceImpact, setPriceImpact] = useState<string>("0.0");
  const [priceImpactColorClass, setPriceImpactColorClass] =
    useState<string>("");
  const [amplifier, setAmplifier] = useState<string | null>(null);
  const [routeMap, setRouteMap] = useState<string[]>([]);
  const [routeMapText, setRouteMapText] = useState<string>("");

  const [submitButtonState, setSubmitButtonState] = useState<string>("");
  const [submitButtonLoading, setSubmitButtonLoading] =
    useState<boolean>(false);

  const [tokenAPerTokenB, setTokenAPerTokenB] = useState<string>("0.0");
  const [tokenBPerTokenA, setTokenBPerTokenA] = useState<string>("0.0");
  const [lastTokenAPerBPrice, setLastTokenAPerBPrice] = useState<string>("");

  const [isCallSmartContractError, setIsCallSmartContractError] =
    useState<boolean>(false);

  const [firstInputToken, setFirstInputToken] = useState<string>("");

  const [isSettingModalOpen, setIsSettingModalOpen] = useState<boolean>(false);
  const [isSwapConfirmModalOpen, setIsSwapConfirmModalOpen] =
    useState<boolean>(false);

  const [tooltipTradingFee, setTooltipTradingFee] = useState<string>("");

  const [priceChangingOnModal, setPriceChangingOnModal] =
    useState<boolean>(false);

  const [displayBalanceTokenA, setDisplayBalanceTokenA] =
    useState<string>("0.0");
  const [displayBalanceTokenB, setDisplayBalanceTokenB] =
    useState<string>("0.0");

  const [txHash, setTxHash] = useState<string>("");

  const { walletApi } = useUnifiedWallet();

  // const wallet = useWallet();
  const ethereum = useEthereum();
  const { checkAllowanceToSpender, approveTokenToSpender } = useTokenHelpers();
  const {
    getRoute,
    findRoute,
    getEstimationAmount,
    getPairData,
    getDMMPoolAddresses,
  } = useRouteHelpers();
  const {
    tooltipDynamicFee,
    swapExactNativeForTokens,
    swapExactTokensForNative,
    swapExactTokensForTokens,
    checkAllowanceBeforeSwap,
    getAllowanceBeforeSwap,
    swapNativeForExactTokens,
    swapTokensForExactNative,
    swapTokensForExactTokens,
  } = useSwapHelpers();
  const { getErrorReason } = useErrorHelpers();

  const { slippage, trxDeadline } = useSlippageState();
  const { setNotify, deleteNotify, clearAll, state } = useNotification();
  const [isApprovePending, setIsApprovePending] = useState<boolean>(false);
  const [triggerBalance, setTriggerBalance] = useState<boolean>(false);

  useEffect(() => {
    mapTokenList();
  }, [tokenA, tokenB]);

  useEffect(() => {
    const tokenBParams = config.swapTokens.find(
      (el) => el.code === paramsPath.get("tokenB")
    );
    if (tokenBParams) {
      setTokenB(tokenBParams);
    }
  }, [paramsPath]);

  useEffect(() => {
    if (
      firstInputToken === "" &&
      (supplyAmountTokenA !== "" || supplyAmountTokenB !== "")
    ) {
      const tmp = supplyAmountTokenB;
      if (supplyAmountTokenA !== "") {
        setFirstInputToken("tokenA");
      }
      if (supplyAmountTokenB !== "") {
        setFirstInputToken("tokenB");
      }
    } else {
      updateTradeInformation(supplyAmountTokenA, supplyAmountTokenB);
    }

    handleSubmitButtonState();
  }, [firstInputToken, supplyAmountTokenA, supplyAmountTokenB, tokenA, tokenB]);

  useEffect(() => {
    if (walletApi.hasAddress) {
      getBalances();
    } else {
      setDisplayBalanceTokenA("0.0");
      setDisplayBalanceTokenB("0.0");
    }
  }, [walletApi.hasAddress, tokenA, tokenB, triggerBalance]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTriggerBalance(!triggerBalance);
    }, 3000);

    return () => clearInterval(intervalId);
  }, [triggerBalance]);

  const isTokensSelected = useMemo(() => {
    return !!tokenA?.symbol && !!tokenB?.symbol;
  }, [tokenA?.symbol, tokenB?.symbol]);

  const submitButtonText = useMemo(() => {
    if (!isTokensSelected || submitButtonState === "selectToken") {
      return "Select token";
    }

    if (submitButtonState === "approveTokenA") {
      return `Approve ${tokenA?.name}`;
    }

    if (submitButtonState === "enterAmount") {
      return "Enter amount";
    }

    if (submitButtonState === "insufficientBalanceTokenA") {
      return `Insufficient ${tokenA?.name}`;
    }

    if (submitButtonState === "swap") {
      return "Swap";
    }

    if (submitButtonState === "price impact too high") {
      return "Price impact too high";
    }

    if (submitButtonState === "swap anyway") {
      return "Swap anyway";
    }

    return "Processing...";
  }, [submitButtonState, isTokensSelected]);

  useEffect(() => {
    if (
      submitButtonState &&
      (!parseFloat(supplyAmountTokenA) ||
        !parseFloat(supplyAmountTokenB) ||
        parseFloat(supplyAmountTokenA) === 0 ||
        parseFloat(supplyAmountTokenB) === 0)
    ) {
      setSubmitButtonState("enterAmount");
    }
  }, [submitButtonState]);

  const getLPConfig = (
    tokenASymbol = "",
    tokenBSymbol = "",
    fallbackEmptyObject = true
  ) => {
    const emptyObj = {
      lpAddress: null,
      lpName: null,
      lpImage: null,
      token0: null,
      token1: null,
    };

    const lpConfig: any = Object.assign({}, config.lp, config.dmmLP);

    if (!tokenASymbol) return null;

    const symbolA = tokenASymbol.toUpperCase();
    const symbolB = tokenBSymbol.toUpperCase();
    const lpAB = `${symbolA}_${symbolB}`;
    const lpBA = `${symbolB}_${symbolA}`;

    if (!!lpConfig[lpAB]) {
      // lpContractName = lpAB
      return lpConfig[lpAB];
    }

    if (!!lpConfig[lpBA]) {
      // lpContractName = lpBA
      return lpConfig[lpBA];
    }

    // lpContractName = null

    return fallbackEmptyObject ? emptyObj : null;
  };

  const lpConfigSelected = () => {
    return getLPConfig(tokenA?.symbol, tokenB?.symbol);
  };

  const isDMMType = () => {
    return lpConfigSelected() && lpConfigSelected().lpType === lpType.DMM_TYPE;
  };

  const isAMMType = () => {
    return lpConfigSelected() && lpConfigSelected().lpType === lpType.AMM_TYPE;
  };

  const isAutoRoute = () => {
    return !(isDMMType() || isAMMType());
  };

  const toPrecision = (value: string, digit: number = 8) => {
    return BigNumber(value).toFixed(digit, 1).toString();
  };

  const calculateMinReceive = useMemo(() => {
    if (parseFloat(supplyAmountTokenB) > 0) {
      const amountTokenB = toPrecision(supplyAmountTokenB);
      const multiplier = BigNumber(slippage).div(100);
      const minAmountTokenB = BigNumber(amountTokenB).times(multiplier);
      return toPrecision(
        BigNumber(amountTokenB).minus(minAmountTokenB).toString()
      );
    }

    return "0";
  }, [supplyAmountTokenB, slippage]);

  const minMaxTradingAmount = useMemo(() => {
    if (parseFloat(supplyAmountTokenB) > 0) {
      if (minMaxTradingText === "Min received") {
        return calculateMinReceive;
      } else {
        const amountTokenA = toPrecision(supplyAmountTokenA);
        const maxAmountTokenA = BigNumber(amountTokenA).plus(
          BigNumber(amountTokenA).times(slippage).div(100)
        );
        return toPrecision(maxAmountTokenA.toString());
      }
    }

    return "0";
  }, [supplyAmountTokenB, minMaxTradingText, supplyAmountTokenA, slippage]);

  const filterTokenList = (token: Token | null) => {
    // if (!token) return [];
    const configList = config.swapTokens;

    return configList.filter(({ symbol }) => `${token?.symbol}` !== symbol);
  };

  const mapTokenList = () => {
    if (tokenA && tokenB) {
      setTokenListA(filterTokenList(tokenB));
      setTokenListB(filterTokenList(tokenA));
    } else if (tokenA && !tokenB) {
      setTokenListA(config.swapTokens);
      setTokenListB(filterTokenList(tokenA));
    } else if (!tokenA && tokenB) {
      setTokenListA(filterTokenList(tokenB));
      setTokenListB(config.swapTokens);
    } else {
      setTokenListA(config.swapTokens);
      setTokenListB(config.swapTokens);
    }
  };

  const openSetting = () => {
    setIsSettingModalOpen(true);
  };

  const closeSetting = () => {
    setIsSettingModalOpen(false);
  };

  const getTokenConfig = (name = "") => {
    const { token } = config;
    const tokenName = `${name.toUpperCase()}_TOKEN`;

    return token[tokenName];
  };

  const clearInputAmount = () => {
    setSupplyAmountTokenA("");
    setSupplyAmountTokenB("");
    setTokenAPerTokenB("0.0000");
    setTokenBPerTokenA("0.0000");
    setSubmitButtonState("enterAmount");
  };

  const isApproveToken = async (
    tokenSymbol: string,
    ownerAddress: string,
    routerAddress: string
  ) => {
    return await checkAllowanceToSpender(
      tokenSymbol,
      ownerAddress,
      routerAddress
    );
  };

  const isInputAmount = useMemo(() => {
    return !!supplyAmountTokenA && !!supplyAmountTokenB;
  }, [supplyAmountTokenA, supplyAmountTokenB]);

  const checkApprove = async () => {
    if (supplyAmountTokenA || supplyAmountTokenB) {
      setSubmitButtonState("");
    }

    if (tokenA?.symbol && tokenB?.symbol) {
      const tokenASymbol =
        tokenA?.symbol === "BNB" ? tokenA.symbol : `${tokenA?.symbol}_TOKEN`;
      const walletAddress = walletApi.account.current ?? "";
      const routerAddress = config.autoRouterContractAddress ?? "";

      // const isApproveTokenA = await isApproveToken(
      //   tokenA.address ?? "",
      //   walletAddress,
      //   routerAddress
      // );

      // if (!isApproveTokenA) {
      //   setSubmitButtonState("approveTokenA");
      //   return false;
      // }

      try {
        if (tokenA?.symbol !== "BNB") {
          const checkAllowance = await getAllowanceBeforeSwap(
            `${tokenA.symbol}_TOKEN`,
            tokenA.address ?? "",
            config.autoRouterContractAddress ?? "",
            walletApi.account.current ?? "",
            supplyAmountTokenA
          );
          if (!checkAllowance) {
            setSubmitButtonState("approveTokenA");
            return false;
          }
        }
      } catch (error) {
        console.error("checkApprove", error);
        setSubmitButtonState("approveTokenA");
        return false;
      }
    }

    return true;
  };

  useEffect(() => {
    const interval = setInterval(async () => {
      if (
        ethereum &&
        !!tokenA?.symbol &&
        walletApi.hasAddress &&
        submitButtonState === "approveTokenA" &&
        isApprovePending
      ) {
        const checkAllowance = await getAllowanceBeforeSwap(
          `${tokenA.symbol}_TOKEN`,
          tokenA.address ?? "",
          config.autoRouterContractAddress ?? "",
          walletApi.account.current ?? "",
          supplyAmountTokenA
        );
        if (checkAllowance) {
          setIsApprovePending(false);
          setSubmitButtonState("swap");
        }
      }
    }, 1000); // 0.5 seconds

    // Cleanup the interval when the component unmounts
    return () => clearInterval(interval);
  }, [
    ethereum,
    tokenA,
    walletApi.hasAddress,
    submitButtonState,
    isApprovePending,
  ]);

  const tokenBalanceOf = async (tokenSymbol: string) => {
    try {
      if (tokenSymbol === "BNB" && walletApi.account.current) {
        return await ethereum.web3?.eth.getBalance(walletApi.account.current);
      }

      return await ethereum.contracts.tokens[`${tokenSymbol}_TOKEN`].methods
        .balanceOf(walletApi.account.current)
        .call();
    } catch {
      console.error("tokenBalanceOf", tokenSymbol);
    }
  };

  const checkIfInputAmount = async () => {
    if (!supplyAmountTokenA && !supplyAmountTokenB) {
      setSubmitButtonState("enterAmount");
      return false;
    }
    if (
      (parseFloat(supplyAmountTokenA) === 0 ||
        isNaN(parseFloat(supplyAmountTokenA))) &&
      (parseFloat(supplyAmountTokenB) === 0 ||
        isNaN(parseFloat(supplyAmountTokenB)))
    ) {
      setSubmitButtonState("enterAmount");
      return false;
    }
    if (!tokenA || !tokenB) {
      setSubmitButtonState("selectToken");
      return false;
    }

    const _balance = await tokenBalanceOf(tokenA?.symbol);

    const supply = BigNumber(supplyAmountTokenA);
    let balance = BigNumber(0);
    if (ethereum?.web3)
      balance = BigNumber(ethereum.web3.utils.fromWei(_balance ?? 0, "ether"));

    if (supply.isGreaterThan(balance)) {
      setSubmitButtonState("insufficientBalanceTokenA");
      return false;
    }
    return true;
  };

  const setLoading = (number: number) => {
    setSubmitButtonLoading(true);
    setSubmitButtonState("");
  };

  const resetLoading = () => {
    setSubmitButtonLoading(false);
    setSubmitButtonState("swap");
  };

  useEffect(() => {
    if (parseFloat(priceImpact) > 15 || isCallSmartContractError) {
      setSubmitButtonState("price impact too high");
    } else if (parseFloat(priceImpact) > 5) {
      setSubmitButtonState("swap anyway");
      setSubmitButtonLoading(false);
    } else {
      if (!supplyAmountTokenA || !supplyAmountTokenB) {
        setLoading(1);
        setSubmitButtonState("processing...");
      } else {
        resetLoading();
        setSubmitButtonState("swap");
      }
    }
  }, [isReadySwap]);
  const handleSubmitButtonState = async () => {
    const isCheckInputAmount = await checkIfInputAmount();
    if (!isCheckInputAmount) {
      return;
    }
    const isCheckApprove = await checkApprove();
    if (!isCheckApprove) return;
    setIsReadySwap((isReadySwap + 1) % 10);
  };

  const updateTradeInformation = async (
    supplyUsedA: string,
    supplyUsedB: string
  ) => {
    if (ethereum.web3 && isTokensSelected) {
      if (!supplyUsedA && !supplyUsedB) {
        setTokenAPerTokenB("0.00");
        setTokenBPerTokenA("0.00");
        return;
      }

      if (parseFloat(supplyUsedA) === 0 || parseFloat(supplyUsedB) === 0) {
        return;
      }

      try {
        if (tokenA && tokenB) {
          const route = await findRoute(
            tokenA,
            tokenB,
            parseFloat(toPrecision(supplyUsedA)),
            parseFloat(toPrecision(supplyUsedB)),
            firstInputToken
          );

          if (firstInputToken === "tokenA") {
            setSupplyAmountTokenB(route.minimumAmountOut);
          } else if (firstInputToken === "tokenB") {
            setSupplyAmountTokenA(route.minimumAmountOut);
          }

          setAmplifier(route.amplifier);
          setTradingFee(route.tradingFee);
          setRouteMap(route.routePathTextArray);
          setRouteMapText(route.routePathText);
          setPriceImpact(route.priceImpact);

          setTooltipTradingFee(
            tooltipDynamicFee(route.tradingFee, route.inputToken.symbol)
          );

          // amplifier = route.amplifier;
          // tradingFee = route.tradingFee;
          // routeMap = route.routePathTextArray;
          // routeMapText = route.routePathText;
          // priceImpact = route.priceImpact;
          // tooltipTradingFee = tooltipDynamicFee(
          //   tradingFee,
          //   route.inputToken.symbol
          // );

          setLastTokenAPerBPrice(tokenAPerTokenB);

          setIsCallSmartContractError(false);
        } else {
          setIsCallSmartContractError(true);
        }
      } catch (error) {
        console.error("updateTradeInformation", error);
        setIsCallSmartContractError(true);
      }

      // set flag to display price update on modal
      if (
        isSwapConfirmModalOpen &&
        !BigNumber(lastTokenAPerBPrice).isEqualTo(tokenAPerTokenB)
      ) {
        setPriceChangingOnModal(true);
      }
    }
  };

  useEffect(() => {
    if (supplyAmountTokenA && supplyAmountTokenB) {
      setTokenBPerTokenA(
        BigNumber(supplyAmountTokenB)
          .div(BigNumber(supplyAmountTokenA))
          .toFixed(8)
          .toString()
      );

      setTokenAPerTokenB(
        BigNumber(supplyAmountTokenA)
          .div(BigNumber(supplyAmountTokenB))
          .toFixed(8)
          .toString()
      );
    } else {
      setTokenBPerTokenA(BigNumber(0).toFixed(8).toString());
      setTokenAPerTokenB(BigNumber(0).toFixed(8).toString());
    }
  }, [supplyAmountTokenA, supplyAmountTokenB]);

  const amountChanged = async (inputToken: string, amount: string = "") => {
    if (!isTokensSelected) return;

    try {
      setFirstInputToken(inputToken);

      let tradeRoute;

      if (tokenA && tokenB) {
        tradeRoute = await getRoute(
          tokenA,
          tokenB,
          parseFloat(toPrecision(supplyAmountTokenA))
        );
        if (!tradeRoute) {
          setIsCallSmartContractError(true);
          return;
        }
        const { minimumAmountOut } = await getEstimationAmount(
          parseFloat(amount),
          inputToken,
          tradeRoute
        );
        let supplyUsedA;
        let supplyUsedB;

        if (inputToken === "tokenA") {
          setSupplyAmountTokenB(minimumAmountOut);
          supplyUsedA = amount;
          supplyUsedB = minimumAmountOut;
        } else if (inputToken === "tokenB") {
          setSupplyAmountTokenA(minimumAmountOut);
          supplyUsedA = minimumAmountOut;
          supplyUsedB = amount;
        }

        updateTradeInformation(supplyUsedA || "0.00", supplyUsedB || "0.00");

        setIsCallSmartContractError(false);
      } else {
        setIsCallSmartContractError(true);
      }
    } catch (error) {
      console.error("amountChanged", error);
      setIsCallSmartContractError(true);
    }
  };

  const updateAmountTokenA = (supplyAmount: string | null) => {
    if (supplyAmountTokenA === supplyAmount) return;
    clearInputAmount();

    handleSubmitButtonState();

    if (!supplyAmount || isNaN(parseFloat(supplyAmount))) return;
    setMinMaxTradingText("Min received");
    setSupplyAmountTokenA(supplyAmount);
    if (!parseFloat(supplyAmount)) return;
    amountChanged("tokenA", supplyAmount);
  };

  const updateAmountTokenB = (supplyAmount: string | null) => {
    if (supplyAmountTokenB === supplyAmount) return;
    clearInputAmount();

    handleSubmitButtonState();

    if (!supplyAmount || isNaN(parseFloat(supplyAmount))) return;
    setMinMaxTradingText("Max sold");
    setSupplyAmountTokenB(supplyAmount);
    if (!parseFloat(supplyAmount)) return;
    amountChanged("tokenB", supplyAmount);
  };

  const clearSwapInfo = () => {
    setTradingFee("0.00");
    setPriceImpact("0.00");
    setRouteMap([]);
    setRouteMapText("");
  };

  const swapToken = () => {
    const tempToken = tokenA;

    setTokenA(tokenB);
    setTokenB(tempToken);

    if (firstInputToken === "tokenA") {
      setSupplyAmountTokenA(supplyAmountTokenB);
      setSupplyAmountTokenB("");
    } else {
      setSupplyAmountTokenB(supplyAmountTokenA);
      setSupplyAmountTokenA("");
    }
    if (minMaxTradingText === "Min received") {
      setMinMaxTradingText("Max sold");
    } else {
      setMinMaxTradingText("Min received");
    }
    clearSwapInfo();
  };

  const getDurationLpType = () => {
    return lpConfigSelected().lpType === "DMM"
      ? durationTime.LONG_TIME
      : durationTime.MIDDLE_TIME;
  };

  const _approveTokenToSpenderForSwap = (
    tokenSymbol: string,
    walletAddress: string,
    spenderAddress: string
  ) => {
    setIsApprovePending(true);
    approveTokenToSpender(tokenSymbol, walletAddress, spenderAddress, {
      onTransactionHash: (txHash) => {
        setLoading(2);
        const payload: NotificationStore = {
          status: "processing",
          txHash,
          duration: getDurationLpType(),
        };
        setNotify(payload);
      },
      onReceipt: (result) => {
        handleSubmitButtonState();
        const payload: NotificationStore = {
          status: "success",
          txHash: result.transactionHash,
          duration: getDurationLpType(),
        };
        setNotify(payload);
        resetLoading();
      },
      onError: (error) => {
        setIsApprovePending(false);
        const payload: NotificationStore = {
          status: "failed",
          txHash: error ? error.transactionHash : null,
        };
        setSubmitButtonLoading(false);

        setSubmitButtonState("approveTokenA");
        setNotify(payload);
        handleSubmitButtonState();
      },
    });
  };

  const getPriceImpactClass = () => {
    if (parseFloat(priceImpact) > 15) return "text-red-1";
    else if (parseFloat(priceImpact) > 5) return "text-yellow-1";
    return "text-white";
  };

  const submitAction = () => {
    if (submitButtonState === "approveTokenA") {
      const routerAddress = config.autoRouterContractAddress;

      return _approveTokenToSpenderForSwap(
        tokenA?.address ?? "",
        // `${tokenA?.symbol}_TOKEN`,
        walletApi.account.current ?? "",
        routerAddress ?? ""
      );
    }

    if (submitButtonState === "swap" || submitButtonState === "swap anyway") {
      // $refs.confirm.open()
      setIsSwapConfirmModalOpen(true);
      setPriceImpactColorClass(getPriceImpactClass());
    }
  };

  const isInvalid = useMemo(() => {
    if (submitButtonState === "approveTokenA") {
      return false;
    } else {
      return (
        !isTokensSelected ||
        submitButtonLoading ||
        [
          "enterAmount",
          "insufficientBalanceTokenA",
          "price impact too high",
        ].includes(submitButtonState) ||
        submitButtonText === "Processing..." ||
        isApprovePending
      );
    }
  }, [submitButtonState, submitButtonLoading, isTokensSelected]);

  // const getWalletAddress = () => {
  //   return walletApi.account.current;
  // };

  const getBalances = async () => {
    try {
      if (tokenA && tokenA?.symbol && tokenA?.symbol !== "" && ethereum?.web3) {
        // tokenA.balance = await tokenBalanceOf(tokenA.symbol)
        const balance = await tokenBalanceOf(tokenA.symbol);
        if (balance === undefined || balance === null) return;
        setDisplayBalanceTokenA(
          ethereum?.web3.utils.fromWei(balance, "ether").toLocaleString()
        );
      }

      if (tokenB && tokenB?.symbol && tokenB?.symbol !== "" && ethereum?.web3) {
        // tokenB.balance = await tokenBalanceOf(tokenB.symbol)
        const balance = await tokenBalanceOf(tokenB.symbol);
        setDisplayBalanceTokenB(
          ethereum?.web3.utils.fromWei(balance, "ether").toLocaleString()
        );
      }
    } catch (error) {
      console.error("getBalances", error);
    }
  };

  const acceptPriceChange = () => {
    setPriceChangingOnModal(false);
    setIsSwapConfirmModalOpen(false);
  };

  const start = () => {
    if (!ethereum.contracts || !ethereum.web3 || !walletApi.account.current) {
      setTimeout(() => {
        start();
      }, 2000);
      return;
    }
    getBalances();
    handleSubmitButtonState();
  };

  const _handleCommitTransaction = (txHash: string) => {
    setLoading(3);
    setIsSwapConfirmModalOpen(false);
    setTxHash(txHash);
    const payload = {
      status: "processing",
      txHash,
      duration: getDurationLpType(),
    };
    setNotify(payload);
  };

  const _handleTransactionSuccess = ({
    transactionHash,
    status,
  }: {
    transactionHash: string;
    status: boolean;
  }) => {
    console.log("_handleTransactionSuccess", transactionHash, status);
    const payload = {
      status: status ? "success" : "failed",
      txHash: transactionHash,
      duration: getDurationLpType(),
    };
    setNotify(payload);
    setIsSwapConfirmModalOpen(false);

    // wallet.updateBalance();

    if (status === true) {
      clearInputAmount();
      start();
    }

    setTimeout(() => {
      resetLoading();
    }, 0);
  };

  const _handleTransactionFailed = async () => {
    const errorReason = await getErrorReason(txHash);
    const payload = {
      status: "failed",
      errorReason,
      txHash: txHash,
      duration: getDurationLpType(),
    };
    setNotify(payload);

    setTimeout(() => {
      setTxHash("");
      resetLoading();
      handleSubmitButtonState();
    }, 0);
  };

  const _swapExactAnyForAny = async (
    abiName: string,
    sourceAndTargetAddress: string[],
    amountIn: string,
    amountOutMin: string,
    poolTypes: string,
    dmmPoolAddresses: string[]
  ) => {
    if (!ethereum || !ethereum?.web3) return;

    switch (abiName) {
      case "swapExactNativeForTokens":
        await swapExactNativeForTokens(
          ethereum?.web3.utils.toWei(amountIn, "ether"),
          ethereum?.web3.utils.toWei(amountOutMin, "ether"),
          sourceAndTargetAddress,
          walletApi.account.current ?? "",
          addMinute(parseInt(trxDeadline)),
          poolTypes,
          dmmPoolAddresses,
          {
            onTransactionHash: _handleCommitTransaction,
            onReceipt: _handleTransactionSuccess,
            onError: _handleTransactionFailed,
          }
        );
        break;
      case "swapExactTokensForNative":
        await swapExactTokensForNative(
          // ethers.parseUnits(amountIn, "ether"),
          // ethers.parseUnits(amountOutMin, "ether"),
          ethereum?.web3.utils.toWei(amountIn, "ether"),
          ethereum?.web3.utils.toWei(amountOutMin, "ether"),
          sourceAndTargetAddress,
          walletApi.account.current ?? "",
          addMinute(parseInt(trxDeadline)),
          poolTypes,
          dmmPoolAddresses,
          {
            onTransactionHash: _handleCommitTransaction,
            onReceipt: _handleTransactionSuccess,
            onError: _handleTransactionFailed,
          }
        );
        break;
      case "swapExactTokensForTokens":
        await swapExactTokensForTokens(
          ethereum?.web3.utils.toWei(amountIn, "ether"),
          ethereum?.web3.utils.toWei(amountOutMin, "ether"),
          sourceAndTargetAddress,
          walletApi.account.current ?? "",
          addMinute(parseInt(trxDeadline)),
          poolTypes,
          dmmPoolAddresses,
          {
            onTransactionHash: _handleCommitTransaction,
            onReceipt: _handleTransactionSuccess,
            onError: _handleTransactionFailed,
          }
        );
        break;
    }
  };

  const _swapAnyForExactAny = async (
    abiName: string,
    sourceAndTargetAddress: string[],
    amountInMax: string,
    amountOut: string,
    poolTypes: string,
    dmmPoolAddresses: string[]
  ) => {
    if (!ethereum || !ethereum.web3) return;
    switch (abiName) {
      case "swapNativeForExactTokens":
        await swapNativeForExactTokens(
          ethereum.web3?.utils.toWei(amountOut, "ether"),
          ethereum.web3?.utils.toWei(amountInMax, "ether"),
          sourceAndTargetAddress,
          walletApi.account.current ?? "",
          addMinute(parseInt(trxDeadline)),
          poolTypes,
          dmmPoolAddresses,
          {
            onTransactionHash: _handleCommitTransaction,
            onReceipt: _handleTransactionSuccess,
            onError: _handleTransactionFailed,
          }
        );
        break;
      case "swapTokensForExactNative":
        await swapTokensForExactNative(
          ethereum.web3?.utils.toWei(amountOut, "ether"),
          ethereum.web3?.utils.toWei(amountInMax, "ether"),
          sourceAndTargetAddress,
          walletApi.account.current ?? "",
          addMinute(parseInt(trxDeadline)),
          poolTypes,
          dmmPoolAddresses,
          {
            onTransactionHash: _handleCommitTransaction,
            onReceipt: _handleTransactionSuccess,
            onError: _handleTransactionFailed,
          }
        );
        break;
      case "swapTokensForExactTokens":
        await swapTokensForExactTokens(
          ethereum.web3?.utils.toWei(amountOut, "ether"),
          ethereum.web3?.utils.toWei(amountInMax, "ether"),
          sourceAndTargetAddress,
          walletApi.account.current ?? "",
          addMinute(parseInt(trxDeadline)),
          poolTypes,
          dmmPoolAddresses,
          {
            onTransactionHash: _handleCommitTransaction,
            onReceipt: _handleTransactionSuccess,
            onError: _handleTransactionFailed,
          }
        );
        break;
    }
  };

  const handleClickConfirm = async () => {
    setLoading(4);

    if (!tokenA || !tokenB) return;
    const { routePathArray } = await findRoute(
      tokenA,
      tokenB,
      parseFloat(toPrecision(supplyAmountTokenA)),
      parseFloat(toPrecision(supplyAmountTokenB)),
      firstInputToken
    );

    let poolTypes: any = getPairData(routePathArray, { onlyType: true });

    const dmmPoolAddresses = getDMMPoolAddresses(routePathArray);

    if (firstInputToken === "tokenA") {
      const inputAmount = toPrecision(supplyAmountTokenA);
      const minReceiveAmount = toPrecision(calculateMinReceive);
      let methodName = "";

      if (
        config.token.BNB_TOKEN.address?.toUpperCase() ===
        tokenA.address?.toUpperCase()
      ) {
        methodName = "swapExactNativeForTokens";
      } else if (
        config.token.BNB_TOKEN.address?.toUpperCase() ===
        tokenB.address?.toUpperCase()
      ) {
        methodName = "swapExactTokensForNative";
      } else {
        methodName = "swapExactTokensForTokens";
      }

      try {
        await checkAllowanceBeforeSwap(
          `${tokenA.symbol}_TOKEN`,
          config.autoRouterContractAddress ?? "",
          walletApi.account.current ?? "",
          inputAmount
        );

        await _swapExactAnyForAny(
          methodName,
          routePathArray,
          inputAmount,
          minReceiveAmount,
          poolTypes,
          dmmPoolAddresses
        );
      } catch (error) {
        console.log("Error happen : ", error);
      }
    } else if (firstInputToken === "tokenB") {
      const inputAmount = toPrecision(supplyAmountTokenB);
      const maximumSoldAmount = toPrecision(minMaxTradingAmount);
      let methodName = null;

      if (
        config.token.BNB_TOKEN.address?.toUpperCase() ===
        tokenA.address?.toUpperCase()
      ) {
        methodName = "swapNativeForExactTokens";
      } else if (
        config.token.BNB_TOKEN.address?.toUpperCase() ===
        tokenB.address?.toUpperCase()
      ) {
        methodName = "swapTokensForExactNative";
      } else {
        methodName = "swapTokensForExactTokens";
      }

      try {
        await checkAllowanceBeforeSwap(
          `${tokenA.symbol}_TOKEN`,
          config.autoRouterContractAddress ?? "",
          walletApi.account.current ?? "",
          maximumSoldAmount
        );
        await _swapAnyForExactAny(
          methodName,
          routePathArray,
          maximumSoldAmount,
          inputAmount,
          poolTypes,
          dmmPoolAddresses
        );
      } catch (error) {
        console.log("Error happen : ", error);
      }
    }
  };

  return (
    <div className="md:container p-0">
      <div className="xl:ml-32 md:flex md:flex-col xl:max-w-[867px]">
        <PageHeader
          title="Exchange"
          subTitle="Trade tokens in an instant"
          isRight={true}
        >
          <div
            className="flex flex-row justify-end w-full items-center cursor-pointer"
            onClick={openSetting}
          >
            <img
              className="flex h-6"
              src={require("/src/assets/icons/swap/settings.png")}
              alt="settings"
            />
            <div className="hidden md:inline ml-2">Setting</div>
          </div>
        </PageHeader>

        <div className="flex flex-col items-center">
          <div className="px-4 md:px-0 w-full">
            <div className="flex flex-col md:flex-row items-center justify-center relative">
              <AmountInput
                className="w-full md:w-[400px] 2xl:w-full col-span-5"
                bgColor="input-background"
                testId="swap_from"
                title="From"
                tokenList={tokenListA}
                token={tokenA}
                amount={supplyAmountTokenA}
                balance={displayBalanceTokenA} // Replace with actual balance
                isInsufficient={isInsufficientBalance}
                isSelectToken={true}
                isShowAddTokenWallet={true}
                onUpdateAmount={updateAmountTokenA}
                onSelectToken={(token: Token) => {
                  setTokenA(token);
                  setSupplyAmountTokenA("");
                }}
                disabled={isApprovePending}
              />
              <div
                className="relative cursor-pointer transform rotate-90 md:rotate-0 m-2 md:pt-6 mx-[17px] col-span-1"
                data-testid="swap_switch_img"
                onClick={swapToken}
              >
                <img
                  className="min-w-[32px] h-8 w-full"
                  src={
                    require("/src/assets/icons/swap/ico_exchange.svg").default
                  }
                  alt="exchange"
                />
              </div>
              <AmountInput
                className="w-full md:w-[400px] 2xl:w-full col-span-5"
                bgColor="input-background"
                testId="swap_to"
                title="To"
                hideMaxButton={true}
                tokenList={tokenListB}
                token={tokenB}
                amount={supplyAmountTokenB}
                balance={displayBalanceTokenB} // Replace with actual balance
                isInsufficient={isInsufficientBalance}
                isSelectToken={true}
                isShowAddTokenWallet={true}
                onUpdateAmount={updateAmountTokenB}
                onSelectToken={(token: Token) => {
                  setTokenB(token);
                  setSupplyAmountTokenA("");
                }}
                disabled={isApprovePending}
              />
            </div>
          </div>

          {isTokensSelected && isInputAmount && (
            <div className="w-full flex flex-col md:flex-row mx-auto mt-10 mb-16 md:mb-8 px-4 md:pl-0 md:pr-0">
              <Table
                position="top"
                className="flex-1 md:mr-2 text-xs border-b-0"
              >
                {/* First Row: Min/Max Trading */}
                <TableRow
                  leftSlot={
                    <div
                      className="flex items-center text-velo-label"
                      data-testid="swap_min_max_trading_txt"
                    >
                      <ToolTip
                        className="mr-1"
                        iconSize="base"
                        iconClass="leading-none text-velo-primary"
                        message="Your transaction will revert if there is a large, unfavorable price movement before it is confirmed."
                      />
                      {minMaxTradingText}
                    </div>
                  }
                  rightSlot={
                    <div data-testid="swap_min_max_trading_value">
                      <span className="text-sm">
                        {minMaxTradingAmount + " "}
                      </span>
                      <span className="text-sm">
                        {minMaxTradingText === "Min received"
                          ? tokenB?.name
                          : tokenA?.name}
                      </span>
                    </div>
                  }
                />

                {/* Second Row: Trading Fee */}
                <TableRow
                  className="mt-2"
                  leftSlot={
                    <div
                      className="flex items-center text-velo-label"
                      data-testid="swap_trading_fee_txt"
                    >
                      <ToolTip
                        className="mr-1"
                        iconSize="base"
                        iconClass="leading-none text-base text-velo-primary"
                        message={
                          "Your transaction will revert if there is a large, unfavorable price movement before it is confirmed."
                        }
                      />
                      Trading fee
                    </div>
                  }
                  rightSlot={
                    <div data-testid="swap_trading_fee_value">
                      <span className="text-sm">{tradingFee}</span>
                      <span className="text-sm">{tokenA?.name}</span>
                    </div>
                  }
                />

                {/* Third Row: Price Impact */}
                <TableRow
                  className="mt-2"
                  leftSlot={
                    <div
                      className="flex items-center text-velo-label"
                      data-testid="swap_price_impact_txt"
                    >
                      <ToolTip
                        className="mr-1"
                        iconSize="base"
                        iconClass="leading-none text-sm text-velo-primary"
                        message="The difference between the market price and estimated price due to trade size."
                      />
                      Price impact
                    </div>
                  }
                  rightSlot={
                    <div
                      className={`${getPriceImpactClass()} text-sm`}
                      data-testid="swap_price_impact_value"
                    >
                      {(parseFloat(priceImpact) > 99.99
                        ? 99.99
                        : parseFloat(priceImpact)
                      ).toFixed(2)}
                      %
                    </div>
                  }
                />

                {/* Fourth Row: Slippage Tolerance */}
                <TableRow
                  className="mt-2"
                  leftSlot={
                    <div
                      className="ml-[18px] text-velo-label"
                      data-testid="swap_slippage_txt"
                    >
                      Slippage tolerance
                    </div>
                  }
                  rightSlot={
                    <div className="text-sm" data-testid="swap_slippage_value">
                      {parseFloat(slippage).toFixed(2)}%
                    </div>
                  }
                />
              </Table>

              <Table position="bottom" className="flex-1 text-xs border-t-0">
                {/* First Row: Rate */}
                <TableRow
                  leftSlot={
                    <div
                      className="flex items-center text-velo-label"
                      data-testid="swap_rate_txt"
                    >
                      <img
                        src={tokenA?.image}
                        alt={tokenA?.name}
                        className="w-4 h-4 mr-1"
                      />
                      <div>
                        Rate
                        <span className="font-semibold text-velo-primary">
                          {" "}
                          1 {tokenA?.name}{" "}
                        </span>
                        per
                      </div>
                    </div>
                  }
                  rightSlot={
                    <div data-testid="swap_rate_value">
                      <span className="text-sm">{tokenBPerTokenA + " "}</span>
                      <span className="text-sm">{tokenB?.name}</span>
                    </div>
                  }
                />

                {/* Second Row: Inverse Rate */}
                <TableRow
                  className="mt-2"
                  leftSlot={
                    <div
                      className="flex items-center text-velo-label"
                      data-testid="swap_inverse_rate_txt"
                    >
                      <img
                        src={tokenB?.image}
                        alt={tokenB?.name}
                        className="w-4 h-4 mr-1"
                      />
                      <div>
                        Inverse rate
                        <span className="font-semibold text-velo-primary">
                          {" "}
                          1 {tokenB?.name}{" "}
                        </span>
                        per
                      </div>
                    </div>
                  }
                  rightSlot={
                    <div data-testid="swap_inverse_rate_value">
                      <span className="text-sm">{tokenAPerTokenB + " "}</span>
                      <span className="text-sm">{tokenA?.name}</span>
                    </div>
                  }
                />

                {/* Third Row: Amplifier (if exists) */}

                {amplifier && (
                  <TableRow
                    className="mt-2"
                    leftSlot={
                      <div className="flex items-center text-velo-label">
                        <a
                          // href={getAmplifierDocument}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="p-0 h-4 text-velo-primary flex items-center"
                          data-testid="swap_amplifier_btn"
                        >
                          {/* <Icon className="text-base mr-1 leading-none">launch</Icon> */}
                          <div data-testid="swap_amplifier_txt">Amplifier</div>
                        </a>
                      </div>
                    }
                    rightSlot={
                      <div
                        className="text-sm"
                        data-testid="swap_amplifier_value"
                      >
                        {amplifier}
                      </div>
                    }
                  />
                )}

                {/* Fourth Row: Auto Route (if exists) */}
                {isAutoRoute() && (
                  <TableRow
                    className="mt-2"
                    leftSlot={
                      <div className="flex items-center text-velo-label">
                        <a
                          // href={getAutoRouteDocument}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="p-0 h-4 text-velo-primary flex items-center"
                          data-testid="swap_route_btn"
                        >
                          {/* <Icon className="text-base mr-1 leading-none">launch</Icon> */}
                          Route
                        </a>
                      </div>
                    }
                    rightSlot={
                      <div
                        className="flex justify-end text-sm"
                        data-testid="swap_route_value"
                      >
                        {routeMap &&
                          routeMap.map((item, index) => (
                            <div
                              key={index}
                              className="flex items-center"
                              data-testid={`swap_route_${item}_value`}
                            >
                              {item}
                              {index + 1 < routeMap.length && (
                                <Icon className="text-base font-bold text-velo-primary">
                                  keyboard_arrow_right
                                </Icon>
                              )}
                            </div>
                          ))}
                      </div>
                    }
                  />
                )}

                {/* Submit Button */}
                {/* <Button
                  className="w-full mt-2 mb-2 uppercase md:w-72 md:hidden"
                  testId="swap_confirm_btn"
                  disabled={isInvalid}
                  onClick={submitAction}
                >
                  {submitButtonText}
                </Button> */}
              </Table>
            </div>
          )}
          <ButtonWrapper>
            <Button
              className="w-full mt-6 mb-4 uppercase md:w-72"
              disabled={isInvalid}
              onClick={submitAction}
            >
              {submitButtonText}
            </Button>
          </ButtonWrapper>
        </div>
      </div>
      <SettingModal
        openModal={openSetting}
        closeModal={closeSetting}
        isModalOpen={isSettingModalOpen}
      />
      <SwapConfirmModal
        isSwapConfirmModalOpen={isSwapConfirmModalOpen}
        tokenA={tokenA}
        tokenB={tokenB}
        amountA={toPrecision(supplyAmountTokenA)}
        amountB={toPrecision(supplyAmountTokenB)}
        tokenAPerTokenB={tokenAPerTokenB}
        tokenBPerTokenA={tokenBPerTokenA}
        minMaxTradingAmount={minMaxTradingAmount}
        minMaxTradingText={minMaxTradingText}
        priceImpact={parseFloat(priceImpact) || 0.0}
        priceImpactColorClass={priceImpactColorClass}
        fee={tradingFee}
        tooltipFee={tooltipTradingFee}
        amplifier={amplifier}
        liquidityPool={lpConfigSelected}
        isProcessing={submitButtonLoading}
        isPriceChanging={priceChangingOnModal}
        isDmmType={isDMMType()}
        slippageTolerance={parseFloat(slippage).toFixed(2)}
        routeMap={routeMap}
        isAutoRoute={isAutoRoute()}
        onConfirm={handleClickConfirm}
        onAcceptPriceChange={acceptPriceChange}
      />
      {/*<SwapConfirmModal
        ref="confirm"
        tokenA={tokenA}
        tokenB={tokenB}
        amountA={supplyAmountTokenA}
        amountB={supplyAmountTokenB}
        minMaxTradingAmount={minMaxTradingAmount}
        priceImpact={priceImpact}
        fee={tradingFee}
        // Add other props
        onConfirm={submitAction}
      /> */}
    </div>
  );
};

export default Swap;

import { ChainDetailType } from "./interfaces/environment";

const buildEnv = process.env.REACT_APP_BUILD_ENV || "";
const operationUserId = process.env.REACT_APP_OPERATION_ID || "";

const allowLogin = (env: string) => {
  switch (env.toLowerCase()) {
    case "develop":
      return [56789, 97, 89, 102, 1001];
    case "master":
      return [56789, 56, 88, 101, 1000];
    default:
      return [];
  }
};

const allowDeposit = (env: string) => {
  switch (env.toLowerCase()) {
    case "develop":
      return [
        {
          chainId: 97,
          tokens: [
            {
              token: "USDV",
              address: "0x1c9f169B0268724240C4C48945287065810c7141",
              depositTokenId: "USDV",
              chainTokenId: "USDV-BSC",
            },
            {
              token: "USDT",
              address: "0xFf535c82472322E0ccb9Af4E92b1B5dc01a9a4e9",
              depositTokenId: "USDV",
              chainTokenId: "USDT-BSC",
            },
            {
              token: "VELO",
              address: "0xd190454201DC1EeCb3b75b4B540c03bc2bE1C80A",
              depositTokenId: "VELO",
              chainTokenId: "VELO-BSC",
            },
            {
              token: "PLG",
              address: "0x4f9D51085f47d7d64b51094813BDe15fff07FD48",
              depositTokenId: "PLG",
              chainTokenId: "PLG-BSC",
            },
          ],
        },
        {
          chainId: 56789,
          tokens: [
            {
              token: "USDG",
              address: "0x81b76ceb082f6e8376a254ab8bfc7d77ec773d4e",
              depositTokenId: "USDG",
              chainTokenId: "USDG",
            },
            // {
            //   token: "USDV",
            //   address: "0x9cC88479229f98319ABb27D6783D77D5AbF65568",
            //   depositTokenId: "USDV",
            // },
          ],
        },
        {
          chainId: 89,
          tokens: [
            {
              token: "VIC",
              address: "",
              depositTokenId: "VIC",
              chainTokenId: "VIC",
            },
            {
              token: "BSV",
              address: "0x32ac435454EeBF4105F6ef6896e13dc34197dfBd",
              depositTokenId: "BSV",
              chainTokenId: "BSV-VRC25",
            },
          ],
        },
        {
          chainId: 102,
          tokens: [
            {
              token: "SOL",
              address: "11111111111111111111111111111111",
              depositTokenId: "SOL",
              chainTokenId: "SOL",
            },
            {
              token: "USDC",
              address: "Gh9ZwEmdLJ8DscKNTkTqPbNwLNNBjuSzaG9Vp2KGtKJr",
              depositTokenId: "USDC",
              chainTokenId: "USDC-SOL-DEV",
            },
          ],
        },
        {
          chainId: 1001,
          tokens: [
            {
              token: "TRX",
              address: "trx",
              depositTokenId: "TRX",
              chainTokenId: "TRX",
            },
            {
              token: "BTT",
              address: "TVSvjZdyDSNocHm7dP3jvCmMNsCnMTPa5W",
              depositTokenId: "BTT",
              chainTokenId: "BTT-TRC20",
            },
            {
              token: "USDT",
              address: "TXLAQ63Xg1NAzckPwKHvzw7CSEmLMEqcdj",
              depositTokenId: "USDV",
              chainTokenId: "USDT-TRC20",
            },
          ],
        },
      ];
    case "master":
      return [
        {
          chainId: 56,
          tokens: [
            {
              token: "USDV",
              address: "0x45204392806768e62211736D2AbFaa41E59f1113",
              depositTokenId: "USDV",
              chainTokenId: "USDV-BSC",
            },
            {
              token: "USDT",
              address: "0x55d398326f99059fF775485246999027B3197955",
              depositTokenId: "USDV",
              chainTokenId: "USDT-BSC",
            },
            {
              token: "VELO",
              address: "0xf486ad071f3bee968384d2e39e2d8af0fcf6fd46",
              depositTokenId: "VELO",
              chainTokenId: "VELO-BSC",
            },
          ],
        },
        {
          chainId: 56789,
          tokens: [
            // {
            //   token: "USDG",
            //   address: "0x81b76ceb082f6e8376a254ab8bfc7d77ec773d4e",
            //   depositTokenId: "USDG",
            // },
            {
              token: "USDV",
              address: "0x9cC88479229f98319ABb27D6783D77D5AbF65568",
              depositTokenId: "USDV",
              chainTokenId: "USDV",
            },
          ],
        },
      ];
    default:
      return [];
  }
};

const allowWithdraw = (env: string) => {
  switch (env.toLowerCase()) {
    case "develop":
      return [
        {
          chainId: 97,
          tokens: [
            {
              token: "USDV",
              address: "0x1c9f169B0268724240C4C48945287065810c7141",
              withDrawTokenId: "USDV",
              chainTokenId: "USDV-BSC",
            },
            {
              token: "USDT",
              address: "0xFf535c82472322E0ccb9Af4E92b1B5dc01a9a4e9",
              withDrawTokenId: "USDV",
              chainTokenId: "USDT-BSC",
            },
            {
              token: "VELO",
              address: "0xd190454201DC1EeCb3b75b4B540c03bc2bE1C80A",
              withDrawTokenId: "VELO",
              chainTokenId: "VELO-BSC",
            },
            {
              token: "PLG",
              address: "0x4f9D51085f47d7d64b51094813BDe15fff07FD48",
              withDrawTokenId: "PLG",
              chainTokenId: "PLG-BSC",
            },
          ],
        },
        {
          chainId: 56789,
          tokens: [
            {
              token: "USDG",
              address: "0x81b76ceb082f6e8376a254ab8bfc7d77ec773d4e",
              withDrawTokenId: "USDG",
              chainTokenId: "USDG",
            },
            // {
            //   token: "USDV",
            //   address: "0x9cC88479229f98319ABb27D6783D77D5AbF65568",
            //   withDrawTokenId: "USDV",
            //   chainTokenId: "USDV",
            // },
          ],
        },
        {
          chainId: 89,
          tokens: [
            {
              token: "VIC",
              address: "",
              withDrawTokenId: "VIC",
              chainTokenId: "VIC",
            },
            {
              token: "BSV",
              address: "0x32ac435454EeBF4105F6ef6896e13dc34197dfBd",
              withDrawTokenId: "BSV",
              chainTokenId: "BSV-VRC25",
            },
          ],
        },
        {
          chainId: 102,
          tokens: [
            {
              token: "SOL",
              address: "11111111111111111111111111111111",
              withDrawTokenId: "SOL",
              chainTokenId: "SOL",
            },
          ],
        },
        {
          chainId: 1001,
          tokens: [
            {
              token: "TRX",
              address: "trx",
              withDrawTokenId: "TRX",
              chainTokenId: "TRX",
            },
            {
              token: "BTT",
              address: "TVSvjZdyDSNocHm7dP3jvCmMNsCnMTPa5W",
              withDrawTokenId: "BTT",
              chainTokenId: "BTT-TRC20",
            },
            {
              token: "USDT",
              address: "TXLAQ63Xg1NAzckPwKHvzw7CSEmLMEqcdj",
              withDrawTokenId: "USDT",
              chainTokenId: "USDT-TRC20",
            },
          ],
        },
      ];
    case "master":
      return [
        {
          chainId: 56,
          tokens: [
            {
              token: "USDV",
              address: "0x45204392806768e62211736D2AbFaa41E59f1113",
              withDrawTokenId: "USDV",
              chainTokenId: "USDV-BSC",
            },
            {
              token: "USDT",
              address: "0x55d398326f99059fF775485246999027B3197955",
              withDrawTokenId: "USDV",
              chainTokenId: "USDT-BSC",
            },
            {
              token: "VELO",
              address: "0xf486ad071f3bee968384d2e39e2d8af0fcf6fd46",
              withDrawTokenId: "VELO",
              chainTokenId: "VELO-BSC",
            },
          ],
        },
        {
          chainId: 56789,
          tokens: [
            // {
            //   token: "USDG",
            //   address: "0x81b76ceb082f6e8376a254ab8bfc7d77ec773d4e",
            //   chainTokenId: "USDG",
            // },
            {
              token: "USDV",
              address: "0x9cC88479229f98319ABb27D6783D77D5AbF65568",
              withDrawTokenId: "USDV",
              chainTokenId: "USDV",
            },
          ],
        },
      ];
    default:
      return [];
  }
};

class Environment {
  private env = {
    // allowToLoginChainIds: process.env.REACT_APP_ALLOW_TO_LOGIN_CHAIN_IDS,
    allowToLoginChainIds: `[${allowLogin(buildEnv)}]`,
    unsupportedWalletName: [
      {
        chainId: 56789,
        walletNames: ["Phantom", "TronLink"],
      },
      {
        chainId: 97,
        walletNames: ["Phantom", "TronLink"],
      },
      {
        chainId: 89,
        walletNames: ["Phantom", "TronLink"],
      },
      {
        chainId: 88,
        walletNames: ["Phantom", "TronLink"],
      },
      {
        chainId: 56,
        walletNames: ["Phantom", "TronLink"],
      },
      {
        chainId: 101,
        walletNames: ["MetaMask", "TronLink"],
      },
      {
        chainId: 102,
        walletNames: ["MetaMask", "TronLink"],
      },
      {
        chainId: 1000,
        walletNames: ["MetaMask", "Coin98", "Phantom", "Bitget"],
      },
      {
        chainId: 1001,
        walletNames: ["MetaMask", "Coin98", "Phantom", "Bitget"],
      },
    ],
    chainGroups: [
      {
        chainGroupName: "EVM",
        chainIds: [56, 97, 56789, 89, 88],
      },
      {
        chainGroupName: "SOL",
        chainIds: [101, 102],
      },
      {
        chainGroupName: "TRX",
        chainIds: [1000, 1001],
      },
    ],
    chainDetails: [
      {
        chainId: 5,
        name: "Goerli test network",
        displayName: "Goerli",
        currencyDecimal: 18,
        currencyName: "GoerliETH",
        currencySymbol: "GoerliETH",
        explorerUrls: ["https://goerli.etherscan.io"],
        rpcUrls: ["https://goerli.infura.io/v3/"],
        chainType: "ERC20",
      },
      {
        chainId: 97,
        name: "Bsc Testnet",
        displayName: "Bsc",
        currencyDecimal: 18,
        currencyName: "BNB",
        currencySymbol: "BNB",
        explorerUrls: ["https://testnet.bscscan.com"],
        rpcUrls: ["https://data-seed-prebsc-2-s2.binance.org:8545"],
        chainType: "BEP20",
      },
      {
        chainId: 56789,
        name: "Nova Chain",
        displayName: "Nova",
        currencyDecimal: 18,
        currencyName: "NOVA",
        currencySymbol: "NOVA",
        explorerUrls: ["https://novascan.velo.org"],
        rpcUrls: ["https://nova.velo.org"],
        chainType: "VELO",
      },
      {
        chainId: 89,
        name: "Viction Testnet",
        displayName: "Viction",
        currencyDecimal: 18,
        currencyName: "VIC",
        currencySymbol: "VIC",
        explorerUrls: ["https://testnet.vicscan.xyz"],
        rpcUrls: ["https://rpc-testnet.viction.xyz"],
        chainType: "VRC25",
      },
      {
        chainId: 88,
        name: "Viction Mainnet",
        displayName: "Viction",
        currencyDecimal: 18,
        currencyName: "VIC",
        currencySymbol: "VIC",
        explorerUrls: ["https://vicscan.xyz"],
        rpcUrls: ["https://rpc.viction.xyz"],
        chainType: "VRC25",
      },
      {
        chainId: 56,
        name: "Bsc Mainnet",
        displayName: "Bsc",
        currencyDecimal: 18,
        currencyName: "BNB",
        currencySymbol: "BNB",
        explorerUrls: ["https://bscscan.com"],
        rpcUrls: ["https://bsc-dataseed.binance.org"],
        chainType: "BEP20",
      },
      {
        chainId: 101, // Solana's mainnet doesn't have a traditional chainId like EVM-based chains, but 101 can be used as a placeholder.
        name: "Solana Mainnet Beta",
        displayName: "Solana",
        currencyDecimal: 9,
        currencyName: "SOL",
        currencySymbol: "SOL",
        explorerUrls: ["https://explorer.solana.com"],
        rpcUrls: ["https://api.mainnet-beta.solana.com"],
        chainType: "SOL",
      },
      {
        chainId: 102, // Similarly, using 102 as a placeholder for Devnet.
        name: "Solana Devnet",
        displayName: "Solana",
        currencyDecimal: 9,
        currencyName: "SOL",
        currencySymbol: "SOL",
        explorerUrls: ["https://explorer.solana.com?cluster=devnet"],
        rpcUrls: ["https://api.devnet.solana.com"],
        chainType: "SOL",
      },
      {
        chainId: 1000,
        name: "Tron Mainnet",
        displayName: "Tron",
        currencyDecimal: 9,
        currencyName: "TRX",
        currencySymbol: "TRX",
        explorerUrls: ["https://tronscan.org"],
        rpcUrls: ["https://api.trongrid.io"],
        chainType: "TRC20",
      },
      {
        chainId: 1001,
        name: "Tron Devnet",
        displayName: "Tron",
        currencyDecimal: 9,
        currencyName: "TRX",
        currencySymbol: "TRX",
        explorerUrls: ["https://nile.tronscan.org"],
        rpcUrls: ["https://nile.trongrid.io"],
        chainType: "TRC20",
      },
    ],
    multiChainTokens: allowDeposit(buildEnv),
    withdrawTokens: allowWithdraw(buildEnv),
    operationUserId: operationUserId,
    wssQuoteEndpoint: wssQuoteEndpoint(buildEnv),
  };

  get operationUserId() {
    return this.env.operationUserId;
  }

  get multiChainTokens() {
    return this.env.multiChainTokens;
  }

  get chainDetail() {
    const chains = this.env.chainDetails;
    const output: ChainDetailType.Output = {
      chains: chains,
      chain: chains.reduce(
        (prev, curr) => ({ ...prev, [curr.chainId]: curr }),
        {}
      ),
    };
    return output;
  }

  get allowToLoginChains() {
    try {
      if (!this.env.allowToLoginChainIds) return [];

      const allowIds = JSON.parse(
        this.env.allowToLoginChainIds
      ) as Array<number>;

      return allowIds.map((allowId) => this.chainDetail?.chain[allowId]);
    } catch {
      return [];
    }
  }

  get unsupportedWalletName() {
    return this.env.unsupportedWalletName;
  }

  get chainGroups() {
    // return map of chainGroupName and chainIds
    return this.env.chainGroups.reduce((acc, chainGroup) => {
      acc[chainGroup.chainGroupName] = chainGroup.chainIds;
      return acc;
    }, {} as { [key: string]: number[] });
  }

  getChainByChainId(chainId: number) {
    return this.env.chainDetails.find((chain) => chain.chainId === chainId);
  }

  isWalletSupportForChainId(walletName: string, chainId: number) {
    if (chainId === 0) return false;
    return this.env.unsupportedWalletName.some(
      (item) =>
        item.chainId === chainId &&
        item.walletNames.some((name) =>
          name.toLowerCase().includes(walletName.toLowerCase())
        )
    );
  }

  isSwitchChainGroup(oldChainId: number, newChainId: number) {
    const chainGroups = this.env.chainGroups;
    const oldChainGroup = chainGroups.find((group) =>
      group.chainIds.includes(oldChainId)
    );
    const newChainGroup = chainGroups.find((group) =>
      group.chainIds.includes(newChainId)
    );
    return oldChainGroup?.chainGroupName !== newChainGroup?.chainGroupName;
  }

  getChainsByWalletName(walletName: string) {
    const chains = this.allowToLoginChains;
    return chains.filter((chain) => {
      return !this.isWalletSupportForChainId(walletName, chain.chainId);
    });
  }

  getUnsupportedWalletByChainId(chainId: number) {
    return this.env.unsupportedWalletName.find(
      (item) => item.chainId === chainId
    );
  }

  getSetOfAllowToLoginChainId() {
    return new Set(this.allowToLoginChains.map((chain) => chain.chainId));
  }

  getWithdrawTokenByChainId(chainid: number) {
    return (
      this.env.withdrawTokens.find(
        (withdrawDetail) => withdrawDetail.chainId === chainid
      )?.tokens || []
    );
  }

  get wssQuoteEndpoint() {
    return this.env.wssQuoteEndpoint;
  }
}

const wssQuoteEndpoint = (env: string) => {
  switch (env.toLowerCase()) {
    case "develop":
      return "wss://dev-universe.velofinance.io/ws/quote/v1?lang=en-us";
    case "master":
      return "wss://universe.velofinance.io/ws/quote/v1?lang=en-us";
    default:
      return "";
  }
};

const environment = new Environment();

export default environment;

import React from "react";
import {
  formatNumberWith2Digits,
  formatNumberWith4Digits,
} from "../../utils/filter";
import { lpExtra } from "../../routes/pool";
import { useNavigate } from "react-router-dom";
import { Token } from "../../interfaces/token";
import Button from "../button";
import MaterialIcon from "../icon";

interface LiquidityCardLpProps {
  id?: number;
  lpToken: lpExtra;
  isOwn: boolean;
  isProcessing?: boolean;
  onClickRemove: (lpToken: lpExtra) => void;
  onClickAddToken: (lpToken: lpExtra) => void;
}

const LiquidityCardLp: React.FC<LiquidityCardLpProps> = ({
  id,
  lpToken,
  isOwn,
  isProcessing,
  onClickRemove,
  onClickAddToken,
}) => {
  const navigate = useNavigate();

  const add = ({ token0, token1 }: { token0: Token; token1: Token }) => {
    navigate(`/pool/add?tokenA=${token0.code}&tokenB=${token1.code}`);
  };

  // const add = ({ token0, token1 }) => {
  //   console.log({ token0, token1 })
  //   this.$router.push({
  //     path: '/pool/add',
  //     query: { tokenA: token0.symbol, tokenB: token1.symbol },
  //   })
  // }

  return (
    <div className="bg-[#909090] bg-opacity-5 border border-[#19506F] rounded-[2px] p-4 flex flex-col gap-4">
      <div className="flex flex-auto gap-2 items-center">
        <img className="h-8" src={lpToken.image} alt="wallet-icon" />
        <div className="flex-auto flex flex-col">
          <div className="flex flex-col gap-1">
            <div className="flex text-sm font-normal">Pool {lpToken.name}</div>
            <div
              className={`flex text-sm font-normal ${
                isOwn ? "" : "text-velo-disable"
              }`}
            >
              Balance: {formatNumberWith4Digits(lpToken.lpBalance)}
            </div>
          </div>
        </div>
        <div className="flex-auto flex flex-col p-[10px]">
          <div
            className={`flex flex-col items-end gap-1 ${
              isOwn ? "" : "text-velo-disable"
            }`}
          >
            <div className="flex text-sm font-normal">Your Pool Share</div>
            <div className="flex text-sm font-normal">
              {isOwn ? (
                <p>
                  {lpToken.percentOfPoolShare &&
                    formatNumberWith2Digits(+lpToken.percentOfPoolShare)}
                  %
                </p>
              ) : (
                <p>0%</p>
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        className={`flex gap-4 items-center ${
          isOwn ? "" : "text-velo-disable"
        }`}
      >
        <div className="w-full p-2 flex gap-1 items-center text-white">
          <img
            src={lpToken.token0.image}
            className="w-4"
            alt={lpToken.token0.name}
          />
          <p
            className={`text-sm font-normal ${
              isOwn ? "" : "text-velo-disable"
            }`}
          >
            {lpToken.token0.name}
          </p>
          <p>
            {lpToken.token0Amount &&
              formatNumberWith4Digits(+lpToken.token0Amount)}
          </p>
        </div>

        <div className="w-full p-2 flex gap-1 items-center text-white">
          <img
            src={lpToken.token1.image}
            className="w-4"
            alt={lpToken.token1.name}
          />
          <p
            className={`text-sm font-normal ${
              isOwn ? "" : "text-velo-disable"
            }`}
          >
            {lpToken.token1.name}
          </p>
          <p>
            {lpToken.token1Amount &&
              formatNumberWith4Digits(+lpToken.token1Amount)}
          </p>
        </div>
      </div>

      {isOwn ? (
        !lpToken.isExpired ? (
          <div className="flex gap-4 whitespace-nowrap">
            <Button
              className="w-full uppercase border-none transition-all"
              onClick={() => add(lpToken)}
            >
              Supply
            </Button>
            <Button
              className="w-full uppercase transition-all"
              onClick={() => onClickRemove(lpToken)}
            >
              Remove
            </Button>
          </div>
        ) : (
          <div className="flex whitespace-nowrap">
            <Button
              className="w-full justify-center"
              onClick={() => onClickRemove(lpToken)}
            >
              <p className="tracking-normal ml-2.5 text-lg">Remove</p>
            </Button>
          </div>
        )
      ) : (
        <div>
          <Button
            disabled={lpToken.isExpired}
            className="w-full justify-center uppercase"
            onClick={() => add(lpToken)}
          >
            {lpToken.isExpired ? <p>Expired</p> : <p>Supply</p>}
          </Button>
        </div>
      )}

      <div
        className={`flex flex-row items-center cursor-pointer justify-center mb-2 ${
          lpToken.isExpired ? "text-velo-disable" : ""
        }`}
        onClick={() => onClickAddToken(lpToken)}
      >
        <MaterialIcon
          className={`text-sm leading-4 ${
            lpToken.isExpired
              ? "text-velo-disable"
              : "bg-clip-text text-transparent bg-velo-primary-gradient4"
          }`}
        >
          east
        </MaterialIcon>
        <div
          className={`ml-1 text-sm ${
            lpToken.isExpired ? "text-velo-disable" : "text-velo-wisp-pink"
          }`}
        >
          Add LP {lpToken.name} to your wallet
        </div>
      </div>
    </div>
  );
};

export default LiquidityCardLp;

import React, { useEffect } from "react";

interface Props {
  url?: string;
}

const FeedTwitter: React.FC<Props> = ({
  url = process.env.REACT_APP_ANNOUNCEMENT_TWITTER_URL,
}) => {
  useEffect(() => {
    const loadTwitterWidget = () => {
      if (window.twttr) {
        window.twttr.ready((twttr: any) => {
          twttr.widgets.load();
        });
      }
    };

    const script = document.createElement("script");
    script.src = "https://platform.twitter.com/widgets.js";
    script.async = true;
    script.onload = loadTwitterWidget;
    document.body.appendChild(script);
  }, [url]);

  return (
    <div>
      <a
        className="twitter-timeline"
        data-height="350"
        data-theme="dark"
        data-chrome="nofooter noheader noborders transparent"
        href={url}
      >
        Tweets by @yourTwitterHandle
      </a>
    </div>
  );
};

export default FeedTwitter;

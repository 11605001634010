import { useEthereum } from "../contexts/etherruemContext";
import { BlockTimeGenerationResult } from "../interfaces/block";

const useBlockTime = () => {
  const ethereum = useEthereum();

  const calculateBlockTimeGeneration =
    (): Promise<BlockTimeGenerationResult | null> | null => {
      if (!ethereum.web3) return null;
      return new Promise((resolve) => {
        try {
          ethereum.web3?.eth.getBlockNumber().then(async (curBlock: any) => {
            // if (err) return null;
            const curBlockInfo = await ethereum.web3?.eth.getBlock(curBlock);

            resolve({
              blockTimeGeneration: 3,
              currentBlock: curBlock,
              currentBlockTimestamp: curBlockInfo?.timestamp ?? null,
            });
          });
        } catch (e) {
          console.log("Err: calculateBlockTimeGeneration", e);
          resolve({
            blockTimeGeneration: null,
            currentBlock: null,
            currentBlockTimestamp: null,
          });
        }
      });
    };
  return { calculateBlockTimeGeneration };
};

export default useBlockTime;

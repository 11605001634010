import React, {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import Modal from "../modal";
import { formatNumberWith4Digits } from "../../utils/filter";
import { toEther } from "../../utils/token";
import BigNumber from "bignumber.js";
import { PairEarned } from "../../interfaces/token";
import Button from "../button";

// Define the props interface for the component
interface Props {
  //   width: string;
  isLockConfirm: boolean;
}

const LockConfirmModal = forwardRef((props: Props, ref) => {
  const { isLockConfirm } = props;
  const [name, setName] = useState<string>("");
  const [logo, setLogo] = useState<string>();
  const [reward, setReward] = useState<string>("EVRY");
  const [earnedBalance, setEarnedBalance] = useState<number>(0);
  const [earnedToken, setEarnedToken] = useState<number>(0);
  const [resolve, setResolve] = useState<any>(null);
  const [reject, setReject] = useState<any>(null);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  useImperativeHandle(ref, () => ({
    open,
  }));

  const confirm = () => {
    resolve({
      confirmed: true,
    });
    setIsModalOpen(false);
  };

  const close = () => {
    resolve({
      confirmed: false,
    });
    setIsModalOpen(false);
  };

  const open = (pair: PairEarned, rewardBalance?: BigNumber, balance = 0) => {
    // const balanceToSet = balance ? balance : pair?.balanceInPool;
    setResolve(null);
    setReject(null);
    setName(pair.staking.name);
    setLogo(pair.staking.image);
    setEarnedBalance(parseFloat(toEther(BigNumber(balance)).toString()));
    if (rewardBalance)
      setEarnedToken(parseFloat(toEther(rewardBalance).toString()));
    if (pair.reward) setReward(pair.reward);
    // resolve = null;
    // reject = null;
    // name = pair.staking.name;
    // logo = pair.staking.image;
    // earnedBalance = $tokenUtils.toEther(balance).toString();
    // earnedToken = $tokenUtils.toEther(rewardBalance).toString();
    // reward = pair.reward;

    setIsModalOpen(true);

    return new Promise((resolve, reject) => {
      setResolve(() => resolve);
      setReject(() => reject);
    });
  };

  const title = useMemo(() => {
    return `${isLockConfirm ? "Withdraw and Claim" : "Claim Your Reward"}`;
  }, [isLockConfirm]);

  return (
    <Modal
      isShowDialog={isModalOpen}
      onClose={close}
      widthClass="w-[360px]"
      title={title}
      footer={
        <div className="w-full">
          <Button className="w-full uppercase" onClick={confirm}>
            {isLockConfirm
              ? "Confirm withdraw and claim"
              : "Confirm claim reward"}
          </Button>
        </div>
      }
    >
      <div className="w-full flex flex-col text-center pt-16 md:pt-1">
        <div className="text-xs mb-2">From</div>
        <div className="flex justify-center items-center">
          <img src={logo} className="px-2 h-6" alt={`${name} Pool`} />
          <span className="px-1 text-white font-bold">{name} Pool</span>
        </div>

        <div className="text-xs mt-6">{reward} earned</div>
        <div className="text-4xl text-velo-pink font-medium mt-2">
          {formatNumberWith4Digits(earnedToken)}
        </div>

        {isLockConfirm && (
          <>
            <div className="text-xs mt-4">Total {name} Token Withdraw</div>
            <div className="text-4xl bg-clip-text text-transparent bg-velo-primary-gradient font-bold mt-2">
              {formatNumberWith4Digits(earnedBalance)}
            </div>
          </>
        )}
      </div>
    </Modal>
  );
});

export default LockConfirmModal;

import React, { useState } from "react";
import { Menu, SubMenu } from "../../interfaces/menu";
import { useNavigate } from "react-router-dom";

interface Props {
  menus: Menu[];
  setIsOpenMenu: (isOpen: boolean) => void;
  isMobile: boolean;
}

const SidebarList: React.FC<Props> = ({ menus, setIsOpenMenu, isMobile }) => {
  const navigate = useNavigate();

  const [menuMapping, setMenuMapping] = useState<{ [key: number]: boolean }>(
    {}
  );

  const toggleSubMenu = (menuId: number) => {
    setMenuMapping((prevState) => ({
      ...prevState,
      [menuId]: !prevState[menuId],
    }));
  };

  const onNavigation = (menu: Menu) => {
    if (menu.externalLink) {
      window.open(menu.path, "_blank");
    } else {
      navigate(menu.path ?? "");
    }
    if (isMobile) {
      setIsOpenMenu(false);
    }
  };

  return (
    <div>
      <ul className="py-6 flex flex-col gap-4 items-center">
        {menus.map((menu, menuId) => {
          return (
            <li
              id={`menu-${menuId}`}
              key={menuId}
              className="select-none cursor-pointer w-full h-full"
              data-testid={`${menu.id}_btn`}
            >
              {menu.subMenus ? (
                <div
                  className="flex items-center md:justify-between"
                  onClick={() => toggleSubMenu(menuId)}
                >
                  <div className="flex flex-row gap-2">
                    <img
                      className="w-8 h-8"
                      src={require(`/src/assets/icons/sidebar/${menu.icon}`)}
                      alt={menu.title}
                    />
                    <span className="flex items-center text-base md:text-sm">
                      {menu.title}
                    </span>
                  </div>
                  <div className="pl-3 md:pl-0">
                    <img
                      src={
                        require("/src/assets/icons/general/arrowdown-icon.svg")
                          .default
                      }
                      className={`w-3 h-3 transform transition duration-200 ease-in-out ${
                        menuMapping[menuId] ? "rotate-180" : ""
                      }`}
                      alt="Toggle"
                    />
                  </div>
                </div>
              ) : (
                <div
                  className="flex flex-row gap-2"
                  onClick={() => onNavigation(menu)}
                >
                  <img
                    className="w-8 h-8"
                    src={require(`../../assets/icons/sidebar/${menu.icon}`)}
                    alt={menu.title}
                  />
                  <span className="flex items-center text-base md:text-sm">
                    {menu.title}
                  </span>
                </div>
              )}
              {menu.subMenus && (
                <ul
                  id={`submenu-${menuId}`}
                  className={`flex md:gap-2 flex-col pt-4 ${
                    menuMapping[menuId] ? "" : "hidden"
                  }`}
                >
                  {menu.subMenus.map((subMenu, subMenuId) => (
                    <div
                      key={subMenuId}
                      data-testid={`${subMenuId}_btn`}
                      className="flex bg-sitebar-list rounded-lg"
                      onClick={() => onNavigation(subMenu)}
                    >
                      <li className="select-none inline-flex items-center tracking-normal">
                        <div className="flex flex-row gap-2">
                          <div className="w-8">
                            {subMenu.suffixIcon && (
                              <img
                                src={require(`../../assets/icons/sidebar/${menu.icon}`)}
                                alt={menu.title}
                              />
                            )}
                          </div>
                          <span className="text-sm py-3 md:py-2">
                            {subMenu.title}
                          </span>
                        </div>
                        {subMenu.suffixIcon && (
                          <span className="ml-1">
                            <img
                              className="w-9"
                              src={require(`../../assets/icons/sidebar/${menu.icon}`)}
                              alt={menu.title}
                            />
                          </span>
                        )}
                      </li>
                    </div>
                  ))}
                </ul>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default SidebarList;

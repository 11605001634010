import { useState, useCallback, useEffect } from "react";
import { BigNumber } from "bignumber.js";
import { useEthereum } from "../contexts/etherruemContext";
import config from "../config";
import { useWallet } from "../contexts/walletContext";
import useLiquidity from "./useLiquidityHelpers";
import useFarmCommon from "./useFarmCommon";
import { useTokenHelpers } from "./useTokenHelpers";
import {
  EarnOtherFixedAPRLock,
  FixAprExtra,
  FixAprLock,
} from "../interfaces/config";
import { FixApr } from "../interfaces/config";
import { useUnifiedWallet } from "../providers/UnifiedWalletProvider";

type Events = {
  onTransactionHash: (txHash: string) => void;
  onReceipt: (receipt: any) => void;
  onError: (error: any, receipt: any) => void;
};

const useAprFixLockHelper = () => {
  const ethereum = useEthereum();
  const wallet = useWallet();
  const { getBalanceOfLP } = useLiquidity();
  const { getPriceTokenExternalApi } = useFarmCommon();
  const { getAllowance, approveTokenToSpender } = useTokenHelpers();
  const [isReady, setIsReady] = useState(false);
  const { walletApi } = useUnifiedWallet();

  useEffect(() => {
    if (ethereum) {
      setIsReady(true);
    }
  }, [ethereum]);

  const claimLpToken = useCallback(
    async (
      contractAddress: string,
      account: string,
      events: Events
    ): Promise<any> => {
      try {
        const receipt = await ethereum.contracts.earnOtherFixedAPRLock[
          contractAddress
        ].methods
          .harvest()
          .send({ from: account })
          .on("transactionHash", (txHash: string) =>
            events.onTransactionHash(txHash)
          )
          .on("receipt", (receipt: any) => events.onReceipt(receipt))
          .on("error", (err: any, receipt: any) => {
            events.onError(err, receipt);
            throw err;
          });

        return receipt;
      } catch (e) {
        // console.error("Error: claimLpToken", e);
        return null;
      }
    },
    [ethereum.contracts]
  );

  const depositLpToken = async (
    contractAddress: string,
    account: string,
    inputAmount: BigNumber,
    events: Events
  ): Promise<any> => {
    try {
      const receipt = await ethereum.contracts.earnOtherFixedAPRLock[
        contractAddress
      ].methods
        .deposit(inputAmount.toFixed())
        .send({ from: account })
        .on("transactionHash", (txHash: string) =>
          events.onTransactionHash(txHash)
        )
        .on("receipt", (receipt: any) => events.onReceipt(receipt))
        .on("error", (err: any, receipt: any) => {
          events.onError(err, receipt);
          throw err;
        });

      return receipt;
    } catch (e) {
      return null;
    }
  };

  const withdrawLpToken = async (
    contractAddress: string,
    account: string,
    inputAmount: BigNumber,
    events: Events
  ): Promise<any> => {
    try {
      const receipt = await ethereum.contracts.earnOtherFixedAPRLock[
        contractAddress
      ].methods
        .withdraw(inputAmount.toString())
        .send({ from: account })
        .on("transactionHash", (txHash: string) =>
          events.onTransactionHash(txHash)
        )
        .on("receipt", (receipt: any) => events.onReceipt(receipt))
        .on("error", (err: any, receipt: any) => {
          events.onError(err, receipt);
          throw err;
        });

      return receipt;
    } catch (e) {
      // console.error("Error: withdrawLpToken", e);
      return null;
    }
  };

  const getAPR = async (contractAddress: string): Promise<BigNumber | null> => {
    try {
      const apr = await ethereum.contracts.earnOtherFixedAPRLock[
        contractAddress
      ].methods
        .apr()
        .call();
      return new BigNumber(apr);
    } catch (e) {
      // console.error("Error: getAPR", e);
      return null;
    }
  };

  const getTotalStaked = async (
    contractAddress: string
  ): Promise<BigNumber | null> => {
    try {
      const totalStaked = await ethereum.contracts.earnOtherFixedAPRLock[
        contractAddress
      ].methods
        .totalStaked()
        .call();
      return new BigNumber(totalStaked);
    } catch (e) {
      // console.error("Error: getTotalStaked", e);
      return null;
    }
  };

  const getPendingReward = async (
    contractAddress: string,
    account: string
  ): Promise<BigNumber | null> => {
    try {
      const pendingReward = await ethereum.contracts.earnOtherFixedAPRLock[
        contractAddress
      ].methods
        .pendingReward(account)
        .call();
      return new BigNumber(pendingReward);
    } catch (e) {
      return BigNumber("0");
    }
  };

  const getCap = async (contractAddress: string): Promise<BigNumber | null> => {
    try {
      const cap = await ethereum.contracts.earnOtherFixedAPRLock[
        contractAddress
      ].methods
        .cap()
        .call();
      return new BigNumber(cap);
    } catch (e) {
      return null;
    }
  };

  const getRewardDebt = async (
    contractAddress: string
  ): Promise<boolean | null> => {
    try {
      return await ethereum.contracts.earnOtherFixedAPRLock[
        contractAddress
      ].methods
        .rewardDebt()
        .call();
    } catch (e) {
      return null;
    }
  };

  const getUserInfo = async (
    contractAddress: string,
    account: string
  ): Promise<any> => {
    try {
      return await ethereum.contracts.earnOtherFixedAPRLock[
        contractAddress
      ].methods
        .userInfo(account)
        .call();
    } catch (e) {
      console.log("Error : getUserInfo", contractAddress, e);
      return BigNumber("0");
    }
  };

  const getStartEndBlock = async (
    contractAddress: string
  ): Promise<{ startBlock: string | null; endBlock: string | null }> => {
    try {
      const [startBlock, endBlock] = await Promise.all([
        ethereum.contracts.earnOtherFixedAPRLock[contractAddress].methods
          .startBlock()
          .call(),
        ethereum.contracts.earnOtherFixedAPRLock[contractAddress].methods
          .endBlock()
          .call(),
      ]);
      // const [endBlock] = await Promise.all([
      //   ethereum.contracts.earnOtherFixedAPRLock[contractAddress].methods
      //     .endBlock()
      //     .call(),
      // ]);

      // return { startBlock: null, endBlock };
      return { startBlock, endBlock };
    } catch (e) {
      return { startBlock: null, endBlock: null };
    }
  };

  const getFarmFixedAPRLockInfo = async (
    contractAddress: string,
    poolData?: EarnOtherFixedAPRLock
  ): Promise<FixAprLock | null> => {
    try {
      poolData =
        poolData ||
        config.earnOtherFixedAPRLock.find(
          (e) => e.contractAddress === contractAddress
        );
      if (!poolData) return null;

      const account = walletApi.account.current;
      if (account === undefined) return null;
      const promises = [
        getTotalStaked(poolData.contractAddress ?? ""),
        getAPR(poolData.contractAddress ?? ""),
        getCap(poolData.contractAddress ?? ""),
        getRewardDebt(poolData.contractAddress ?? ""),
        getBalanceOfLP(
          poolData.rewardToken ?? "",
          poolData.contractAddress ?? ""
        ),
        getStartEndBlock(poolData.contractAddress ?? ""),
      ];

      const rewardExternalPricePromise = poolData.rewardExternalPrice
        ? [getPriceTokenExternalApi(poolData.rewardSlug ?? "")]
        : [];

      const stakingExternalPricePromise = poolData.stakingExternalPrice
        ? [getPriceTokenExternalApi(poolData.stakingSlug ?? "")]
        : [];

      const extendPromises = account
        ? [
            getBalanceOfLP(poolData.id ?? "", account),
            getUserInfo(poolData.contractAddress ?? "", account),
            getPendingReward(poolData.contractAddress ?? "", account),
          ]
        : [];

      const [
        totalStaked,
        apr,
        cap,
        rewardDebt,
        rewardBalance,
        // balanceOfLp,
        blockData,
        rewardPrice,
        stakingPrice,
        lpBalance,
        userInfo,
        pendingReward,
        // rewardDebt,
      ] = await Promise.all([
        ...promises,
        ...rewardExternalPricePromise,
        ...stakingExternalPricePromise,
        ...extendPromises,
      ]);
      const { startBlock, endBlock } = blockData;
      let aprCalbyRewardPrice = new BigNumber(apr).div(1e18).div(100);

      // if (poolData.rewardExternalPrice) {
      //   aprCalbyRewardPrice = aprCalbyRewardPrice.multipliedBy(
      //     rewardPrice || 0
      //   );
      // }

      const result: FixAprLock = {
        ...poolData,
        reward: config.token[poolData.rewardToken ?? ""].name, // Adjust according to your tokenInfo structure
        totalStaked: new BigNumber(totalStaked),
        apr: aprCalbyRewardPrice,
        rewardExternalPrice: rewardPrice || poolData.rewardPrice,
        lpPerUsd: new BigNumber(stakingPrice ?? ""),
        startBlock: startBlock,
        endBlock: endBlock,
        cap: new BigNumber(cap),
        rewardBalance: new BigNumber(rewardBalance),
        rewardPrice: rewardPrice || poolData.rewardPrice,
        earnedToken: pendingReward
          ? new BigNumber(pendingReward)
          : new BigNumber("0"),
        lpBalance: lpBalance ? new BigNumber(lpBalance) : new BigNumber("0"),
        balanceInPool: userInfo?.amount
          ? new BigNumber(userInfo.amount)
          : new BigNumber("0"),
        rewardDebt: BigNumber(rewardDebt),
      };

      return result;
    } catch (e) {
      console.log(`Err: getFarmFixedAPRLockInfo ${contractAddress}`, e);
      return null;
    }
  };

  const getAllFarmFixedAPRLockInfoLive = async (): Promise<FixAprLock[]> => {
    const { earnOtherFixedAPRLock } = config;
    const filterIsNotExpired = earnOtherFixedAPRLock.filter(
      (item) => !item.isExpired
    );
    try {
      const result = await filterIsNotExpired.reduce(
        async (
          prev: Promise<FixAprLock[]>,
          cur: EarnOtherFixedAPRLock,
          index: number
        ) => {
          let acc = await prev;

          try {
            const response = await getFarmFixedAPRLockInfo(
              cur.contractAddress ?? "",
              cur
            );

            if (response !== null) acc = [...acc, response];
          } catch (error) {
            console.error(`Error processing item at index ${index}:`, error);
          }

          return acc;
        },
        Promise.resolve([])
      );

      return result;
    } catch (error) {
      // console.error("Error in getAllFarmFixedAPRLockInfo:", error);
      throw error; // Optionally rethrow to propagate the error
    }
  };

  const getAllFarmFixedAPRLockInfo = async (): Promise<FixAprLock[]> => {
    const { earnOtherFixedAPRLock } = config;

    try {
      const result = await earnOtherFixedAPRLock.reduce(
        async (
          prev: Promise<FixAprLock[]>,
          cur: EarnOtherFixedAPRLock,
          index: number
        ) => {
          let acc = await prev;

          try {
            const response = await getFarmFixedAPRLockInfo(
              cur.contractAddress ?? "",
              cur
            );

            if (response !== null) acc = [...acc, response];
          } catch (error) {
            // console.error(`Error processing item at index ${index}:`, error);
            // You can decide whether to skip the failed item or propagate the error
          }

          return acc;
        },
        Promise.resolve([])
      );

      return result;
    } catch (error) {
      // console.error("Error in getAllFarmFixedAPRLockInfo:", error);
      throw error; // Optionally rethrow to propagate the error
    }
  };

  const getTotalValueLock = async (): Promise<BigNumber> => {
    const { earnOtherFixedAPRLock } = config;

    const tvl = await earnOtherFixedAPRLock.reduce(
      async (prev: Promise<BigNumber>, cur: any) => {
        let acc = await prev;
        const response = await getTotalStaked(cur.contractAddress);

        if (response) {
          let stakingPrice = cur.stakingPrice;
          if (!stakingPrice && cur.stakingExternalPrice) {
            stakingPrice = await getPriceTokenExternalApi(cur.stakingSlug);
          }
          acc = acc.plus(new BigNumber(response).multipliedBy(stakingPrice));
        }

        return acc;
      },
      Promise.resolve(new BigNumber("0"))
    );

    return tvl;
  };

  const depositLpTokenWithCheckAllowance = async (
    code: string,
    lpAddress: string,
    contractAddress: string,
    account: string,
    inputAmount: BigNumber,
    events: Events
  ): Promise<void> => {
    const spender = contractAddress;
    const amount = new BigNumber(inputAmount);

    if (ethereum.contracts) {
      const allowance = await getAllowance(code, account, spender, lpAddress);
      if (allowance && new BigNumber(allowance).isLessThan(amount)) {
        await approveTokenToSpender(lpAddress, account, spender, {
          onTransactionHash: (txHash) => {},
          onReceipt: ({ transactionHash, status }) => {},
          onError: (error: any) => {},
        });
      }

      await depositLpToken(contractAddress, account, inputAmount, events);
    }
  };

  return {
    isReady,
    claimLpToken,
    depositLpToken,
    withdrawLpToken,
    getAPR,
    getTotalStaked,
    getPendingReward,
    getCap,
    getRewardDebt,
    getUserInfo,
    getStartEndBlock,
    getFarmFixedAPRLockInfo,
    getAllFarmFixedAPRLockInfo,
    getAllFarmFixedAPRLockInfoLive,
    getTotalValueLock,
    depositLpTokenWithCheckAllowance,
  };
};

export default useAprFixLockHelper;

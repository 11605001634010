import { useAccount, useSignMessage } from "wagmi";
import { config } from "../../../../wagmi.config";
import { ChainDetailType } from "../../../interfaces/wallet";
import { MutableRefObject, useCallback } from "react";

const useEthWalletSignMessage = (
  address: MutableRefObject<`0x${string}` | undefined>
) => {
  const { signMessageAsync } = useSignMessage({ config });
  const { connector: currentConnector } = useAccount();
  const signMessage = useCallback(
    async (message: string) => {
      try {
        console.log(
          "useEthWalletSignMessage: signMessage, connector",
          currentConnector
        );
        const _address = await currentConnector?.getAccounts();
        console.log(
          "useEthWalletSignMessage: signMessage, connector address",
          _address
        );
        console.log(
          "useEthWalletSignMessage: signMessage, address",
          address.current
        );
        const signature = await signMessageAsync({
          account: address.current,
          message: message,
          connector: currentConnector,
        });
        return signature as string;
      } catch (error) {
        console.error("useEthWalletSignMessage: signMessage", error);
        throw new Error("Failed to sign message");
      }
    },
    [signMessageAsync, address, currentConnector]
  );

  return {
    signMessage,
  };
};

export default useEthWalletSignMessage;

import Web3, { Contract, Web3EthInterface } from "web3";
// @ts-ignore
import Web3PromiEvent from "web3-core-promievent";
import { BigNumber } from "bignumber.js";
import ERC20 from "../../src/assets/abi/farm/ERC20.json";
import CommonFarms from "../../src/assets/abi/farm/CommonFarms.json";
import EarnOtherNoFixedAPR from "../../src/assets/abi/farm/EarnOtherNoFixedAPR.json";
import EarnOtherFixedAPRLock from "../../src/assets/abi/farm/EarnOtherFixedAPRLock.json";
import EarnOtherFixedAPRLockRewardWithChangeableRatio from "../../src/assets/abi/farm/EarnOtherFixedAPRLockRewardWithChangeableRatio.json";
import EarnOtherFixedAPR from "../../src/assets/abi/farm/EarnOtherFixedAPR.json";
import InitialDEXOffering from "../../src/assets/abi/launchpad/InitialDEXOffering.json";
import Pair from "../../src/assets/abi/swap/EvryPair.json";
import DMMPool from "../../src/assets/abi/swap/DMMPool.json";
import BinaryOption from "../../src/assets/abi/binaryOption/BinaryOption.json";
import AggregatorV3Interface from "../../src/assets/abi/binaryOption/AggregatorV3Interface.json";
import DirectExchange from "../../src/assets/abi/directExchange/DirectExchange.json";

import BondDepository from "../../src/assets/abi/hyper/BondDepository.json";
import StakingHelper from "../../src/assets/abi/hyper/StakingHelper.json";
import Staking from "../../src/assets/abi/hyper/Staking.json";
import sEvryHyper from "../../src/assets/abi/hyper/sEvryHyper.json";

import AMMRouter from "../../src/assets/abi/swap/AMMRouter.json";
import DMMRouter from "../../src/assets/abi/swap/DMMRouter.json";
import AutoRouter from "../../src/assets/abi/swap/AutoRouter.json";
import Evryloan from "../../src/assets/abi/loans/Evryloan.json";
import evUSD from "../../src/assets/abi/loans/evUSD.json";
import eBNB from "../../src/assets/abi/loans/eBNB.json";
import eBTC from "../../src/assets/abi/loans/eBTC.json";
import eBUSD from "../../src/assets/abi/loans/eBUSD.json";
import eETH from "../../src/assets/abi/loans/eETH.json";
import eUSDT from "../../src/assets/abi/loans/eUSDT.json";
import eUSDV from "../../src/assets/abi/loans/eUSDV.json";
import eUSDC from "../../src/assets/abi/loans/eUSDC.json";
import ePLN from "../../src/assets/abi/loans/ePLN.json";
import EvryLens from "../../src/assets/abi/loans/EvryLens.json";
import Comptroller from "../../src/assets/abi/loans/Comptroller.json";

import config from "../config";
import { BrowserProvider, JsonRpcProvider } from "ethers";
import { Farm, Network } from "../interfaces/network";
import { LiquidityPool, Token } from "../interfaces/token";
import { EthereumContextType } from "../contexts/etherruemContext";
import { RegisteredSubscription } from "web3-eth";

// type SendParams = {
//   from: string;
//   value?: string;
//   gas?: string;
//   gasPrice?: string;
// };

// type ContractMethod = (...params: any[]) => any;

interface Env {
  contractAddress: string;
  ABIName?: string;
}

// const ethereum = useEthereum();

const createWeb3Contract = (
  eth: Web3EthInterface,
  abi: any,
  address: string
) => {
  const getBufferValue = (value: string, bufferPercentage: string) =>
    BigNumber(value)
      .times(BigNumber(100).plus(bufferPercentage))
      .div(100)
      .toFixed(0);

  const getGasOptions = async (method: any, sendParams: any) => {
    const [originalGasLimit, originalGasPrice] = await Promise.all([
      method.estimateGas(sendParams),
      eth.getGasPrice(),
    ]);

    const gasLimitBuffer = config.gas.limitBufferPercentage || "20";
    const gasPriceBuffer = config.gas.priceBufferPercentage || "20";

    return {
      gas: getBufferValue(originalGasLimit.toString(), gasLimitBuffer),
      gasPrice: getBufferValue(originalGasPrice.toString(), gasPriceBuffer),
      originalGasLimit: originalGasLimit.toString(),
      originalGasPrice: originalGasPrice.toString(),
    };
  };

  const withSendByEstimateGas =
    (originalMethod: any) =>
    (...params: any) => ({
      ...originalMethod(...params),
      send: (options: any) => {
        const promiEvent = Web3PromiEvent();

        getGasOptions(originalMethod(...params), options)
          .then((gasOptions) => {
            originalMethod(...params)
              .send({ ...options, ...gasOptions })
              .on("transactionHash", (transactionHash: string) => {
                promiEvent.eventEmitter.emit(
                  "transactionHash",
                  transactionHash
                );
              })
              .on("receipt", (receipt: any) => {
                promiEvent.eventEmitter.emit("receipt", receipt);
              })
              .on("error", (error: Error) => {
                promiEvent.eventEmitter.emit("error", error);
              })
              .then((receipt: any) => {
                promiEvent.resolve(receipt);
              })
              .catch((err: Error) => {
                promiEvent.reject(err);
              });
          })
          .catch((err: Error) => {
            promiEvent.reject(err);
          });

        return promiEvent.eventEmitter;
      },
    });

  const wrapContractMethods = (contract: any) => {
    const wrappedMethods: any = {};
    Object.keys(contract.methods).forEach((methodName) => {
      wrappedMethods[methodName] = withSendByEstimateGas(
        contract.methods[methodName]
      );
    });
    return wrappedMethods;
  };

  const contract: any = new eth.Contract(abi, address);

  const wrappedContract = {
    ...contract,
    methods: wrapContractMethods(contract),
  };

  // override method's send function with estimate gas before sending
  // wrappedContract.methods = Object.keys(contract.methods).reduce(
  //   (acc: any, methodName: string) => ({
  //     ...acc,
  //     [methodName]: withSendByEstimateGas(contract.methods[methodName]),
  //   }),
  //   {}
  // );

  return wrappedContract;
};

const createTokensContract = (
  eth: Web3EthInterface,
  tokensEnv: Record<string, Token> | null = null,
  tokensEnvLp: Record<string, LiquidityPool> | null = null,
  tokensEnvDMMLP: Record<string, LiquidityPool> | null = null
) => {
  if (!tokensEnv) tokensEnv = config.token;
  if (!tokensEnvLp) tokensEnvLp = config.lp;
  if (!tokensEnvDMMLP) tokensEnvDMMLP = config.dmmLP;

  const result: Record<string, Contract<any>> = {};

  for (const id in tokensEnv) {
    if (id === "EVUSD_TOKEN") {
      result[id] = createWeb3Contract(eth, evUSD, tokensEnv[id].address ?? "");
    } else if (id === "SEVHY_TOKEN") {
      result[id] = createWeb3Contract(
        eth,
        sEvryHyper,
        tokensEnv[id].address ?? ""
      );
    } else {
      result[id] = createWeb3Contract(eth, ERC20, tokensEnv[id].address ?? "");
    }
  }

  for (const key in tokensEnvLp) {
    result[key] = createWeb3Contract(eth, Pair, tokensEnvLp[key].address ?? "");
  }

  for (const key in tokensEnvDMMLP) {
    result[key] = createWeb3Contract(
      eth,
      DMMPool,
      tokensEnvDMMLP[key].address ?? ""
    );
  }

  return result;
};

const createContract = (eth: Web3EthInterface, env: any[], ABI: any) => {
  const result = (env || []).reduce(
    (acc: Record<string, Contract<any>>, cur: Farm) => {
      let tmpABI = ABI;
      if (cur.ABIName) {
        tmpABI =
          require(`../../src/assets/abi/farm/${cur.ABIName}.json`) || ABI;
      }
      acc[cur.contractAddress ?? ""] = createWeb3Contract(
        eth,
        tmpABI,
        cur.contractAddress ?? ""
      );

      return acc;
    },
    {}
  );

  return result;
};

const eTokenContractList: Record<string, any> = {
  eBNB,
  eUSDT,
  eBUSD,
  eETH,
  eBTC,
  eUSDV,
  eUSDC,
  ePLN,
};

const eTokenBorrowContractList: Record<string, any> = { eUSDV };

const createETokensContract = (eth: Web3EthInterface) => {
  const result: Record<string, Contract<any>> = {};
  const tokensEnv = {
    ...config.eTokenList,
  };

  for (const key in tokensEnv) {
    const eTokenName = tokensEnv[key].eToken;
    result[eTokenName] = createWeb3Contract(
      eth,
      eTokenContractList[eTokenName],
      tokensEnv[key].eAddress ?? ""
    );
  }
  return result;
};

const createETokensBorrowContract = (eth: Web3EthInterface) => {
  const result: Record<string, Contract<any>> = {};
  const tokensEnv = {
    ...config.eTokenBorrowList,
  };

  for (const key in tokensEnv) {
    const eTokenName = tokensEnv[key].eToken;
    result[eTokenName] = createWeb3Contract(
      eth,
      eTokenBorrowContractList[eTokenName],
      tokensEnv[key].eAddress ?? ""
    );
  }
  return result;
};

const getRandomRpcUrl = (rpcUrls: string[], infuraId: string | null = null) => {
  let rpcUrl = rpcUrls[Math.floor(Math.random() * rpcUrls.length)];
  rpcUrl = infuraId ? `${rpcUrl}${infuraId}` : rpcUrl;
  return rpcUrl;
};

const createNetworkContract = (
  ethWeb3: Web3,
  isConnected: boolean,
  contracts: Record<string, any>,
  currentChainId: number
) => {
  const allNetwork = Object.values(config.networks).reduce(
    (acc: any, network: Network) => {
      const defaultNetwork = config.networks[config.chainId];
      const farmEnv =
        config.networks[network.chainId]?.farm || defaultNetwork?.farm;
      const tokenEnv =
        config.networks[network.chainId]?.token || defaultNetwork?.token;

      let web3: Web3<RegisteredSubscription> | null = null;
      if (isConnected && +network.chainId === currentChainId) {
        web3 = ethWeb3;
      } else {
        const rpcUrl = getRandomRpcUrl(
          config.networks[network.chainId]?.rpcUrls || [],
          config.networks[network.chainId]?.infuraId || null
        );
        const httpProvider = new Web3.providers.HttpProvider(rpcUrl);
        web3 = new Web3(httpProvider);
      }

      if (web3)
        acc = {
          ...acc,
          [network.chainId]: {
            farm: createContract(web3.eth, farmEnv, CommonFarms),
            ...(tokenEnv
              ? { tokens: createTokensContract(web3.eth, tokenEnv) }
              : {}),
          },
        };

      return acc;
    },
    {}
  );

  return {
    ...contracts,
    ...allNetwork,
  };
};

export const initContract = (
  ethWeb3: Web3,
  isConnected: boolean,
  currentChainId: number
) => {
  try {
    let web3 = ethWeb3;
    if (web3 === null || +currentChainId !== +config.chainId) {
      const httpProvider = new Web3.providers.HttpProvider(
        config.chainRPC ?? ""
      );
      web3 = new Web3(httpProvider);
    }

    let contracts = {};

    if (web3)
      contracts = {
        tokens: createTokensContract(web3.eth),
        eTokens: createETokensContract(web3.eth),
        eTokensBorrow: createETokensBorrowContract(web3.eth),
        earnOtherNoFixedAPR: createContract(
          web3.eth,
          config.earnOtherNoFixedAPR,
          EarnOtherNoFixedAPR
        ),
        earnOtherFixedAPRLock: createContract(
          web3.eth,
          config.earnOtherFixedAPRLock,
          EarnOtherFixedAPRLock
        ),
        earnOtherFixedAPR: createContract(
          web3.eth,
          config.earnOtherFixedAPR,
          EarnOtherFixedAPR
        ),
        earnOtherFixedAPRLockRewardWithChangeableRatio: createContract(
          web3.eth,
          config.earnOtherFixedAPRLockChangeRatio,
          EarnOtherFixedAPRLockRewardWithChangeableRatio
        ),
        IDO: createContract(web3.eth, config.IDO, InitialDEXOffering),
        binaryOption: createContract(
          web3.eth,
          config.binaryOption,
          BinaryOption
        ),
        oracle: createContract(
          web3.eth,
          config.binaryOption.map((o) => ({
            contractAddress: o.oracleAddress,
          })),
          AggregatorV3Interface
        ),
        directExchange: createContract(
          web3.eth,
          config.directExchange,
          DirectExchange
        ),
        bond: createContract(web3.eth, config.bond, BondDepository),
        stakingHelper: createWeb3Contract(
          web3.eth,
          StakingHelper,
          config.stakingHelper.contractAddress ?? ""
        ),
        staking: createWeb3Contract(
          web3.eth,
          Staking,
          config.staking.contractAddress ?? ""
        ),
        ammRouter: createWeb3Contract(
          web3.eth,
          AMMRouter,
          config.ammRouterContractAddress ?? ""
        ),
        dmmRouter: createWeb3Contract(
          web3.eth,
          DMMRouter,
          config.dmmRouterContractAddress ?? ""
        ),
        autoRouter: createWeb3Contract(
          web3.eth,
          AutoRouter,
          config.autoRouterContractAddress ?? ""
        ),
        evryloan: createWeb3Contract(
          web3.eth,
          Evryloan,
          config.evryLoansContractAddress ?? ""
        ),
        evrylens: createWeb3Contract(
          web3.eth,
          EvryLens,
          config.evryLensContractAddress ?? ""
        ),
        comptroller: createWeb3Contract(
          web3.eth,
          Comptroller,
          config.comptrollerContractAddress ?? ""
        ),
      };
    contracts = createNetworkContract(
      ethWeb3,
      isConnected,
      contracts,
      currentChainId
    );
    return contracts;
  } catch (e) {
    console.log("Err: initContract", e);
  }
};

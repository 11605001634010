// src/pages/Farm.tsx
import React, { useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import PageHeader from "../../components/pageHeader";
import Tab from "../../components/tab";

interface Props {
  children?: React.ReactNode;
}

const FarmLayout: React.FC<Props> = ({ children }) => {
  const { chain } = useParams<{ chain: string }>();
  const location = useLocation();
  const navigate = useNavigate();
  const [network, setNetwork] = useState("bnb");

  useEffect(() => {
    // Update the network based on the route params
    if (chain) {
      setNetwork(chain);
    }
  }, [chain]);

  const tabs = [
    {
      name: "Live",
      linkName: `/farms/${network}/live`,
      link: `/farms/${network}/live`,
      disabled: false,
      value: 0,
    },
    {
      name: "Expired",
      linkName: `/farms/${network}/expired`,
      link: `/farms/${network}/expired`,
      disabled: network === "eth",
      value: 1,
    },
  ];

  const onSelectedNetwork = (value: string) => {
    setNetwork(value);
    navigate({ pathname: location.pathname, search: `?chain=${value}` });
  };

  const isShowEarnOther = chain === "bnb";

  return (
    <div className="md:container p-0">
      <div className="xl:ml-32 md:flex md:flex-col xl:max-w-[1048px]">
        <PageHeader
          title="Yield Farming"
          subTitle="Simply stake tokens to earn"
          isRight={true}
        >
          <div className="flex flex-row justify-end w-full hidden md:block">
            <Tab
              tabs={tabs}
              className="pt-5"
              tabWidth="w-[104px]"
              type="secondary"
            />
          </div>
        </PageHeader>
        <Tab
          tabs={tabs}
          className="px-4 md:hidden justify-center"
          tabWidth="w-full"
          type="secondary"
        />
        {children}
      </div>
    </div>
  );
};

export default FarmLayout;

import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { BigNumber } from "bignumber.js"; // Assuming you use bignumber.js
import { ethers } from "ethers"; // ethers.js for contract interactions
import config from "../config";
import { Farm } from "../interfaces/network";
import { useEthereum } from "./etherruemContext";
import useFarmCommon from "../hooks/useFarmCommon";
import { mainPair, NewPair, Pair } from "../interfaces/token";
import { useTokenHelpers } from "../hooks/useTokenHelpers";
import { useWriteContract } from "wagmi";
import useLazyReadContractAsync from "../hooks/useLazyReadContractAsync";
import ERC20 from "../../src/assets/abi/farm/ERC20.json";
import CommonFarms from "../../src/assets/abi/farm/CommonFarms.json";
import { ChainId } from "@pancakeswap/sdk";
import useDirectExchangeHelpers from "../hooks/useDirectExchangeHelpers";

interface Props {
  children: React.ReactNode;
}

export interface PairExtras {
  [key: string]: {
    totalStaked: BigNumber;
    apr: BigNumber;
    lpPerUsd: BigNumber;
    lastRewardBlock: number;
  };
}

interface FarmContextType {
  farmContract: any;
  farmEnv: Farm[] | undefined;
  tokenContract: any;
  isReadyForGetFarmInfo: boolean;
  depositLpToken: (
    contractAddress: string,
    poolId: number,
    account: string,
    inputAmount: BigNumber,
    events: any
  ) => Promise<any>;
  withdrawLpToken: (
    contractAddress: string,
    poolId: number,
    account: string,
    inputAmount: BigNumber,
    events: any
  ) => Promise<any>;
  claimLpToken: (
    contractAddress: string,
    poolId: number,
    account: string,
    events: any
  ) => Promise<any>;
  getFarmInfo: (
    processEnvFarm: Farm,
    isEvry?: boolean
  ) => Promise<{
    pairExtras: PairExtras;
    rewardPerUsd: BigNumber;
    rewardPerBlock: BigNumber;
  }>;
  getTotalSupplyExternalApi: (token: string) => Promise<any>;
  getBalanceOf: (code: string, address: string) => Promise<any>;
  getPendingReward: (
    contractAddress: string,
    pid: number,
    account: string
  ) => Promise<any>;
  isApprovedAllowance: (
    account: string,
    spender: string,
    lpName: string
  ) => Promise<boolean>;
  getUserInfo: (
    contractAddress: string,
    pid: number,
    account: string
  ) => Promise<
    | {
        amount: BigNumber;
      }
    | {
        amount: null;
      }
  >;
  depositLpTokenWithCheckAllowance: (
    lpName: string,
    lpAddress: string,
    contractAddress: string,
    poolId: number,
    account: string,
    inputAmount: string,
    events: any
  ) => Promise<void>;
  // Add other methods here as needed
}

// Initialize the context
const FarmContext = createContext<FarmContextType | undefined>(undefined);

export const useFarm = () => {
  const context = useContext(FarmContext);
  if (!context) {
    throw new Error("useFarm must be used within a FarmProvider");
  }
  return context;
};

// The Provider component
export const FarmProvider: React.FC<Props> = ({ children }) => {
  const [farmContract, setFarmContract] = useState<any>({});
  const [farmEnv, setFarmEnv] = useState<Farm[]>();
  const [tokenContract, setTokenContract] = useState<any>({});
  const [isReadyForGetFarmInfo, setIsReadyForGetFarmInfo] = useState(false);
  const { getTokenRationOmniPrice } = useDirectExchangeHelpers();

  const ethereum = useEthereum();
  const { getMapIDofReserves, getPrice, approveToken } = useFarmCommon();
  const { getAllowance } = useTokenHelpers();
  const { writeContractAsync } = useWriteContract();
  const { lazyReadContract } = useLazyReadContractAsync();

  const initFarmContract = useCallback(
    (chainId: number = config.chainId) => {
      if (!ethereum?.contracts) return;
      const currentFarmContract = ethereum?.contracts[chainId]?.farm;
      setFarmContract(
        currentFarmContract && Object.keys(currentFarmContract).length > 0
          ? currentFarmContract
          : ethereum?.contracts[config.chainId]?.farm
      );
    },
    [ethereum?.contracts]
  );

  const initFarmEnv = (chainId: number = config.chainId) => {
    const currentFarmEnv = config.networks?.[chainId]?.farm;

    setFarmEnv(
      currentFarmEnv && Object.keys(currentFarmEnv).length > 0
        ? currentFarmEnv
        : config.networks?.[config.chainId].farm
    );
  };

  const initTokenContract = useCallback(
    (chainId: number = config.chainId) => {
      if (!ethereum?.contracts) return;
      const currentTokenContract =
        ethereum?.contracts[chainId]?.tokens || ethereum?.contracts.tokens;

      setTokenContract(
        currentTokenContract && Object.keys(currentTokenContract).length > 0
          ? currentTokenContract
          : ethereum?.contracts[config.chainId]?.tokens
      );
    },
    [ethereum?.contracts]
  );

  useEffect(() => {
    // Assuming you have the chainId available in context or state
    const chainId = config.chainId;
    initFarmContract(chainId);
    initTokenContract(chainId);
  }, [ethereum, ethereum.contracts, initFarmContract, initTokenContract]);

  useEffect(() => {
    // console.count("### innit farm");
    initFarmEnv(config.chainId);
  }, []);

  useEffect(() => {
    if (ethereum.contracts !== null && farmContract && tokenContract) {
      setIsReadyForGetFarmInfo(true);
    }
  }, [ethereum.contracts, farmContract, tokenContract]);

  const depositLpToken = async (
    contractAddress: string,
    poolId: number,
    account: string,
    inputAmount: BigNumber,
    events: any
  ) => {
    const result = await writeContractAsync(
      // @ts-ignore
      {
        // abi: AutoRouter,
        // account: account.current,
        abi: CommonFarms,
        // @ts-ignore
        from: account,
        // @ts-ignore
        address: contractAddress,
        functionName: "deposit",
        args: [account, poolId, inputAmount.toFixed(0)],
      },
      {
        onSuccess(data: any, variables: any, context: any) {
          console.log("depositLpToken: data", data);
          events.onReceipt({ transactionHash: data, status: true });
          return data;
        },
        onError: (error: any) => {
          events.onError(error);
          console.log("depositLpToken: error", error);
        },
      }
    );
    // if (ethereum?.contracts != null) {
    //   try {
    //     const receipt = await farmContract[contractAddress].methods
    //       .deposit(account, poolId, inputAmount.toString())
    //       .send({ from: account })
    //       .on("transactionHash", (txHash: string) => {
    //         events.onTransactionHash(txHash);
    //       })
    //       .on("receipt", (receipt: any) => {
    //         events.onReceipt(receipt);
    //       })
    //       .on("error", (err: any, receipt: any) => {
    //         events.onError(err, receipt);
    //         throw err;
    //       });

    //     console.log("depositLpToken receipt", receipt);

    //     return receipt;
    //   } catch (error) {
    //     console.error("Error in depositLpToken:", error);
    //     return null;
    //   }
    // }
    return { transactionHash: result, status: Boolean(result) };
  };

  const withdrawLpToken = async (
    contractAddress: string,
    poolId: number,
    account: string,
    inputAmount: BigNumber,
    events: any
  ) => {
    const result = await writeContractAsync(
      // @ts-ignore
      {
        // abi: AutoRouter,
        // account: account.current,
        abi: CommonFarms,
        // @ts-ignore
        from: account,
        // @ts-ignore
        address: contractAddress,
        functionName: "withdraw",
        args: [account, poolId, inputAmount.toFixed(0)],
      },
      {
        onSuccess(data: any, variables: any, context: any) {
          console.log("withdrawLpToken: data", data);
          events.onReceipt({ transactionHash: data, status: true });
          return data;
        },
        onError: (error: any) => {
          events.onError(error);
          console.log("withdrawLpToken: error", error);
        },
      }
    );
    // if (ethereum?.contracts != null) {
    //   try {
    //     const receipt = await farmContract[contractAddress].methods
    //       .withdraw(account, poolId, inputAmount.toString())
    //       .send({ from: account })
    //       .on("transactionHash", (txHash: string) => {
    //         events.onTransactionHash(txHash);
    //       })
    //       .on("receipt", (receipt: any) => {
    //         events.onReceipt(receipt);
    //       })
    //       .on("error", (err: any, receipt: any) => {
    //         events.onError(err, receipt);
    //         throw err;
    //       });

    //     return receipt;
    //   } catch (error) {
    //     console.error("Error in withdrawLpToken:", error);
    //     return null;
    //   }
    // }
    return { transactionHash: result, status: Boolean(result) };
  };

  const claimLpToken = async (
    contractAddress: string,
    poolId: number,
    account: string,
    events: any
  ) => {
    if (ethereum?.contracts != null) {
      try {
        const receipt = await farmContract[contractAddress].methods
          .harvest(poolId)
          .send({ from: account })
          .on("transactionHash", (txHash: string) => {
            events.onTransactionHash(txHash);
          })
          .on("receipt", (receipt: any) => {
            events.onReceipt(receipt);
          })
          .on("error", (err: any, receipt: any) => {
            events.onError(err, receipt);
          });

        return receipt;
      } catch (error) {
        console.error("Error in claimLpToken:", error);
        return null;
      }
    }
    return null;
  };

  const getEvryEmissionPerBlock = async (contractAddress: string) => {
    if (
      ethereum.contracts !== null &&
      farmContract &&
      farmContract[contractAddress]
    ) {
      return BigNumber(
        await farmContract[contractAddress].methods.evryPerBlock().call()
      );
    }
    return null;
  };

  const getRewardTokenPerBlock = async (contractAddress: string) => {
    if (
      ethereum.contracts !== null &&
      farmContract &&
      farmContract[contractAddress]
    ) {
      return BigNumber(
        await farmContract[contractAddress].methods.rewardPerBlock().call()
      );
    }
    return null;
  };

  const getTotalSupply = async (lpName: string) => {
    if (tokenContract != null && tokenContract[lpName] != null) {
      try {
        const supply = await tokenContract[lpName].methods.totalSupply().call();
        const supplyValue = BigNumber(supply);
        return supplyValue;
      } catch (e) {
        return BigNumber(0);
      }
    }
    return BigNumber(0);
  };

  const getListTotalSupply = async (lpNames: string[]) => {
    if (ethereum.contracts !== null) {
      try {
        const listSupply = await Promise.all(
          lpNames.map((lpName) => getTotalSupply(lpName))
        );
        return listSupply;
      } catch (e) {
        console.log("getListTotalSupply", e);
      }
    }
    return null;
  };

  const getRewardTokenPerYear = (rewardPerBlock: BigNumber) => {
    return rewardPerBlock.multipliedBy(config.blockPerDay * 365);
  };

  const getAllPairInfo = async (processEnvFarm: Farm, isEvry = false) => {
    const { pairs, rewardToken, contractAddress } = processEnvFarm;

    if (
      ethereum.contracts !== null &&
      farmContract &&
      tokenContract &&
      Object.keys(farmContract).length > 0 &&
      Object.keys(tokenContract).length > 0
    ) {
      const totalStaked = await Promise.all(
        pairs?.map(async (pair) => {
          try {
            if (pair.id === rewardToken.code) {
              return isEvry
                ? await farmContract[contractAddress ?? ""].methods
                    .evrySupply()
                    .call()
                : await farmContract[contractAddress ?? ""].methods
                    .rewardTokenSupply()
                    .call();
            }

            return await tokenContract[pair.id].methods
              .balanceOf(contractAddress)
              .call();
          } catch (e) {
            console.log("totalStaked", e);
            return BigNumber("0");
          }
        })
      );

      const info = await Promise.all(
        pairs.map(async (pair) => {
          try {
            return await farmContract[contractAddress ?? ""].methods
              .poolInfo(pair.poolID)
              .call();
          } catch (e) {
            console.log("info", e);
            return null;
          }
        })
      );

      return pairs?.map((pair, idx) => {
        let totalStakedValue = BigNumber(totalStaked[idx]);
        // TODO: refactor later
        if (config.token[pair.id]?.decimals) {
          const decimals = 18 - (config.token[pair.id].decimals ?? 0);
          totalStakedValue = totalStakedValue.multipliedBy(`1e${decimals}`);
        }

        return {
          ...pair,
          totalStaked: totalStakedValue,
          info: info[idx],
        };
      });
    }
    return null;
  };

  const getFarmInfo = async (processEnvFarm: Farm, isEvry = false) => {
    const rewardPerBlock = isEvry
      ? await getEvryEmissionPerBlock(processEnvFarm.contractAddress ?? "")
      : await getRewardTokenPerBlock(processEnvFarm.contractAddress ?? "");
    const result: {
      pairExtras: PairExtras;
      rewardPerUsd: BigNumber;
      rewardPerBlock: BigNumber;
    } = {
      pairExtras: {},
      rewardPerUsd: BigNumber("0"),
      rewardPerBlock: rewardPerBlock ?? BigNumber("0"),
    };

    // ------------ Total supply for each token (including the one not in farm) --------------- //
    const farmIds = processEnvFarm.pairs.map((pool) => pool.id);
    const mapTotalSupply = (await getListTotalSupply(farmIds))?.reduce(
      (map: { [key: string]: BigNumber }, totalSupply, i) => {
        map[farmIds[i]] = totalSupply ?? BigNumber("0");
        return map;
      },
      {} as { [key: string]: BigNumber }
    );

    // --------------------- reserve ratio each pair (AMM + DMM) ------------------------ //
    const pairIds = (processEnvFarm.pairs as (Pair | NewPair)[])
      .filter((o: Pair | NewPair) => !!o.lpType)
      .map((pool: Pair | NewPair) => pool.id);

    const mapIDofReserves = await getMapIDofReserves(pairIds);

    const tokens = processEnvFarm.tokens;
    const tokenPrice = await getPrice(tokens, mapIDofReserves);
    if (processEnvFarm.rewardToken.code === "OMNI_TOKEN") {
      const tokenRatio: any = await getTokenRationOmniPrice();
      result.rewardPerUsd = tokenRatio;
    } else {
      result.rewardPerUsd = BigNumber(
        tokenPrice[processEnvFarm.rewardToken.symbol]
      );
    }

    const rewardTokenPerYear = getRewardTokenPerYear(result.rewardPerBlock);
    const rewardTokenPerYearUSD = rewardTokenPerYear.multipliedBy(
      result.rewardPerUsd
    );
    console.log(`💰rewardTokenPerYearUSD:${rewardTokenPerYearUSD}`);

    // -------------- get total farm allocation --------------- //
    const pairs: any = await getAllPairInfo(processEnvFarm, isEvry);
    const totalAllocPoint = pairs?.reduce((value: any, pair: any) => {
      return BigNumber(pair?.info?.allocPoint ?? 0).plus(value);
    }, BigNumber("0"));
    // ----------------  calculate APR --------------- //
    for (const key in pairs) {
      const pair = pairs[key];
      let reserveTotalUsd = BigNumber("0");
      const reservePair = mapIDofReserves[pair.id];
      let lpPerUsd;
      if (!pair.lpType) {
        lpPerUsd = BigNumber(tokenPrice[pair.staking.symbol]);
      } else {
        const reserveTotalUsd0 = reservePair.reserves[reservePair.token0.code]
          ? reservePair.reserves[reservePair.token0.code].multipliedBy(
              tokenPrice[reservePair.token0.symbol]
            )
          : BigNumber("0");
        const reserveTotalUsd1 = reservePair.reserves[reservePair.token1.code]
          ? reservePair.reserves[reservePair.token1.code].multipliedBy(
              tokenPrice[reservePair.token1.symbol]
            )
          : BigNumber("0");
        reserveTotalUsd = reserveTotalUsd0.plus(reserveTotalUsd1);
        reserveTotalUsd.div(pair.totalSupply);

        lpPerUsd = pair.totalStaked.isGreaterThan(BigNumber(0))
          ? reserveTotalUsd.div(
              mapTotalSupply ? mapTotalSupply[pair.id] : BigNumber(0)
            )
          : BigNumber(0);
      }

      const pairAllocPoint = BigNumber(pair?.info?.allocPoint ?? 0);

      const totalStaked = pair.totalStaked;
      const apr = rewardTokenPerYearUSD
        .multipliedBy(pairAllocPoint.div(totalAllocPoint ?? BigNumber("1")))
        .div(lpPerUsd.multipliedBy(totalStaked));
      const lastRewardBlock = pair?.info?.lastRewardBlock || 0;

      result.pairExtras[pair.id] = {
        totalStaked,
        apr: apr.isNaN() ? BigNumber("0") : apr,
        lpPerUsd,
        lastRewardBlock,
      };
    }

    return result;
  };

  const getTotalSupplyExternalApi = async (token: string) => {
    try {
      const url = config.externalPriceAPI + token;
      const xmlHttp = new XMLHttpRequest();
      xmlHttp.open("GET", url, false);
      await xmlHttp.send(null);

      const supplySet = JSON.parse(xmlHttp.responseText);

      return (await supplySet?.market_data?.total_supply) || 0;
    } catch (e) {
      console.log("getTotalSupplyExternalApi", e);
    }
  };

  const getBalanceOf = async (code: string, address: string) => {
    if (tokenContract != null) {
      return await tokenContract[code].methods.balanceOf(address).call();
    }
    return 0;
  };

  const getPendingReward = async (
    contractAddress: string,
    pid: number,
    account: string
  ) => {
    try {
      if (ethereum.contracts !== null) {
        return BigNumber(
          await farmContract[contractAddress].methods
            .pendingReward(pid, account)
            .call()
        );
      }
      return null;
    } catch (e) {
      console.log("Err: getPendingReward", e);
      return null;
    }
  };

  const isApprovedAllowance = useCallback(
    async (account: string, spender: string, lpName: string) => {
      if (lpName === "BNB") return true;

      const allowance: any = await lazyReadContract(
        ERC20,
        lpName,
        "allowance",
        [account, spender]
      );
      if (allowance === null) return false;

      // const allow = await tokenContract[lpName].methods
      //   .allowance(account, spender)
      //   .call();
      return !BigNumber(allowance).isEqualTo(0);
    },
    [lazyReadContract]
  );

  const getUserInfo = async (
    contractAddress: string,
    pid: number,
    account: string
  ) => {
    if (ethereum.contracts !== null) {
      try {
        const userInfo = await farmContract[contractAddress].methods
          .userInfo(pid, account)
          .call();

        return {
          amount: BigNumber(userInfo.amount),
        };
      } catch (e) {
        console.log("getUserInfo", e);
        return {
          amount: BigNumber("0"),
        };
      }
    }
    return {
      amount: null,
    };
  };

  const depositLpTokenWithCheckAllowance = async (
    lpName: string,
    lpAddress: string,
    contractAddress: string,
    poolId: number,
    account: string,
    inputAmount: string,
    events: any
  ) => {
    const spender = contractAddress;
    const amount = BigNumber(inputAmount);
    if (ethereum.contracts !== null) {
      const allowance = await getAllowance(lpName, account, spender, lpAddress);
      if (allowance && BigNumber(allowance).isLessThan(amount)) {
        await approveToken(lpAddress, lpAddress, account, {
          onTransactionHash: (txHash: any) => {},
          onReceipt: ({
            transactionHash,
            status,
          }: {
            transactionHash: any;
            status: any;
          }) => {},
          onError: (_: any, receipt: any) => {},
        });
      }

      await depositLpToken(contractAddress, poolId, account, amount, events);
    }
  };

  return (
    <FarmContext.Provider
      value={{
        farmContract,
        farmEnv,
        tokenContract,
        isReadyForGetFarmInfo: isReadyForGetFarmInfo,
        depositLpToken,
        withdrawLpToken,
        claimLpToken,
        getFarmInfo,
        getTotalSupplyExternalApi,
        getBalanceOf,
        getPendingReward,
        isApprovedAllowance,
        getUserInfo,
        depositLpTokenWithCheckAllowance,
        // Add other methods to context as necessary
      }}
    >
      {children}
    </FarmContext.Provider>
  );
};

import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Menu } from "../../interfaces/menu";

interface Props {
  className?: string;
  menus: Menu[];
  onEmit: (isOpen: boolean) => void;
  isOpenMenu: boolean;
}

const MenuBar: React.FC<Props> = ({ menus, onEmit, isOpenMenu }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [currentRoute, setCurrentRoute] = useState<string>(location.pathname);

  useEffect(() => {
    setCurrentRoute(location.pathname);
  }, [location]);

  const isActiveLink = (activePath: string[]) => {
    return activePath.includes(currentRoute);
  };

  return (
    <div className="fixed bottom-0 z-10 inset-x-0">
      <ul className="grid grid-cols-4 h-[67px] menu-bar bg-[#091520] border border-[#19506F]">
        {menus.map((menu, menuId) =>
          menu.menu ? (
            <li
              key={menuId}
              className="flex flex-col pt-2 cursor-pointer items-center"
              onClick={() => navigate(menu.path ?? "/")}
            >
              <img
                className="w-[32px] h-[32px]"
                src={require(`../../assets/icons/sidebar/${menu.icon}`)}
                alt={menu.title}
              />
              <span
                className={`text-menu-bar text-center ${
                  isActiveLink(menu.activePath ?? []) ? "text-[#2EADD4]" : ""
                }`}
              >
                {menu.title}
              </span>
              <div
                className={`h-[2px] w-11 mt-1 ${
                  isActiveLink(menu.activePath ?? [])
                    ? "bg-[#2EADD4]"
                    : "bg-transparent"
                }`}
              />
            </li>
          ) : null
        )}

        <li
          className="flex flex-col pt-2 cursor-pointer items-center"
          onClick={() => onEmit(!isOpenMenu)}
        >
          <div className="flex flex-col justify-center border-b-2 border-transparent">
            <img
              className="w-[32px] h-[32px] m-auto"
              src={
                require("../../assets/icons/sidebar/burger-icon.svg").default
              }
            />
            <span className="text-menu-bar text-center">Menu</span>
            <div className="h-[2px] w-11 mt-1" />
          </div>
        </li>
      </ul>
    </div>
  );
};

export default MenuBar;

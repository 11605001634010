import React, { useMemo } from "react";
// import chainsList from "./chainsList";
import Icon from "../icon/index";
import { useWallet } from "../../contexts/walletContext";
import { useEthereum } from "../../contexts/etherruemContext";
import { shortenAddress } from "../../utils/formatter";
import config from "../../config";
import { useUnifiedWallet } from "../../providers/UnifiedWalletProvider";

interface Props {
  onClickWallet: () => void;
}

const chains = config.networks;
const chainSolana = config.networkSol;

const WalletConnectButton: React.FC<Props> = ({ onClickWallet }) => {
  const wallet = useWallet();
  const ethereum = useEthereum();
  const { walletApi, chain } = useUnifiedWallet();

  const chainsList = useMemo(() => {
    return {
      ...chains,
      [chainSolana.chainId]: chainSolana,
    };
  }, [chains, chainSolana]);

  const isConnectWallet = useMemo(() => {
    return walletApi.isConnected;
  }, [walletApi.isConnected]);

  const walletAddress = useMemo(() => {
    if (walletApi.isConnected) {
      return shortenAddress(walletApi.account.current);
    }
    return "Connect Wallet";
  }, [walletApi.isConnected, walletApi.account.current]);

  const isShowWrongNetWork = useMemo(() => {
    return !(chainsList as any)[chain.chainId];
  }, [chainsList, chain]);

  // const isShowWrongNetWork = () => {
  //   return false;
  // };

  // const getCurrentChain = () => {
  //   return wallet.state.currentChain;
  // };

  // const getWalletAddress = () => {
  //   return walletApi.account.current
  //     ? shortenAddress(walletApi.account.current)
  //     : "Connect Wallet";
  // };

  const buttonClass = useMemo(() => {
    return isShowWrongNetWork && ethereum.isConnected
      ? "bg-evry-yellow"
      : "bg-gradient-to-tr from-velo-orange to-velo-pink";
  }, []);

  return (
    <div
      className={`h-8 w-[160px] rounded-[2px] p-px cursor-pointer transition-all ${buttonClass}`}
      onClick={onClickWallet}
    >
      <div className="px-0 bg-velo-bg rounded-[2px] h-full w-full">
        {!isConnectWallet ? (
          <div className="px-0 w-full h-full flex flex-nowrap items-center justify-center">
            <p className="block text-sm text-white">Connect Wallet</p>
          </div>
        ) : isShowWrongNetWork ? (
          <div className="min-w-[36px] w-full h-full flex flex-nowrap justify-center items-center">
            {/* <Icon round className="text-2xl mr-2 text-evry-yellow">
              info
            </Icon> */}
            <p
              className={`font-semibold block text-sm ${
                isConnectWallet ? "text-white" : "text-evry-primary"
              }`}
            >
              {walletAddress}
            </p>
          </div>
        ) : (
          <div className="min-w-[36px] w-full h-full flex flex-nowrap justify-center items-center">
            <img
              className="w-6 mr-2"
              src={config.networks[config.chainId]?.image || ""}
              alt="wallet-icon"
            />
            <p className="font-semibold block text-sm text-white">
              {walletAddress}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default WalletConnectButton;

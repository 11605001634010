import {
  FarmToken,
  Token,
  Pair,
  FixAPRToken,
  NewPair,
  LiquidityPool,
  EToken,
} from "./interfaces/token";

const token: Record<string, Token> = {
  XLM_TOKEN: {
    code: "XLM_TOKEN",
    name: "XLM",
    symbol: "XLM",
    // image: process.env.REACT_APP_XLM_IMG, // image name link to ~/assets
    image: require("/src/assets/icons/token/xlm.svg").default,
    address: process.env.REACT_APP_XLM_TOKEN,
  },
  ETH_TOKEN: {
    code: "ETH_TOKEN",
    name: "ETH",
    symbol: "ETH",
    image: process.env.REACT_APP_ETH_IMG, // image name link to ~/assets
    address: process.env.REACT_APP_ETH_TOKEN,
  },
  EVRY_TOKEN: {
    code: "EVRY_TOKEN",
    name: "EVRY",
    symbol: "EVRY",
    image: require("/src/assets/icons/token/evry.svg").default,
    address: process.env.REACT_APP_EVRY_TOKEN,
  },
  BNB_TOKEN: {
    code: "BNB_TOKEN",
    name: "BNB",
    symbol: "BNB",

    image: require("/src/assets/icons/token/bnb.svg").default,
    address: process.env.REACT_APP_BNB_TOKEN,
  },
  BUSD_TOKEN: {
    code: "BUSD_TOKEN",
    name: "BUSD",
    symbol: "BUSD",
    image: require("/src/assets/icons/token/busd.svg").default,
    address: process.env.REACT_APP_BUSD_TOKEN,
  },
  USDT_TOKEN: {
    code: "USDT_TOKEN",
    name: "USDT",
    symbol: "USDT",
    image: require("/src/assets/icons/token/usdt.svg").default,
    address: process.env.REACT_APP_USDT_TOKEN,
  },
  USDC_TOKEN: {
    code: "USDC_TOKEN",
    name: "USDC",
    symbol: "USDC",
    image: "",
    // image: process.env.REACT_APP_USDC_IMG, // image name link to ~/assets
    address: process.env.REACT_APP_USDC_TOKEN,
  },
  VELO_TOKEN: {
    code: "VELO_TOKEN",
    name: "VELO",
    symbol: "VELO",
    image: require("/src/assets/icons/token/velo.svg").default,
    address: process.env.REACT_APP_VELO_TOKEN,
    // address: "0xd190454201DC1EeCb3b75b4B540c03bc2bE1C80A",
  },
  VVI1_TOKEN: {
    code: "VVI1_TOKEN",
    name: "VVI1",
    symbol: "VVI1",
    image: "",
    address: process.env.REACT_APP_VVI1_TOKEN,
  },
  VELO_DEPRECATED_TOKEN: {
    code: "VELO_DEPRECATED_TOKEN",
    name: "VELO",
    symbol: "VELO_DEPRECATED",
    image: require("/src/assets/icons/token/velo.svg").default,
    address: process.env.REACT_APP_VELO_DEPRECATED_TOKEN,
    decimals: 5,
  },
  GGC1_TOKEN: {
    code: "GGC1_TOKEN",
    name: "GGC1",
    symbol: "GGC1",
    image: process.env.REACT_APP_GGC1_IMG,
    address: process.env.REACT_APP_GGC1_TOKEN,
  },
  KSW_TOKEN: {
    code: "KSW_TOKEN",
    name: "KSW",
    symbol: "KSW",
    image: require("/src/assets/icons/token/ksw.svg").default,
    address: process.env.REACT_APP_KSW_TOKEN,
  },
  EVHY_TOKEN: {
    code: "EVHY_TOKEN",
    name: "EVHY",
    symbol: "EVHY",
    image: process.env.REACT_APP_EVHY_IMG,
    address: process.env.REACT_APP_EVHY_TOKEN,
  },
  SEVHY_TOKEN: {
    code: "SEVHY_TOKEN",
    name: "sEVHY",
    symbol: "sEVHY",
    image: process.env.REACT_APP_SEVHY_IMG, // image name link to ~/assets
    address: process.env.REACT_APP_SEVHY_TOKEN,
  },
  EVUSD_TOKEN: {
    code: "EVUSD_TOKEN",
    name: "evUSD",
    symbol: "evUSD",
    image: process.env.REACT_APP_EVUSD_IMG,
    address: process.env.REACT_APP_EVUSD_TOKEN,
  },
  USDV_TOKEN: {
    code: "USDV_TOKEN",
    name: "O-USDV",
    symbol: "USDV",
    image: require("/src/assets/icons/token/usdv.svg").default,
    address: process.env.REACT_APP_O_USDV_TOKEN,
  },
  MAIN_USDV_TOKEN: {
    code: "MAIN_USDV_TOKEN",
    name: "USDV",
    symbol: "USDV",
    image: require("/src/assets/icons/token/usdv.svg").default,
    address: process.env.REACT_APP_USDV_TOKEN,
  },
  BTC_TOKEN: {
    code: "BTC_TOKEN",
    name: "BTC",
    symbol: "BTC",
    image: process.env.REACT_APP_BTC_IMG,
    address: process.env.REACT_APP_BTC_TOKEN,
  },
  PLN_TOKEN: {
    code: "PLN_TOKEN",
    name: "PLN",
    symbol: "PLN",
    image: require("/src/assets/icons/token/pln.svg").default,
    address: process.env.REACT_APP_PLN_TOKEN,
  },
  OMNI_TOKEN: {
    code: "OMNI_TOKEN",
    name: "OMNI",
    symbol: "OMNI",
    image: require("/src/assets/icons/token/omni.svg").default,
    address: process.env.REACT_APP_OMNI_TOKEN,
  },
};

const solTokens: Record<string, Token> = {
  EVRY_TOKEN: {
    code: "EVRY_TOKEN",
    name: "solEVRY",
    symbol: "solEVRY",
    image: process.env.REACT_APP_EVRY_IMG, // image name link to ~/assets
    address: process.env.REACT_APP_EVRY_SOL_TOKEN,
  },
  VELO_TOKEN: {
    code: "VELO_TOKEN",
    name: "solVELO",
    symbol: "solVELO",
    image: process.env.REACT_APP_VELO_IMG, // image name link to ~/assets
    address: process.env.REACT_APP_VELO_SOL_TOKEN,
  },
  KSW_TOKEN: {
    code: "KSW_TOKEN",
    name: "solKSW",
    symbol: "solKSW",
    image: process.env.REACT_APP_KSW_IMG, // image name link to ~/assets
    address: process.env.REACT_APP_KSW_SOL_TOKEN,
  },
};

const farmToken: Record<string, FarmToken> = {
  XLM_TOKEN: {
    ...token.XLM_TOKEN,
    priceRoute: ["BNB", "BUSD"],
  },
  EVRY_TOKEN: {
    ...token.EVRY_TOKEN,
    priceRoute: ["BNB", "BUSD"],
  },
  BNB_TOKEN: {
    ...token.BNB_TOKEN,
    priceRoute: ["BUSD"],
  },
  BUSD_TOKEN: {
    ...token.BUSD_TOKEN,
    priceRoute: [],
  },
  USDT_TOKEN: {
    ...token.USDT_TOKEN,
    priceRoute: ["BUSD"],
  },
  VELO_TOKEN: {
    ...token.VELO_TOKEN,
    priceRoute: [],
    externalPrice: true,
    slug: "velo",
  },
  KSW_TOKEN: {
    ...token.KSW_TOKEN,
    priceRoute: ["USDT"],
  },
  USDV_TOKEN: {
    ...token.USDV_TOKEN,
    priceRoute: ["USDV"],
  },
  VELO_DEPRECATED_TOKEN: {
    ...token.VELO_DEPRECATED_TOKEN,
    priceRoute: [],
    externalPrice: true,
    slug: "velo",
  },
};

const newFarmToken: Record<string, FarmToken> = {
  ...farmToken,
  VELO_TOKEN: {
    ...token.VELO_TOKEN,
    priceRoute: ["BNB", "BUSD"],
  },
  OMNI_TOKEN: {
    ...token.OMNI_TOKEN,
    priceRoute: ["BNB", "BUSD"],
  },
};

const fixAPRLockToken: Record<string, FixAPRToken> = {
  VELO_TOKEN: {
    ...token.VELO_TOKEN,
    externalPrice: true,
    slug: "velo",
  },
  // VVI1_TOKEN: {
  //   ...token.VVI1_TOKEN,
  //   fixedPrice: 0.1,
  // },
  // USDT_TOKEN: {
  //   ...token.USDT_TOKEN,
  //   fixedPrice: 1,
  // },
};

const fixAPRLockTokenChangeRatio: Record<string, FixAPRToken> = {
  USDV_TOKEN: {
    ...token.USDV_TOKEN,
    fixedPrice: 1,
  },
  VELO_TOKEN: {
    ...token.VELO_TOKEN,
    externalPrice: true,
    slug: "velo",
  },
};

const fixAPRToken: Record<string, FixAPRToken> = {
  USDV_TOKEN: {
    ...token.USDV_TOKEN,
    fixedPrice: 1,
  },
  VELO_TOKEN: {
    ...token.VELO_TOKEN,
    fixedPrice: 0.075,
    externalPrice: true,
    slug: "velo",
  },
};

const earnOtherToken: Record<string, FixAPRToken> = {
  VELO_TOKEN: {
    ...token.VELO_TOKEN,
    externalPrice: true,
    slug: "velo",
  },
  GGC1_TOKEN: {
    ...token.GGC1_TOKEN,
    fixedPrice: 0.1,
  },
};

const IDOToken = {
  USDT_TOKEN: {
    ...token.USDT_TOKEN,
  },
  GGC1_TOKEN: {
    ...token.GGC1_TOKEN,
  },
};

const bondToken = {
  BUSD_TOKEN: token.BUSD_TOKEN,
  EVUSD_TOKEN: token.EVUSD_TOKEN,
};

const stakeToken = {
  EVHY_TOKEN: token.EVHY_TOKEN,
  SEVHY_TOKEN: token.SEVHY_TOKEN,
};

const lp: Record<string, LiquidityPool> = {
  //TODO : need to change config for add in real production
  OMNI_VELO: {
    code: "OMNI_VELO",
    sortOrder: 1,
    address: process.env.REACT_APP_OMNI_VELO,
    name: "OMNI/VELO",
    image: require("/src/assets/icons/token/omni.svg").default,
    // image: process.env.REACT_APP_VELO_BNB_IMG,
    token0: token.OMNI_TOKEN,
    token1: token.VELO_TOKEN,
    lpType: "AMM",
    isExpired: false,
  },
  VELO_BNB: {
    code: "VELO_BNB",
    sortOrder: 1,
    address: process.env.REACT_APP_VELO_BNB,
    name: "VELO/BNB",
    image: require("/src/assets/icons/token/velo-bnb.svg").default,
    // image: process.env.REACT_APP_VELO_BNB_IMG,
    token0: token.VELO_TOKEN,
    token1: token.BNB_TOKEN,
    lpType: "AMM",
    isExpired: false,
  },
  VELO_USDV: {
    code: "VELO_USDV",
    sortOrder: 2,
    address: process.env.REACT_APP_VELO_USDV,
    name: "VELO/O-USDV",
    image: require("/src/assets/icons/token/velo-usdv.svg").default,
    token0: token.VELO_TOKEN,
    token1: token.USDV_TOKEN,
    lpType: "AMM",
    isExpired: false,
  },
  BNB_BUSD: {
    code: "BNB_BUSD",
    sortOrder: 3,
    address: process.env.REACT_APP_BNB_BUSD, // Get contract address from back-end team
    name: "BNB/BUSD",
    image: require("/src/assets/icons/token/bnb-busd.svg").default,
    token0: token.BNB_TOKEN,
    token1: token.BUSD_TOKEN,
    lpType: "AMM",
    isExpired: false,
  },
  KSW_USDT: {
    code: "KSW_USDT",
    sortOrder: 4,
    address: process.env.REACT_APP_KSW_USDT,
    name: "KSW/USDT",
    image: require("/src/assets/icons/token/ksw-usdt.svg").default,
    token0: token.KSW_TOKEN,
    token1: token.USDT_TOKEN,
    lpType: "AMM",
    isExpired: false,
  },
  // BNB_XLM: {
  //   code: "BNB_XLM",
  //   sortOrder: 6,
  //   address: process.env.REACT_APP_BNB_XLM,
  //   name: "BNB/XLM",
  //   image: require("/src/assets/icons/token/bnb-xlm.svg").default,
  //   token0: token.BNB_TOKEN,
  //   token1: token.XLM_TOKEN,
  //   lpType: "AMM",
  //   isExpired: true,
  // },
  // EVRY_BNB: {
  //   code: "EVRY_BNB",
  //   sortOrder: 7,
  //   address: process.env.REACT_APP_EVRY_BNB,
  //   name: "EVRY/BNB",
  //   image: require("/src/assets/icons/token/evry-bnb.svg").default,
  //   token0: token.EVRY_TOKEN,
  //   token1: token.BNB_TOKEN,
  //   lpType: "AMM",
  //   isExpired: true,
  // },
  VELO_PLN: {
    code: "VELO_PLN",
    sortOrder: 6,
    address: process.env.REACT_APP_VELO_PLN,
    name: "VELO/PLN",
    image: require("/src/assets/icons/token/velo-pln.svg").default,
    token0: token.VELO_TOKEN,
    token1: token.PLN_TOKEN,
    lpType: "AMM",
    isExpired: false,
  },
  VELO_MAIN_USDV: {
    code: "VELO_MAIN_USDV",
    sortOrder: 2,
    address: process.env.REACT_APP_VELO_MAIN_USDV,
    name: "VELO/USDV",
    image: require("/src/assets/icons/token/velo-usdv.svg").default,
    token0: token.VELO_TOKEN,
    token1: token.MAIN_USDV_TOKEN,
    lpType: "AMM",
    isExpired: false,
  },
  VELO_MAIN_USDV_OMNI: {
    code: "VELO_MAIN_USDV_OMNI",
    sortOrder: 2,
    address: process.env.REACT_APP_VELO_USDV_OMNI_POOL,
    name: "VELO/USDV",
    image: require("/src/assets/icons/token/velo-usdv.svg").default,
    token0: token.VELO_TOKEN,
    token1: token.MAIN_USDV_TOKEN,
    lpType: "AMM",
    isExpired: false,
  },
  // EVHY_BUSD: {
  //   code: 'EVHY_BUSD',
  //   sortOrder: null,
  //   address: process.env.REACT_APP_EVHY_BUSD,
  //   name: 'EVHY/BUSD',
  //   image: process.env.REACT_APP_EVHY_BUSD_IMG,
  //   token0: token.EVHY_TOKEN,
  //   token1: token.BUSD_TOKEN,
  //   lpType: 'AMM',
  //   isHidden: true,
  // },
};

const dmmLP: Record<string, LiquidityPool> = {
  USDT_BUSD: {
    isHidden: false,
    code: "USDT_BUSD",
    sortOrder: 5,
    address: process.env.REACT_APP_USDT_BUSD, // Get contract address from back-end team
    name: "USDT/BUSD",
    image: require("/src/assets/icons/token/usdt-busd.svg").default,
    token0: token.USDT_TOKEN,
    token1: token.BUSD_TOKEN,
    lpType: "DMM",
    isExpired: false,
  },
};

const pairs: Pair[] = [
  {
    id: "EVRY_TOKEN",
    staking: farmToken.EVRY_TOKEN,
    poolID: 0,
    lpType: null,
    expired: true,
  },
  // {
  //   id: "EVRY_BNB",
  //   staking: lp.EVRY_BNB,
  //   poolID: 1,
  //   lpType: lp.EVRY_BNB.lpType,
  //   expired: true,
  // },
  {
    id: "BNB_BUSD",
    staking: lp.BNB_BUSD,
    poolID: 2,
    lpType: lp.BNB_BUSD.lpType,
    expired: true,
  },
  // {
  //   id: "BNB_XLM",
  //   staking: lp.BNB_XLM,
  //   poolID: 3,
  //   lpType: lp.BNB_XLM.lpType,
  //   expired: true,
  // },
  {
    id: "USDT_BUSD",
    staking: dmmLP.USDT_BUSD,
    poolID: 4,
    lpType: dmmLP.USDT_BUSD.lpType,
    expired: true,
  },
  {
    id: "KSW_USDT",
    staking: lp.KSW_USDT,
    poolID: 7,
    lpType: lp.BNB_BUSD.lpType,
    expired: true,
  },
  {
    id: "VELO_TOKEN",
    staking: farmToken.VELO_TOKEN,
    poolID: 6,
    lpType: null,
    expired: true,
  },
  {
    id: "VELO_DEPRECATED_TOKEN",
    staking: farmToken.VELO_DEPRECATED_TOKEN,
    poolID: 5,
    lpType: null,
    expired: true,
  },
];

const newPairs: NewPair[] = [
  {
    id: "BNB_BUSD",
    staking: lp.BNB_BUSD,
    poolID: 0,
    lpType: lp.BNB_BUSD.lpType,
    recommendedOrder: 2,
  },
  {
    id: "VELO_USDV",
    staking: lp.VELO_USDV,
    poolID: 1,
    lpType: lp.VELO_USDV.lpType,
    recommendedOrder: 3,
  },
  {
    id: "VELO_BNB",
    staking: lp.VELO_BNB,
    poolID: 2,
    lpType: lp.VELO_BNB.lpType,
  },
  {
    id: "USDT_BUSD",
    staking: dmmLP.USDT_BUSD,
    poolID: 3,
    lpType: dmmLP.USDT_BUSD.lpType,
  },
  {
    id: "KSW_USDT",
    staking: lp.KSW_USDT,
    poolID: 4,
    lpType: lp.BNB_BUSD.lpType,
  },
  {
    id: "VELO_TOKEN",
    staking: newFarmToken.VELO_TOKEN,
    poolID: 5,
    lpType: null,
    recommendedOrder: 1,
  },
  // {
  //   id: "VELO_MAIN_USDV",
  //   staking: lp.VELO_MAIN_USDV,
  //   poolID: 5,
  //   lpType: null,
  //   recommendedOrder: 1,
  // },
];

const newPairOmniRewards: NewPair[] = [
  {
    id: "VELO_MAIN_USDV_OMNI",
    staking: lp.VELO_MAIN_USDV_OMNI,
    poolID: 1,
    lpType: lp.VELO_MAIN_USDV_OMNI.lpType,
    recommendedOrder: 1,
  },
];

const bridgeTokens: Record<string, Record<string, Token>> = {
  bnb: {
    // EVRY_TOKEN: token.EVRY_TOKEN,
    // VELO_TOKEN: token.VELO_TOKEN,
    KSW_TOKEN: token.KSW_TOKEN,
  },
  sol: {
    // EVRY_TOKEN: {
    //   code: 'EVRY_TOKEN',
    //   name: 'EVRY',
    //   symbol: 'EVRY',
    //   image: process.env.REACT_APP_EVRY_IMG, // image name link to ~/assets
    //   address: process.env.REACT_APP_EVRY_SOL_TOKEN,
    // },
    // VELO_TOKEN: {
    //   code: 'VELO_TOKEN',
    //   name: 'VELO',
    //   symbol: 'VELO',
    //   image: process.env.REACT_APP_VELO_IMG,
    //   address: process.env.REACT_APP_VELO_SOL_TOKEN,
    // },
    KSW_TOKEN: {
      code: "KSW_TOKEN",
      name: "KSW",
      symbol: "KSW",
      image: process.env.REACT_APP_KSW_IMG,
      address: process.env.REACT_APP_KSW_SOL_TOKEN,
    },
  },
};

// const bridgeTokens = {
//   EVRY_TOKEN: {
//     bnb: {
//       code: 'EVRY_TOKEN',
//       name: 'EVRY',
//       symbol: 'EVRY',
//       image: process.env.REACT_APP_EVRY_IMG, // image name link to ~/assets
//       address: process.env.REACT_APP_EVRY_TOKEN,
//       // address: '0xf2C73c816AB2C09dD7Be8938c382Aed82aa7794A',
//       // address: '0x03e67a6469c25bdcc3af37a4c4676c2ac6047aed',
//     },
//     sol: {
//       code: 'SOLEVRY_TOKEN',
//       name: 'bEVRY',
//       symbol: 'bEVRY',
//       image: process.env.REACT_APP_EVRY_IMG, // image name link to ~/assets
//       address: process.env.REACT_APP_EVRY_SOL_TOKEN,
//       // address: '68kPEnEh6oLQqXX78uebkTPjbbfGCs9B6jifH2MJfXiv',
//       // address: '5HkhZTpskBepEngDVXQLBEhkix2phRtFw5gj3yybgwyc',
//     },
//   },
// }

const loansDepositTokens = [token.BUSD_TOKEN];
const loansRepayTokens = [token.BUSD_TOKEN, token.EVUSD_TOKEN];
const swapTokens: Token[] = [
  token.VELO_TOKEN,
  token.BNB_TOKEN,
  token.BUSD_TOKEN,
  token.USDV_TOKEN,
  token.USDT_TOKEN,
  token.KSW_TOKEN,
  token.PLN_TOKEN,
];

const eTokenList: Record<string, EToken> = {
  BNB_TOKEN: {
    ...token.BNB_TOKEN,
    eToken: "eBNB",
    eAddress: process.env.REACT_APP_EBNB_ADDRESS,
  },
  BTC_TOKEN: {
    ...token.BTC_TOKEN,
    eToken: "eBTC",
    eAddress: process.env.REACT_APP_EBTC_ADDRESS,
  },
  BUSD_TOKEN: {
    ...token.BUSD_TOKEN,
    eToken: "eBUSD",
    eAddress: process.env.REACT_APP_EBUSD_ADDRESS,
  },
  ETH_TOKEN: {
    ...token.ETH_TOKEN,
    eToken: "eETH",
    eAddress: process.env.REACT_APP_EETH_ADDRESS,
  },
  USDC_TOKEN: {
    ...token.USDC_TOKEN,
    eToken: "eUSDC",
    eAddress: process.env.REACT_APP_EUSDC_ADDRESS,
  },
  USDT_TOKEN: {
    ...token.USDT_TOKEN,
    eToken: "eUSDT",
    eAddress: process.env.REACT_APP_EUSDT_ADDRESS,
  },
  USDV_TOKEN: {
    ...token.USDV_TOKEN,
    eToken: "eUSDV",
    eAddress: process.env.REACT_APP_EUSDV_ADDRESS,
  },
  PLN_TOKEN: {
    ...token.PLN_TOKEN,
    eToken: "ePLN",
    eAddress: process.env.REACT_APP_EPLN_ADDRESS,
  },
};

const eTokenBorrowList: Record<string, EToken> = {
  USDV_TOKEN: {
    ...token.USDV_TOKEN,
    eToken: "eUSDV",
    eAddress: process.env.REACT_APP_EUSDV_ADDRESS,
  },
};

const oracleTokens: Record<string, Token> = {
  BTC_TOKEN: {
    ...token.BTC_TOKEN,
    decimals: parseFloat(process.env.REACT_APP_BTC_PRICE_FEED_DECIMALS || "8"),
  },
};

// ETH
const tokenETH = {
  VELO_TOKEN: {
    ...token.VELO_TOKEN,
    address: process.env.REACT_APP_VELO_TOKEN_ETH,
  },
  ETH_VELO_TOKEN: {
    code: "ETH_VELO_TOKEN",
    name: "ETH/VELO",
    symbol: "ETH_VELO",
    image: "/token/sevhy.svg",
    address: "0xFf535c82472322E0ccb9Af4E92b1B5dc01a9a4e9",
  },
};
// TODO: change config afer got requirement
const farmTokenETH = {
  VELO_TOKEN: {
    ...tokenETH.VELO_TOKEN,
    fixedPrice: 0.1,
  },
  ETH_VELO_TOKEN: {
    ...tokenETH.ETH_VELO_TOKEN,
    fixedPrice: 0.2,
  },
};
// TODO: change config afer got requirement
const pairsETH = [
  // TODO: remove after test
  {
    id: "ETH_VELO_TOKEN",
    staking: farmTokenETH.ETH_VELO_TOKEN,
    poolID: 0,
    lpType: null,
  },
  {
    id: "VELO_TOKEN",
    staking: farmTokenETH.VELO_TOKEN,
    poolID: 1,
    lpType: null,
  },
];

export {
  token,
  solTokens,
  farmToken,
  newFarmToken,
  fixAPRLockToken,
  fixAPRLockTokenChangeRatio,
  fixAPRToken,
  earnOtherToken,
  IDOToken,
  bondToken,
  stakeToken,
  lp,
  dmmLP,
  pairs,
  newPairs,
  newPairOmniRewards,
  bridgeTokens,
  loansDepositTokens,
  loansRepayTokens,
  swapTokens,
  eTokenList,
  eTokenBorrowList,
  oracleTokens,
  tokenETH,
};
